import React, { ReactElement } from 'react';
import { useReferralPrivateOfficeState } from 'context/referralPrivateOfficeState';
import { Grid, TableBody, TableRow } from '@mui/material';
import { isMobile } from 'react-device-detect';
// styles
import {
  StyledPayoutTableHeader as TH,
  StyledTableCell as TC,
  StyledRow as TR,
  StatusBlock,
} from 'styles/mobile/StyledReferralPrivateOffice';
import {
  StyledTableContent,
} from 'styles/desktop/StyledReferralPrivateOffice';
import {
  StyledTableContainer,
} from 'styles/desktop/StyledClosedPools';
import {
  IconImage,
} from 'styles/common/StyledPoolBlock';
import BNB from 'static/images/currency_icons/BNB_icon.svg';

const PayoutTableContent: React.FC = (): ReactElement => {
  const { referralPrivateOfficeState } = useReferralPrivateOfficeState();

  const shortWallet = (wallet: string): string => `${wallet.slice(0, 7)}...${wallet.slice(-4)}`;

  const calculatedAmount = (amount: number): string => {
    if (amount) {
      if (amount >= 0.01) {
        const fractionDigits = 2;
        const multiplier = 10 ** fractionDigits;
        const fixedNumber = Math.floor(amount * multiplier);
        const numberToReturn = fixedNumber / multiplier;
        return numberToReturn.toString();
      }
      return '< 0.01';
    }
    return '0';
  };

  return (
    <StyledTableContainer>
      <StyledTableContent>
        <TH>
          <TableRow>
            <TC>Date</TC>
            <TC align="right">Amount</TC>
          </TableRow>
        </TH>
        <TableBody>
          {
            referralPrivateOfficeState.payouts.slice(0, referralPrivateOfficeState.tablePage * 7)
              .map((payout) => (
                <TR key={payout.id}>
                  <TC align="left">
                    {payout.date || '-'}
                  </TC>
                  <TC align="right">
                    <Grid container direction="column" alignItems="center">
                      <Grid item container alignItems="center" justifyContent="center">
                        <IconImage
                          alt="icon"
                          height={isMobile ? 16 : 18}
                          src={
                          BNB
                        }
                        />
                        {calculatedAmount(payout.amount)}
                      </Grid>
                      <StatusBlock paid={payout.status}>
                        {payout.status ? 'Paid' : 'Unpaid'}
                      </StatusBlock>
                    </Grid>
                  </TC>
                </TR>
              ))
          }
        </TableBody>
      </StyledTableContent>
    </StyledTableContainer>
  );
};

export default PayoutTableContent;
