import React from 'react';

interface IProps {
    colour : string,
    pct : number,
    strokeWidth : string
}
const Circle = (props : IProps) => {
  const { colour, pct, strokeWidth } = props;
  const r = 70;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? colour : 'yellow'} // remove colour as 0% sets full circumference
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    />
  );
};
interface IProp {
    percentage : number;
}

const Text = ({ percentage } : { percentage: number}) => (
  <div
    color="white"
    style={{
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
    }}
  >
    <span>
      {percentage.toFixed(2)}
      %
    </span>
  </div>
);

const ProgressCircle = ({ percentage } : {percentage : number}) => {
  const pct = percentage; return (
    <div style={{
      position: 'relative', display: 'flex', justifyContent: 'center', alignContent: 'center',
    }}
    >
      <svg width={200} height={200}>
        <g transform={`rotate(-90 ${'100 100'})`}>
          <Circle strokeWidth="1rem" colour="yellow" pct={0} />
          <Circle strokeWidth="1.1rem" colour="#5900F7" pct={pct} />
        </g>
      </svg>
      <Text percentage={pct} />
    </div>
  );
};

export default ProgressCircle;
