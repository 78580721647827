import styled from 'styled-components';
import {
  Button,
} from '@mui/material';

const StyledTwitterButton = styled(Button)`
  &.MuiButton-root{
    width: 100%;
    background: linear-gradient(180deg, #008FE7 0%, #005589 100%);
    border-radius: 12px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.04em;
    height: 43px;
    margin: 16px 0;
  }
`;

export default StyledTwitterButton;
