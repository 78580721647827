import React, { createContext, useContext, useReducer } from 'react';
import {
  IStatistics,
  IStatisticsRounds,
  Ipnl, IStatStatistics,
} from 'interfaces/statistics.d';

type ActionType = 'SWITCH_STATE'
  | 'SET_STATISTICS'
  | 'SET_TOKEN'
  | 'SET_TOKEN_COST';
type PayloadType = IStatistics;
interface IAction {
  type: ActionType;
  payload: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const StatisticsContext = createContext<{statisticsState: IStatistics;
  statisticsDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (statisticsState: IStatistics, action: IAction): IStatistics => {
  switch (action.type) {
    case 'SWITCH_STATE':
      return {
        ...statisticsState,
        state: action.payload.state,
      };
    case 'SET_STATISTICS':
      return {
        ...statisticsState,
        rounds: action.payload.rounds,
        pnl: action.payload.pnl,
      };
    case 'SET_TOKEN':
      return {
        ...statisticsState,
        token: action.payload.token,
      };
    case 'SET_TOKEN_COST':
      return {
        ...statisticsState,
        tokenCost: action.payload.tokenCost,
      };
    default:
      return initialState;
  }
};

const initialRoundsState: IStatisticsRounds = {
  wonRounds: 0,
  wonMoney: 0,
  lostRounds: 0,
  lostMoney: 0,
  overallMoney: 0,
};

const initialPnlState: Ipnl = {
  averageReturn: 0,
  bestRoundId: 1,
  bestRoundProfit: 0,
  averagePosition: 0,
  netResults: 0,
};

const initialState: IStatistics = {
  state: 0,
  token: '',
  tokenCost: 0,
  rounds: initialRoundsState,
  pnl: initialPnlState,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [statisticsState, statisticsDispatch] = useReducer(stateReducer, initialState);

  return (
    <StatisticsContext.Provider value={{ statisticsState, statisticsDispatch }}>
      { children }
    </StatisticsContext.Provider>
  );
};

export const useStatisticsState = () => {
  const context = useContext(StatisticsContext);

  if (!context) throw new Error('useStatisticsState must be used in a statistics state provider');

  return context;
};

export default StateProvider;
