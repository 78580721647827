import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Fade,
  Backdrop,
  Grid,
} from '@mui/material';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
import {
  getChainBlockExplorerUrls, getChainGuideUrl,
  getChainName,
  getChainRpcUrls,
  getCurrencyName,
} from 'networksHelper';
// styles
import {
  ModalContainer,
  StyledPaper,
  Title,
  Content,
  CloseButton,
} from 'styles/modals/StyledMobileWrongNetworkModal';

const WrongNetworkModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);

  const { modalsState, modalDispatch } = useModalState();
  const { state } = useGlobalState();
  const { t } = useTranslation(['modals']);

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_WRONG_NETWORK_MODAL' });
  };

  const addChainAndRequestChange = useCallback(async () => {
    if (state.networkProvider && state.currentNetworkId) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: `0x${state.currentNetworkId.toString(16)}`,
            chainName: getChainName(state.currentNetworkId),
            nativeCurrency: {
              name: getCurrencyName(state.currentNetworkId),
              symbol: getCurrencyName(state.currentNetworkId),
              decimals: 18,
            },
            rpcUrls: getChainRpcUrls(state.currentNetworkId),
            blockExplorerUrls: getChainBlockExplorerUrls(state.currentNetworkId),
          }],
        });
      } catch (e) {
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${state.currentNetworkId.toString(16)}` }],
        });
      }
    }
  }, [state.currentNetworkId, state.networkProvider]);

  useEffect(() => {
    if (modalsState.openedWrongNetworkModal) {
      addChainAndRequestChange();
    }

    setOpen(modalsState.openedWrongNetworkModal);
  }, [modalsState.openedWrongNetworkModal]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <StyledPaper>
          <CloseButton onClick={handleClose}>
            {`${t('Close')} X`}
          </CloseButton>
          <Grid container direction="column" justifyContent="space-around">
            <Grid item>
              <Title>
                {
                  t('In order to participate you should use Binance Smart Chain network',
                    {
                      networkName: getChainName(state.currentNetworkId || 56),
                    })
                }
              </Title>
            </Grid>
            <Grid item container justifyContent="center">
              <Content>
                {
                  t('If you do not know how to connect MetaMask to Binance Smart Chain network',
                    {
                      networkName: getChainName(state.currentNetworkId || 56),
                    })
                }
              </Content>
            </Grid>
            <Grid item container justifyContent="center">
              <Content>
                <a
                  href={getChainGuideUrl(state.currentNetworkId || 56)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`${t('check out this guide')}.`}
                </a>
              </Content>
            </Grid>
          </Grid>
        </StyledPaper>
      </Fade>
    </ModalContainer>
  );
};

export default WrongNetworkModal;
