import styled from 'styled-components';

export const ProgressContainer = styled.div`
  position: relative;
  transform: rotate(-90deg);
`;

export const StyledCircle = styled.circle<{progress: number}>`
  stroke-dasharray: 126.92;
  stroke-dashoffset: ${
  (props) => (126.92 - (126.92 * (props.progress / 100)))
}
`;

export const CircleData = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  font-size: 15px;
  line-height: 19px;
`;
