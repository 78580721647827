import React, { ReactElement, useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter as Router } from 'react-router-dom';
import Mobile from 'components/mobile/MobileContainer';
import Desktop from 'components/desktop/DesktopContainer';
import { ethers } from 'ethers';
import { useGlobalState } from '../context/globalState';

const App: React.FC = (): ReactElement => {
  const { dispatch, state } = useGlobalState();

  const getCurrentAccountBalance = useCallback(async (account : string) => {
    if (state.networkProvider) {
      const balance = await state.networkProvider.getBalance(account);
      return +ethers.utils.formatEther(balance);
    }
    return 0;
  }, [state.networkProvider]);

  const setAccount = async (account : string) => {
    await dispatch({
      type: 'SET_ACCOUNT',
      payload: { account },
    });
  };

  const setBalance = useCallback(async (account) => {
    await getCurrentAccountBalance(account).then(async (balance: number) => {
      await dispatch({
        type: 'SET_BALANCE',
        payload: { balance },
      });
    });
  }, [getCurrentAccountBalance]);

  useEffect(() => {
    if (state.networkProvider) {
      window.ethereum.on('accountsChanged', (accounts: Array<string>) => {
        setAccount(accounts[0]);
        setBalance(accounts[0]);
      });
    }
  }, [state.networkProvider, setAccount, setBalance]);

  return (
    <Router>
      {
          isMobile
            ? <Mobile />
            : <Desktop />
        }
    </Router>
  );
};

export default App;
