import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import axios from 'axios';
import shortMonth from 'languageHelper';
import Header from 'components/mobile/common/MenuContainer';
import {
  IPool,
  IPoolStatus,
} from 'interfaces/state.d';
import {
  IQueryParameters,
  IPersonalQueryParameters,
} from 'interfaces/common.d';
import { useGlobalState } from 'context/globalState';
import { useTableState } from 'context/tableState';
import { useCurrentBlockContext } from 'context/currentBlockContext';
import { useTranslation } from 'react-i18next';
// styles
import StyledClosedPoolsContainer, {
  ClosedPoolsTitle,
} from 'styles/mobile/StyledClosedPools';
import {
  SpinnerContainer,
} from 'styles/desktop/StyledClosedPools';
import Spinner from 'styles/common/StyledSpinner';
import Pool from 'components/common/PoolBlock';
import Switcher from './FilterSwitcher';
import Filters from './PoolsFilters';
import LoadButton from './LoadMoreButton';

type PoolSideType = 'BULL' | 'BEAR' | undefined;
type UserSideType = PoolSideType;

const ClosedPoolsContainer: React.FC = (): ReactElement => {
  const [pools, setPools] = useState<Array<IPool> | null>(null);
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [statusedPools, setStatusedPools] = useState<Array<IPool> | null>(null);
  const [readyPools, setReadyPools] = useState<Array<IPool> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const { state, dispatch } = useGlobalState();
  const { tableState, tableDispatch } = useTableState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t, i18n } = useTranslation(['closed_pools']);

  const getUserPoolStatus = (priceIsSet: boolean,
    userSide: UserSideType,
    poolSide: PoolSideType): string => {
    if (priceIsSet) {
      if (userSide === poolSide) {
        return t('youWon');
      }
      return t('youLost');
    }
    return `${t('Pending')}...`;
  };

  const getDisplayTime = useCallback((pool: IPool): string => {
    const { start } = pool;
    const diffInBlocks: number = start - currentBlock;
    const diffInSeconds: number = diffInBlocks * state.blockTime;
    const date: Date = new Date(new Date().getTime() + diffInSeconds * 1000);
    const month: string = date.toLocaleString(shortMonth(i18n.language), { month: 'short' });
    let hrs: string = date.getHours().toString();
    if (+hrs < 10) {
      hrs = `0${hrs}`;
    }
    let mins = date.getMinutes().toString();
    if (+mins < 10) {
      mins = `0${mins}`;
    }
    return `${hrs}:${mins} ${date.getDate()} ${month}`;
  }, [currentBlock, state.blockTime]);

  const loadMore = async () => {
    await dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform switch page closed pools',
        amplitudeProps: {
          page: tableState.page + 1,
        },
      },
    });
    await tableDispatch({ type: 'SET_PAGE', payload: { ...tableState, page: tableState.page + 1 } });
  };

  const getPoolSide = (isPriceSet: boolean, bullsWon: boolean): PoolSideType => {
    if (isPriceSet) {
      return bullsWon ? 'BULL' : 'BEAR';
    }
    return undefined;
  };

  const convertPoolsToShow = useCallback((poolsToConvert: Array<IPool>):
    Array<IPool> => poolsToConvert.map((pool) => {
    const { price }: {price: number} = pool;
    const volume: number = pool.bullsQuote + pool.bearsQuote;
    const finalPrice: number = pool.finalPrice || 0;
    const poolSide: PoolSideType = getPoolSide(pool.isPriceSet, pool.bullsWon);
    const time: string = getDisplayTime(pool);

    const tmpPool = { ...pool };

    tmpPool.price = price;
    tmpPool.volume = parseFloat(volume.toFixed(4));
    tmpPool.finalPrice = finalPrice;
    tmpPool.side = poolSide;
    tmpPool.statusText = t('notParticipated');
    tmpPool.time = time;

    return tmpPool;
  }), [getDisplayTime]);

  const getPools = useCallback(async (): Promise<void> => {
    const queryParameters: IQueryParameters = {
      duration: 0,
      asset: tableState.asset.pair || undefined,
      token: tableState.token.value || undefined,
      page: tableState.page,
      networkId: state.currentNetworkId,
      user: tableState.accountFilter ? state.account : undefined,
    };
    try {
      const poolsResponse = await axios.get(`${state.baseUrl}/pools`, { params: queryParameters });
      if (!poolsResponse.data.length) {
        setCanLoadMore(false);
      } else {
        setCanLoadMore(true);
      }
      const poolsToShow = convertPoolsToShow(poolsResponse.data as Array<IPool>);
      if (pools) {
        setPools(pools.concat(poolsToShow));
      } else {
        setPools(poolsToShow);
      }
    } catch (e) {
      setCanLoadMore(false);
    } finally {
      setLoading(false);
    }
  }, [state.baseUrl, state.account, state.currentNetworkId,
    tableState, convertPoolsToShow, pools]);

  const getSearchedPool = useCallback(async (poolId: number): Promise<void> => {
    try {
      const pool = await axios.get(`${state.baseUrl}/pools/${poolId}?networkId=${state.currentNetworkId}`);
      if (pool.data) {
        const poolToShow = convertPoolsToShow([pool.data as IPool]);
        setPools(poolToShow);
      } else {
        getPools();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Some error happened during pool search', e);
    }
  }, [convertPoolsToShow, state.baseUrl, state.currentNetworkId, getPools]);

  const getPersonalPools = useCallback(async (poolsToConvert: Array<IPool>,
    baseUrl: string): Promise<void> => {
    const ids: Array<number> = poolsToConvert.map((pool) => pool.id);
    const queryPersonalParams: IPersonalQueryParameters = {
      ids,
      user: state.account,
      networkId: state.currentNetworkId,
    };

    const poolStatuses = await axios.post(`${baseUrl}/users/${queryPersonalParams.user}/user-volume`, {
      ids: queryPersonalParams.ids,
      networkId: queryPersonalParams.networkId,
    });
    const statusedPoolsResponse = converPoolsToStatus(poolsToConvert,
      poolStatuses.data as Array<IPoolStatus>);
    setStatusedPools(statusedPoolsResponse);
  }, [state.account, state.currentNetworkId]);

  const getUserSide = (isBull: boolean | null): IPool['userSide'] => {
    if (typeof isBull === 'boolean') {
      return isBull ? 'BULL' : 'BEAR';
    }
    return undefined;
  };

  const getPoolStatus = (isPriceSet: boolean, side: IPool['side'], userSide: IPool['userSide']):
  IPool['status'] => {
    if (isPriceSet) {
      return side === userSide ? 'won' : 'lost';
    }
    return undefined;
  };

  const converPoolsToStatus = (poolsToConvert: Array<IPool>,
    poolStatuses: Array<IPoolStatus>): Array<IPool> => {
    poolStatuses.forEach((status) => {
      if (status.userVolume.betAmount) {
        const pool = poolsToConvert.find((poolToConvert) => poolToConvert.id === status.poolId);
        const { userVolume } = status;
        if (pool) {
          pool.prediction = userVolume.betAmount.toFixed(4);
          pool.userSide = getUserSide(userVolume.isBull);
          pool.reward = userVolume.collectAmount.toFixed(4);
          pool.status = getPoolStatus(pool.isPriceSet, pool.side, pool.userSide);
          pool.statusText = getUserPoolStatus(pool.isPriceSet, pool.userSide, pool.side);
        }
      }
    });

    return poolsToConvert;
  };

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);
  }, [getCurrentBlock]);

  useEffect(() => {
    if (state.hubConnection) {
      const events = ['new-pool', 'bet', 'collect'];
      events.forEach((event) => {
        state.hubConnection?.on(event, async (msg) => {
          if (event === 'collect') {
            await dispatch({
              type: 'LOG_AMPLITUDE_ACTION',
              payload: {
                actionName: 'platform claim prize',
                amplitudeProps: {
                  pool_id: msg.pool.id,
                  prize_amount: msg.amount,
                },
              },
            });
          }

          setPools(null);
          setStatusedPools(null);
        });
      });
    }
  }, [state.hubConnection]);

  useEffect(() => {
    if (!pools && state.blockTime && currentBlock) {
      if (typeof tableState.searchId === 'number') {
        getSearchedPool(tableState.searchId);
      } else {
        getPools();
      }
    }
  }, [getPools, getSearchedPool, tableState.searchId,
    pools, state.blockTime, currentBlock]);

  // get statuses when we have pools
  useEffect(() => {
    if (!statusedPools && pools && pools.length && state.account) {
      getPersonalPools(pools, state.baseUrl);
    }
  }, [statusedPools, pools, getPersonalPools,
    state.baseUrl, state.account]);

  useEffect(() => {
    if (statusedPools) {
      setReadyPools(statusedPools);
    } else {
      setReadyPools(pools);
    }
  }, [statusedPools, pools]);

  // as tableState changes we need to get new pools
  useEffect(() => {
    setPools(null);
    setStatusedPools(null);
    tableDispatch({ type: 'SET_PAGE', payload: { ...tableState, page: 0 } });
    setLoading(true);
  }, [tableState.accountFilter, tableState.asset, tableState.token, i18n.language]);

  useEffect(() => {
    if (tableState.page !== 0) {
      getPools();
      setStatusedPools(null);
      setLoading(true);
    }
  }, [tableState.page]);

  // as tableState, language, account, or network changes we need to get new pools
  useEffect(() => {
    if (state.currentNetworkId) {
      setPools(null);
      setStatusedPools(null);
    }
  }, [tableState, i18n.language, state.account, state.currentNetworkId]);

  return (
    <>
      <Header />
      <StyledClosedPoolsContainer>
        <ClosedPoolsTitle>
          {t('common:closedPools')}
        </ClosedPoolsTitle>
        <Switcher />
        <Filters />
        {
          readyPools
            ? readyPools.map((pool) => (
              <Pool
                key={pool.id}
                id={pool.id}
                creationDate={pool.time || ''}
                pair={state.feeds.find((feed) => (feed.id === pool.feedId))?.displayName || ''}
                condition={pool.price}
                volume={pool.volume}
                betSide={pool.userSide || ''}
                result={pool.side || ''}
                final={pool.finalPrice || '-'}
                prediction={pool.prediction || '-'}
                statusText={pool.statusText || ''}
                status={pool.status}
                timeframe={`1 ${t('common:h')}`}
                reward={!pool.reward || (+(pool.reward as string) <= 0) ? '-' : pool.reward}
                end={pool.end}
                endPeriod={pool.endPeriod}
                currentBlock={currentBlock}
                isPriceSet={pool.isPriceSet}
                bullsQuote={pool.bullsQuote}
                bearsQuote={pool.bearsQuote}
                tokenId={pool.tokenId}
              />
            ))
            : (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )
        }
        {
          readyPools && (
          <LoadButton
            canLoadMore={canLoadMore}
            loading={loading}
            onClick={loadMore}
          />
          )
        }
      </StyledClosedPoolsContainer>
    </>
  );
};

export default ClosedPoolsContainer;
