import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
// import styles
import {
  CardContainer,
  StatisticComponent,
  WinningComponent,
  WinningValues,
  PersonIcon,
  StatisticComponentsContainer,
  CupIcon,
  CardHeader, PersonSender,
} from 'styles/desktop/StyledLeaderBoard';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import cupThird from 'static/images/leaderboard/cupThird.svg';
import cupSecond from 'static/images/leaderboard/cupSecond.svg';
import cupFirst from 'static/images/leaderboard/cupFirst.svg';
import iconPurple from 'static/images/leaderboard/iconPurple.svg';
import { StyledWinningsGrid } from 'styles/mobile/StyledPersonCard';
import { useLeaderboardState } from 'context/leaderboardContext';
import { useModalState } from 'context/modalState';
import { getCurrencyName } from 'networksHelper';
import { useGlobalState } from 'context/globalState';

interface IPlaces {
    1: string,
    2: string,
    3: string
}

const places: IPlaces = {
  1: cupFirst,
  2: cupSecond,
  3: cupThird,
};

interface IPlace {
    place: number;
    isCurrentUser: boolean;
}

const LeaderCard: React.FC<IPlace> = (props: IPlace): ReactElement => {
  const { i18n, t } = useTranslation(['leaderboard']);
  const { place } = props;
  const { state, dispatch } = useGlobalState();
  const { isCurrentUser } = props;
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();
  const { modalDispatch } = useModalState();

  const getLeaderInfo = (count : number) => {
    if (leaderboardState.users) {
      return leaderboardState.users[count];
    }
    return null;
  };

  const openLeaderboardModal = (sender : string | undefined) => {
    modalDispatch({
      type: 'OPEN_LEADERBOARD_MODAL',
      payload: {
        sender,
      },
    });
  };

  const cupByPlace: string = places[place as keyof IPlaces];
  return (
    <Grid item>
      <CardContainer isCurrentUser={isCurrentUser} place={place} mobile={isMobile}>
        <CardHeader container justifyContent="flex-start" alignItems="center">
          <PersonIcon src={iconPurple} mobile={isMobile} onClick={() => openLeaderboardModal(getLeaderInfo(place - 1)?.sender)} alt="icon" />
          <PersonSender item onClick={() => openLeaderboardModal(getLeaderInfo(place - 1)?.sender)}>
            {getLeaderInfo(place - 1)?.sender.slice(0, 17)}
            ...
            {' >'}
          </PersonSender>
          <Grid item><CupIcon place={place} src={cupByPlace} mobile={isMobile} alt="cup" /></Grid>
        </CardHeader>
        <StatisticComponentsContainer>
          <StatisticComponent container justifyContent="space-between">
            <Grid item>{t('Win rate')}</Grid>
            <Grid item>
              {getLeaderInfo(place - 1)?.winrate}
              %
            </Grid>
          </StatisticComponent>
          <StatisticComponent container justifyContent="space-between">
            <Grid item>{t('Rounds won')}</Grid>
            <Grid item>
              {' '}
              {getLeaderInfo(place - 1)?.roundsWon}
              /
              {getLeaderInfo(place - 1)?.roundsPlayed}
            </Grid>
          </StatisticComponent>
          <WinningComponent container justifyContent="space-between" alignItems="center">
            <Grid item>
              {t('Net winnings')}
              {` (${getCurrencyName(state.currentNetworkId)})`}
            </Grid>
            <Grid item>
              <WinningValues container direction="column">
                <StyledWinningsGrid
                  netWinnings={getLeaderInfo(place - 1)?.netWinnings}
                  item
                >
                  {' '}
                  {getLeaderInfo(place - 1)?.netWinnings.toFixed(6)}
                </StyledWinningsGrid>
              </WinningValues>
            </Grid>
          </WinningComponent>
        </StatisticComponentsContainer>
      </CardContainer>
    </Grid>
  );
};

export default LeaderCard;
