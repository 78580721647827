import React, {
  ReactElement, useEffect, useCallback, useState,
} from 'react';
import axios from 'axios';
import {
  Grid,
} from '@mui/material';
import PoolStatus from 'components/common/PoolStatus';
import CountDown from 'components/common/CountDown';
import { useGlobalState } from 'context/globalState';
import { useActualPoolState } from 'context/actualPoolState';
import { useCurrentBlockContext } from 'context/currentBlockContext';
// styles
import {
  PoolStatusContainer,
} from 'styles/desktop/StyledClosedPools';

const ActualPoolStatus: React.FC = (): ReactElement => {
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const { state } = useGlobalState();
  const { actualPoolState, actualPoolDispatch } = useActualPoolState();
  const { getCurrentBlock } = useCurrentBlockContext();

  // eslint-disable-next-line no-shadow
  const getDeadline = useCallback((): void => {
    const endEnd: number = actualPoolState.actualPool.end + actualPoolState.actualPool.endPeriod;
    const diff: number = (endEnd - currentBlock) * state.blockTime;
    const today: Date = new Date();
    const timeLeft: Date = diff < 0 ? today : new Date(today.setSeconds(diff));

    actualPoolDispatch({ type: 'SET_DEADLINE', payload: { ...actualPoolState, deadline: timeLeft } });
  }, [state.blockTime, actualPoolState.actualPool.end,
    actualPoolState.actualPool.endPeriod, actualPoolDispatch, currentBlock]);

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);
    const intervalId = setInterval(() => {
      getCurrentBlock().then(setCurrentBlock);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getDeadline();
  }, [currentBlock]);

  return (
    <PoolStatusContainer container justifyContent="center">
      <Grid item>
        <PoolStatus pool={actualPoolState.actualPool} currentBlock={currentBlock} />
      </Grid>
      <Grid item>
        <CountDown deadline={actualPoolState.deadline} />
      </Grid>
    </PoolStatusContainer>
  );
};

export default ActualPoolStatus;
