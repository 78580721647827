import React, { ReactElement } from 'react';
import StyledButton from 'styles/common/StyledCustomButton';
import { isMobile } from 'react-device-detect';

interface IProps {
    disabledButton?: boolean;
    children?: React.ReactNode;
    secondary?: boolean;
    poolId?: number;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    claimPrize?: (poolId: string) => void;
}

const CustomButton: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    children,
    disabledButton,
    secondary,
    poolId,
    onClick,
    claimPrize,
  } = props;

  return (
    <StyledButton
      id={poolId?.toString()}
      fullWidth
      disabled={disabledButton}
      secondary={(!!secondary).toString()}
      mobile={isMobile.toString()}
      onClick={claimPrize ? (e) => claimPrize(e.currentTarget.id) : onClick}
    >
      { children }
    </StyledButton>
  );
};

CustomButton.defaultProps = {
  disabledButton: false,
  children: '',
  secondary: false,
  poolId: 0,
  onClick: () => {},
  claimPrize: undefined,
};

export default CustomButton;
