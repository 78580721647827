import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';

const PoolBlockContainer = styled(Grid)<{isMobile: String}>`
  position: relative;
  background-color: white;
  border-radius: 16px;
  margin-top: 16px;
  padding-top: 24px;
  color: #0C0C21;
  letter-spacing: 0.01em;
  padding-bottom: ${(props) => (props.isMobile === 'true' ? 22 : 24)}px;
  font-size: ${(props) => (props.isMobile === 'true' ? 12 : 18)}px;
  line-height: ${(props) => (props.isMobile === 'true' ? 14 : 21)}px;
`;

export const PoolCreationDate = styled.div<{isMobile: String}>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E7E7E9;
  border-radius: 0 16px;
  min-width: ${(props) => (props.isMobile === 'true' ? 110 : 210)}px;
  height: ${(props) => (props.isMobile === 'true' ? 24 : 33)}px;
`;

export const PoolRow = styled(Grid)<{isMobile: String}>`
  margin: 4px 0;
  padding-left: ${(props) => (props.isMobile === 'true' ? 16 : 32)}px;
  padding-right: ${(props) => (props.isMobile === 'true' ? 10 : 22)}px;
  &.MuiGrid-spacing-xs-undefined{
    margin-bottom: 12px;
  }
`;

export const PoolHeader = styled(PoolRow)`
  font-weight: bold;
  letter-spacing: 0.01em;
  margin-bottom: 12px;
`;
export const PoolContentTitle = styled(Grid)<{isMobile: String}>`
  color: rgba(12, 12, 33, 0.7);
  letter-spacing: 0.01em;
  margin-bottom: 4px;
  font-size: ${(props) => (props.isMobile === 'true' ? 12 : 16)}px;
  line-height: ${(props) => (props.isMobile === 'true' ? 14 : 24)}px;
`;

export const PoolBetSideBlock = styled.div<{side: String, isMobile: String}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  color: white;
  background-color: ${(props) => {
    if (props.side) {
      if (props.side === 'BULL') {
        return '#5900F7';
      }
      return '#2D2C2C';
    }
    return 'white';
  }};
  width: ${(props) => (props.isMobile === 'true' ? 51 : 120)}px;
  height: ${(props) => (props.isMobile === 'true' ? 16 : 24)}px;
`;

export const PoolStatusRow = styled(PoolRow)<{status: 'won' | 'lost' | undefined}>`
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: ${(props) => {
    if (props.status) {
      if (props.status === 'won') {
        return 'rgba(50, 248, 0, 0.1);';
      }
      return 'rgba(248, 0, 30, 0.1);';
    }
    return '#E7E7E9';
  }};
`;

export const StatusText = styled.span<{status: 'won' | 'lost' | undefined}>`
  color: ${(props) => {
    if (props.status) {
      if (props.status === 'won') {
        return '#1F9D00';
      }
      return '#D30E26';
    }
    return '#0C0C21';
  }};
`;

export const IconImage = styled.img`
  margin-right: 8px;
`;

export default PoolBlockContainer;
