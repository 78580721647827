import styled from 'styled-components';

const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 48px;
`;

const StyledLoadingImg = styled.img`
  position: absolute;
  animation-duration: 15s;
  animation-name: rotation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
`;

const StyledLoadingDots = styled.span`
  &:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;}

  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
              .25em 0 0 rgba(0,0,0,0),
              .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: white;
      text-shadow:
              .25em 0 0 rgba(0,0,0,0),
              .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
              .25em 0 0 white,
              .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
              .25em 0 0 white,
              .5em 0 0 white;}}
  `;

// return
export {
  StyledLoadingContainer, StyledLoadingImg, StyledLoadingDots,
};
