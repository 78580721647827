import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useGlobalState } from 'context/globalState';
import {
  IPool,
} from 'interfaces/state.d';
import PoolBlock from 'components/common/PoolBlock';

interface IProps {
  pools: Array<IPool>;
  currentBlock: number;
}

const TableMobileView: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    pools,
    currentBlock,
  } = props;

  const { state } = useGlobalState();

  return (
    <Grid container spacing={3}>
      {
        pools.map((pool) => (
          <Grid item xs={6} key={pool.id}>
            <PoolBlock
              id={pool.id}
              creationDate={pool.time || ''}
              pair={state.feeds.find((feed) => (feed.id === pool.feedId))?.displayName || ''}
              condition={pool.price}
              volume={pool.volume}
              betSide={pool.userSide || ''}
              result={pool.side || ''}
              final={pool.finalPrice || '-'}
              prediction={pool.prediction || '-'}
              statusText={pool.statusText || ''}
              status={pool.status}
              timeframe="1 h"
              reward={!pool.reward || (+(pool.reward as string) <= 0) ? '-' : pool.reward}
              end={pool.end}
              endPeriod={pool.endPeriod}
              currentBlock={currentBlock}
              isPriceSet={pool.isPriceSet}
              bullsQuote={pool.bullsQuote}
              bearsQuote={pool.bearsQuote}
              tokenId={pool.tokenId}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

export default TableMobileView;
