import styled from 'styled-components';
import { Grid } from '@mui/material';

export const PersonCardContainer = styled.div<{isCurrentUser: boolean}>`
  position: relative;
  background: ${({ isCurrentUser }) => (isCurrentUser ? 'linear-gradient(65.34deg, #7E38F9 45.4%, #00BCF7 96.64%)' : '#0C0C21')};
  box-shadow: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 220px;
  //margin-right: 29px;
  margin-top: 30px;
  border-radius: 16px;
  padding: 15px 32px;
  border: 1px solid #4410ea;
`;

export const StatisticPersonComponent = styled(Grid)<{isCurrentUser: boolean}>`
  position: relative;
  background: ${({ isCurrentUser }) => (isCurrentUser ? 'linear-gradient(65.34deg, #7E38F9 45.4%, #00BCF7 96.64%)' : '#0C0C21')};
  color: white;
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  font-weight: 600;
  border: 1px solid white;
  &.MuiGrid-root {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 2px;
  }
`;
export const WinningPersonComponent = styled(Grid)<{isCurrentUser: boolean}>`
  background : ${({ isCurrentUser }) => (isCurrentUser ? 'white' : 'rgba(89, 0, 247, 1)')};
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  font-weight: 600;
  color: ${({ isCurrentUser }) => (isCurrentUser ? 'black' : 'white')};
  &.MuiGrid-root {
    width: 100%;
    height: 50px;
    padding: 8px 12px;
    border-radius: 8px;
  }
`;
export const WinningPersonValues = styled(Grid)<{isCurrentUser: boolean}>`
  color: ${({ isCurrentUser }) => (isCurrentUser ? 'black' : 'white')};
`;
export const StyledWinningsGrid = styled(Grid)<{netWinnings : number | undefined}>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${({ netWinnings }) => (typeof netWinnings === 'number' && (netWinnings >= 0 ? 'rgba(44, 214, 2, 1)' : 'red'))};

`;
export const StyledWinningsConvertGrid = styled(Grid)`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: rgba(144, 144, 144, 1);
`;
