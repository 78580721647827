import React, {
  ReactElement, useEffect, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
} from '@mui/material';
import { useModalState } from 'context/modalState';
import { useActualPoolState } from 'context/actualPoolState';
import { useGlobalState } from 'context/globalState';
import { useCurrentBlockContext } from 'context/currentBlockContext';
// styles
import {
  StyledBetContainer,
  StyledPredictButton,
  PredictButtonContainer,
  PredictButtonTitle,
} from 'styles/desktop/StyledClosedPools';
import BetSide from './ActualPoolBetSide';

const ActualPoolBetContainer: React.FC = (): ReactElement => {
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const { modalDispatch } = useModalState();
  const { actualPoolState } = useActualPoolState();
  const { state, dispatch } = useGlobalState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t } = useTranslation();

  const openModal = (): void => {
    // if expired or user has no account or it's not loaded yet
    if (!isDeadlineExpired(actualPoolState.actualPool.start
      + actualPoolState.actualPool.startPeriod,
    actualPoolState.deadline) && state.account
      && +window.ethereum.chainId === state.currentNetworkId) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'platform predict click',
        },
      });

      modalDispatch({
        type: 'OPEN_BET_MODAL',
        payload: {
          betModalData: actualPoolState,
        },
      });
    }
  };

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);
    const intervalId = setInterval(() => {
      getCurrentBlock().then(setCurrentBlock);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const isDeadlineExpired = (aceptanceEndBlock: number, deadline: Date):
    boolean => aceptanceEndBlock < currentBlock || deadline < new Date();

  return (
    <StyledBetContainer container>
      <Grid item xs={6}>
        <BetSide isBull currency={actualPoolState.token.displayName} />
      </Grid>
      <PredictButtonContainer
        disabled={isDeadlineExpired(actualPoolState.actualPool.start
        + actualPoolState.actualPool.startPeriod,
        actualPoolState.deadline) || !state.account
        || +window.ethereum.chainId !== state.currentNetworkId}
        onClick={openModal}
      >
        <PredictButtonTitle>
          {t('Predict')}
        </PredictButtonTitle>
        <StyledPredictButton />
      </PredictButtonContainer>
      <Grid item xs={6}>
        <BetSide isBull={false} currency={actualPoolState.token.displayName} />
      </Grid>
    </StyledBetContainer>
  );
};

export default ActualPoolBetContainer;
