import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
`;

const Spinner = styled.div<{isMobile?: String}>`
    display: inline-block;
    width: ${(props) => (props.isMobile === 'true' ? 40 : 80)}px;
    height: ${(props) => (props.isMobile === 'true' ? 40 : 80)}px;

    &:after {
    content: ' ';
    display: block;
    width: ${(props) => (props.isMobile === 'true' ? 32 : 64)}px;
    height: ${(props) => (props.isMobile === 'true' ? 32 : 64)}px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid grey;
    border-color: grey transparent grey transparent;
    animation: ${rotate} 1.2s linear infinite;
    }
`;

export default Spinner;
