/* eslint-disable no-alert */
import React, { ReactElement, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  IPool,
} from 'interfaces/state.d';
import {
  IIcons,
} from 'interfaces/common.d';
import { ethers } from 'ethers';
import Button from 'components/common/CustomButton';
import {
  TableRow,
  TableBody,
  Grid,
} from '@mui/material';
import { useGlobalState } from 'context/globalState';
import { useCurrentBlockContext } from 'context/currentBlockContext';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledTableContainer,
  StyledTableCell as TC,
  StyledTableHeader as TH,
  StyledTableRow as TR,
  IDTableCell,
  SideTableCell,
  StatusCell,
  StyledTableContent,
  RatioBlock,
  RatioBlockItem,
} from 'styles/desktop/StyledClosedPools';
import { IconImage } from 'styles/common/StyledPoolBlock';
// icons
import PROS from 'static/images/currency_icons/PROS_icon.svg';
import USDC from 'static/images/currency_icons/USDC_icon.svg';
import BNB from 'static/images/currency_icons/BNB_icon.svg';
import BTC from 'static/images/currency_icons/BTC_icon.svg';
import REVV from 'static/images/currency_icons/REVV_icon.svg';
import AXS from 'static/images/currency_icons/AXIE_icon.png';
import OKT from 'static/images/currency_icons/OKT_icon.svg';

const icons: IIcons = {
  PROS,
  USDC,
  BNB,
  BTC,
  REVV,
  AXS,
  OKT,
};

interface IProps {
  pools: Array<IPool>;
}

const TableDesktopView: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    pools,
  } = props;
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const { state, dispatch } = useGlobalState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t } = useTranslation();

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);

    const intervalId = setInterval(() => { getCurrentBlock().then(setCurrentBlock); }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const claimPrize = async (poolId: string) => {
    const { contract } = state;
    if (!contract) {
      alert('Your wallet is not connected');
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'platform claim prize',
          amplitudeProps: {
            error: 'wallet is not connected',
          },
        },
      });
      return;
    }
    const overrides = {
      gasPrice: ethers.utils.parseUnits(`${state.currentNetworkId === 97 ? '15' : '7.5'}`, 'gwei'),
    };
    try {
      contract.collect(poolId, overrides);
    } catch (e) {
      alert('You are not eligable for claiming the prize');
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'platform claim prize',
          amplitudeProps: {
            error: 'not eligable for claiming the prize',
          },
        },
      });
    }
  };

  const getCoef = (volume: number, quote: number): string => {
    if (volume) {
      if (quote === 0) {
        return (2).toFixed(2);
      }
      return (volume / quote).toFixed(2);
    }
    return (1).toFixed(2);
  };

  return (
    <StyledTableContainer>
      <StyledTableContent>
        <TH>
          <TableRow>
            <TC>{`ID/${t('Date')}`}</TC>
            <TC>{t('Condition')}</TC>
            <TC>{t('Volume')}</TC>
            <TC>{t('Prediction')}</TC>
            <TC>{t('Reward')}</TC>
            <TC>{t('Final')}</TC>
            <TC>{t('Ratio')}</TC>
            <TC>{t('Side')}</TC>
            <TC>{t('Result')}</TC>
            <TC>{t('Status')}</TC>
            <TC />
          </TableRow>
        </TH>
        <TableBody>
          {
            pools.map((pool) => (
              <TR key={pool.id}>
                <IDTableCell>
                  <div>
                    ID
                    { pool.id }
                  </div>
                  {' '}
                  { pool.time }
                </IDTableCell>
                <TC>
                  { pool.price }
                  {' '}
                  USD
                </TC>
                <TC>
                  <Grid container alignItems="center">
                    <IconImage
                      alt="icon"
                      height={isMobile ? 16 : 24}
                      src={
                        icons[state.tokens.find(
                          (token) => token.id === pool.tokenId,
                        )?.displayName as keyof IIcons]
                      }
                    />
                    { pool.volume }
                  </Grid>
                </TC>
                <TC>
                  <Grid container alignItems="center">
                    <IconImage
                      alt="icon"
                      height={isMobile ? 16 : 24}
                      src={
                        icons[state.tokens.find(
                          (token) => token.id === pool.tokenId,
                        )?.displayName as keyof IIcons]
                      }
                    />
                    { pool.prediction || '-' }
                  </Grid>
                </TC>
                <TC>
                  <Grid container alignItems="center">
                    <IconImage
                      alt="icon"
                      height={isMobile ? 16 : 24}
                      src={
                        icons[state.tokens.find(
                          (token) => token.id === pool.tokenId,
                        )?.displayName as keyof IIcons]
                      }
                    />
                    { !pool.reward || (+(pool.reward as string) <= 0) ? '-' : pool.reward }
                  </Grid>
                </TC>
                <TC>
                  $
                  { pool.finalPrice || '-' }
                </TC>
                <RatioBlock>
                  <Grid container direction="column">
                    <RatioBlockItem item container justifyContent="center" alignItems="center" side="BULL">
                      { `x ${getCoef(pool.bullsQuote + pool.bearsQuote, pool.bullsQuote)}` }
                    </RatioBlockItem>
                    <RatioBlockItem item container justifyContent="center" alignItems="center" side="BEAR">
                      { `x ${getCoef(pool.bullsQuote + pool.bearsQuote, pool.bearsQuote)}` }
                    </RatioBlockItem>
                  </Grid>
                </RatioBlock>
                <SideTableCell side={pool.userSide}>{ t(`common:${pool.userSide || ''}`) }</SideTableCell>
                <SideTableCell side={pool.side}>{ t(`common:${pool.side || ''}`) }</SideTableCell>
                <StatusCell status={pool.status}>{ pool.statusText }</StatusCell>
                <StatusCell status={pool.status}>
                  {
                    // TODO: write function to display button
                    (pool.status === 'won'
                      || (pool.end && pool.endPeriod && currentBlock
                        && (pool.end + pool.endPeriod) < currentBlock
                        && !pool.isPriceSet && pool.prediction))
                    && (
                    <Button
                      poolId={pool.id}
                      disabledButton={(pool.reward !== undefined
                        && parseFloat(pool.reward as string) > 0)
                        || pool.end > currentBlock
                        || state.currentNetworkId !== +window.ethereum.chainId}
                      claimPrize={claimPrize}
                    >
                      {
                        pool.reward !== undefined
                        && parseFloat(pool.reward as string) > 0
                          ? t('Claimed')
                          : t('Claim')
                      }
                    </Button>
                    )
                  }
                </StatusCell>
              </TR>
            ))
          }
        </TableBody>
      </StyledTableContent>
    </StyledTableContainer>
  );
};

export default TableDesktopView;
