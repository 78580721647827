import React, { ReactElement, useEffect } from 'react';
import Switcher from 'components/common/PillsSwitcher';
import { useTableState } from 'context/tableState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
// styles
import {
  SwitcherContainer,
} from 'styles/mobile/StyledClosedPools';

const FilterSwitcher: React.FC = (): ReactElement => {
  const { tableState, tableDispatch } = useTableState();
  const { state, dispatch } = useGlobalState();
  const { t } = useTranslation(['closed_pools']);

  const changePersonalFilter = (tab: number): void => {
    if (state.account) {
      tableDispatch({ type: 'SET_ACCOUNT_FILTER', payload: { ...tableState, accountFilter: !tab } });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform switch my/all',
        amplitudeProps: {
          filter: tableState.accountFilter ? 'my' : 'all',
        },
      },
    });
  }, [tableState.accountFilter]);

  return (
    <SwitcherContainer>
      <Switcher
        tabs={[t('My'), t('All')]}
        selectedTab={tableState.accountFilter ? 0 : 1}
        changeTab={changePersonalFilter}
      />
    </SwitcherContainer>
  );
};

export default FilterSwitcher;
