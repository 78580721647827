import React from 'react';
import ReactDOM from 'react-dom';
import StateProvider from 'context/globalState';
import ModalStateProvider from 'context/modalState';
import CurrentBlockProvider from 'context/currentBlockContext';
import ThemeProvider from 'themes/DarkTheme';
import reportWebVitals from './reportWebVitals';
import App from './components/App';
import './i18n';
// global font
import 'static/fonts/supermolot/stylesheet.css';

declare global {
  interface Window {
      ethereum: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <CurrentBlockProvider>
        <ModalStateProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </ModalStateProvider>
      </CurrentBlockProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
