import styled from 'styled-components';
import {
  Grid,
  Modal,
} from '@mui/material';

export const ReferalConnectWalletModalContainer = styled(Modal)`
  width: 924px;
  height: 392px;
  background: #1A1A39;
  border-radius: 16px;
  margin: 0 auto;
  &.MuiModal-root{
    top: 280px;
  }

`;

export const ReferalConnectWalletModalContainer2 = styled(Modal)`
  width: 924px;
  height: 392px;
  background: #1A1A39;
  border-radius: 16px;
`;

export const ReferalConnectWalletTextContainer = styled(Grid)`
  padding-top: 72px;
  padding-right: 88px;
  &.MuiGrid-root {
    margin-left: 80px;
  }
`;

export const ReferalConnectWalletTextTitle = styled(Grid)`
  margin-right: 75px !important;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 29.375px;
  line-height: 34px;
  letter-spacing: 0.03em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
  padding-bottom: 24px;
`;

export const ReferalConnectWalletTextSubtitle = styled(Grid)`
  text-align: center;
  width: 339px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.01em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
`;

export const ReferalConnectWalletImgContainer = styled(Grid)`
  width: 339px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.01em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
`;
export const ReferalConnectWalletImg = styled.img`
  padding-top: 46px;
  padding-right: 138px;
`;

export const ReferalRecommendedLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #FFFFFF;
`;

export const ReferalRecommendTextTitle = styled(Grid)`
  font-style: normal;
  font-weight: bold;
  font-size: 29.375px;
  line-height: 34px;
  width: 340px;
  letter-spacing: 0.03em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
`;

export const ReferalConnectRecommendImg = styled.img`
  padding-right: 138px;
`;
