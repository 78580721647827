import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import BoldTitle from '../../common/BoldTitle';
import { ReferalVideoText } from '../../../styles/desktop/StyledReferal';

const ReferralIntroText: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['referral']);

  return (
    <>
      <BoldTitle placement="referral">
        {t('Prosper Referral Program')}
      </BoldTitle>
      <ReferalVideoText>
        {t('We share our platform commission with those who'
            + ' have introduced other users to our platform'
            + ' (30% of our income is to be redistributed)')}
      </ReferalVideoText>
    </>
  );
};

export default ReferralIntroText;
