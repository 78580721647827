/* eslint-disable no-console */
import React, {
  ReactElement,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  Backdrop,
  Grid,
} from '@mui/material';
import axios from 'axios';
import { goToAnchor } from 'react-scrollable-anchor';
import { ReactComponent as NotificationIcon } from 'static/images/common_icons/timeframe_icon.svg';
import { ReactComponent as Close } from 'static/images/common_icons/close_icon.svg';
import { useModalState } from 'context/modalState';
import { useStatisticsState } from 'context/statisticsState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
import { IPersonalStatisticsParameters } from 'interfaces/common.d';
import { IUserStatisticsResponse } from 'interfaces/statistics.d';
// styles
import {
  ModalContainer,
  ContentContainer,
  StatisticsContainer,
  CloseButton,
  SwitcherContainer,
  NotificationContainer,
  NotificationContent,
  StyledLink,
  StyledLinkIcon,
} from 'styles/modals/StyledStatisticsModal';
import Switcher from 'components/common/PillsSwitcher';
import RoundsData from 'components/common/statistics/RoundsData';
import PNLData from 'components/common/statistics/PNLData';
// helpers
import { numberToProperFixedNumber } from 'numbersHelper';

const StatisticsModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const [userStatistics, setUserStatistics] = useState<IUserStatisticsResponse | null>(null);

  const { modalsState, modalDispatch } = useModalState();
  const { statisticsState, statisticsDispatch } = useStatisticsState();
  const { state, dispatch } = useGlobalState();
  const { t } = useTranslation(['modals']);

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_STATISTICS_MODAL' });
  };

  const scrollToPoolsTable = () => {
    goToAnchor('table');
    handleClose();
  };

  const switchTab = (tabIndex: number) => {
    statisticsDispatch({
      type: 'SWITCH_STATE',
      payload: {
        ...statisticsState,
        state: tabIndex,
      },
    });
  };

  const getStatistics = useCallback(async () => {
    const queryStatisticsParams: IPersonalStatisticsParameters = {
      networkId: state.currentNetworkId,
      user: state.account,
    };

    const statisticsResponse = await axios.post(`${state.baseUrl}/users/${queryStatisticsParams.user}/statistics`, {
      networkId: queryStatisticsParams.networkId,
    });

    if (statisticsResponse.data) {
      setUserStatistics(statisticsResponse.data as IUserStatisticsResponse);
    }
  }, [state.account, state.currentNetworkId]);

  const getTokenCost = useCallback(async () => {
    try {
      const tokenResponse = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin&order=market_cap_desc&per_page=1&page=1&sparkline=false');
      if (tokenResponse.data) {
        statisticsDispatch({
          type: 'SET_TOKEN_COST',
          payload: {
            ...statisticsState,
            tokenCost: tokenResponse.data[0].current_price,
          },
        });
      }
    } catch (e) {
      console.log('Something went wrong during getting token data');
    }
  }, []);

  useEffect(() => {
    if (modalsState.openedStatisticsModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open statistics modal',
        },
      });

      getStatistics();
    }

    setOpen(modalsState.openedStatisticsModal);
  }, [modalsState.openedStatisticsModal]);

  useEffect(() => {
    if (userStatistics) {
      statisticsDispatch({
        type: 'SET_STATISTICS',
        payload: {
          ...statisticsState,
          rounds: {
            wonRounds: userStatistics.victoryStatistics.betAmount,
            wonMoney: numberToProperFixedNumber(userStatistics.victoryStatistics.moneyAmount, 4),
            lostRounds: userStatistics.lossStatistics.betAmount,
            lostMoney: numberToProperFixedNumber(userStatistics.lossStatistics.moneyAmount, 4),
            overallMoney: numberToProperFixedNumber(
              userStatistics.overallStatistics.moneyAmount,
              4,
            ),
          },
          pnl: {
            averageReturn: numberToProperFixedNumber(userStatistics.averageIncome, 4),
            bestRoundId: userStatistics.largestIncomePoolId,
            bestRoundProfit: numberToProperFixedNumber(userStatistics.largestIncome, 4),
            averagePosition: numberToProperFixedNumber(userStatistics.averageParticipateAmount, 4),
            netResults: numberToProperFixedNumber(userStatistics.netWorth, 4),
          },
        },
      });
    }
  }, [userStatistics]);

  useEffect(() => {
    if (state.account) {
      getStatistics();
    }
  }, [getStatistics]);

  useEffect(() => {
    getTokenCost();
  }, []);

  useEffect(() => {
    if (state.tokens.length) {
      statisticsDispatch({ type: 'SET_TOKEN', payload: { ...statisticsState, token: state.tokens[0].displayName } });
    }
  }, [state.tokens]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <ContentContainer>
        <CloseButton onClick={handleClose}>
          <Close />
        </CloseButton>
        <StatisticsContainer>
          <NotificationContainer>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <NotificationIcon />
              </Grid>
              <Grid item xs={11} container justifyContent="space-between">
                <NotificationContent>
                  {t('statistics notification')}
                </NotificationContent>
                <StyledLink onClick={() => scrollToPoolsTable()}>
                  {t('Go to closed pools')}
                  <StyledLinkIcon />
                </StyledLink>
              </Grid>
            </Grid>
          </NotificationContainer>
          <SwitcherContainer>
            <Switcher tabs={[t('Rounds'), 'PNL']} selectedTab={statisticsState.state} changeTab={switchTab} />
          </SwitcherContainer>
          {
            statisticsState.state
              ? <PNLData />
              : <RoundsData />
          }
        </StatisticsContainer>
      </ContentContainer>
    </ModalContainer>
  );
};

export default StatisticsModal;
