import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
} from '@mui/material';
import PoolStatus from 'components/common/PoolStatus';
import CountDown from 'components/common/CountDown';
import { useActualPoolState } from 'context/actualPoolState';
import { useGlobalState } from 'context/globalState';
import { useCurrentBlockContext } from 'context/currentBlockContext';
// styles
import {
  ActualPoolContainer,
} from 'styles/mobile/StyledHeader';
import timeframeIcon from 'static/images/common_icons/timeframe_icon.svg';

const ActualPoolStatus: React.FC = (): ReactElement => {
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { state } = useGlobalState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t } = useTranslation();
  const { actualPoolState, actualPoolDispatch } = useActualPoolState();

  // eslint-disable-next-line no-shadow
  const getDeadline = useCallback((): void => {
    const endEnd: number = actualPoolState.actualPool.end + actualPoolState.actualPool.endPeriod;
    const diff: number = (endEnd - currentBlock) * state.blockTime;
    const today: Date = new Date();
    const timeLeft: Date = diff < 0 ? today : new Date(today.setSeconds(diff));

    actualPoolDispatch({ type: 'SET_DEADLINE', payload: { ...actualPoolState, deadline: timeLeft } });
  }, [state.blockTime, actualPoolState.actualPool.end,
    actualPoolState.actualPool.endPeriod, actualPoolDispatch, currentBlock]);

  const hideTooltip = () => {
    setShowTooltip(false);
  };

  const triggerTooltip = () => {
    setShowTooltip(true);
  };

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);
    const intervalId = setInterval(() => {
      getCurrentBlock().then(setCurrentBlock);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getDeadline();
  }, [currentBlock]);

  return (
    <ActualPoolContainer container alignItems="center">
      <PoolStatus pool={actualPoolState.actualPool} currentBlock={currentBlock} />
      <CountDown deadline={actualPoolState.deadline} />
      <Tooltip
        open={showTooltip}
        onClose={hideTooltip}
        onClick={triggerTooltip}
        title={`${t('Timeframe')}: 1 ${t('h')}`}
        placement="top"
      >
        <img src={timeframeIcon} alt="i" />
      </Tooltip>
    </ActualPoolContainer>
  );
};

export default ActualPoolStatus;
