import React, { ReactElement } from 'react';
import {
  IIcons,
} from 'interfaces/common.d';
import { useActualPoolState } from 'context/actualPoolState';
import { useTranslation } from 'react-i18next';
// currency
import BNB from 'static/images/currency_icons/BNB_icon_white.svg';
import PROS from 'static/images/currency_icons/PROS_icon_white.svg';
import USDC from 'static/images/currency_icons/USDC_icon_white.svg';
import BTC from 'static/images/currency_icons/BTC_icon_white.svg';
import REVV from 'static/images/currency_icons/REVV_icon.svg';
import OKT from 'static/images/currency_icons/OKT_icon_white.svg';
// styles
import ButtonContainer, {
  ButtonContentContainer,
  ButtonHint,
  ButtonContentTitle,
  ButtonContent,
} from 'styles/mobile/StyledSideButton';

interface IProps {
  isBull: boolean;
  currency: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const currencies: IIcons = {
  PROS,
  USDC,
  BNB,
  BTC,
  REVV,
  OKT,
};

const ActualPoolSideButton: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    isBull,
    currency,
    selected,
    onClick,
  } = props;
  const { actualPoolState } = useActualPoolState();
  const { t } = useTranslation(['actual_pool']);

  return (
    <ButtonContainer
      container
      bull={isBull.toString()}
      selected={selected}
      onClick={onClick}
    >
      <ButtonContentContainer bull={isBull.toString()} item>
        <ButtonHint>{isBull ? t('above') : t('below')}</ButtonHint>
        <ButtonContentTitle>
          {t('common:participantVolume')}
        </ButtonContentTitle>
        <ButtonContent>
          <img
            src={currencies[currency as keyof IIcons]}
            alt="icon"
            height="16"
          />
          {
              isBull
                ? actualPoolState.bullQuote
                : actualPoolState.bearQuote
            }
        </ButtonContent>
        <ButtonContentTitle>
          {t('common:Ratio')}
        </ButtonContentTitle>
        <ButtonContent>
          {
            `x ${isBull
              ? actualPoolState.bullCoef
              : actualPoolState.bearCoef}`
          }
        </ButtonContent>
      </ButtonContentContainer>
    </ButtonContainer>
  );
};

export default ActualPoolSideButton;
