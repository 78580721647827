import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';

const BodyContainer = styled(Grid)`
  padding: 0 17px;
  margin-top: 8px;
`;

export const ConditionPriceContainer = styled(Grid)`
  margin-top: 10px;
  margin-bottom: 26px;
`;

export const ConditionPriceTitle = styled.span`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
`;

export const ConditionPrice = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #FD0;
  margin-left: 10px;
`;
export const StyledChartContainer = styled(BodyContainer)`
  border-radius: 24px;
  overflow: hidden;
`;

export default BodyContainer;
