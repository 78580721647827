import React, { ReactElement } from 'react';
import {
  ReferalProfitContainer,
  ReferalProfitSvgContentContainer,
  ReferalProfitSvgContentIconWithText,
  ReferalProfitTitle,
} from 'styles/mobile/StyledReferal';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import planets from '../../../static/images/mobile-planets.svg';
import iconWithTextBoxes from '../../../static/images/mobile-iconWithTextBoxes.svg';

const ReferralProfit: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['referral']);

  return (
    <ReferalProfitContainer>
      <ReferalProfitTitle>
        {t('The profit of the referral program ')}
      </ReferalProfitTitle>
      <ReferalProfitSvgContentContainer src={planets} />
      <ReferalProfitSvgContentIconWithText src={iconWithTextBoxes} />

    </ReferalProfitContainer>
  );
};

export default ReferralProfit;
