import React, { createContext, useContext, useReducer } from 'react';
import {
  IReferralPrivateOfficeState,
} from 'interfaces/state.d';

type ActionType = 'SET_FILTER'
  | 'SET_TABLE_PAGE'
  | 'SET_PAYOUTS'
  | 'SET_UNPAID_AMOUNT';
type PayloadType = IReferralPrivateOfficeState;
interface IAction {
  type: ActionType;
  payload: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const ReferralPrivateOfficeContext = createContext<{
  referralPrivateOfficeState: IReferralPrivateOfficeState;
  referralPrivateOfficeDispatch: DispatchType
} | undefined>(undefined);

const stateReducer = (privateOfficeState: IReferralPrivateOfficeState, action: IAction):
  IReferralPrivateOfficeState => {
  const {
    filter,
    tablePage,
    payouts,
    unpaidAmount,
  } = action.payload;

  switch (action.type) {
    case 'SET_FILTER':
      return setFilter(privateOfficeState, filter);
    case 'SET_TABLE_PAGE':
      return setTablePage(privateOfficeState, tablePage);
    case 'SET_PAYOUTS':
      return setPayouts(privateOfficeState, payouts);
    case 'SET_UNPAID_AMOUNT':
      return setUnpaidAmount(privateOfficeState, unpaidAmount);
    default:
      return initialState;
  }
};

const setFilter = (state: IReferralPrivateOfficeState, filter: IReferralPrivateOfficeState['filter']) => ({
  ...state,
  tablePage: 1,
  filter,
});

const setTablePage = (state: IReferralPrivateOfficeState, tablePage: number) => ({
  ...state,
  tablePage,
});

const setPayouts = (state: IReferralPrivateOfficeState, payouts: IReferralPrivateOfficeState['payouts']) => {
  const tmpPayouts = payouts.sort((a, b) => (
    new Date(b.date).getTime() - new Date(a.date).getTime())).map((payout) => ({
    ...payout,
    status: !!payout.date,
    date: payout.date ? new Date(payout.date).toLocaleDateString() : '-',
  }));

  return {
    ...state,
    payouts: tmpPayouts,
  };
};

const setUnpaidAmount = (state: IReferralPrivateOfficeState, amount: string | null) => ({
  ...state,
  unpaidAmount: amount,
});

const initialState: IReferralPrivateOfficeState = {
  filter: 'all time',
  tablePage: 1,
  payouts: [],
  unpaidAmount: null,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [
    referralPrivateOfficeState,
    referralPrivateOfficeDispatch,
  ] = useReducer(stateReducer, initialState);

  return (
    <ReferralPrivateOfficeContext.Provider value={
      { referralPrivateOfficeState, referralPrivateOfficeDispatch }
    }
    >
      { children }
    </ReferralPrivateOfficeContext.Provider>
  );
};

export const useReferralPrivateOfficeState = () => {
  const context = useContext(ReferralPrivateOfficeContext);

  if (!context) throw new Error('useReferralPrivateOfficeState must be used in a referral private office state provider');

  return context;
};

export default StateProvider;
