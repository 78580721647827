import React, { ReactElement, useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useActualPoolState } from 'context/actualPoolState';
import { useGlobalState } from 'context/globalState';
import BetContainer from './BetContainer';
import ChartContainer from './ChartContainer';

const ActualPoolBodyContainer: React.FC = (): ReactElement => {
  const [state, setState] = useState(0);
  const { actualPoolState, actualPoolDispatch } = useActualPoolState();
  const { dispatch } = useGlobalState();

  const change = () => {
    actualPoolDispatch({
      type: 'SWITCH_CHART',
      payload: {
        ...actualPoolState,
        chartExpanded: !actualPoolState.chartExpanded,
      },
    });
  };

  useEffect(() => {
    setState(actualPoolState.chartExpanded ? 1 : 0);

    if (actualPoolState.chartExpanded) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'platform show chart',
        },
      });
    }
  }, [actualPoolState.chartExpanded]);

  return (
    <SwipeableViews index={state} onChangeIndex={change}>
      <BetContainer />
      <ChartContainer />
    </SwipeableViews>
  );
};

export default ActualPoolBodyContainer;
