import React, {
  ReactElement, useEffect, useState, useCallback,
} from 'react';
import { ethers } from 'ethers';
import { ContractInterface } from '@ethersproject/contracts';
import
{
  useHistory,
  Switch,
  Route, useLocation,
} from 'react-router-dom';
import StatisticsContext from 'context/statisticsState';
import LeaderboardContext from 'context/leaderboardContext';
import ReferralPrivateOfficeContext from 'context/referralPrivateOfficeState';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import axios from 'axios';
import abi from 'static/abi/contract.abi.json';
import { HubConnectionBuilder } from '@microsoft/signalr';
import {
  IFeed,
  IToken,
} from 'interfaces/common.d';
// styles
import {
  StyledDesktop,
} from 'styles/desktop/StyledDesktop';
// modals
import BetModal from 'components/modals/desktop/BetModal';
import YouAreInModal from 'components/modals/desktop/YouAreInModal';
import WrongNetworkModal from 'components/modals/desktop/WrongNetworkModal';
import LoadingModal from 'components/modals/desktop/LoadingModal';
import LeaderBoardModal from 'components/modals/desktop/LeaderBoardModal';
import StatisticsModal from 'components/modals/desktop/StatisticsModal';
import ReferralConnectWalletModal from 'components/modals/desktop/ReferralConnectWalletModal';
import ReferralRecommendModal from 'components/modals/desktop/ReferralRecommendModal';
// helpers
import { getNetworksIds } from 'networksHelper';

import Pools from './pools/ClosedPoolsContainer';
import Mining from './mining/MiningContainer';
import Referral from './referral/ReferralContainer';
import LeaderBoard from './leaderboard/LeaderBoardContainer';
import ReferralFollowedTheLinkPage from './referral/ReferralFollowedTheLinkPage';
import Header from './header/Header';
import Footer from './footer/Footer';

const getContractAbi = (): ContractInterface => abi;

const DesktopContainer: React.FC = (): ReactElement => {
  const [accounts, setAccounts] = useState<Array<string> | null>(null);
  const [balance, setBalance] = useState<number | null>(null);
  const [contractAbi, setContractAbi] = useState<ContractInterface | null>(null);
  const [address, setAddress] = useState<string>('');
  const [feeds, setFeeds] = useState<Array<IFeed> | null>(null);
  const [tokens, setTokens] = useState<Array<IToken> | null>(null);
  const [blockTime, setBlockTime] = useState<number>(0);
  const [appInitialized, setAppInitialized] = useState<boolean>(false);
  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();
  const location = useLocation();
  const networkId = state.currentNetworkId;

  const getAbi = useCallback(async (): Promise<void> => {
    const contractAbiResponse = getContractAbi();
    setContractAbi(contractAbiResponse as ContractInterface);
  }, []);

  const getAddress = useCallback(async (baseUrl: string): Promise<void> => {
    const addressReponse = await axios.get(`${baseUrl}/network/${networkId}/contract-address`);
    setAddress(addressReponse.data as string);
  }, [networkId]);

  const getBlockTime = useCallback(async (baseUrl: string): Promise<void> => {
    const blockTimeResponse = await axios.get(`${baseUrl}/network/${networkId}/block-time`);
    setBlockTime(blockTimeResponse.data as number);
  }, [networkId]);

  const getBalance = useCallback(async () => {
    if (accounts && state.networkProvider) {
      const balanceResponse = await state.networkProvider.getBalance(accounts[0]);
      setBalance(+ethers.utils.formatEther(balanceResponse));
    }
  }, [accounts, state.networkProvider]);

  const getFeeds = useCallback(async (baseUrl: string): Promise<void> => {
    const feedsResponse = await axios.get(`${baseUrl}/network/${networkId}/feeds`);
    feedsResponse.data = feedsResponse.data.map((feed: IFeed) => ({
      ...feed,
      assetName: feed.displayName.split('/', 1)[0].trim(),
    }));
    setFeeds(feedsResponse.data as Array<IFeed>);
  }, [networkId]);

  const getTokens = useCallback(async (baseUrl: string): Promise<void> => {
    const tokensResponse = await axios.get(`${baseUrl}/network/${networkId}/tokens`);
    if (tokensResponse.data) {
      if (!state.acceptPros) {
        tokensResponse.data = tokensResponse.data.filter((token: IToken) => token.displayName !== 'PROS');
      }
      setTokens(tokensResponse.data);
    }
  }, [networkId, state.acceptPros]);

  const initContract = useCallback(() => {
    if (!state.networkProvider || !address || !abi || !accounts || !accounts.length) {
      throw new Error('Unable to initialize contract');
    }

    dispatch({
      type: 'INIT_CONTRACT',
      payload: {
        address,
        abi,
        signer: state.networkProvider.getUncheckedSigner(accounts[0]),
      },
    });
  }, [state.networkProvider, address, abi, accounts]);

  const connect = useCallback(async (): Promise<void> => {
    if (!(window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet))) {
      window.location.href = 'https://metamask.io';
      return;
    }

    let accountList: Array<string> = [];
    if (state.networkProvider) {
      const knownNetworksIds = getNetworksIds();
      const metamaskNetworkId = +window.ethereum.chainId;
      if (knownNetworksIds.includes(metamaskNetworkId)) {
        dispatch({
          type: 'SET_CURRENT_NETWORK',
          payload: {
            ...state,
            currentNetworkId: metamaskNetworkId,
          },
        });
      } else {
        modalDispatch({ type: 'OPEN_WRONG_NETWORK_MODAL' });
        return;
      }
      accountList = await state.networkProvider.listAccounts();
    }

    if (accountList.length < 1) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.location.reload();
    } else {
      setAccounts(accountList);
    }
  }, [state.networkProvider, networkId, modalDispatch]);

  const componentWithCheckedLocation = (urls: string[], component: React.ReactElement)
      : React.ReactElement | null => {
    const restrictedLink = (url: string) => location.pathname.includes(url);
    const containsInUrls = urls.some(restrictedLink);
    return containsInUrls ? null : component;
  };

  useEffect(() => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform opened main page',
      },
    });
  }, [dispatch]);

  useEffect(() => {
    setAddress('');
    setFeeds(null);
    setTokens(null);
    setBlockTime(0);
    setAppInitialized(false);
  }, [state.currentNetworkId]);

  // getting balance only when user have at least 1 connected account
  useEffect(() => {
    if (accounts && accounts.length) {
      getBalance();
    }
  }, [accounts, getBalance]);

  // init contract if only we have user account address
  useEffect(() => {
    if (accounts && address) {
      initContract();
    }
  }, [accounts, address]);

  // setting global account and balance only when we have both
  useEffect(() => {
    if (accounts && typeof balance === 'number') {
      dispatch({ type: 'SET_ACCOUNT', payload: { account: accounts[0] } });
      dispatch({ type: 'SET_BALANCE', payload: { balance } });
    }
  }, [balance, accounts, dispatch]);

  useEffect(() => {
    if (!appInitialized && abi && address
            && networkId && feeds && tokens && blockTime) {
      dispatch({
        type: 'INITIALIZE_APPLICATION',
        payload: {
          abi,
          address,
          currentNetworkId: networkId,
          feeds,
          tokens,
          blockTime,
        },
      });
      setAppInitialized(true);
    }
  }, [appInitialized, dispatch, blockTime,
    abi, address, networkId, feeds, tokens]);

  useEffect(() => {
    if (!abi) {
      getAbi();
    } else if (!address) {
      getAddress(state.baseUrl);
    } else if (!feeds) {
      getFeeds(state.baseUrl);
    } else if (!tokens) {
      getTokens(state.baseUrl);
    } else if (!blockTime) {
      getBlockTime(state.baseUrl);
    }
  }, [abi, address,
    state, networkId, appInitialized,
    feeds, tokens, blockTime]);

  // call connect only if application is initialized, provider exists and accounts is not set yet
  useEffect(() => {
    if (appInitialized && !accounts && state.networkProvider) {
      connect();
    }
  }, [appInitialized, state.networkProvider, accounts, connect]);

  useEffect(() => {
    if (!state.hubConnection) {
      const hubConnection = new HubConnectionBuilder()
        .withUrl(`${state.baseUrl}/event-hub`)
        .build();

      dispatch({ type: 'SET_HUB_CONNECTION', payload: { hubConnection } });

      hubConnection.start();
    }
  }, [state.hubConnection, state.baseUrl, dispatch]);

  return (
    <StyledDesktop container direction="column">
      <StatisticsContext>
        {componentWithCheckedLocation(['referral-link'], <Header />)}
        <Switch>
          <Route path="/" exact>
            <Pools />
          </Route>

          <Route path="/mining">
            <Mining />
          </Route>

          <Route path="/referral-link/:referrerCode">
            <ReferralFollowedTheLinkPage />
          </Route>

          <ReferralPrivateOfficeContext>
            <Route path="/referral-info">
              <Referral />
            </Route>
          </ReferralPrivateOfficeContext>

          <LeaderboardContext>
            <Route path="/leaderboard">
              <LeaderBoard />
            </Route>
            <LeaderBoardModal />
          </LeaderboardContext>

        </Switch>
        <BetModal />
        <YouAreInModal />
        <WrongNetworkModal />
        <LoadingModal />
        <StatisticsModal />
        <ReferralConnectWalletModal />
        <ReferralRecommendModal />
        {componentWithCheckedLocation(['referral-link'], <Footer />)}
      </StatisticsContext>
    </StyledDesktop>
  );
};

export default DesktopContainer;
