import React, { ReactElement } from 'react';
import { useActualPoolState } from 'context/actualPoolState';
import { ReactComponent as ChartButton } from 'static/images/chart_button.svg';
import { ReactComponent as ExpandedChartButton } from 'static/images/expanded_chart_button.svg';
// styles
import {
  ChartSwitcherContainer,
} from 'styles/mobile/StyledHeader';

const ChartSwitcher: React.FC = (): ReactElement => {
  const { actualPoolState, actualPoolDispatch } = useActualPoolState();

  const switcherClickHandle = () => {
    actualPoolDispatch({
      type: 'SWITCH_CHART',
      payload: {
        ...actualPoolState,
        chartExpanded: !actualPoolState.chartExpanded,
      },
    });
  };

  return (
    <ChartSwitcherContainer
      container
      alignItems="center"
    >
      {
        actualPoolState.chartExpanded
          ? <ExpandedChartButton onClick={switcherClickHandle} />
          : <ChartButton onClick={switcherClickHandle} />
      }
    </ChartSwitcherContainer>
  );
};

export default ChartSwitcher;
