import styled from 'styled-components';
import { MiningStatisticsContainer } from 'styles/desktop/StyledMining';

export const MiningContentContainer = styled.div`
  padding: 22px 17px;
  background: #0C0C21;
  min-height: calc(100vh - 64px);
  width: 100vw;
`;

export const HistoricalDataContainer = styled.div`
  margin: 24px 0;
`;

export const MiningTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
`;

export const StageDataContainer = styled.div`
  margin-top: 40px;
`;

export const MiningMobileStatisticsContainer = styled(MiningStatisticsContainer)`
  background: #0C0C21;
  padding: 24px 0;
`;

export const PredictButtonContainer = styled.div`
  margin-top: 24px;
`;
