import styled from 'styled-components';
import {
  Grid, Table, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import cardBackground from '../../static/images/leaderboard/cardBackground.svg';
import TableHeader from '../../components/desktop/pools/table/header/TableHeader';
import DropDown from '../../components/common/DropDown';

export const StyledLeaderBoard = styled.div`
  width: 1400px;
  padding-top: 96px;
`;

export const CardContainer = styled.div<{ place: number, mobile: boolean, isCurrentUser : boolean}>`
  position: relative;
  background: url(${cardBackground}), ${({ isCurrentUser }) => (!isCurrentUser
  ? 'linear-gradient(45deg, rgba(49, 16, 141, 1), rgba(34, 12, 98, 1))'
  : 'linear-gradient(rgba(126, 56, 249, 1), rgba(0, 188, 247, 1))')};
  box-shadow: rgba(0, 0, 0, 0.15);
  width: ${({ mobile }) => (mobile ? '100%' : '446px')};
  height: ${({ place, mobile }) => {
    if (mobile) return 220;
    switch (place) {
      case 1:
        return 325;
      case 2:
        return 271;
      case 3:
        return 251;
      default:
        return 251;
    }
  }}px ;
  margin-right: ${({ mobile }) => (mobile ? 0 : 29)}px;
  margin-top: ${({ mobile }) => (mobile ? 40 : 86)}px;
  border-radius: 16px;
  padding: 15px 32px;
  border: ${({ mobile }) => (mobile ? 1 : 0)}px solid #4410ea;
`;

export const CardHeader = styled(Grid)`
  margin-bottom: 28px;
  &.MuiGrid-root {
    color: rgba(255, 221, 0, 1);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PersonIcon = styled.img<{ mobile: boolean }>`
  width: ${({ mobile }) => (mobile ? 47 : 63.9)}px;
  height: ${({ mobile }) => (mobile ? 50 : 68.9)}px;
  margin-right: 8px;
  &:hover{
    cursor: pointer;
  }
`;

export const PersonSender = styled(Grid)`
  &:hover{
    cursor: pointer;
  }
`;

export const CupIcon = styled.img<{ place: number, mobile: boolean }>`
  position: absolute;
  top: ${({ place, mobile }) => {
    if (mobile) return '-10px';
    switch (place) {
      case 1:
        return '-24px';
      case 2:
        return '-3px';
      case 3:
        return '-15px';
      default:
        return '-15px';
    }
  }};
  right: ${({ place, mobile }) => {
    if (mobile) return '0px';
    switch (place) {
      case 1:
        return '-12px';
      case 2:
        return '3px';
      case 3:
        return '3px';
      default:
        return '0';
    }
  }};
  z-index: 0;
  width: ${({ place, mobile }) => {
    if (mobile) return '70px';
    switch (place) {
      case 1:
        return '142px';
      case 2:
        return '116px';
      case 3:
        return '97px';
      default:
        return '97px';
    }
  }};
  height: ${({ place, mobile }) => {
    if (mobile) return '88px';
    switch (place) {
      case 1:
        return '212px';
      case 2:
        return '132px';
      case 3:
        return '123.65px';
      default:
        return '123.65px';
    }
  }};
`;

export const StyledCardContainer = styled(Grid)`
  flex-wrap: nowrap;
`;

export const StatisticComponent = styled(Grid)`
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  font-weight: 600;

  &.MuiGrid-root {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 2px;
  }

`;

export const WinningComponent = styled(Grid)`
  background-color: rgba(89, 0, 247, 1);
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  font-weight: 600;

  &.MuiGrid-root {
    width: 100%;
    height: 50px;
    padding: 8px 12px;
    border-radius: 8px;
  }
`;

export const RoundedIconComponent = styled(Grid)`
  background: linear-gradient(rgba(126, 56, 249, 1), rgba(0, 188, 247, 1));
  border-radius: 50%;
  width: 63px;
  height: 68px;
`;

export const WinningValues = styled(Grid)`

`;

export const StatisticComponentsContainer = styled(Grid)`
  position: absolute;
  width: calc(100% - 64px);
  bottom: 23px;
  left: 32px;
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 64px;
`;

export const StyledTable = styled(Table)`
  &.MuiTable-root {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 2px;
    font-family: "TT Supermolot Neue", serif;
  }
`;

export const StyledTableCell = styled(TableCell)<{isCurrentUser?: boolean}>`
  &.MuiTableCell-root {
    font-family: "TT Supermolot Neue", serif;
    color:  ${({ isCurrentUser }) => (typeof isCurrentUser === 'boolean' && isCurrentUser ? 'white' : 'black')};
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    border-collapse: unset;
    border-bottom: none;
  }
  
`;

export const StyledTableHead = styled(TableHead)<{ mobile: boolean }>`
  ${StyledTableCell} {
    font-weight: 600;
    font-size: ${({ mobile }) => (mobile ? '12' : '14')}px;
    line-height: 14px;
    color: ${({ mobile }) => (mobile ? 'rgba(255, 255, 255, 0.7);' : 'white')};
  }
`;

export const StyledTableRow = styled(TableRow)<{ isCurrentUser: boolean }>`
  height: 80px;
  padding: 0;
  border-radius: 24px;
  background: ${({ isCurrentUser }) => (isCurrentUser ? 'linear-gradient(90deg, #7E38F9 38.83%, #00BCF7 80.93%, #00BCF7 98.99%)' : 'white')};

  ${StyledTableCell}:first-child {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
  }

  ${StyledTableCell}:last-child {
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
  }

  border-collapse: unset;
  margin: 2px 0px;
`;

export const StyledUserInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const StyledId = styled.div<{ isCurrentUser: boolean }>`
  color: ${({ isCurrentUser }) => (isCurrentUser ? 'white' : 'rgba(89, 0, 247, 1)')};
  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledRanked = styled.div<{ isCurrentUser: boolean, mobile: boolean }>`
  position: absolute;
  bottom: ${({ mobile }) => (mobile ? '-7px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 65px;
  border-radius: 22px;
  left: ${({ mobile }) => (mobile ? '-4.6px' : '0')};
  background: ${({ mobile, isCurrentUser }) => {
    if (isCurrentUser) return 'white';
    if (mobile) return 'rgba(89, 0, 247, 1)';
    return 'rgba(255, 199, 0, 1)';
  }};
  color:  ${({ mobile, isCurrentUser }) => {
    if (isCurrentUser) return 'black';
    if (mobile) return 'white';
    return 'black';
  }};
`;

export const StyledWinningsGrid = styled(Grid)<{netWinnings : number}>`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ netWinnings }) => (netWinnings > 0 ? 'rgba(44, 214, 2, 1)' : 'red')};
`;
export const StyledWinningsConvertGrid = styled(Grid)<{ isCurrentUser: boolean}>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ isCurrentUser }) => (isCurrentUser ? 'white' : 'rgba(144, 144, 144, 1);')};
`;

export const StyledLeaderboardDropdown = styled(DropDown)`
  &.MuiPaper-elevation {
    text-transform: uppercase;
    color: black;
  }
`;
