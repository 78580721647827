import React, {
  ReactElement, useState, useEffect, useCallback,
} from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import {
  IPool,
} from 'interfaces/state.d';
import {
  IPersonalQueryParameters,
} from 'interfaces/common.d';
import {
  Fade,
  Grid,
  TextField,
} from '@mui/material';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
import { useCurrentBlockContext } from 'context/currentBlockContext';
import SideButton from 'components/common/BetModalSideButton';
import CountDown from 'components/common/CountDown';
import Button from 'components/common/CustomButton';
import PoolStatus from 'components/common/PoolStatus';
import { ethers } from 'ethers';
// styles
import {
  ModalContainer,
  StyledPaper,
  ChoiseBlock,
  Title,
  ChoiceItemTitle,
  ChoiceItemContent,
  ChoiceContentBlock,
  SubmitTitle,
  SubmitBlock,
  SubmitContent,
} from 'styles/modals/StyledBetModal';
import BetModalWarningTitle from '../../common/BetModalWarningTitle';

type UserSideType = null | boolean;

const getCoef = (volume: number, quote: number): string => {
  if (volume) {
    if (quote === 0) {
      return (2).toFixed(2);
    }
    return (volume / quote).toFixed(2);
  }
  return (1).toFixed(2);
};

const BetModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [userAmount, setUserAmount] = useState<string>('0');
  const [chosenSide, setChosenSide] = useState<number | null>(null);
  const [betInProgress, setBetInProgress] = useState<boolean>(false);
  const [signedUpHub, setSignedUpHub] = useState<boolean>(false);
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [userSide, setUserSide] = useState<UserSideType>(null);

  const { modalsState, modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t } = useTranslation(['modals']);

  const getPersonalPool = useCallback(async (actualPool: IPool,
    baseUrl: string): Promise<void> => {
    const ids: Array<number> = [actualPool.id];
    const queryPersonalParams: IPersonalQueryParameters = {
      ids,
      user: state.account,
      networkId: state.currentNetworkId,
    };

    const poolStatuses = await axios.post(`${baseUrl}/users/${queryPersonalParams.user}/user-volume`, {
      ids: queryPersonalParams.ids,
      networkId: queryPersonalParams.networkId,
    });

    if (poolStatuses.data) {
      setUserSide(poolStatuses.data[0].userVolume.isBull);
    }
  }, [state.account, state.currentNetworkId]);

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);
    const intervalId = setInterval(() => {
      getCurrentBlock().then(setCurrentBlock);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setOpen(modalsState.openedBetModal);
    if (modalsState.openedBetModal) {
      getPersonalPool(modalsState.betModalData.actualPool, state.baseUrl);
    }
  }, [modalsState.openedBetModal, state.baseUrl, modalsState.betModalData.actualPool]);

  // initialize token contract every time modal is opened
  useEffect(() => {
    if (modalsState.openedBetModal) {
      dispatch({
        type: 'INIT_TOKEN_CONTRACT',
        payload: {
          ...state,
          tokenAddress: modalsState.betModalData.token.value,
        },
      });
    }
  }, [modalsState.openedBetModal]);

  useEffect(() => {
    if (state.hubConnection && !signedUpHub && state.account) {
      state.hubConnection?.on('bet', (msg) => {
        if (msg.sender === state.account) {
          setBetInProgress(false);
          modalDispatch({ type: 'CLOSE_LOADING_MODAL' });
          modalDispatch({
            type: 'OPEN_YOU_ARE_IN_MODAL',
            payload: { youAreInModalData: { isBull: msg.isBull } },
          });
        }
      });
      setSignedUpHub(true);
    }
  }, [state.hubConnection, signedUpHub, state.account,
    betInProgress, modalDispatch]);

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_BET_MODAL' });
  };

  const changeSide = (sideIndex: number): void => {
    setChosenSide(sideIndex);
  };

  const changeUserAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let amount: string = event.target.value;
    if (+amount < 0) {
      amount = '0';
    }
    setUserAmount(amount);
  };

  const sideUserPosition = (sideNeedToBe: number): number => {
    if (typeof chosenSide === 'number') {
      return chosenSide === sideNeedToBe ? +userAmount : 0;
    }
    return 0;
  };

  const sideVolume = (side: number): number => (side
    ? parseFloat(modalsState.betModalData.bearQuote) + sideUserPosition(1)
    : parseFloat(modalsState.betModalData.bullQuote) + sideUserPosition(0));

  const sideRatio = (side: number): string => (side
    ? getCoef(sideVolume(0) + sideVolume(1), sideVolume(1))
    : getCoef(sideVolume(0) + sideVolume(1), sideVolume(0)));

  const sidePotential = (side: number): number => (side
    ? sideUserPosition(1) * +sideRatio(1)
    : sideUserPosition(0) * +sideRatio(0));

  const confirmBet = async () => {
    setBetInProgress(true);
    modalDispatch({ type: 'OPEN_LOADING_MODAL' });
    modalDispatch({ type: 'CLOSE_BET_MODAL', payload: { betModalData: {} } });
    try {
      const amount = ethers.utils.parseEther(userAmount);
      const spender = state.address;
      const isToken = modalsState.betModalData.token.value !== '0x0000000000000000000000000000000000000000';
      const overrides = {
        value: isToken ? undefined : amount,
        gasPrice: ethers.utils.parseUnits(`${state.currentNetworkId === 97 ? '15' : '7.5'}`, 'gwei'),
      };
      if (isToken) {
        const approveTx = await state.tokenContract?.approve(
          spender,
          amount,
          overrides,
        );
        await approveTx.wait();
      }
      if (state.contract) {
        await state.contract.bet(
          modalsState.betModalData.actualPool.id,
          modalsState.betModalData.token.value,
          amount,
          !chosenSide,
          overrides,
        );

        await dispatch({
          type: 'LOG_AMPLITUDE_ACTION',
          payload: {
            actionName: 'platform make bet',
            amplitudeProps: {
              token: modalsState.betModalData.asset.pair,
              asset: modalsState.betModalData.token.displayName,
              current_timeframe: 1,
              side: getChosenSide(),
              amount: userAmount,
              ratio: sideRatio(Number(chosenSide)),
              poolId: modalsState.betModalData.actualPool.id,
            },
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'platform make bet',
          amplitudeProps: {
            error: e,
          },
        },
      });
      modalDispatch({ type: 'CLOSE_LOADING_MODAL' });
      setBetInProgress(false);
      // this.$modal.show('wrong-modal');
    }
  };

  const getChosenSide = (): string => (chosenSide ? 'BEAR' : 'BULL');

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <StyledPaper>
          <ChoiseBlock container direction="column">
            <Title>{t('My position')}</Title>
            <ChoiceContentBlock item container justifyContent="space-between">
              <Grid item xs={4}>
                <Grid container direction="column">
                  <ChoiceItemTitle item>
                    {t('Prediction pair')}
                  </ChoiceItemTitle>
                  <ChoiceItemContent item>
                    { modalsState.betModalData.asset.pair }
                  </ChoiceItemContent>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column">
                  <ChoiceItemTitle item>
                    {t('common:Timeframe')}
                  </ChoiceItemTitle>
                  <ChoiceItemContent item>
                    {`1 ${t('common:h')}`}
                  </ChoiceItemContent>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column">
                  <ChoiceItemTitle item>
                    <PoolStatus
                      pool={modalsState.betModalData.actualPool}
                      currentBlock={currentBlock}
                    />
                  </ChoiceItemTitle>
                  <ChoiceItemContent item>
                    <CountDown deadline={modalsState.betModalData.deadline} />
                  </ChoiceItemContent>
                </Grid>
              </Grid>
            </ChoiceContentBlock>
            <ChoiceContentBlock item container justifyContent="space-between">
              <Grid item xs={4}>
                <Grid container direction="column">
                  <ChoiceItemTitle item>
                    {t('Prediction in')}
                  </ChoiceItemTitle>
                  <ChoiceItemContent item>
                    { modalsState.betModalData.token.displayName }
                  </ChoiceItemContent>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column">
                  <ChoiceItemTitle item>
                    {t('actual_pool:conditionalPrice')}
                  </ChoiceItemTitle>
                  <ChoiceItemContent item>
                    {`$ ${modalsState.betModalData.conditionPrice}`}
                  </ChoiceItemContent>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column">
                  <ChoiceItemTitle item>
                    {
                      `${t('My position')} (${modalsState.betModalData.token.displayName})`
                    }
                  </ChoiceItemTitle>
                  <ChoiceItemContent item>
                    <TextField
                      value={userAmount}
                      onChange={changeUserAmount}
                      variant="outlined"
                      placeholder={t('Enter the amount')}
                      type="number"
                      disabled={state.balance === 0}
                    />
                    <BetModalWarningTitle
                      userAmount={userAmount}
                      accountBalance={state.balance}
                      isMobile={isMobile}
                    />
                  </ChoiceItemContent>
                </Grid>
              </Grid>
            </ChoiceContentBlock>
            <ChoiceContentBlock item container justifyContent="space-between">
              <SideButton
                isBull
                currency={modalsState.betModalData.token.displayName}
                selected={chosenSide === 0}
                volume={sideVolume(0)}
                ratio={sideRatio(0)}
                potential={sidePotential(0)}
                onClick={() => changeSide(0)}
              />
              <SideButton
                isBull={false}
                currency={modalsState.betModalData.token.displayName}
                selected={chosenSide === 1}
                volume={sideVolume(1)}
                ratio={sideRatio(1)}
                potential={sidePotential(1)}
                onClick={() => changeSide(1)}
              />
            </ChoiceContentBlock>
          </ChoiseBlock>
          <SubmitBlock container direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item xs={2} container direction="column">
                  <SubmitTitle item>{t('Pair')}</SubmitTitle>
                  <SubmitContent item>{ modalsState.betModalData.asset.pair }</SubmitContent>
                </Grid>
                <Grid item xs={2} container direction="column">
                  <SubmitTitle item>{t('common:Condition')}</SubmitTitle>
                  <SubmitContent item>
                    {`${modalsState.betModalData.conditionPrice} USD`}
                  </SubmitContent>
                </Grid>
                <Grid item xs={2} container direction="column">
                  <SubmitTitle item>{t('common:Side')}</SubmitTitle>
                  <SubmitContent item>
                    {
                      typeof chosenSide === 'number'
                        ? (
                          <SubmitContent item>
                            {t(`common:${getChosenSide()}`)}
                          </SubmitContent>
                        )
                        : (
                          <SubmitContent item>
                            {t('Choose a side bull or bear?')}
                          </SubmitContent>
                        )
                    }
                  </SubmitContent>
                </Grid>
                <Grid item xs={2} container direction="column">
                  <SubmitTitle item>{t('common:Timeframe')}</SubmitTitle>
                  <SubmitContent item>{`1 ${t('common:h')}`}</SubmitContent>
                </Grid>
                <Grid item xs={2} container direction="column">
                  <SubmitTitle item>{t('Your position')}</SubmitTitle>
                  <SubmitContent item>
                    { userAmount }
                    {' '}
                    { modalsState.betModalData.token.displayName }
                  </SubmitContent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                disabledButton={typeof chosenSide !== 'number'
                || !!chosenSide === userSide || +userAmount < 0.005 || +userAmount > state.balance}
                onClick={confirmBet}
              >
                {t('Confirm')}
              </Button>
            </Grid>
          </SubmitBlock>
        </StyledPaper>
      </Fade>
    </ModalContainer>
  );
};

export default BetModal;
