import React, { createContext, useContext, useReducer } from 'react';
import {
  IMiningState,
  IStageData,
  ITotalStageStatistics,
  IPersonalStageStatistics,
} from 'interfaces/mining.d';

type ActionType = 'SWITCH_STAGE'
  | 'SWITCH_NETWORK'
  | 'SET_EPOCHS_DATA'
  | 'SET_STAGE_DATA'
  | 'SET_TOTAL_STAGE_STATISTICS'
  | 'SET_PERSONAL_STAGE_STATISTICS'
  | 'SET_HISTORICAL_AMOUNT';
type PayloadType = IMiningState;
interface IAction {
  type: ActionType;
  payload: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const MiningContext = createContext<{miningState: IMiningState;
 miningDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (miningState: IMiningState, action: IAction): IMiningState => {
  const {
    epochsData,
    stage,
    network,
    stageData,
    totalStageStatistics,
    personalStageStatistics,
    historicalPredictionVolume,
  } = action.payload;
  switch (action.type) {
    case 'SET_EPOCHS_DATA':
      return {
        ...miningState,
        epochsData,
      };
    case 'SWITCH_STAGE':
      return {
        ...miningState,
        stage,
      };
    case 'SWITCH_NETWORK':
      return {
        ...miningState,
        network,
      };
    case 'SET_STAGE_DATA':
      return {
        ...miningState,
        stageData,
      };
    case 'SET_TOTAL_STAGE_STATISTICS':
      return {
        ...miningState,
        totalStageStatistics,
      };
    case 'SET_PERSONAL_STAGE_STATISTICS':
      return {
        ...miningState,
        personalStageStatistics,
      };
    case 'SET_HISTORICAL_AMOUNT':
      return {
        ...miningState,
        historicalPredictionVolume,
      };
    default:
      return initialState;
  }
};

const initialStageData: IStageData = {
  stageDate: '22.03.21 — 29.04.21',
  stageTotalRewardPool: 7581,
  stageDailyRewardPools: [{
    feed: 'BNB/USD',
    token: 'PROS',
    payoutRatio: 7581,
  }],
};

const initialTotalStageStatistics: ITotalStageStatistics = {
  totalMiningAmount: 12,
  alreadyMined: 2,
  participants: 13,
  currentDayParticipants: 0,
  totalPredictionVolume: 123,
  currentDayTotalPredictionVolume: 0,
  dailyPredictionVolume: 13,
};

const initialPersonalStatistics: IPersonalStageStatistics = {
  predictionVolume: 0,
  currentDayPredictionVolume: 0,
  tokensEarned: 0,
  currentDayTokensEarned: 0,
  currentReturnRatio: 0,
  currentDayReturnRatio: 0,
  netProfit: 0,
  currentDayNetProfit: 0,
  correctPredictions: 0,
  predictionsMade: 0,
};

const initialState: IMiningState = {
  stage: 0,
  network: 0,
  historicalPredictionVolume: 0,
  epochsData: null,
  stageData: initialStageData,
  totalStageStatistics: initialTotalStageStatistics,
  personalStageStatistics: initialPersonalStatistics,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [miningState, miningDispatch] = useReducer(stateReducer, initialState);

  return (
    <MiningContext.Provider value={{ miningState, miningDispatch }}>
      { children }
    </MiningContext.Provider>
  );
};

export const useMiningState = () => {
  const context = useContext(MiningContext);

  if (!context) throw new Error('useMiningContext must be used in a mining state provider');

  return context;
};

export default StateProvider;
