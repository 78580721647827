import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  PersonIcon,
  StatisticComponentsContainer,
  StyledRanked,
  StyledUserInfo,
} from '../../../styles/desktop/StyledLeaderBoard';
import {
  PersonCardContainer,
  StatisticPersonComponent,
  WinningPersonComponent,
  WinningPersonValues,
  StyledWinningsGrid,
} from '../../../styles/mobile/StyledPersonCard';
import iconWhite from '../../../static/images/leaderboard/iconWhite.svg';
import { useModalState } from '../../../context/modalState';
import { useLeaderboardState } from '../../../context/leaderboardContext';
import { useGlobalState } from '../../../context/globalState';
import { ILeaderboardUser } from '../../../interfaces/leaderboard.d';
import { getCurrencyName } from '../../../networksHelper';

const PersonCard: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['leaderboard']);
  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();

  const openLeaderboardModal = (sender : string) => {
    modalDispatch({
      type: 'OPEN_LEADERBOARD_MODAL',
      payload: {
        sender,
      },
    });
  };

  const isCurrentUser = (userValue : ILeaderboardUser) => {
    if (userValue) {
      return userValue.sender === state.account.toLowerCase();
    }
    return false;
  };

  return (
    <Grid item>
      {leaderboardState.users?.slice(leaderboardState.currentPage * 10 + 3,
        leaderboardState.currentPage * 10 + 10 + 3).map((row, index) => (
          <PersonCardContainer isCurrentUser={isCurrentUser(row)}>
            <StyledUserInfo>
              <PersonIcon
                src={iconWhite}
                mobile={isMobile}
                onClick={() => openLeaderboardModal(row.sender)}
                alt="icon"
              />
              {row.sender.slice(0, 17)}
              ...
              {' >'}
              <StyledRanked
                mobile={isMobile}
                isCurrentUser={isCurrentUser(row)}
              >
                {leaderboardState.currentPage * 10 + index + 4}
                {' '}
                {t('place')}
              </StyledRanked>
            </StyledUserInfo>
            <StatisticComponentsContainer>
              <StatisticPersonComponent isCurrentUser={isCurrentUser(row)} container justifyContent="space-between">
                <Grid item>{t('Win rate')}</Grid>
                <Grid item>
                  {`${row.winrate}%`}
                </Grid>
              </StatisticPersonComponent>
              <StatisticPersonComponent isCurrentUser={isCurrentUser(row)} container justifyContent="space-between">
                <Grid item>{t('Rounds won')}</Grid>
                <Grid item>{`${row.roundsWon}/${row.roundsPlayed}`}</Grid>
              </StatisticPersonComponent>
              <WinningPersonComponent isCurrentUser={isCurrentUser(row)} container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {t('Net winnings')}
                  {` (${getCurrencyName(state.currentNetworkId)})`}
                </Grid>
                <Grid item>
                  <WinningPersonValues isCurrentUser={isCurrentUser(row)} container direction="column">
                    <StyledWinningsGrid
                      item
                      netWinnings={row.netWinnings}
                    >
                      {row.netWinnings > 0 ? `+${row.netWinnings.toFixed(6)}` : `-${row.netWinnings.toFixed(6)}` }
                    </StyledWinningsGrid>
                  </WinningPersonValues>
                </Grid>
              </WinningPersonComponent>
            </StatisticComponentsContainer>
          </PersonCardContainer>
      ))}
    </Grid>
  );
};

export default PersonCard;
