import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import {
  ConnectWalletImg,
  FollowedTheLinkContainer,
  FollowedTheLinkDataContainer,
  ProsperIcon,
} from 'styles/common/StyledReferalFollowedTheLInk';
import { useTranslation } from 'react-i18next';
import ProsIcon from '../../../static/images/propsper_logo.svg';
import {
  ReferalConnectWalletImgContainer,
  ReferalConnectWalletTextContainer,
  ReferalConnectWalletTextSubtitle,
  ReferalConnectWalletTextTitle,
} from '../../../styles/common/StyledReferalModals';
import ConnectWallet from '../../../static/images/connect_wallet.svg';
import ReferralFollowedSnackbar from './ReferralFollowedSnackbar';

const ReferralFollowedTheLinkPage: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['referral']);

  return (
    <FollowedTheLinkContainer>
      <ProsperIcon src={ProsIcon} />
      <FollowedTheLinkDataContainer container justifyContent="center" wrap="nowrap">
        <ReferalConnectWalletTextContainer
          item
          container
          direction="column"
          wrap="nowrap"
          align-items="flex-start"
        >
          <ReferalConnectWalletTextTitle item>
            {t('Connect a wallet')}
          </ReferalConnectWalletTextTitle>
          <ReferalConnectWalletTextSubtitle item>
            {t('To participate in Predix pool you first need to connect a wallet. Please select an option Below. You can also connect a Ledger via your Metamask')}
          </ReferalConnectWalletTextSubtitle>
          <ReferralFollowedSnackbar />
        </ReferalConnectWalletTextContainer>

        <ReferalConnectWalletImgContainer item container>
          <ConnectWalletImg src={ConnectWallet} />
        </ReferalConnectWalletImgContainer>

      </FollowedTheLinkDataContainer>
    </FollowedTheLinkContainer>
  );
};

export default ReferralFollowedTheLinkPage;
