import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
  ReferalConnectWalletTextSubtitle,
} from '../../../styles/common/StyledReferalModals';
// @ts-ignore
import ConnectWallet from '../../../static/images/connect_wallet.svg';
import { useGlobalState } from '../../../context/globalState';
import {
  ReferalConnectWalletTextContainer,
  ReferalConnectWalletImg,
  ReferalConnectWalletImgContainer,
  ReferalConnectWalletTextTitle, ReferralFollowedContainer,
} from '../../../styles/mobile/StyledReferal';
import ReferralFollowedSnackbar from './ReferralFollowedSnackbar';

const ReferralFollowedTheLinkPage: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['referral']);

  return (
    <>
      <ReferralFollowedContainer container flexDirection="column" justifyContent="center" alignItems="center">
        <Grid item container flexDirection="column" wrap="nowrap">
          <ReferalConnectWalletTextContainer
            item
            container
            direction="column"
            wrap="nowrap"
          >
            <ReferalConnectWalletTextTitle item>
              {t('Connect a wallet')}
            </ReferalConnectWalletTextTitle>
            <ReferalConnectWalletTextSubtitle item>
              {t('To participate in Predix pool you first need to connect a wallet. Please select an option Below. You can also connect a Ledger via your Metamask')}
            </ReferalConnectWalletTextSubtitle>
          </ReferalConnectWalletTextContainer>

          <ReferalConnectWalletImgContainer item>
            <ReferalConnectWalletImg src={ConnectWallet} />
          </ReferalConnectWalletImgContainer>
        </Grid>
      </ReferralFollowedContainer>
      <ReferralFollowedSnackbar />
    </>
  );
};

export default ReferralFollowedTheLinkPage;
