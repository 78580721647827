import styled from 'styled-components';
import {
  Modal,
  Paper,
  Grid,
} from '@mui/material';

export const ModalContainer = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 23px 18px;
    overflow: hidden;
    background: #1A1A39;
  }
`;

export const PaperContainer = styled(Grid)`
  height: 100%;
`;

export const CloseButton = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
`;

export const Title = styled.h2`
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
`;

export const Congrats = styled.div`
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
`;

export const SearchContainer = styled(Grid)`
  margin-top: 32px;
`;
