import React, { ReactElement } from 'react';
import {
  Grid,
  Divider,
} from '@mui/material';
import { useActualPoolState } from 'context/actualPoolState';
import { useTranslation } from 'react-i18next';
// styles
import {
  InformationTitle,
  InformationContent,
  InformationItem,
  InformationConditionPrice,
} from 'styles/desktop/StyledClosedPools';

const ActualPoolInformation: React.FC = (): ReactElement => {
  const { actualPoolState } = useActualPoolState();
  const { t } = useTranslation(['actual_pool']);

  return (
    <Grid container direction="column">
      <InformationTitle item>
        {t('common:Information')}
      </InformationTitle>
      <InformationContent item container direction="row">
        <Grid item xs={5} container direction="column">
          <InformationTitle item>
            {t('conditionalPrice')}
          </InformationTitle>
          <InformationConditionPrice item>
            {`$ ${actualPoolState.conditionPrice.toFixed(5)}`}
          </InformationConditionPrice>
        </Grid>
        <Grid item xs={2} container justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        {/* <Grid item xs={3} container direction="column">
          <InformationTitle item>
            {t('Current price')}
          </InformationTitle>
          <InformationItem item>
            {`$ ${actualPoolState.currentPrice?.toFixed(5)}`}
          </InformationItem>
        </Grid>
        <Grid item xs={1} container justify="center">
          <Divider orientation="vertical" />
        </Grid> */}
        <Grid item xs={5} container direction="column">
          <InformationTitle item>
            {t('common:Timeframe')}
          </InformationTitle>
          <InformationItem item>
            {`1 ${t('common:h')}`}
          </InformationItem>
        </Grid>
      </InformationContent>
    </Grid>
  );
};

export default ActualPoolInformation;
