import React, { ReactElement } from 'react';
import { ReactComponent as TwitterIcon } from 'static/images/social_icons/twitter_share.svg';
// styles
import StyledTwitterButton from 'styles/common/StyledTwitterButton';

interface IProps {
  children: string;
  onClick: () => void;
}

const TwitterButton: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    children,
    onClick,
  } = props;

  return (
    <StyledTwitterButton onClick={onClick}>
      <TwitterIcon height="16" />
      { children }
    </StyledTwitterButton>
  );
};

export default TwitterButton;
