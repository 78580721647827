import React, {
  ChangeEvent,
  ReactElement, ReactNode,
  useMemo,
  useState,
} from 'react';
import {
  FormControl,
  ListItemIcon, SelectChangeEvent,
} from '@mui/material';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
// icons
import iconEng, { ReactComponent as Eng } from 'static/images/country_icons/eng_icon.svg';
import iconArab, { ReactComponent as Arab } from 'static/images/country_icons/arab_icon.svg';
import iconGer, { ReactComponent as Ger } from 'static/images/country_icons/ger_icon.svg';
import iconSpa, { ReactComponent as Spa } from 'static/images/country_icons/spa_icon.svg';
import iconPhilip, { ReactComponent as Philip } from 'static/images/country_icons/philip_icon.svg';
import iconFr, { ReactComponent as Fr } from 'static/images/country_icons/fr_icon.svg';
import iconIndo, { ReactComponent as Indo } from 'static/images/country_icons/indo_icon.svg';
import iconIta, { ReactComponent as Ita } from 'static/images/country_icons/ita_icon.svg';
import iconJapan, { ReactComponent as Japan } from 'static/images/country_icons/japan_icon.svg';
import iconChina, { ReactComponent as China } from 'static/images/country_icons/china_icon.svg';
import iconNed, { ReactComponent as Ned } from 'static/images/country_icons/ned_icon.svg';
import iconPol, { ReactComponent as Pol } from 'static/images/country_icons/pol_icon.svg';
import iconPort, { ReactComponent as Port } from 'static/images/country_icons/port_icon.svg';
import iconRoma, { ReactComponent as Roma } from 'static/images/country_icons/roma_icon.svg';
import iconRus, { ReactComponent as Rus } from 'static/images/country_icons/rus_icon.svg';
import iconTurc, { ReactComponent as Turc } from 'static/images/country_icons/turc_icon.svg';
import iconUkr, { ReactComponent as Ukr } from 'static/images/country_icons/ukr_icon.svg';
// styles
import {
  DropDownMenuItem,
  DropDownLogo,
  DropDownSelect,
  LanguageItemLogo,
} from 'styles/desktop/StyledHeader';
import languages from 'languages.json';

interface ILanguageIcon {
  dropDownIcon: React.ReactNode;
  icon: string;
}

interface ILanguageIcons {
  [key: string]: ILanguageIcon;
}

const languageIcons: ILanguageIcons = {
  en: {
    dropDownIcon: <Eng />,
    icon: iconEng,
  },
  ae: {
    dropDownIcon: <Arab />,
    icon: iconArab,
  },
  de: {
    dropDownIcon: <Ger />,
    icon: iconGer,
  },
  es: {
    dropDownIcon: <Spa />,
    icon: iconSpa,
  },
  ph: {
    dropDownIcon: <Philip />,
    icon: iconPhilip,
  },
  fr: {
    dropDownIcon: <Fr />,
    icon: iconFr,
  },
  id: {
    dropDownIcon: <Indo />,
    icon: iconIndo,
  },
  it: {
    dropDownIcon: <Ita />,
    icon: iconIta,
  },
  jp: {
    dropDownIcon: <Japan />,
    icon: iconJapan,
  },
  cn: {
    dropDownIcon: <China />,
    icon: iconChina,
  },
  nl: {
    dropDownIcon: <Ned />,
    icon: iconNed,
  },
  pl: {
    dropDownIcon: <Pol />,
    icon: iconPol,
  },
  pt: {
    dropDownIcon: <Port />,
    icon: iconPort,
  },
  ro: {
    dropDownIcon: <Roma />,
    icon: iconRoma,
  },
  ru: {
    dropDownIcon: <Rus />,
    icon: iconRus,
  },
  'ru-RU': {
    dropDownIcon: <Rus />,
    icon: iconRus,
  },
  tr: {
    dropDownIcon: <Turc />,
    icon: iconTurc,
  },
  ua: {
    dropDownIcon: <Ukr />,
    icon: iconUkr,
  },
};

const LanguageSwitcher: React.FC = (): ReactElement => {
  const [openedSelect, setOpenedSelect] = useState<boolean>(false);
  const { dispatch } = useGlobalState();
  const { i18n } = useTranslation();

  const openSelect = () => {
    setOpenedSelect(true);
  };

  const closeSelect = () => {
    setOpenedSelect(false);
  };

  const changeLanguage = async (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    await i18n.changeLanguage(event.target.value as string);
    await dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'change language',
        amplitudeProps: {
          language: event.target.value,
        },
      },
    });
  };

  const dropDownIcon = useMemo(
    () => {
      const currentLanguageIcon = languageIcons[i18n.language];
      if (currentLanguageIcon) {
        return currentLanguageIcon.dropDownIcon;
      }
      i18n.changeLanguage('en').then(() => {}).catch(() => {});
      return languageIcons.en.dropDownIcon;
    },
    [i18n.language],
  );

  return (
    <FormControl>
      <DropDownLogo id="openLanguageMenu" onClick={openSelect}>
        {
          dropDownIcon
        }
      </DropDownLogo>
      <DropDownSelect
        variant="outlined"
        open={openedSelect}
        onClose={closeSelect}
        value={i18n.language}
        onChange={changeLanguage}
        MenuProps={{
          anchorEl: document.getElementById('openLanguageMenu'),
          MenuListProps: {
            className: 'language-list',
          },
        }}
      >
        {
          Object.entries(languages).map((language: [string, string]) => (
            <DropDownMenuItem value={language[0]} key={language[0]}>
              <ListItemIcon>
                <LanguageItemLogo
                  selected={i18n.language === language[0]}
                  src={languageIcons[language[0]].icon}
                  alt="lang"
                />
              </ListItemIcon>
              { language[1] }
            </DropDownMenuItem>
          ))
        }
      </DropDownSelect>
    </FormControl>
  );
};

export default LanguageSwitcher;
