import React, { ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
// styles
import {
  ProgressContainer,
  StyledCircle,
  CircleData,
} from 'styles/images/StyledCircleProgress';

interface IProps {
  progress: number;
}

const CircleProgress: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    progress,
  } = props;

  return (
    <ProgressContainer>
      <CircleData>
        {`${progress}%`}
      </CircleData>
      <svg width={isMobile ? 54 : 64} height={isMobile ? 54 : 64} viewBox={isMobile ? '0 0 54 54' : '0 0 64 64'} xmlns="http://www.w3.org/2000/svg">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="38%" stopColor="#7E38F9" />
          <stop offset="98%" stopColor="#00BCF7" />
        </linearGradient>
        <StyledCircle r="20.2" cx="50%" cy="50%" progress={progress} strokeWidth="2" fill="none" stroke="url(#linearColors)" />
      </svg>
    </ProgressContainer>
  );
};

export default CircleProgress;
