import React, { ReactElement, useState, createRef } from 'react';
import SearchIcon from 'static/images/images_as_components/SearchIcon';
import {
  Grid,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// styles
import {
  SearchTitle,
  StyledTextField,
} from 'styles/common/StyledSearch';

interface IProps {
    doSearch: (searchData: string) => void;
}

const Search: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    doSearch,
  } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [focusedInput, setFocusedInput] = useState<boolean>(false);
  const searchInput: React.RefObject<HTMLInputElement> = createRef();
  const { t } = useTranslation();

  const changeIconColor = (): void => {
    setFocusedInput(!focusedInput);
  };

  const handleBlur = (): void => {
    doSearch(searchText);
    changeIconColor();
  };

  const changeSearchText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const data = e.target.value;
    setSearchText(data);
  };

  const keyPressed = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      searchInput.current?.blur();
    }
  };

  return (
    <Grid container direction="column">
      <SearchTitle item>
        {`${t('Search')} ID`}
      </SearchTitle>
      <Grid item>
        <StyledTextField
          variant="outlined"
          placeholder={t('Enter ID')}
          value={searchText}
          onChange={changeSearchText}
          onFocus={changeIconColor}
          onBlur={handleBlur}
          onKeyPress={keyPressed}
          InputProps={{
            inputRef: searchInput,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color={focusedInput ? '#5900F7' : 'black'} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Search;
