import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import darkBackground from 'static/images/dark_theme_bg.svg';
// TODO : Решаем вопрос с темой
const darkTheme = createTheme({
  palette: {
    background: {
      default: '#00094b',
    },
    text: {
      primary: '#fff',
    },
  },
  typography: {
    fontFamily: ['"TT Supermolot Neue"', 'Roboto', 'serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
          backgroundImage: `url(${darkBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundPositionY: 'top',
        },
        '#root': {
          minHeight: '100vh',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: 'flex',
          alignItems: 'center',
          background: '#fff',
          borderRadius: '16px',
          color: '#1A1A39',
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: '21px',
          letterSpacing: '0.01em',

          '&:focus': {
            background: '#fff',
            borderRadius: '16px',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&.language-list': {
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: '572px',
            '&.language-list li': {
              width: '33%',
            },
          },
          '&.mobile-language-list': {
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: '100%',
            maxHeight: '196px',
            '&.mobile-language-list li': {
              width: '50%',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          background: 'white',
          color: '#0C0C21',

          placeholder: {
            color: '#828282',
          },

          '&$focused $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: 1,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  },
});

const DarkTheme = ({ children } : { children: React.ReactNode }) => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    { children }
  </ThemeProvider>
);

export default DarkTheme;
