import React, { ReactElement } from 'react';
import { ReactComponent as Fire } from 'static/images/common_icons/fire_icon.svg';
import { useModalState } from 'context/modalState';
import { useTranslation } from 'react-i18next';
// styles
import {
  StatisticsButton,
  StatisticsFireContainer,
} from 'styles/desktop/StyledHeader';

const Statistics: React.FC = (): ReactElement => {
  const { modalDispatch } = useModalState();
  const { t } = useTranslation(['modals']);

  const opeStatistics = () => {
    modalDispatch({
      type: 'OPEN_STATISTICS_MODAL',
    });
  };

  return (
    <StatisticsButton onClick={opeStatistics}>
      <StatisticsFireContainer golden>
        <Fire />
      </StatisticsFireContainer>
      {t('Statistics')}
    </StatisticsButton>
  );
};

export default Statistics;
