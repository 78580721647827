import { Grid, styled } from '@mui/material';

export const StyledLeaderboardContainer = styled(Grid)`
  width: 100%;
  background-color: #0C0C21;
  padding: 16px;
`;

export const CurrentPriceContainer = styled(Grid)`
    margin-top: 12px;
`;
