import styled from 'styled-components';

export const Header = styled.header`
  background-color: #1A1A39;
  padding: 12px 21px;
`;

export const LogoContainer = styled.span`
  align-items: center;
  margin: 0 20px;
`;
