/* eslint-disable no-console */
import React, { ReactElement, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { useMiningState } from 'context/miningState';
import { useTranslation } from 'react-i18next';
// styles
import {
  MiningStatisticsTitle,
} from 'styles/desktop/StyledMining';

import Statistics from './TotalStatistics';

const TotalStatisticsContainer: React.FC = (): ReactElement => {
  const { state } = useGlobalState();
  const { t } = useTranslation(['mining']);
  const { miningState, miningDispatch } = useMiningState();

  const getTotalStatistics = useCallback(async () => {
    const queryParams = {
      networkId: state.currentNetworkId,
      stage: miningState.stage,
    };
    try {
      const totalStatisticsResponse = await axios.get(`${state.baseUrl}/mining-data/total`, { params: queryParams });
      if (totalStatisticsResponse.data) {
        miningDispatch({
          type: 'SET_TOTAL_STAGE_STATISTICS',
          payload: {
            ...miningState,
            totalStageStatistics: {
              totalMiningAmount: miningState.stageData.stageTotalRewardPool,
              alreadyMined: totalStatisticsResponse.data.totalMined,
              participants: totalStatisticsResponse.data.participants,
              currentDayParticipants: totalStatisticsResponse.data.currentDayParticipants,
              totalPredictionVolume: totalStatisticsResponse.data.totalPredicted,
              currentDayTotalPredictionVolume:
                totalStatisticsResponse.data.currentDayTotalPredicted,
              dailyPredictionVolume: totalStatisticsResponse.data.dailyPredicted,
            },
          },
        });
      }
    } catch (e) {
      console.log('Something went wrong during getting total minings statistics');
    }
  }, [state.baseUrl, state.currentNetworkId, miningState.stage]);

  useEffect(() => {
    getTotalStatistics();
  }, [miningState.network, miningState.stage]);

  return (
    <div>
      <MiningStatisticsTitle>
        {t('TOTAL STATISTICS')}
      </MiningStatisticsTitle>
      <Statistics />
    </div>
  );
};

export default TotalStatisticsContainer;
