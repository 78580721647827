import React, { ReactElement, useState } from 'react';
import {
  FormControl,
  ListItemIcon,
  SelectChangeEvent,
} from '@mui/material';
import { useGlobalState } from 'context/globalState';
// icons
import {
  getNetworkComponent,
  getNetworkIconLink,
} from 'networksHelper';
// styles
import {
  DropDownMenuItem,
  DropDownLogo,
  DropDownSelect,
} from 'styles/desktop/StyledHeader';

const NetworkSwitcher: React.FC = (): ReactElement => {
  const [openedSelect, setOpenedSelect] = useState<boolean>(false);
  const { state, dispatch } = useGlobalState();

  const openSelect = (): void => {
    setOpenedSelect(true);
  };

  const closeSelect = (): void => {
    setOpenedSelect(false);
  };

  const changeNetwork = (event: SelectChangeEvent<unknown>) => {
    dispatch({
      type: 'SET_CURRENT_NETWORK',
      payload: {
        ...state,
        currentNetworkId: event.target.value,
      },
    });
  };

  return (
    <FormControl>
      <DropDownLogo id="openMenu" onClick={openSelect}>{getNetworkComponent({ networkId: state.currentNetworkId })}</DropDownLogo>
      <DropDownSelect
        variant="outlined"
        open={openedSelect}
        onClose={closeSelect}
        value={state.currentNetworkId}
        MenuProps={{
          anchorEl: document.getElementById('openMenu'),
        }}
        onChange={changeNetwork}
      >
        <DropDownMenuItem value={56}>
          <ListItemIcon><img src={getNetworkIconLink(56)} alt="BNB" /></ListItemIcon>
          BSC
        </DropDownMenuItem>
        {/* <DropDownMenuItem value={66}> */}
        {/*  <ListItemIcon><img src={getNetworkIconLink(66)}
        alt="OKEX" width="32" height="32" /></ListItemIcon> */}
        {/*  OKEX */}
        {/* </DropDownMenuItem> */}
      </DropDownSelect>
    </FormControl>
  );
};

export default NetworkSwitcher;
