import React, { ReactElement } from 'react';
import { ReactComponent as WiteBnb } from 'static/images/currency_icons/BNB_icon_white.svg';
import { ReactComponent as Bnb } from 'static/images/currency_icons/BNB_icon.svg';
// styles
import {
  StyledTotalEarningsItem,
  TotalEarningsItemTitle,
  TotalEarningsItemAmount,
} from 'styles/desktop/StyledReferralPrivateOffice';

interface IProps {
  active: boolean;
  title: string;
  amount: string;
  onClick?: () => void;
}

const TotalEarningsItem: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    active,
    title,
    amount,
    onClick,
  } = props;

  return (
    <StyledTotalEarningsItem active={active.toString()} onClick={onClick}>
      <TotalEarningsItemTitle>{title}</TotalEarningsItemTitle>
      <TotalEarningsItemAmount>
        {`${amount} `}
        {
          active
            ? <Bnb height={24} width={24} />
            : <WiteBnb height={24} width={24} />
        }
      </TotalEarningsItemAmount>
    </StyledTotalEarningsItem>
  );
};

TotalEarningsItem.defaultProps = {
  onClick: () => {},
};

export default TotalEarningsItem;
