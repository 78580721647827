import React, { ReactElement, useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MiningStateProvider from 'context/miningState';
import timeframeIcon from 'static/images/common_icons/timeframe_icon.svg';
// styles
import {
  MiningStatisticsColoredContainer,
  MiningStatisticsContainer,
  PageContainer,
  StyledMiningBetContainer,
  StyledTooltip, TooltipInfo,
} from 'styles/desktop/StyledMining';
import {
  StyledPredictButton,
  PredictButtonContainer,
  PredictButtonTitle,
} from 'styles/desktop/StyledClosedPools';

import BoldTitle from 'components/common/BoldTitle';
import PersonalStatisticsContainer from 'components/common/mining_personal_statistics/PersonalStatisticsContainer';
import StageDataContainer from 'components/common/mining_stage_data/StageDataContainer';
import TotalStatisticsContainer from 'components/common/mining_total_statistics/TotalStatisticsContainer';

const MiningContainer: React.FC = (): ReactElement => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const history = useHistory();
  const { i18n, t } = useTranslation(['mining']);

  const redirectToPools = () => {
    history.push('/');
  };

  const hideTooltip = () => {
    setShowTooltip(false);
  };

  const triggerTooltip = () => {
    setShowTooltip(true);
  };

  return (
    <Grid container justifyContent="center">
      <PageContainer>
        <Grid container justifyContent="center" alignItems="center">
          <BoldTitle placement="mining">
            {t('Prediction mining')}
          </BoldTitle>
          {
            !i18n.language.includes('en')
            && (
              <StyledTooltip
                open={showTooltip}
                onClose={hideTooltip}
                onOpen={triggerTooltip}
                title={(
                  <TooltipInfo>
                    {t('mining hint')}
                  </TooltipInfo>
                )}
                placement="top"
              >
                <img src={timeframeIcon} height={24} width={24} alt="i" />
              </StyledTooltip>
            )
          }
        </Grid>
        <MiningStateProvider>
          <StageDataContainer />
          <MiningStatisticsColoredContainer>
            <MiningStatisticsContainer>
              <PersonalStatisticsContainer />
            </MiningStatisticsContainer>
          </MiningStatisticsColoredContainer>
          <MiningStatisticsColoredContainer>
            <MiningStatisticsContainer>
              <TotalStatisticsContainer />
            </MiningStatisticsContainer>
          </MiningStatisticsColoredContainer>
          <Grid container justifyContent="center" alignItems="center">
            <StyledMiningBetContainer>
              <PredictButtonContainer disabled={false} onClick={redirectToPools}>
                <PredictButtonTitle>
                  {t('common:Predict')}
                </PredictButtonTitle>
                <StyledPredictButton />
              </PredictButtonContainer>
            </StyledMiningBetContainer>
          </Grid>
        </MiningStateProvider>
      </PageContainer>
    </Grid>
  );
};

export default MiningContainer;
