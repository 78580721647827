import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  TableBody,
  TableRow,
} from '@mui/material';
import {
  RoundCountLabel,
  StyledDirectionItem,
  StyledLastBets,
  StyledLeaderboardModal,
  StyledLeaderboardModalContainer,
  StyledTableModalRow,
  StyledTableRoundModalCell,
  StyledUserIdGrid,
  StyledUserInformation,
} from 'styles/common/StyledLeaderboardModal';
import {
  PersonIcon,
  StyledTable,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  StyledWinningsGrid,
} from 'styles/desktop/StyledLeaderBoard';
import iconPurple from 'static/images/leaderboard/iconPurple.svg';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../../context/modalState';
import { useGlobalState } from '../../../context/globalState';
import { useLeaderboardState } from '../../../context/leaderboardContext';
import { getCurrencyName } from '../../../networksHelper';

const LeaderBoardModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const { modalsState, modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();
  const { i18n, t } = useTranslation(['leaderboard']);
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();
  const { leaderboardSender } = modalsState;
  const [betState, setBetState] = useState<any[]>([]);

  const currentSender = modalsState.leaderboardSender || 'empty';
  const getLastFiveBets = (async () => {
    const userId = modalsState.leaderboardSender?.toLowerCase();
    const data = await axios.get(`${state.baseUrl}/statistics/user/${userId}/last-bets?networkId=${state.currentNetworkId}`);
    if (data.data) {
      setBetState(data.data);
    }
  });

  const getCurrentUser = () => (
    leaderboardState.users?.filter((data) => data.sender === state.account.toLowerCase())[0]
  );
  const getUserBySender = (sender : string) => (
    leaderboardState.users?.filter((data) => data.sender === sender.toLowerCase())[0]
  );

  useEffect(() => {
    if (modalsState.leaderboardSender) getLastFiveBets();
  }, [state.baseUrl, state.account, modalsState.leaderboardSender, open]);

  useEffect(() => {
    if (modalsState.openedLeaderboardModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open leaderboard modal',
        },
      });
    }

    setOpen(modalsState.openedLeaderboardModal);
  }, [modalsState.openedLeaderboardModal]);

  const handleClose = () => {
    modalDispatch({
      type: 'CLOSE_LEADERBOARD_MODAL',
      payload: {
        sender: '',
      },
    });
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <StyledLeaderboardModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        style={{ top: '180px' }}
        onClose={handleClose}
        closeAfterTransition
      >
        <>
          <StyledUserInformation container justifyContent="flex-start" alignItems="center">
            <PersonIcon src={iconPurple} mobile={isMobile} alt="icon" />
            <Grid item>
              <StyledUserIdGrid item>{leaderboardSender}</StyledUserIdGrid>
            </Grid>
          </StyledUserInformation>
          <StyledLeaderboardModalContainer container justifyContent="center">
            <TableBody>
              <StyledTable sx={{ minWidth: 712, maxWidth: 720 }} size="small" aria-label="a dense table">
                <StyledTableHead mobile={isMobile}>
                  <TableRow>
                    <StyledTableCell align="center" style={{ width: '187px' }}>
                      {t('Net winnings')}
                      {` (${getCurrencyName(state.currentNetworkId)})`}
                    </StyledTableCell>
                    <StyledTableCell align="center">{t('Win rate')}</StyledTableCell>
                    <StyledTableCell align="center">{t('Rounds won')}</StyledTableCell>
                    <StyledTableCell align="center">{t('Rounds played')}</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <StyledTableRow isCurrentUser={false}>
                  <StyledTableCell align="center">
                    <Grid container direction="column">
                      <StyledWinningsGrid
                        netWinnings={getUserBySender(currentSender)?.netWinnings}
                        item
                      >
                        { (getUserBySender(currentSender)?.netWinnings.toFixed(6)) }
                      </StyledWinningsGrid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell align="center">{getUserBySender(currentSender)?.winrate}</StyledTableCell>
                  <StyledTableCell align="center">{getUserBySender(currentSender)?.roundsWon}</StyledTableCell>
                  <StyledTableCell align="center">{getUserBySender(currentSender)?.roundsPlayed}</StyledTableCell>
                </StyledTableRow>
              </StyledTable>
            </TableBody>
          </StyledLeaderboardModalContainer>

          <RoundCountLabel item>{t('LAST 5 BETS')}</RoundCountLabel>
          <StyledLastBets container justifyContent="center">
            <TableBody>
              <StyledTable sx={{ minWidth: 712, maxWidth: 720 }} size="small" aria-label="a dense table">
                <StyledTableHead mobile={isMobile}>
                  <TableRow>
                    <StyledTableCell align="center">{t('Round')}</StyledTableCell>
                    <StyledTableCell align="center">{t('Side')}</StyledTableCell>
                    <StyledTableCell align="center">
                      {t('Winnings')}
                      {` (${getCurrencyName(state.currentNetworkId)})`}
                    </StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                {betState.map((row) => (
                  <StyledTableModalRow>
                    <StyledTableRoundModalCell align="center">{row.poolId}</StyledTableRoundModalCell>
                    <StyledTableCell align="center">
                      <StyledDirectionItem container justifyContent="center">
                        <Grid item style={row.isBull ? { color: 'rgba(44, 214, 2, 1)' } : { color: 'red' }}>{row.isBull ? 'Bull' : 'Bear'}</Grid>
                      </StyledDirectionItem>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Grid container direction="column">
                        <StyledWinningsGrid
                          netWinnings={row.netWinnings}
                          item
                        >
                          {row.netWinnings.toFixed(6)}
                        </StyledWinningsGrid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableModalRow>
                ))}
              </StyledTable>
            </TableBody>
          </StyledLastBets>
        </>

      </StyledLeaderboardModal>
    </Grid>
  );
};

export default LeaderBoardModal;
