import React, { ReactElement } from 'react';
import { AdvancedChart } from 'react-tradingview-embed';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledChartContainer,
} from 'styles/mobile/StyledActualPoolBody';

const ChartContainer: React.FC = React.memo((): ReactElement => {
  const { t } = useTranslation(['chart']);

  return (
    <StyledChartContainer>
      <AdvancedChart
        widgetProps={{
          theme: 'dark',
          symbol: 'BINANCE:AXSUSDT',
          interval: '5',
          height: '400px',
          withdateranges: false,
          hide_top_toolbar: true,
          hide_side_toolbar: true,
          style: '1',
          range: undefined,
        }}
      />
      <div>
        {`* ${t('chart hint')} .`}
      </div>
    </StyledChartContainer>
  );
});

export default ChartContainer;
