import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Title from 'components/common/BoldTitle';

const ClosedPoolsHeader: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center">
      <Title placement="header">{t('Who are you today?')}</Title>
    </Grid>
  );
};

export default ClosedPoolsHeader;
