import styled from 'styled-components';
import {
  Modal,
  Paper,
} from '@mui/material';

export const ModalContainer = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root{
    width: 924px;
    height: 400px;
    border-radius: 16px;
    outline: none;
    background: #1A1A39;
    padding: 72px 88px;
  }
`;

export const Title = styled.h2`
    font-weight: bold;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.03em;
    color: #fd0;
`;

export const Content = styled.p`
    text-align: center;
    font-size: 24px;
    width: 60%;

    a {
        color: #00ffff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;
