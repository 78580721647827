import styled from 'styled-components';
import {
  Grid, OutlinedInput, Select,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import DropDownBlockAbout from '../../components/desktop/header/DropDownNavigationComponents/DropDownBlockAbout';

export const DropDownBlockContainer = styled(Grid)<{ active: boolean; isDark: boolean; }>`
  position: relative;
  margin-right: 10px;
  height: 48px;
  width: 300px;
  border-radius: 12px;
  padding: 14px 16px;
  cursor: pointer;
  background: ${({ isDark }) => (isDark ? '#000523' : '#00094b')};
  background-clip: padding-box;
  border: 2px solid transparent;
  &:after {
    content: '';
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -2px;
    z-index: -1;
    background: ${({ active }) => (!active ? 'linear-gradient(to right, rgb(126, 56, 249) 50%, rgb(0, 188, 247) 100%)' : '#F2B92F')};
    border-radius: 12px;
}
`;

export const DropDownName = styled(Grid)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
  text-align: center;
`;
export const StyledOutlinedInput = styled(OutlinedInput)`
    color: black;
`;

export const StyledMenuItemIcon = styled.img`
  height: 20px;
  width: 20px;
  padding-left: 5px;
  padding-top: 6px;
  margin-right: 5px;
`;

export const StyledDropDownSelect = styled(Select)`
  &.MuiOutlinedInput-root{
    display: none;
  }
`;

export const StyledHamburgerItem = styled.img`
  padding-right: 8px;
  margin-top: -2px;
`;

export const StyledDefaultLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 20px;
  border-radius: 16px 0px 0px 16px;
  :hover{
    text-shadow: 0px 0px 1px black
  }
`;

export const StyledDropDownBlockAbout = styled(DropDownBlockAbout)`
  margin-left: 15px;
`;

export const StyledNavlink = styled(NavLink)`
  color: black;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 20px;
  border-radius: 16px 0px 0px 16px;
  :hover{
    text-shadow: 0px 0px 1px black
  }
`;
