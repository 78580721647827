import React, {
  ReactElement, useState, useEffect, ReactNode,
} from 'react';
import { useGlobalState } from 'context/globalState';
import {
  Grid, SelectChangeEvent,
} from '@mui/material';
import {
  IToken,
  IAsset,
  IIcons,
} from 'interfaces/common.d';
import { useTranslation } from 'react-i18next';
// icons
import PROS from 'static/images/currency_icons/PROS_icon.svg';
import USDC from 'static/images/currency_icons/USDC_icon.svg';
import BNB from 'static/images/currency_icons/BNB_icon.svg';
import BTC from 'static/images/currency_icons/BTC_icon.svg';
import REVV from 'static/images/currency_icons/REVV_icon.svg';
import AXS from 'static/images/currency_icons/AXIE_icon.png';
import OKT from 'static/images/currency_icons/OKT_icon.svg';
import DropDown from './DropDown';

interface IProps {
    tokens: Array<IToken> | null;
    assets: Array<IAsset> | null;
    changeToken: (token: IToken) => void;
    changeAsset: (asset: IAsset) => void;
}

const CurrencyPredictBlock: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    tokens,
    assets,
    changeToken,
    changeAsset,
  } = props;

  const [currentToken, setCurrentToken] = useState<IToken>({ id: 0, displayName: '', value: '' });
  const [currentAsset, setCurrentAsset] = useState<IAsset>({ name: '', pair: '/' });
  const { dispatch } = useGlobalState();
  const { t } = useTranslation(['common']);

  const icons: IIcons = {
    PROS,
    USDC,
    BNB,
    BTC,
    REVV,
    AXS,
    OKT,
  };

  useEffect(() => {
    if (tokens && tokens.length && !currentToken.displayName) {
      setCurrentToken(tokens[0]);
    }

    if (assets && assets.length && !currentAsset.name) {
      setCurrentAsset(assets[0]);
    }
  }, [tokens, assets, currentToken, currentAsset]);

  useEffect(() => {
    if (assets) {
      setCurrentAsset(assets[0]);
    }
  }, [assets]);

  useEffect(() => {
    if (tokens) {
      setCurrentToken(tokens[0]);
    }
  }, [tokens]);

  const changeCurrentToken = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const tokenName = event.target.value as string;
    if (tokens) {
      const tokenObject = tokens.find((token) => token.displayName === tokenName) || currentToken;
      setCurrentToken(tokenObject);
      changeToken(tokenObject);
    }
  };

  const changeCurrentAsset = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const assetName = event.target.value as string;
    if (assets) {
      const assetObject = assets.find((asset) => asset.name === assetName) || currentAsset;
      setCurrentAsset(assetObject);
      changeAsset(assetObject);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform filters in history',
        amplitudeProps: {
          token: currentToken.displayName,
          asset: currentAsset.name,
        },
      },
    });
  }, [currentToken, currentAsset]);

  return (
    <Grid container justifyContent="center" wrap="nowrap">
      {
          tokens
          && (
          <DropDown
            title={t('You predict in')}
            items={tokens.map((token) => token.displayName)}
            icons={icons}
            currentItem={currentToken.displayName}
            changeItem={changeCurrentToken}
          />
          )
      }
      {
          assets
          && (
          <DropDown
            title={t('You predict on pair')}
            items={assets.map((asset) => asset.name)}
            icons={icons}
            currentItem={currentAsset.name}
            changeItem={changeCurrentAsset}
          />
          )
      }
    </Grid>
  );
};

export default CurrencyPredictBlock;
