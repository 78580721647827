/* eslint-disable no-alert */
import React, {
  ReactElement, useState, useEffect, useCallback,
} from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { useTableState } from 'context/tableState';
import {
  Grid,
} from '@mui/material';
import Spinner from 'styles/common/StyledSpinner';
import {
  IPool,
} from 'interfaces/state.d';
import {
  IQueryParameters,
} from 'interfaces/common.d';
// styles
import {
  SpinnerContainer,
} from 'styles/desktop/StyledClosedPools';
import Pagination from './ContentPagination';
import DesktopView from './TableDesktopView';
import BlockView from './TableMobileView';

interface IProps {
    pools: Array<IPool> | null;
    currentBlock: number;
}

const ContentContainer: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    pools,
    currentBlock,
  } = props;

  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [contentInitialized, setContentInitialized] = useState<boolean>(false);
  const { state, dispatch } = useGlobalState();
  const { tableState, tableDispatch } = useTableState();

  const getTotalRows = useCallback(async (baseUrl: string): Promise<void> => {
    const queryParameters: IQueryParameters = {
      duration: 0,
      asset: tableState.asset.pair || undefined,
      token: tableState.token.displayName || undefined,
      page: tableState.page,
      networkId: state.currentNetworkId,
      user: tableState.accountFilter ? state.account || undefined : undefined,
    };
    const totalRowsResponse = await axios.get(`${baseUrl}/pools/_total`, { params: queryParameters });
    setTotalRows(totalRowsResponse.data as number);
  }, [state.account, state.currentNetworkId, tableState]);

  const changePage = (newPage: number) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform switch page closed pools',
        amplitudeProps: {
          page: newPage,
        },
      },
    });

    setCurrentOffset(newPage);
    tableDispatch({ type: 'SET_PAGE', payload: { ...tableState, page: newPage - 1 } });
  };

  const getTableView = () => {
    if (pools) {
      return (tableState.tableViewIndex
        ? <DesktopView pools={pools} />
        : <BlockView pools={pools} currentBlock={currentBlock} />);
    }
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  };

  useEffect(() => {
    if (!totalRows && !contentInitialized && state.currentNetworkId) {
      getTotalRows(state.baseUrl);
    }
  }, [totalRows, contentInitialized,
    getTotalRows, state.currentNetworkId, state.baseUrl]);

  useEffect(() => {
    if (totalRows) {
      setContentInitialized(true);
    }
  }, [totalRows]);

  // as table state changes we need to get new amount of rows in table
  useEffect(() => {
    setTotalRows(0);
    setContentInitialized(false);
  }, [tableState]);

  return (
    <>
      {
        getTableView()
      }
      <Grid container justifyContent="center" id="table">
        <Grid item>
          <Pagination
            total={totalRows}
            changePage={changePage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContentContainer;
