import styled from 'styled-components';
import {
  Modal,
  Paper,
} from '@mui/material';

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    outline: none;
    background: #1A1A39;
    padding: 30px 18px;  
  }
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 23px;
  line-height: 34px;
  letter-spacing: 0.03em;
  color: #fd0;
`;

export const Content = styled.p`
  font-size: 24px;

  a {
    color: #00ffff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
`;
