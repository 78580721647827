import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';

export const StyledFooter = styled.footer`
    height: 220px;
    margin-top: auto;
    background: linear-gradient(180deg, rgba(26, 26, 57, 0) 0%, #1A1A39 100%);
`;

export const LinkRow = styled(Grid)`
    border-top: 1px solid rgba(228, 228, 228, 0.2);
    border-bottom: 1px solid rgba(228, 228, 228, 0.2);
    padding: 16px 0;

    a {
        margin: 0 32px;
        color: white;
        text-decoration: none;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.05em;
    }
`;

export const SocialContainer = styled(Grid)`
    margin: 32px 0;
`;

export const ReservedRow = styled(Grid)`
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.6);
`;
