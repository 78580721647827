import React, { ChangeEvent, MouseEventHandler, ReactElement } from 'react';
// styles
import {
  StyledPagination,
} from 'styles/desktop/StyledClosedPools';

interface IProps {
    total: number;
    changePage: any;
    currentPage?: number;
}

const ContentPagination: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    total,
    changePage,
    currentPage,
  } = props;

  const totalPages = (rows : number) => Math.ceil(rows / 7);
  return (
    <StyledPagination
      count={totalPages(total)}
      defaultPage={1}
      page={currentPage}
      variant="outlined"
      shape="rounded"
      onChange={(_, offset) => changePage(offset)}
    />
  );
};

ContentPagination.defaultProps = {
  currentPage: undefined,
};

export default ContentPagination;
