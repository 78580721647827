import React, {
  ReactElement,
  useState,
  useEffect, useCallback,
} from 'react';
import {
  Slide,
  Grid,
} from '@mui/material';
import axios from 'axios';

import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
import Search from 'components/common/Search';
// styles
import {
  ModalContainer,
  StyledPaper,
  Title,
  CloseButton,
  PaperContainer,
  SearchContainer,
} from 'styles/modals/StyledSearchModal';
import { isMobile } from 'react-device-detect';
import { ILeaderboardUser } from '../../../interfaces/leaderboard.d';
import {
  PersonIcon,
  StatisticComponentsContainer,
  StyledRanked,
  StyledUserInfo,
} from '../../../styles/desktop/StyledLeaderBoard';
import iconWhite from '../../../static/images/leaderboard/iconWhite.svg';
import {
  PersonCardContainer,
  StatisticPersonComponent, StyledWinningsGrid,
  WinningPersonComponent,
  WinningPersonValues,
} from '../../../styles/mobile/StyledPersonCard';
import { useLeaderboardState } from '../../../context/leaderboardContext';

const LeaderboardSearchModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [cards, setCards] = useState<Array<ILeaderboardUser> | null>(null);
  const [loading, setLoading] = useState(false);
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();
  const { modalsState, modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();
  const { t, i18n } = useTranslation(['modals']);
  const [searchId, setSearchId] = useState<string>('');

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_LEADERBOARD_SEARCH_MODAL' });
    setCards(null);
  };

  const openLeaderboardModal = (sender : string | undefined) => {
    modalDispatch({
      type: 'OPEN_LEADERBOARD_MODAL',
      payload: {
        sender,
      },
    });
  };

  const currentUserId = () => {
    if (cards) {
      return leaderboardState.users?.findIndex((user) => user.sender === cards[0]?.sender);
    }
    return null;
  };

  const getSearchCards = useCallback(async (wallet : string) => {
    try {
      const user = await axios.get(`${state.baseUrl}/statistics/user/${wallet}/leaderboard/?networkId=${state.currentNetworkId}`);
      if (user.data) {
        setCards([user.data]);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error received in LeaderboardSearchModal when calling the method getSearchCards', e);
    }
  }, [state.baseUrl, state.currentNetworkId]);

  const changeSearchId = (wallet: string): void => {
    setCards(null);
    getSearchCards(wallet);
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform use Leaderboard Search',
        amplitudeProps: {
          text: wallet,
        },
      },
    });
    setSearchId(wallet);
  };

  const getPersonCard = () => {
    if (loading) {
      return t('loading');
    }
    if (cards) {
      return (cards.map((card) => (
        <PersonCardContainer isCurrentUser={card.sender === state.account.toLowerCase()}>
          <StyledUserInfo>
            <PersonIcon src={iconWhite} mobile={isMobile} onClick={() => openLeaderboardModal(card.sender)} alt="icon" />
            {card.sender.slice(0, 17)}
            ...
            <StyledRanked
              mobile={isMobile}
              isCurrentUser={card.sender === state.account.toLowerCase()}
            >
              {currentUserId() || 0 + 1}
              {' '}
              place
            </StyledRanked>
          </StyledUserInfo>
          <StatisticComponentsContainer>
            <StatisticPersonComponent isCurrentUser={card.sender === state.account.toLowerCase()} container justifyContent="space-between">
              <Grid item>{t('Win rate')}</Grid>
              <Grid item>
                {`${card.winrate}%`}
              </Grid>
            </StatisticPersonComponent>
            <StatisticPersonComponent isCurrentUser={card.sender === state.account.toLowerCase()} container justifyContent="space-between">
              <Grid item>{t('Rounds won')}</Grid>
              <Grid item>{`${card.roundsWon}/${card.roundsPlayed}`}</Grid>
            </StatisticPersonComponent>
            <WinningPersonComponent isCurrentUser={card.sender === state.account.toLowerCase()} container justifyContent="space-between" alignItems="center">
              <Grid item>{t('Net winning (BNB)')}</Grid>
              <Grid item>
                <WinningPersonValues isCurrentUser={card.sender === state.account.toLowerCase()} container direction="column">
                  <StyledWinningsGrid
                    netWinnings={card.netWinnings}
                    item
                  >
                    {card.netWinnings > 0 ? `+${card.netWinnings.toFixed(6)}` : `-${card.netWinnings.toFixed(6)}` }
                  </StyledWinningsGrid>
                </WinningPersonValues>
              </Grid>
            </WinningPersonComponent>
          </StatisticComponentsContainer>
        </PersonCardContainer>
      )));
    }
    if (!loading && !cards && searchId) {
      return t('Sorry, nothing was found for your request. Try another one.');
    }
    return t('Enter wallet id to find it');
  };

  useEffect(() => {
    setOpen(modalsState.openedLeaderboardSearchModal);
  }, [modalsState.openedLeaderboardSearchModal]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Slide direction="up" in={open}>
        <StyledPaper>
          <PaperContainer
            container
            justifyContent="space-between"
            direction="column"
          >
            <Grid item>
              <Grid container direction="column">
                <Grid item container justifyContent="flex-end">
                  <CloseButton onClick={handleClose}>
                    {`${t('Close')} X`}
                  </CloseButton>
                </Grid>
                <SearchContainer item>
                  <Search doSearch={changeSearchId} />
                </SearchContainer>
                <Grid item>
                  <Title>{t('Searching results')}</Title>
                </Grid>
                <Grid item>
                  {
                    getPersonCard()
                  }
                </Grid>
              </Grid>
            </Grid>
          </PaperContainer>
        </StyledPaper>
      </Slide>
    </ModalContainer>
  );
};

export default LeaderboardSearchModal;
