import React, { ReactElement } from 'react'; import {
  Grid,
} from '@mui/material';
import {
  IIcons,
} from 'interfaces/common.d';
import { useActualPoolState } from 'context/actualPoolState';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Bull } from 'static/images/standing_bull.svg';
import { ReactComponent as Bear } from 'static/images/standing_bear.svg';
// currency
import BNB from 'static/images/currency_icons/BNB_icon_white.svg';
import PROS from 'static/images/currency_icons/PROS_icon_white.svg';
import USDC from 'static/images/currency_icons/USDC_icon_white.svg';
import BTC from 'static/images/currency_icons/BTC_icon_white.svg';
import REVV from 'static/images/currency_icons/REVV_icon.svg';
import OKT from 'static/images/currency_icons/OKT_icon_white.svg';
// styles
import {
  SideButtonContainer,
  SideTitle,
  SideContentTitle,
  SideContent,
  SideImageContainer,
  SideContentContainer,
} from 'styles/desktop/StyledClosedPools';

interface IProps {
    isBull: boolean;
    currency: string;
}

const currencies: IIcons = {
  PROS,
  USDC,
  BNB,
  BTC,
  REVV,
  OKT,
};

const ActualPoolBetSide: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    isBull,
    currency,
  } = props;

  const { actualPoolState } = useActualPoolState();
  const { t } = useTranslation(['actual_pool']);

  return (
    <SideButtonContainer
      container
      direction={isBull ? 'row' : 'row-reverse'}
      bull={isBull.toString()}
    >
      <SideImageContainer bull={isBull.toString()} item xs={5}>
        {
          isBull
            ? <Bull />
            : <Bear />
        }
      </SideImageContainer>
      <SideContentContainer item xs={5}>
        <SideTitle>
          {
            isBull
              ? t('above')
              : t('below')
          }
        </SideTitle>
        <Grid container direction="column">
          <SideContentTitle item>
            {t('common:participantVolume')}
          </SideContentTitle>
          <SideContent item>
            <img
              src={currencies[currency as keyof IIcons]}
              alt="icon"
              height="24"
            />
            {
              isBull
                ? actualPoolState.bullQuote
                : actualPoolState.bearQuote
            }
          </SideContent>
        </Grid>
        <Grid container direction="column">
          <SideContentTitle item>
            {t('common:Ratio')}
          </SideContentTitle>
          <SideContent item>
            {
              `x ${isBull
                ? actualPoolState.bullCoef
                : actualPoolState.bearCoef}`
            }
          </SideContent>
        </Grid>
      </SideContentContainer>
    </SideButtonContainer>
  );
};

export default ActualPoolBetSide;
