import React, {
  ReactElement, useEffect, useState, useCallback,
} from 'react';
import { ethers } from 'ethers';
import
{
  Switch,
  Route,
} from 'react-router-dom';
import ActualPoolStateProvider from 'context/actualPoolState';
import TableStateProvider from 'context/tableState';
import StatisticsProvider from 'context/statisticsState';
import MiningStateProvider from 'context/miningState';
import ReferralPrivateOfficeStateProvider from 'context/referralPrivateOfficeState';
import { ContractInterface } from '@ethersproject/contracts';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import contractAbi from 'static/abi/contract.abi.json';
import axios from 'axios';
import { HubConnectionBuilder } from '@microsoft/signalr';
import {
  IFeed,
  IToken,
} from 'interfaces/common.d';
import { getNetworksIds } from 'networksHelper';
import BetModal from 'components/modals/mobile/BetModal';
import LoadingModal from 'components/modals/desktop/LoadingModal';
import WrongNetworkModal from 'components/modals/mobile/WrongNetworkModal';
import YouAreInModal from 'components/modals/mobile/YouAreInModal';
import SearchModal from 'components/modals/mobile/SearchModal';
import StatisticsModal from 'components/modals/mobile/StatisticsModal';
import LeaderboardModal from 'components/modals/mobile/LeaderboardModal';
import ActualPool from './actual_pool/ActualPoolContainer';
import ClosedPools from './closed_pools/ClosedPoolsContainer';
import Mining from './mining/MiningContainer';
import Leaderboard from './leaderboard/LeaderboardContainer';
import LeaderboardContext from '../../context/leaderboardContext';
import LeaderboardSearchModal from '../modals/mobile/LeaderboardSearchModal';
import ReferralContainer from './referral/ReferralContainer';
import ReferralConnectWalletModal from '../modals/mobile/ReferralConnectWalletModal';
import ReferralRecommendModal from '../modals/mobile/ReferralRecommendModal';
import ReferralFollowedTheLinkPage from './referral/ReferralFollowedTheLinkPage';

const getContractAbi = (): ContractInterface => contractAbi;

const MobileContainer: React.FC = (): ReactElement => {
  const [accounts, setAccounts] = useState<Array<string> | null>(null);
  const [balance, setBalance] = useState<number | null>(null);
  const [abi, setAbi] = useState<ContractInterface | null>(null);
  const [address, setAddress] = useState<string>('');
  const [feeds, setFeeds] = useState<Array<IFeed> | null>(null);
  const [tokens, setTokens] = useState<Array<IToken> | null>(null);
  const [blockTime, setBlockTime] = useState<number>(0);
  const [appInitialized, setAppInitialized] = useState<boolean>(false);
  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();
  const networkId = state.currentNetworkId;

  const getAbi = useCallback(async (): Promise<void> => {
    const contractAbiResponse = getContractAbi();
    setAbi(contractAbiResponse as ContractInterface);
  }, []);

  const getAddress = useCallback(async (baseUrl: string): Promise<void> => {
    const addressResponse = await axios.get(`${baseUrl}/network/${networkId}/contract-address`);
    setAddress(addressResponse.data as string);
  }, [networkId]);

  const getBalance = useCallback(async () => {
    if (accounts && state.networkProvider) {
      const balanceResponse = await state.networkProvider.getBalance(accounts[0]);
      setBalance(+ethers.utils.formatEther(balanceResponse));
    }
  }, [accounts, state.networkProvider]);

  const getFeeds = useCallback(async (baseUrl: string): Promise<void> => {
    const feedsResponse = await axios.get(`${baseUrl}/network/${networkId}/feeds`);
    const feedsData = feedsResponse.data.map((feed: IFeed) => {
      const tmpFeed: IFeed = { ...feed };
      tmpFeed.assetName = tmpFeed.displayName.split('/', 1)[0].trim();

      return tmpFeed;
    });
    setFeeds(feedsData as Array<IFeed>);
  }, [networkId]);

  const getTokens = useCallback(async (baseUrl: string): Promise<void> => {
    const tokensResponse = await axios.get(`${baseUrl}/network/${networkId}/tokens`);
    if (tokensResponse.data) {
      if (!state.acceptPros) {
        tokensResponse.data = tokensResponse.data.filter((token: IToken) => token.displayName !== 'PROS');
      }
      setTokens(tokensResponse.data);
    }
  }, [networkId, state.acceptPros]);

  const initContract = useCallback(() => {
    if (!state.networkProvider || !address || !contractAbi || !accounts || !accounts.length) {
      throw new Error('Unable to initialize contract');
    }

    dispatch({
      type: 'INIT_CONTRACT',
      payload: {
        address,
        abi: contractAbi,
        signer: state.networkProvider.getUncheckedSigner(accounts[0]),
      },
    });
  }, [state.networkProvider, address, contractAbi, dispatch, accounts]);

  const connect = useCallback(async (): Promise<void> => {
    if (!(window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet))) {
      window.location.href = 'https://metamask.io';
      return;
    }

    let accountList: Array<string> = [];
    if (state.networkProvider) {
      const knownNetworksIds = getNetworksIds();
      const metamaskNetworkId = +window.ethereum.chainId;
      if (knownNetworksIds.includes(metamaskNetworkId)) {
        dispatch({
          type: 'SET_CURRENT_NETWORK',
          payload: {
            ...state,
            currentNetworkId: metamaskNetworkId,
          },
        });
      } else {
        modalDispatch({ type: 'OPEN_WRONG_NETWORK_MODAL' });
        return;
      }
      accountList = await state.networkProvider.listAccounts();
    }

    if (accountList.length < 1) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.location.reload();
    } else {
      setAccounts(accountList);
    }
  }, [state.networkProvider, networkId, modalDispatch]);

  const getBlockTime = useCallback(async (baseUrl: string): Promise<void> => {
    const blockTimeResponse = await axios.get(`${baseUrl}/network/${networkId}/block-time`);
    setBlockTime(blockTimeResponse.data as number);
  }, [networkId]);

  useEffect(() => {
    setAddress('');
    setFeeds(null);
    setTokens(null);
    setBlockTime(0);
    setAppInitialized(false);
  }, [networkId]);

  // getting balance only when user have at least 1 connected account
  useEffect(() => {
    if (accounts && accounts.length) {
      getBalance();
    }
  }, [accounts, getBalance]);

  // init contract if only we have user account address
  useEffect(() => {
    if (accounts) {
      initContract();
    }
  }, [accounts]);

  useEffect(() => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform opened main page',
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (!appInitialized && abi && address
            && networkId && feeds && tokens && blockTime) {
      dispatch({
        type: 'INITIALIZE_APPLICATION',
        payload: {
          abi,
          address,
          currentNetworkId: networkId,
          feeds,
          tokens,
          blockTime,
        },
      });
      setAppInitialized(true);
    }
  }, [appInitialized, dispatch, blockTime,
    abi, address, networkId, feeds, tokens]);

  useEffect(() => {
    if (!abi) {
      getAbi();
    } else if (!address) {
      getAddress(state.baseUrl);
    } else if (!feeds) {
      getFeeds(state.baseUrl);
    } else if (!tokens) {
      getTokens(state.baseUrl);
    } else if (!blockTime) {
      getBlockTime(state.baseUrl);
    }
  }, [abi, address,
    state, networkId, appInitialized,
    feeds, tokens, blockTime]);

  // setting global account and balance only when we have both
  useEffect(() => {
    if (accounts && typeof balance === 'number') {
      dispatch({ type: 'SET_ACCOUNT', payload: { account: accounts[0] } });
      dispatch({ type: 'SET_BALANCE', payload: { balance } });
    }
  }, [balance, accounts, dispatch]);

  // call connect only if application is initialized, provider exists and accounts is not set yet
  useEffect(() => {
    if (appInitialized && !accounts && state.networkProvider) {
      connect();
    }
  }, [appInitialized, state.networkProvider, accounts, connect]);

  useEffect(() => {
    if (!state.hubConnection) {
      const hubConnection = new HubConnectionBuilder()
        .withUrl(`${state.baseUrl}/event-hub`)
        .build();

      dispatch({ type: 'SET_HUB_CONNECTION', payload: { hubConnection } });

      hubConnection.start();
    }
  }, [state.hubConnection, state.baseUrl, dispatch]);

  return (
    <StatisticsProvider>
      <Switch>
        <Route exact path="/">
          <ActualPoolStateProvider>
            <ActualPool />
          </ActualPoolStateProvider>
        </Route>
        <Route path="/referral-info">
          <ReferralPrivateOfficeStateProvider>
            <ReferralContainer />
          </ReferralPrivateOfficeStateProvider>
        </Route>
        <Route path="/mining">
          <MiningStateProvider>
            <Mining />
          </MiningStateProvider>
        </Route>
        <Route path="/referral-link/:referrerCode">
          <ReferralFollowedTheLinkPage />
        </Route>
        <TableStateProvider>
          <Route path="/closed-pools">
            <ClosedPools />
            <SearchModal />
          </Route>
        </TableStateProvider>
        <LeaderboardContext>
          <Route path="/leaderboard">
            <Leaderboard />
            <LeaderboardSearchModal />
          </Route>
          <LeaderboardModal />
        </LeaderboardContext>
      </Switch>
      <StatisticsModal />
      <BetModal />
      <LoadingModal />
      <WrongNetworkModal />
      <YouAreInModal />
      <ReferralConnectWalletModal />
      <ReferralRecommendModal />
    </StatisticsProvider>
  );
};

export default MobileContainer;
