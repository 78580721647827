import styled from 'styled-components';
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

export const FollowedTheLinkContainer = styled.div`
  max-width: 1440px;
  padding-top: 100px;
  margin:0 25% 0 25%;
  width:50%;
`;

export const ProsperIcon = styled.img`
  display: block;
  margin: 0 auto;
  padding-top: 37px;
;`;

export const FollowedTheLinkDataContainer = styled(Grid)`
  padding-top: 34px;
  width: 940px;
  justify-content: center;
;`;

export const ConnectWalletImg = styled.img`
  padding-top: 72px;
  margin-left: 250px;
`;

export const ReferalSnackbar = styled(Snackbar)`
  color: white;
`;
