import React, { ReactElement, useState, useEffect } from 'react';
import {
  Modal,
  Fade,
} from '@mui/material';
import { useModalState } from 'context/modalState';
import { useTranslation } from 'react-i18next';
import sphere from 'static/images/sphere.svg';
// styles
import {
  StyledLoadingContainer,
  StyledLoadingDots,
  StyledLoadingImg,
} from 'styles/modals/StyledLoadingModal';

const LoadingModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);

  const { modalsState, modalDispatch } = useModalState();
  const { t } = useTranslation('modals');

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_LOADING_MODAL' });
  };

  useEffect(() => {
    setOpen(modalsState.openedLoadingModal);
  }, [modalsState.openedLoadingModal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledLoadingContainer>
          <StyledLoadingImg src={sphere} alt="sphere" />
          {`${t('loading')}`}
          <StyledLoadingDots />
        </StyledLoadingContainer>
      </Fade>
    </Modal>
  );
};

export default LoadingModal;
