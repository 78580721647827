import styled from 'styled-components';
import {
  Grid,
  LinearProgress,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
} from '@mui/material';
import { ReactComponent as PredictButton } from 'static/images/predict_button.svg';

export const PageContainer = styled(Grid)`
  width: 1400px;
`;

// actual pool

export const StyledInformationContainer = styled(Grid)`
  width: 685px;
`;

export const StyledPredictButton = styled(PredictButton)`
  cursor: pointer;
`;

export const StyledDisabledPredictButton = styled(PredictButton)`
  cursor: pointer;
`;

export const PredictButtonContainer = styled.div<{ disabled: boolean }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #1A1A39;
  opacity: ${(props) => props.disabled && '.7'};
`;

export const PredictButtonTitle = styled.span`
  position: absolute;
  cursor: pointer;
`;

export const InformationTitle = styled(Grid)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
`;

export const InformationContent = styled(Grid)`
  border: 1px solid #FFFFFF;
  border-radius: 16px;
  padding: 4px 24px;
`;

export const InformationItem = styled(Grid)`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
`;

export const InformationConditionPrice = styled(InformationItem)`
  color: #fd0;
`;

export const PoolStatusContainer = styled(Grid)`
  margin-top: 50px;
`;

export const StyledBetContainer = styled(Grid)`
  position: relative;
  margin-top: 50px;

  ${PredictButtonContainer} {
    position: absolute;
    left: calc(50% - 112px);
    top: calc(50% - 112px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SideButtonContainer = styled(Grid)<{ bull: String }>`
  height: 256px;
  padding: 38px 0px;
  border-radius: 16px;

  background: ${(props) => (props.bull === 'true'
    ? 'linear-gradient(89.29deg, #5900F7 0.55%, rgba(89, 0, 247, 0) 85.05%)'
    : 'linear-gradient(90.31deg, rgba(51, 51, 51, 0) 1.11%, #2D2C2C 102.34%)')
};
`;

const sideInagePosition = {
  bull: {
    top: '-106px',
    left: '-20px',
  },
  bear: {
    top: '-130px',
    left: '-8px',
  },
};

export const SideImageContainer = styled(Grid)<{ bull: String }>`
  position: relative;

  svg {
    position: absolute;
    ${(props) => (props.bull === 'true'
    ? sideInagePosition.bull
    : sideInagePosition.bear)};
  }
`;

export const SideContentContainer = styled(Grid)`
  padding: 0 30px;
`;

export const SideTitle = styled.div`
  padding: 5.42403px 36.1602px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5.74545px;
  width: 148px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 2px;
  text-align: center;
`;

export const SideContentTitle = styled(Grid)`
  &.MuiGrid-root{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
    margin: 24px 0 4px;
  }
`;

export const SideContent = styled(Grid)`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;

  img {
    margin-right: 12px;
  }
`;

export const ProgressContainer = styled(Grid)`
  margin-top: 96px;
`;

export const ProgressTitle = styled(Grid)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const StylesProgressBar = styled(LinearProgress)`
  box-shadow: 0px 0px 4px #5900F7, 0px 0px 16px #5900F7;
  border-radius: 24px;

  &.MuiLinearProgress-colorPrimary {
    background: rgba(0, 188, 247, 1);
  }

  .MuiLinearProgress-bar {
    background: rgba(126, 56, 249, 1);
  }
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  margin-top: 26px;
`;

export const ProgressCursor = styled.span<{ progress: number }>`
  position: absolute;
  bottom: 0;
  left: calc(${(props) => props.progress}% - 35px);

  img {
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

export const ProgressAmountSide = styled.span`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 2px;
  margin-top: 8px;
`;

// chart
export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root{
    border-radius: 24px;
    margin-top: 97px;

    #advanced-chart-widget-container {
      width: 100%;
    } 
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiButtonBase-root{
    display: flex;
    color: #1A1A39;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    height: 100%;
    padding-left: 32px;
    padding-right: 36px;
    text-transform: uppercase;
    height: 95px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
`;

export const ExpandComment = styled.span`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #5900F7;
  margin-right: 5px;
`;

export const ChartHint = styled.div`
  margin: 24px 12px;
  color: black;
  font-size: 16px;
`;

// table
export const ClosedPoolsTableContainer = styled(Grid)`
  margin-top: 88px;
`;

const FlooredComponent = styled(Grid)`
  display: flex;
  align-items: flex-end;
`;

export const AppearenceTitle = styled(Grid)`
  &.MuiGrid-root {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2px;
  }
`;

export const AppearenceIconContainer = styled(Grid)<{ active: string }>`
  &.MuiGrid-root {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 16px;
    height: 55px;
    width: 55px;
    margin: 0 6px;
  }


  background: ${(props) => (props.active === 'true'
    ? 'linear-gradient(180deg, #FFDD00 0%, #FFC700 100%)'
    : 'white')};
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 55px;
  
  @media screen and (max-width: 420px) {
    margin: 0;
  }
`;

export const StyledTableContent = styled(Table)`
  &.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 16px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    color: #1A1A39;
    text-align: center;
    border: none;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
  }

  &[side="BULL"], &[side="BEAR"] {
    color: white;
  }
`;

export const IDTableCell = styled(StyledTableCell)`
  div {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
  }
`;

export const StyledTableHeader = styled(TableHead)`
  ${StyledTableCell} {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: white;
  }
`;

export const StyledTableRow = styled(TableRow)`
  background-color: white;
  color: #00094b;
  height: 77px;

  ${StyledTableCell}:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  ${StyledTableCell}:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const SideTableCell = styled(StyledTableCell)<{ side: string | undefined }>`
  background-color: ${(props) => {
    if (props.side) {
      if (props.side === 'BULL') {
        return '#5900F7';
      }
      return '#333';
    }
    return 'white';
  }};
  color: white;
  text-align: center;
`;

export const RatioBlock = styled(StyledTableCell)`
  padding: 1px 16px;
`;

export const RatioBlockItem = styled(Grid)<{side: string | undefined}>`
  background-color: ${(props) => {
    if (props.side) {
      if (props.side === 'BULL') {
        return '#5900F7';
      }
      return '#333';
    }
    return 'white';
  }};
  color: white;
  text-align: center;
  height: 37px;
  margin: 1px 0;
`;

export const StatusCell = styled(StyledTableCell)<{status: 'won' | 'lost' | undefined}>`
    color: ${(props) => {
    if (props.status) {
      if (props.status === 'won') {
        return '#1F9D00';
      }
      return '#D30E26';
    }
    return '#0C0C21';
  }};
  background-color: ${(props) => {
    if (props.status) {
      if (props.status === 'won') {
        return 'rgba(50, 248, 0, 0.1);';
      }
      return 'rgba(248, 0, 30, 0.1);';
    }
    return 'white';
  }};
`;

export const StyledPagination = styled(Pagination)`
  margin: 50px 0;
  .MuiButtonBase-root {
    border-radius: 6px;
    color: white;
  }

  .Mui-selected {
    background: linear-gradient(180deg, #FFDD00 0%, #FFC700 100%);
    border-radius: 6px;
    color: black;
    margin: 0 5px;
  }
  
  .MuiPaginationItem-icon {
    background: #5900F7;
    border-radius: 6px;
    color: white;
    width: 30px;
    height: 30px;
  }
  
  .MuiFlatPagination-text {
    color: white;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 64px;
`;

export const SwitcherContainer = styled(FlooredComponent)``;
export const SearchContainer = styled(FlooredComponent)``;
export const AppearenceContainer = styled(FlooredComponent)``;
