import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { ReferalManIcon, ReferalSvgContainer, ReferalWomenIcon } from 'styles/mobile/StyledReferal';
import manIcon from '../../../static/images/man-with-coin.svg';
import womanIcon from '../../../static/images/women-with-coin.svg';

const ReferralImages: React.FC = (): ReactElement => (
  <ReferalSvgContainer
    container
    justifyContent="space-around"
    alignItems="center"
    direction="row"
    position="relative"
  >
    <Grid item>
      <ReferalManIcon src={manIcon} />
    </Grid>
    <Grid item>
      <ReferalWomenIcon src={womanIcon} />
    </Grid>

  </ReferalSvgContainer>
);

export default ReferralImages;
