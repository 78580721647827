import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useStatisticsState } from 'context/statisticsState';
import { useTranslation } from 'react-i18next';
import { numberToFixedNumber } from 'numbersHelper';
import Row from './StatisticsModalRow';
import Stat from './PNLStat';

const PNLData: React.FC = (): ReactElement => {
  const { statisticsState } = useStatisticsState();
  const { t } = useTranslation(['modals']);
  const { pnl } = statisticsState;

  const getDifferenceColor: (difference: number) => 'yellow' | 'red' | 'green' = (difference: number) => {
    if (difference === 0) {
      return 'yellow';
    }
    if (difference < 0) {
      return 'red';
    }
    return 'green';
  };

  const checkForRounds = (value : string | number) => {
    if (typeof value === 'number') return `#${value}`;
    return NaN;
  };

  return (
    <Grid container>
      <Grid item xs={isMobile ? 12 : 6}>
        <Stat />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <Row
          short
          title={t('Average return')}
          color={getDifferenceColor(pnl.averageReturn)}
          difference={pnl.averageReturn}
          differenceUsd={numberToFixedNumber(pnl.averageReturn)}
        />
        <Row
          short
          title={`${t('Best round')}: ${checkForRounds(pnl.bestRoundId)}`}
          color={getDifferenceColor(pnl.bestRoundProfit)}
          difference={pnl.bestRoundProfit}
          differenceUsd={numberToFixedNumber(pnl.bestRoundProfit)}
        />
        <Row
          short
          title={t('Average position entered')}
          color={getDifferenceColor(pnl.averagePosition)}
          difference={pnl.averagePosition}
          differenceUsd={numberToFixedNumber(pnl.averagePosition)}
        />
      </Grid>
    </Grid>
  );
};

export default PNLData;
