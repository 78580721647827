import styled from 'styled-components';
import { Modal } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { ReactComponent as LinkIcon } from 'static/images/common_icons/link_icon.svg';

const colors = {
  red: '#D30E26',
  green: '#2CD602',
  yellow: '#FD0',
};

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
`;

export const SwitcherContainer = styled.div`
  margin-bottom: 24px;
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 1440px;
  height: 100%;
  outline: none;
`;

export const StatisticsContainer = styled.div`
  position: absolute;
  top: 127px;
  right: 0;
  background: rgba(26, 26, 57, 0.9);
  border: 1px solid #FFDD00;
  backdrop-filter: blur(30px);
  border-radius: 24px;
  width: 792px;
  height: 584px;
  padding: 11px 24px;
`;

export const NotificationContainer = styled.div`
  background: #5900F7;
  border-radius: 24px;
  padding: 10px 18px;
  margin-bottom: 24px;
`;

export const NotificationContent = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  color: #FD0;
  cursor: pointer;
`;

export const StyledLinkIcon = styled(LinkIcon)`
  margin-left: 8px;
  
  path {
    fill: #FD0;
  }
`;

export const StatisticsRow = styled.div`
  background: rgba(206, 181, 181, 0.1);
  border-radius: 24px;
  padding: 16px 24px;
  margin: 15px 0;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 94px;
  right: 0;
  height: 25px;
  width: 25px;
  cursor: pointer;
`;

export const StatisticsRowTitle = styled.div`
  font-size: ${isMobile ? 12 : 16}px;
  line-height: ${isMobile ? 14 : 18}px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
`;

export const StatisticsRowContent = styled.div<{color: 'red' | 'green' | 'yellow'}>`
  font-size: ${isMobile ? 18 : 24}px;
  line-height: ${isMobile ? 27 : 28}px;
  letter-spacing: ${isMobile ? 0.01 : 0.02}em;
  margin: ${isMobile ? '4px 0 8px' : '8px 0 14px'};
  color: ${(props) => (colors[props.color])};
  text-align: left;
`;

export const StatisticsRowHint = styled.div`
  font-size: ${isMobile ? 14 : 18}px;
  line-height: ${isMobile ? 16 : 21}px;
  letter-spacing: .01em;
  color: rgba(255, 255, 255, .8);
  text-align: left;
`;

export const StatisticsPnlStatContainer = styled.div`
  background: rgba(206, 181, 181, 0.1);
  border-radius: 24px;
  padding: 24px;
  margin-top: 15px;
  margin-right: ${!isMobile && '24px'};
`;

export const CircularContainer = styled.div`
  position: relative;
  height: 210px;
  width: 210px;
  margin: auto;
`;

export const CircleInner = styled.div`
  position: absolute;
  z-index: 6;
  height: 190px;
  width: 190px;
  margin: 11px;
  background: #1A1A39FF;
  border-radius: 100%;
`;

export const ProgressData = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 18px;
  font-weight: 500;
  height: calc(100% - 50px);
`;

export const VictorySign = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5EFF5A;
`;

export const VictoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VictoryStat = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export const VictoryPercent = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .01em;
  color: rgba(255, 255, 255, 0.8);
`;

export const ProgressBar = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(56, 20, 159, 1);
  -webkit-border-radius: 100%;
  clip: rect(0px, 210px, 210px, 104px);
`;

export const Progress = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 104px, 210px, 0px);
  background: #FD0;
`;

export const RightProgressBar = styled(ProgressBar)<{percent: number}>`
  ${Progress} {
    transform: rotate(-${(props) => {
    const { percent } = props;
    if (percent > 50) {
      return 3.6 * (percent - 50);
    }
    return 0;
  }}deg);
  }
`;
export const LeftProgressBar = styled(ProgressBar)<{percent: number}>`
  transform: rotate(180deg);
  
  ${Progress} {
    transform: rotate(-${(props) => {
    const { percent } = props;
    if (percent < 50) {
      return 3.6 * percent;
    }
    return 180;
  }}deg);
  }
`;

export const ProgressDifferenceContainer = styled.div`
  margin-top: 38px;
`;
