import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Grid,
} from '@mui/material';
import SearchIcon from 'static/images/images_as_components/SearchIcon';
import {
  IToken,
  IAsset,
} from 'interfaces/common.d';
import { useTableState } from 'context/tableState';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
import CurrencySwitch from 'components/common/CurrencyPredictContainer';
// styles
import {
  SeatchIconContainer,
} from 'styles/mobile/StyledClosedPools';

const PoolsFilters: React.FC = (): ReactElement => {
  const [tokens, setTokens] = useState<Array<IToken> | null>(null);
  const [assets, setAssets] = useState<Array<IAsset> | null>(null);
  const { tableState, tableDispatch } = useTableState();
  const { modalDispatch } = useModalState();
  const { state } = useGlobalState();
  const { t, i18n } = useTranslation(['closed_pools']);

  const getDisplayTokens = useCallback((): void => {
    let tmpTokens: Array<IToken> = [{ id: 0, displayName: t('All'), value: '' }];
    const stateTokens: Array<IToken> = state.tokens.map((token) => ({
      id: token.id,
      displayName: token.displayName,
      value: token.displayName,
    }));
    tmpTokens = tmpTokens.concat(stateTokens);
    setTokens(tmpTokens);
  }, [state.tokens]);

  const getDisplayAssets = useCallback(() => {
    let tmpAssets: Array<IAsset> = [{ name: t('All'), pair: '' }];
    const stateAssets: Array<IAsset> = state.feeds.map((feed) => ({
      name: feed.assetName,
      pair: feed.displayName,
    }));
    tmpAssets = tmpAssets.concat(stateAssets);
    setAssets(tmpAssets);
  }, [state.feeds]);

  const changeToken = (token: IToken): void => {
    tableDispatch({ type: 'SET_TOKEN', payload: { ...tableState, token } });
  };

  const changeAsset = (asset: IAsset): void => {
    tableDispatch({ type: 'SET_ASSET', payload: { ...tableState, asset } });
  };

  const changeSearchId = (poolId: string): void => {
    tableDispatch({ type: 'SET_SEARCH_ID', payload: { ...tableState, searchId: poolId ? +poolId : null } });
  };

  const openSearchModal = () => {
    modalDispatch({ type: 'OPEN_SEARCH_MODAL' });
  };

  useEffect(() => {
    setAssets(null);
    setTokens(null);
  }, [i18n.language]);

  // if tokens not set and in state there is some tokens
  useEffect(() => {
    if (!tokens && state.tokens.length) {
      getDisplayTokens();
    }
  }, [state.tokens, tokens]);

  // if assets not set and in state there is some feeds
  useEffect(() => {
    if (!assets && state.feeds.length) {
      getDisplayAssets();
    }
  }, [state.feeds, assets]);

  return (
    <Grid container>
      <Grid item xs={10}>
        <CurrencySwitch
          tokens={tokens}
          assets={assets}
          changeToken={changeToken}
          changeAsset={changeAsset}
        />
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <SeatchIconContainer onClick={openSearchModal}>
          <SearchIcon color="#5900F7" />
        </SeatchIconContainer>
      </Grid>
    </Grid>
  );
};

export default PoolsFilters;
