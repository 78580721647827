import React, { ReactElement, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useMiningState } from 'context/miningState';
import { useTranslation } from 'react-i18next';

import DataBlock from 'components/common/MiningDataBlock';

const TotalStatistics: React.FC = (): ReactElement => {
  const { miningState } = useMiningState();
  const { t } = useTranslation(['mining']);

  const minedPercent = useMemo(
    () => Math.ceil(
      (miningState.totalStageStatistics.alreadyMined
        / miningState.stageData.stageTotalRewardPool) * 100,
    ),
    [miningState.totalStageStatistics.alreadyMined,
      miningState.stageData.stageTotalRewardPool],
  );

  const participantsDifference = useMemo(() => (
    +((miningState.totalStageStatistics.currentDayParticipants
    / miningState.totalStageStatistics.participants) * 100).toFixed(2)
  ), [miningState.totalStageStatistics.participants,
    miningState.totalStageStatistics.currentDayParticipants]);

  const predictedVolumeDifference = useMemo(() => (
    +((miningState.totalStageStatistics.currentDayTotalPredictionVolume
    / miningState.totalStageStatistics.totalPredictionVolume) * 100).toFixed(2)
  ), [miningState.totalStageStatistics.totalPredictionVolume,
    miningState.totalStageStatistics.currentDayTotalPredictionVolume]);

  return (
    <div>
      <Grid container columnSpacing={7} rowSpacing={2}>
        <DataBlock title={t('Total mining amount', { token: 'REVV' })} amount={miningState.stageData.stageTotalRewardPool} withChart percent={100} />
        <DataBlock title={t('already mined', { token: 'REVV' })} amount={870000} />
        <DataBlock title={t('Participants')} amount={miningState.totalStageStatistics.participants} difference={participantsDifference} />
        <DataBlock title={t('Total prediction volume')} amount={miningState.totalStageStatistics.totalPredictionVolume} difference={predictedVolumeDifference} />
        <DataBlock title={t('Daily prediction volume')} amount={miningState.totalStageStatistics.dailyPredictionVolume} />
      </Grid>
    </div>
  );
};

export default TotalStatistics;
