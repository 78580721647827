import React, { ReactElement, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import {
  ListItemIcon,
  SelectChangeEvent,
} from '@mui/material';
import {
  IIcons,
  INetworkIcons,
} from 'interfaces/common.d';
// styles
import {
  StyledLabel,
  StyledSelectContainer,
  StyledMenuItem,
  StyledSelect, StyledListItemIcon,
} from 'styles/common/StyledCurrencyPredictBlock';

interface IProps {
    title: string;
    items: Array<string>;
    icons?: IIcons | INetworkIcons | undefined;
    currentItem: string;
    changeItem: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
    dark?: boolean | undefined;
}

const DropDown: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    title,
    items,
    icons,
    currentItem,
    changeItem,
    dark,
  } = props;
  const history = useHistory();

  return (
    <StyledSelectContainer
      mobile={isMobile.toString()}
      short={(history.location.pathname === '/closed-pools'
        || history.location.pathname === '/mining').toString()}
      item
    >
      <StyledLabel id="item-label" mobile={isMobile.toString()}>{ title }</StyledLabel>
      <StyledSelect
        fullWidth
        margin="dense"
        labelId="item-label"
        variant="outlined"
        value={currentItem}
        onChange={changeItem}
        mobile={isMobile.toString()}
        dark={(!!dark).toString()}
      >
        {
          items.length
            && items.map((item, i) => (
              <StyledMenuItem value={item} key={item}>
                {
                  icons && icons[item as keyof (IIcons | INetworkIcons)] && (
                    <StyledListItemIcon>
                      <img
                        style={{ marginRight: '30px' }}
                        alt="icon"
                        height="24"
                        src={icons[item as keyof (IIcons | INetworkIcons)]}
                      />
                    </StyledListItemIcon>
                  )
                }
                { item }
              </StyledMenuItem>
            ))
        }
      </StyledSelect>
    </StyledSelectContainer>
  );
};

DropDown.defaultProps = {
  dark: false,
  icons: undefined,
};

export default DropDown;
