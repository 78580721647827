import styled from 'styled-components';
import { Grid } from '@mui/material';
import CountDown from 'styles/common/StyledCountDown';
import PoolStatus from 'styles/common/StyledPoolStatus';

export const ActualPoolContainer = styled(Grid)`
    margin-top: 18px;

    ${CountDown} {
        line-height: 18px;
    }

    ${PoolStatus} {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: .01em;
        color: rgba(255, 255, 255, 0.8);
        text-transform: none;
    }
`;

export const CurrentPriceContainer = styled(Grid)`
    margin-top: 12px;
`;

export const CurrentPriceTitle = styled.span`
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
`;

export const CurrentPrice = styled.span`
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
    margin: 0 10px;
`;

export const ActualPoolDataContainer = styled(Grid)`
    padding: 0 21px;
`;

export const ChartSwitcherContainer = styled(Grid)`
    margin-top: 18px;
`;
