import { Grid, Modal, TableRow } from '@mui/material';
import styled from 'styled-components';
import { StyledTableCell } from '../desktop/StyledLeaderBoard';

export const ModalContainer = styled(Modal)`
`;
export const StyledLeaderboardModalContainer = styled(Grid)`
  width: 80%;
  background-color: #0C0C21;
  padding: 16px;
  position: fixed;
  border-radius: 24px;
  height: 100%;
`;

export const CurrentPriceContainer = styled(Grid)`
    margin-top: 12px;
`;

export const LeaderboardTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
  margin: 0;
`;

export const PersonCardContainer = styled.div`
  position: relative;
  background: #0C0C21;
  box-shadow: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 251px;
  //margin-right: 29px;
  margin-top: 30px;
  border-radius: 16px;
  padding: 15px 32px;
`;

export const CardHeader = styled(Grid)`
  margin-bottom: 28px;
  &.MuiGrid-root {
    color: rgba(255, 221, 0, 1);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PersonIcon = styled.img`
  width: 47px;
  height: 50px;
  margin-right: 8px;
`;

export const StatisticPersonComponent = styled(Grid)`
  position: relative;
  background: #0C0C21;
  color: white;
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  font-weight: 600;
  border: 1px solid white;
  &.MuiGrid-root {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 2px;
  }
`;

export const StatisticComponentsContainer = styled(Grid)`
  position: absolute;
  width: calc(100% - 64px);
  bottom: 23px;
  left: 32px;
`;

export const WinningPersonComponent = styled(Grid)`
  background-color: rgba(89, 0, 247, 1) ;
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  font-weight: 600;
  color: white;
  &.MuiGrid-root {
    width: 100%;
    height: 50px;
    padding: 8px 12px;
    border-radius: 8px;
  }
`;

export const WinningPersonValues = styled(Grid)`
  color: white;
`;

export const StyledWinningsGrid = styled(Grid)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #2CD602;
`;
export const StyledWinningsConvertGrid = styled(Grid)`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: rgba(144, 144, 144, 1);
`;

export const StyledTableModalMobileRow = styled(TableRow)`
  height: 50px;
  padding: 0;
  border-radius: 24px;
  background: rgba(206, 181, 181, 0.1);;
  color: white;

  ${StyledTableCell}:first-child {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
  }

  ${StyledTableCell}:last-child {
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
  }

  border-collapse: unset;
  margin: 2px 0px;
`;
