import styled from 'styled-components';
import {
  Modal,
  Paper,
  Grid,
} from '@mui/material';
import bull from 'static/images/modals/bull_with_pros.svg';
import bear from 'static/images/modals/bear_with_pros.svg';

export const ModalContainer = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPaper = styled(Paper)<{bull: String}>`
  &.MuiPaper-root{
    width: 100%;
    height: 100%;
    outline: none;
    padding: 23px 18px;
    overflow: hidden;
    background: ${(props) => ((props.bull === 'true')
    ? `url(${bull}) no-repeat 50% 100% / 500px 500px`
    : `url(${bear}) no-repeat -15% 100% / 500px 500px`)}, #1A1A39;
  }
`;

export const PaperContainer = styled(Grid)`
  height: 100%;
`;

export const CloseButton = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
`;

export const Title = styled.h2`
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
`;

export const Congrats = styled.div`
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
`;
