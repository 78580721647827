/* eslint-disable no-alert */
import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { ethers } from 'ethers';
import { isMobile } from 'react-device-detect';
import { useGlobalState } from 'context/globalState';
import Button from 'components/common/CustomButton';
import {
  IIcons,
} from 'interfaces/common.d';
import {
  IPool,
} from 'interfaces/state.d';
import { useTranslation } from 'react-i18next';
// icons
import PROS from 'static/images/currency_icons/PROS_icon.svg';
import USDC from 'static/images/currency_icons/USDC_icon.svg';
import BNB from 'static/images/currency_icons/BNB_icon.svg';
import BTC from 'static/images/currency_icons/BTC_icon.svg';
import REVV from 'static/images/currency_icons/REVV_icon.svg';
import AXS from 'static/images/currency_icons/AXIE_icon.png';
import OKT from 'static/images/currency_icons/OKT_icon.svg';
// styles
import PoolBlockContainer, {
  PoolCreationDate,
  PoolHeader,
  PoolContentTitle,
  PoolBetSideBlock,
  PoolRow,
  PoolStatusRow,
  StatusText,
  IconImage,
} from 'styles/common/StyledPoolBlock';

const icons: IIcons = {
  PROS,
  USDC,
  BNB,
  BTC,
  REVV,
  AXS,
  OKT,
};

interface IProps {
  id: number;
  creationDate: string;
  pair: string;
  condition: number;
  volume: number | undefined;
  betSide: string;
  result: string;
  final: string | number;
  prediction: string;
  status: IPool['status'];
  statusText: string;
  timeframe: string;
  reward: string;
  end: number;
  endPeriod: number;
  currentBlock: number;
  isPriceSet: boolean;
  bearsQuote: number;
  bullsQuote: number;
  tokenId: number;
}

const PoolBlock: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    id,
    creationDate,
    pair,
    condition,
    volume,
    betSide,
    result,
    final,
    prediction,
    status,
    timeframe,
    reward,
    statusText,
    end,
    endPeriod,
    currentBlock,
    isPriceSet,
    bearsQuote,
    bullsQuote,
    tokenId,
  } = props;
  const { state } = useGlobalState();
  const { t } = useTranslation();

  const claimPrize = (poolId: string) => {
    const { contract } = state;
    if (!contract) {
      alert('Your wallet is not connected');
      return;
    }
    const overrides = {
      gasPrice: ethers.utils.parseUnits(`${state.currentNetworkId === 97 ? '15' : '7.5'}`, 'gwei'),
    };
    contract.collect(poolId, overrides).catch(() => {
      alert('You are not eligable for claiming the prize');
    });
  };

  const getCoef = (quote: number): string => {
    if (volume) {
      if (quote === 0) {
        return (2).toFixed(2);
      }
      return (volume / quote).toFixed(2);
    }
    return (1).toFixed(2);
  };

  return (
    <PoolBlockContainer container direction="column" isMobile={isMobile.toString()}>
      <PoolCreationDate isMobile={isMobile.toString()}>
        {creationDate}
      </PoolCreationDate>
      {/* header */}
      <PoolHeader item container isMobile={isMobile.toString()}>
        <Grid item xs={4}>
          {`ID ${id}`}
        </Grid>
        <Grid item xs={4} container alignItems="center">
          <IconImage
            alt="icon"
            height={isMobile ? 16 : 24}
            src={icons[(pair.split('/')[0]) as keyof IIcons]}
          />
          {pair}
        </Grid>
      </PoolHeader>
      {/* first row */}
      <PoolRow item container isMobile={isMobile.toString()}>
        <Grid item xs={4} container direction="column">
          <PoolContentTitle item isMobile={isMobile.toString()}>{t('Condition')}</PoolContentTitle>
          <Grid item>
            {`${condition} USD`}
          </Grid>
        </Grid>
        <Grid item xs={4} container direction="column">
          <PoolContentTitle item isMobile={isMobile.toString()}>{t('Volume')}</PoolContentTitle>
          <Grid item container alignItems="center">
            <IconImage
              alt="icon"
              height={isMobile ? 16 : 24}
              src={
                icons[state.tokens.find(
                  (token) => token.id === tokenId,
                )?.displayName as keyof IIcons]
              }
            />
            {` ${volume}`}
          </Grid>
        </Grid>
        <Grid item xs={4} container>
          <Grid item xs={6} container direction="column">
            <PoolContentTitle item isMobile={isMobile.toString()}>{t('Side')}</PoolContentTitle>
            <Grid item>
              <PoolBetSideBlock side={betSide} isMobile={isMobile.toString()}>
                {t(betSide)}
              </PoolBetSideBlock>
            </Grid>
          </Grid>
          <Grid item xs={6} container direction="column">
            <PoolContentTitle item isMobile={isMobile.toString()}>{t('Result')}</PoolContentTitle>
            <Grid item>
              <PoolBetSideBlock side={result} isMobile={isMobile.toString()}>
                {t(result)}
              </PoolBetSideBlock>
            </Grid>
          </Grid>
        </Grid>
      </PoolRow>
      {/* second row */}
      <PoolRow item container isMobile={isMobile.toString()}>
        <Grid item xs={4} container direction="column">
          <PoolContentTitle item isMobile={isMobile.toString()}>{t('Final')}</PoolContentTitle>
          <Grid item>
            {`$ ${final}`}
          </Grid>
        </Grid>
        <Grid item xs={4} container direction="column">
          <PoolContentTitle item isMobile={isMobile.toString()}>{t('Prediction')}</PoolContentTitle>
          <Grid item container alignItems="center">
            <IconImage
              alt="icon"
              height={isMobile ? 16 : 24}
              src={
                icons[state.tokens.find(
                  (token) => token.id === tokenId,
                )?.displayName as keyof IIcons]
              }
            />
            {` ${prediction}`}
          </Grid>
        </Grid>
        <Grid item xs={4} container direction="column">
          <PoolContentTitle item isMobile={isMobile.toString()}>{t('Ratio')}</PoolContentTitle>
          <Grid item container>
            <Grid item xs={6}>
              <PoolBetSideBlock side="BULL" isMobile={isMobile.toString()}>
                { `x ${getCoef(bullsQuote)}` }
              </PoolBetSideBlock>
            </Grid>
            <Grid item xs={6}>
              <PoolBetSideBlock side="BEAR" isMobile={isMobile.toString()}>
                { `x ${getCoef(bearsQuote)}` }
              </PoolBetSideBlock>
            </Grid>
          </Grid>
        </Grid>
      </PoolRow>
      {/* third row */}
      <PoolStatusRow status={status} item container isMobile={isMobile.toString()}>
        <Grid item xs={4} container direction="column">
          <PoolContentTitle item isMobile={isMobile.toString()}>{t('Timeframe')}</PoolContentTitle>
          <Grid item>
            {`${timeframe}`}
          </Grid>
        </Grid>
        <Grid item xs={4} container direction="column">
          <PoolContentTitle item isMobile={isMobile.toString()}>{t('Reward')}</PoolContentTitle>
          <Grid item container alignItems="center">
            <IconImage
              alt="icon"
              height={isMobile ? 16 : 24}
              src={
                icons[state.tokens.find(
                  (token) => token.id === tokenId,
                )?.displayName as keyof IIcons]
              }
            />
            {` ${reward}`}
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="center">
          {
            status === 'won'
              ? (
                <Button
                  poolId={id}
                  disabledButton={(reward !== undefined
                && parseFloat(reward as string) > 0)
              || !!(end && endPeriod && currentBlock
                && (end + endPeriod) < currentBlock
                && !isPriceSet && prediction)
              || state.currentNetworkId !== +window.ethereum.chainId}
                  claimPrize={claimPrize}
                >
                  {
                reward !== undefined
                && parseFloat(reward as string) > 0
                  ? t('Claimed')
                  : t('Claim')
              }
                </Button>
              )
              : (<StatusText status={status}>{statusText}</StatusText>)
          }
        </Grid>
      </PoolStatusRow>
    </PoolBlockContainer>
  );
};

export default PoolBlock;
