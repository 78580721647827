import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';
import bull from 'static/images/standing_bull.svg';
import bear from 'static/images/standing_bear.svg';

interface IChoiceSideButton {
  bull: string;
  active: string;
}

export const ChoiceSideButton = styled.div<IChoiceSideButton>`
  background: ${(props) => (props.bull === 'true'
    ? `linear-gradient(359.5deg, #5900F7 58%, rgba(89, 0, 247, 0) 103.6%),
          url(${bull}) no-repeat -70px -10px/230px 230px,
          linear-gradient(180deg,#4919D0 0%,#38149F 100%)`
    : `linear-gradient(360deg,#323232 44%,rgba(50,50,50,0) 104.17%),
          url(${bear}) no-repeat 30px 5px/230px 230px,
          linear-gradient(180deg,#333333 0%,#2D2C2C 100%)`)};
  box-shadow: ${(props) => props.active === 'true'
      && `inset 0 0 0 1px rgb(255 221 0),
      0px 0px 4px rgb(255 221 0 / 50%),
      0px 0px 4px rgb(255 221 0 / 50%)`};
  border-radius: 16px;
  width: 164px;
  height: 255px;
  padding: 16px;
  cursor: pointer;
`;

export const ChoiceContainer = styled(Grid)`
  height: 100%;
`;

export const SideItemContent = styled(Grid)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;

  img {
      margin-right: 10px;
  }
`;

export const SideButtonHint = styled(Grid)<{ active: String }>`
  padding: 4px 20px;
  border-radius: 4px;
  color: #0C0C21;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 24px;
  background-color: ${(props) => (props.active === 'true' ? '#FD0' : 'white')};
`;
