import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledLoadMoreButton,
} from 'styles/mobile/StyledClosedPools';
import Spinner from 'styles/common/StyledSpinner';

interface IProps {
  onClick: () => void;
  loading: boolean;
  canLoadMore: boolean;
}

const LoadMoreButton: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    onClick,
    loading,
    canLoadMore,
  } = props;
  const { t } = useTranslation(['closed_pools']);

  return (
    <StyledLoadMoreButton disabled={loading || !canLoadMore} fullWidth onClick={onClick}>
      {
        loading
          ? <Spinner isMobile="true" />
          : t('Load more')
      }
    </StyledLoadMoreButton>
  );
};

export default LoadMoreButton;
