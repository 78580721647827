import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import { ReactComponent as Row } from 'static/images/appearence_row.svg';
import { ReactComponent as Column } from 'static/images/appearence_column.svg';
import { useTableState } from 'context/tableState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
// styles
import {
  AppearenceTitle,
  AppearenceIconContainer,
} from 'styles/desktop/StyledClosedPools';

const TableAppearenceButtons: React.FC = (): ReactElement => {
  const { tableState, tableDispatch } = useTableState();
  const { dispatch } = useGlobalState();
  const { t } = useTranslation('closed_pools');

  const changeTableView = (viewIndex: 0 | 1) => {
    tableDispatch({
      type: 'CHANGE_TABLE_VIEW',
      payload: {
        ...tableState,
        tableViewIndex: viewIndex,
      },
    });

    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform change table view',
        amplitudeProps: {
          view: viewIndex ? 'row' : 'block',
        },
      },
    });
  };

  return (
    <Grid container direction="column">
      <AppearenceTitle item>
        {t('View')}
      </AppearenceTitle>
      <Grid item container>
        <AppearenceIconContainer
          item
          active={(tableState.tableViewIndex === 0).toString()}
          onClick={() => changeTableView(0)}
        >
          <Column />
        </AppearenceIconContainer>
        <AppearenceIconContainer
          item
          active={(tableState.tableViewIndex === 1).toString()}
          onClick={() => changeTableView(1)}
        >
          <Row />
        </AppearenceIconContainer>
      </Grid>
    </Grid>
  );
};

TableAppearenceButtons.defaultProps = {
  activeView: 1,
};

export default TableAppearenceButtons;
