/* eslint-disable no-console */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  Slide,
} from '@mui/material';
import axios from 'axios';
import { ReactComponent as NotificationIcon } from 'static/images/common_icons/timeframe_icon.svg';
import { ReactComponent as Return } from 'static/images/common_icons/return_icon.svg';
import { useTranslation } from 'react-i18next';
import { useStatisticsState } from 'context/statisticsState';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import Switcher from 'components/common/PillsSwitcher';
import Rounds from 'components/common/statistics/RoundsData';
import PNL from 'components/common/statistics/PNLData';
import { IPersonalStatisticsParameters } from 'interfaces/common.d';
import { IUserStatisticsResponse } from 'interfaces/statistics.d';
// styles
import {
  ModalContainer,
  StyledPaper,
  StatisticsContentContainer,
  ReturnRow,
  StatisticsTitle,
  SwitcherContainer,
  NotificationContainer,
  NotificationContent,
  StyledLink,
  StyledLinkIcon,
} from 'styles/modals/StyledMobileStatisticsModal';
// helpers
import { numberToProperFixedNumber } from 'numbersHelper';

const StatisticsModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [userStatistics, setUserStatistics] = useState<IUserStatisticsResponse | null>(null);

  const { statisticsState, statisticsDispatch } = useStatisticsState();
  const { modalsState, modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();
  const { t } = useTranslation(['modals']);

  const switchTab = (tabIndex: number) => {
    statisticsDispatch({
      type: 'SWITCH_STATE',
      payload: {
        ...statisticsState,
        state: tabIndex,
      },
    });
  };

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_STATISTICS_MODAL' });
  };

  const getStatistics = useCallback(async () => {
    const queryStatisticsParams: IPersonalStatisticsParameters = {
      networkId: state.currentNetworkId,
      user: state.account,
    };

    const statisticsResponse = await axios.post(`${state.baseUrl}/users/${queryStatisticsParams.user}/statistics`, {
      networkId: queryStatisticsParams.networkId,
    });

    if (statisticsResponse.data) {
      setUserStatistics(statisticsResponse.data as IUserStatisticsResponse);
    }
  }, [state.account, state.currentNetworkId]);

  const getTokenCost = useCallback(async () => {
    try {
      const tokenResponse = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin&order=market_cap_desc&per_page=1&page=1&sparkline=false');
      if (tokenResponse.data) {
        statisticsDispatch({
          type: 'SET_TOKEN_COST',
          payload: {
            ...statisticsState,
            ...statisticsState,
            tokenCost: tokenResponse.data[0].current_price,
          },
        });
      }
    } catch (e) {
      console.log('Something went wrong during getting token data');
    }
  }, []);

  useEffect(() => {
    if (modalsState.openedStatisticsModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open statistics modal',
        },
      });

      getStatistics();
    }

    setOpen(modalsState.openedStatisticsModal);
  }, [modalsState.openedStatisticsModal]);

  useEffect(() => {
    if (userStatistics) {
      statisticsDispatch({
        type: 'SET_STATISTICS',
        payload: {
          ...statisticsState,
          rounds: {
            wonRounds: userStatistics.victoryStatistics.betAmount,
            wonMoney: numberToProperFixedNumber(userStatistics.victoryStatistics.moneyAmount, 4),
            lostRounds: userStatistics.lossStatistics.betAmount,
            lostMoney: numberToProperFixedNumber(userStatistics.lossStatistics.moneyAmount, 4),
            overallMoney: numberToProperFixedNumber(
              userStatistics.overallStatistics.moneyAmount,
              4,
            ),
          },
          pnl: {
            averageReturn: numberToProperFixedNumber(userStatistics.averageIncome, 4),
            bestRoundId: userStatistics.largestIncomePoolId,
            bestRoundProfit: numberToProperFixedNumber(userStatistics.largestIncome, 4),
            averagePosition: numberToProperFixedNumber(userStatistics.averageParticipateAmount, 4),
            netResults: numberToProperFixedNumber(userStatistics.netWorth, 4),
          },
        },
      });
    }
  }, [userStatistics]);

  useEffect(() => {
    if (state.account) {
      getStatistics();
    }
  }, [getStatistics]);

  useEffect(() => {
    getTokenCost();
  }, []);

  useEffect(() => {
    if (state.tokens.length) {
      statisticsDispatch({ type: 'SET_TOKEN', payload: { ...statisticsState, token: state.tokens[0].displayName } });
    }
  }, [state.tokens]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Slide direction="up" in={open}>
        <StyledPaper>
          <StatisticsContentContainer>
            <ReturnRow onClick={handleClose}>
              <Return />
              {t('Return')}
            </ReturnRow>
            <StatisticsTitle>{t('Statistics')}</StatisticsTitle>
            <NotificationContainer>
              <Grid container>
                <Grid item xs={2} container justifyContent="flex-start">
                  <NotificationIcon height={20} width={20} />
                </Grid>
                <Grid item xs={10}>
                  <NotificationContent>
                    {t('statistics notification')}
                  </NotificationContent>
                  <Grid container justifyContent="flex-start">
                    <StyledLink to="/closed-pools" onClick={handleClose}>
                      {t('Go to closed pools')}
                      <StyledLinkIcon />
                    </StyledLink>
                  </Grid>
                </Grid>
              </Grid>
            </NotificationContainer>
            <SwitcherContainer>
              <Switcher tabs={[t('Rounds'), 'PNL']} selectedTab={statisticsState.state} changeTab={switchTab} />
            </SwitcherContainer>
            {
                statisticsState.state
                  ? <PNL />
                  : <Rounds />
              }
          </StatisticsContentContainer>
        </StyledPaper>
      </Slide>
    </ModalContainer>
  );
};

export default StatisticsModal;
