import React, { ReactElement } from 'react';
import { useMiningState } from 'context/miningState';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { ReactComponent as MiningHistoricalCircle } from 'static/images/mining-historical.svg';
import { ReactComponent as FireIcon } from 'static/images/common_icons/fire_icon.svg';
// styles
import {
  HistoricalContentContainer,
  HistoricalAmount,
  HistoricalContent,
} from 'styles/desktop/StyledMining';

const HistoricalContainer: React.FC = (): ReactElement => {
  const { miningState } = useMiningState();
  const { t } = useTranslation(['mining']);

  return (
    <HistoricalContentContainer>
      <MiningHistoricalCircle height={isMobile ? 252 : 355} width={isMobile ? 252 : 355} />
      <HistoricalContent>
        <div>
          {t('Historical prediction volume')}
        </div>
        <HistoricalAmount>{`$${15972330 + (miningState.historicalPredictionVolume * 635)}`}</HistoricalAmount>
        <FireIcon width={isMobile ? 41 : 58} height={isMobile ? 41 : 58} />
      </HistoricalContent>
    </HistoricalContentContainer>
  );
};

export default HistoricalContainer;
