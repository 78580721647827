import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';

export const StyledDesktop = styled(Grid)`
    min-height: 100vh;
`;

export default StyledDesktop;
