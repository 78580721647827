/* eslint-disable no-console */
import React, {
  createContext,
  useContext,
  useCallback,
  useState, useEffect,
} from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import { useGlobalState } from './globalState';

export const CurrentBlockContext = createContext<
  {getCurrentBlock:() => Promise<number>} | undefined
    >(undefined);
let localNetworkProvider: ethers.providers.Web3Provider | null = null;

const CurrentBlockProvider = ({ children }: { children: React.ReactNode }) => {
  const { state } = useGlobalState();

  useEffect(() => {
    if (window.ethereum) {
      localNetworkProvider = new ethers.providers.Web3Provider(window.ethereum);
    }
  }, [window.ethereum]);

  const getCurrentBlock = useCallback(async (): Promise<number> => {
    let block = 0;
    try {
      if (localNetworkProvider) {
        const blockResponse = await localNetworkProvider.getBlockNumber();

        if (blockResponse) {
          block = blockResponse || 0;
        }
      } else {
        const blockResponse = await axios.get(`${state.baseUrl}/network/${state.currentNetworkId}/get-block`);
        if (blockResponse.data) {
          block = blockResponse.data || 0;
        }
      }
    } catch (e) {
      console.log('Something went wrong during getting current block number', e);
    }
    return block;
  }, [state.baseUrl, state.currentNetworkId, localNetworkProvider]);

  return (
    <CurrentBlockContext.Provider value={{
      getCurrentBlock,
    }}
    >
      {children}
    </CurrentBlockContext.Provider>
  );
};

export const useCurrentBlockContext = () => {
  const context = useContext(CurrentBlockContext);

  if (!context) throw new Error('useCurrentPriceContext must be used in a current block context provider');

  return context;
};

export default CurrentBlockProvider;
