import React, { ReactElement } from 'react';
import {
  IPool,
} from 'interfaces/state.d';
import { useTranslation } from 'react-i18next';
// styles
import StyledStatus from 'styles/common/StyledPoolStatus';

interface IProps {
    pool: IPool;
    currentBlock: number;
}

const getPoolStatus = (pool: IPool, currentBlock: number): string => {
  const { t } = useTranslation();
  const { start } = pool;
  const startEnd: number = pool.startPeriod + pool.start;
  const endEnd: number = pool.endPeriod + pool.end;

  if (currentBlock < start) return t('Pool not started');
  if (currentBlock >= start && currentBlock <= startEnd) {
    return t('Acceptance period');
  } if (currentBlock > startEnd && currentBlock <= endEnd) return t('Settlement period');
  if (currentBlock > endEnd) return t('Pool is closed');
  return t('Unknown status');
};

const PoolStatus: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    pool,
    currentBlock,
  } = props;

  return (
    <StyledStatus>
      {
        getPoolStatus(pool, currentBlock)
      }
    </StyledStatus>
  );
};

export default PoolStatus;
