import React, { ReactElement, useMemo } from 'react';
import {
  Grid,
} from '@mui/material';
import { useMiningState } from 'context/miningState';
import { useTranslation } from 'react-i18next';

import DataBlock from 'components/common/MiningDataBlock';

const PersonalStatisticsContainer: React.FC = (): ReactElement => {
  const { miningState } = useMiningState();
  const { t } = useTranslation(['mining']);

  const predictionPercent = useMemo(() => (
    Math.ceil((miningState.personalStageStatistics.predictionVolume
      / miningState.totalStageStatistics.totalPredictionVolume) * 100)),
  [miningState.personalStageStatistics.predictionVolume,
    miningState.totalStageStatistics.totalPredictionVolume]);

  const predictionVolumeDifference = useMemo(() => (
    +((miningState.personalStageStatistics.currentDayPredictionVolume
  / miningState.personalStageStatistics.predictionVolume) * 100).toFixed(2)
  ), [miningState.personalStageStatistics.predictionVolume,
    miningState.personalStageStatistics.currentDayPredictionVolume]);

  const tokensEarnedDifference = useMemo(() => (
    +((miningState.personalStageStatistics.currentDayTokensEarned
    / miningState.personalStageStatistics.tokensEarned) * 100).toFixed(2)
  ), [miningState.personalStageStatistics.tokensEarned,
    miningState.personalStageStatistics.currentDayTokensEarned]);

  const netProfitDifference = useMemo(() => (
    +((miningState.personalStageStatistics.currentDayNetProfit
    / miningState.personalStageStatistics.netProfit) * 100).toFixed(2)
  ),
  [miningState.personalStageStatistics.netProfit,
    miningState.personalStageStatistics.currentDayNetProfit]);

  const returnRatioDifference = useMemo(() => (
    +((miningState.personalStageStatistics.currentDayReturnRatio
    / miningState.personalStageStatistics.currentReturnRatio) * 100).toFixed(2)
  ), [miningState.personalStageStatistics.currentReturnRatio,
    miningState.personalStageStatistics.currentDayReturnRatio]);

  return (
    <div>
      <Grid container columnSpacing={7} rowSpacing={2}>
        <DataBlock title={t('Your prediction volume')} amount={miningState.personalStageStatistics.predictionVolume} withChart percent={predictionPercent} difference={predictionVolumeDifference} />
        <DataBlock title={`${t('earned', { token: 'REVV' })}`} amount={miningState.personalStageStatistics.tokensEarned} difference={tokensEarnedDifference} />
        <DataBlock title={t('Current return ratio')} amount={miningState.personalStageStatistics.currentReturnRatio} isPercent difference={returnRatioDifference} />
        <DataBlock title={t('Net Profit')} amount={miningState.personalStageStatistics.netProfit} difference={netProfitDifference} />
        <DataBlock title={t('Correct predictions')} amount={miningState.personalStageStatistics.correctPredictions} />
        <DataBlock title={t('Predictions made')} amount={miningState.personalStageStatistics.predictionsMade} />
      </Grid>
    </div>
  );
};

export default PersonalStatisticsContainer;
