import React, {
  ReactElement, useCallback, useState,
} from 'react';
import { Slider } from '@mui/material';
import CursorBull from 'static/images/stats-result-icon.svg';
import { useTranslation } from 'react-i18next';
import {
  AmountValue,
  ReferalCalculatorBar,
  ReferalCalculatorBarAmountText,
  ReferalCalculatorBarText,
  ReferalCalculatorBoxIncomeText,
  ReferalCalculcatorBox,
  ReferalCalculcatorBoxProsText,
  ReferalProfitCalculatorContainer,
} from '../../../styles/desktop/StyledReferal';

const ReferralProfitCalculator: React.FC = (): ReactElement => {
  const [currentAmount, setCurrentAmount] = useState<number>(3.5);
  const factor = 0.03 * 0.3;
  const { i18n, t } = useTranslation(['referral']);
  const calculatePros = (value : number) : number => Number((value * factor));
  const getCurrentAmount = useCallback(() => currentAmount, [currentAmount]);
  const handleChange = useCallback((event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setCurrentAmount(newValue);
    }
  }, []);
  return (
    <>
      <ReferalProfitCalculatorContainer
        container
        direction="row"
        alignItems="center"
        wrap="nowrap"
        justifyContent="space-between"
      >
        <ReferalCalculatorBar item>
          <ReferalCalculatorBarText>
            {t('PROFIT CALCULATOR')}
          </ReferalCalculatorBarText>
          <Slider
            sx={{
              color: 'rgba(126,56,249,1)',
              '& .MuiSlider-thumb': {
                background: `url(${CursorBull})`,
                width: '71px',
                height: '88px',
                borderRadius: '100px',
                top: '-30px',
                boxShadow: 'none',
              },
              '& .MuiSlider-rail': {
                background: 'rgba(0, 188, 247, 1)',
                opacity: 100,
              },
              '& .MuiSlider-thumbColorPrimary.Mui-active.Mui-focusVisible': {
                boxShadow: 'none',
              },
              '& .MuiSlider-thumb:before': {
                boxShadow: 'none',
              },
              '& .MuiSlider-thumb:hover, .MuiSlider-thumb.Mui-focusVisible': {
                boxShadow: 'none',
              },
            }}
            value={currentAmount}
            scale={getCurrentAmount}
            onChange={handleChange}
            min={0.5}
            step={0.5}
            max={5}
          />
          <ReferalCalculatorBarAmountText>
            {t('THE AMOUNT OF BETS OF INVITED FRIENDS')}
            <AmountValue>
              {` ${currentAmount} BNB `}
            </AmountValue>
          </ReferalCalculatorBarAmountText>
        </ReferalCalculatorBar>
        <ReferalCalculcatorBox item>
          <ReferalCalculatorBoxIncomeText>{t('You potential income')}</ReferalCalculatorBoxIncomeText>
          <ReferalCalculcatorBoxProsText>
            {calculatePros(currentAmount).toFixed(4)}
            {' '}
            BNB
          </ReferalCalculcatorBoxProsText>
        </ReferalCalculcatorBox>
      </ReferalProfitCalculatorContainer>
    </>
  );
};

export default ReferralProfitCalculator;
