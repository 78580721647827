/* eslint-disable no-shadow */
import React, {
  ReactElement, useState, useEffect, useCallback,
} from 'react';
// styles
import StyledCount from 'styles/common/StyledCountDown';

interface IProps {
    deadline: Date;
}

const CountDown: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    deadline,
  } = props;

  const [hours, setHours] = useState<string>('00');
  const [minutes, setMinutes] = useState<string>('00');
  const [seconds, setSeconds] = useState<string>('00');
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

  const getTime = useCallback(() => {
    const countDownDate: number = deadline.getTime();
    const now: number = new Date().getTime();
    const diff: number = countDownDate - now;
    const hours: number = Math.floor(
      (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes: number = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((diff % (1000 * 60)) / 1000);

    if (diff >= 0) {
      return {
        hours: hours < 10 ? `0${hours}` : hours.toString(),
        minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
        seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
      };
    }

    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }, [deadline]);

  const setCurrentTime = useCallback(() => {
    const {
      hours,
      minutes,
      seconds,
    } = getTime();

    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }, [getTime]);

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setCurrentTime();
    setIntervalId(setInterval(() => setCurrentTime(), 1000));

    // clearing timeout on unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [deadline, setCurrentTime]);

  return (
    <StyledCount>
      { `${hours} : ${minutes} : ${seconds}` }
    </StyledCount>
  );
};

export default CountDown;
