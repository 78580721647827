import React, {
  ReactElement,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
// styles
import {
  ReferralButtonContainer,
  ReferralLinkBlockContainer,
} from 'styles/desktop/StyledReferralPrivateOffice';
// components
import BoldTitle from 'components/common/BoldTitle';
import ReferralCurrentButton from 'components/desktop/referral/ReferralCurrentButton';

const ReferralLinkBlock: React.FC = (): ReactElement => {
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const { state } = useGlobalState();

  const referralInviteLink = useMemo(() => `${state.baseUrl}/referral-link/${referralCode}`, [referralCode, state.baseUrl]);

  const getReferralCode = useCallback(async () => {
    try {
      const referralCodeResponse = await axios.get(`${state.baseUrl}/referral/code?referrerWallet=${state.account.toLowerCase()}`);

      if (referralCodeResponse.data) {
        return referralCodeResponse.data;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wring while getting referral code');
    }

    return '';
  }, [state.account, state.baseUrl]);

  useEffect(() => {
    if (referralCode === null && state.account) {
      getReferralCode().then(
        (code) => setReferralCode(code),
      );
    }
  }, [referralCode, state.account]);

  return (
    <ReferralLinkBlockContainer>
      <BoldTitle placement="table">Personal referral link</BoldTitle>
      <ReferralButtonContainer>
        <ReferralCurrentButton referalLink={referralInviteLink} />
      </ReferralButtonContainer>
    </ReferralLinkBlockContainer>
  );
};

export default ReferralLinkBlock;
