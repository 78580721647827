import React, { ReactElement } from 'react';
import {
  ReferalPage,
  ReferalPageContainer,
  ReferalProsperProgram,
} from '../../../styles/desktop/StyledReferal';

import ReferralImages from './ReferralImages';
import ReferralIntroText from './ReferralIntroText';
import ReferralHowItWorks from './ReferralHowItWorks';
import ReferralOurAdvantages from './ReferralOurAdvantages';
import ReferralProfit from './ReferralProfit';
import ReferralProfitCalculator from './ReferralProfitCalculator';
import ReferralQuestions from './ReferralQuestions';

const ReferralLandingContainer: React.FC = (): ReactElement => (
  <ReferalPage
    container
    justifyContent="center"
  >
    <ReferalPageContainer
      container
      item
      alignItems="center"
      flexDirection="column"
    >
      <ReferalProsperProgram>
        <ReferralIntroText />
        <ReferralImages />
      </ReferalProsperProgram>
      <ReferralHowItWorks />
      <ReferralOurAdvantages />
      <ReferralProfit />
      <ReferralProfitCalculator />
      {/*
      <ReferralAdditionalBonuses />
      */}
      <ReferralQuestions />
    </ReferalPageContainer>
  </ReferalPage>
);

export default ReferralLandingContainer;
