import React, { ReactElement } from 'react';
import SocialBlock from 'components/common/SocialsBlock';
import { useTranslation } from 'react-i18next';
// styles
import { SocialsLabel } from 'styles/mobile/StyledSideMenu';

const SideMenuSocialBlock: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div>
      <SocialsLabel>{t('Join the community')}</SocialsLabel>
      <SocialBlock />
    </div>
  );
};

export default SideMenuSocialBlock;
