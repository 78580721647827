import React, { createContext, useContext, useReducer } from 'react';
import {
  IModalState,
  IPool,
} from 'interfaces/state.d';

type ActionType = 'OPEN_BET_MODAL'
    | 'CLOSE_BET_MODAL'
    | 'OPEN_YOU_ARE_IN_MODAL'
    | 'CLOSE_YOU_ARE_IN_MODAL'
    | 'OPEN_WRONG_NETWORK_MODAL'
    | 'CLOSE_WRONG_NETWORK_MODAL'
    | 'OPEN_LOADING_MODAL'
    | 'CLOSE_LOADING_MODAL'
    | 'OPEN_SEARCH_MODAL'
    | 'CLOSE_SEARCH_MODAL'
    | 'OPEN_STATISTICS_MODAL'
    | 'CLOSE_STATISTICS_MODAL'
    | 'OPEN_LEADERBOARD_MODAL'
    | 'CLOSE_LEADERBOARD_MODAL'
    | 'OPEN_LEADERBOARD_SEARCH_MODAL'
    | 'CLOSE_LEADERBOARD_SEARCH_MODAL'
    | 'OPEN_REFERAL_CONNECT_MODAL'
    | 'CLOSE_REFERAL_CONNECT_MODAL'
    | 'OPEN_REFERAL_RECOMMEND_MODAL'
    | 'CLOSE_REFERAL_RECOMMEND_MODAL'

type PayloadType = any;

interface IAction {
    type: ActionType;
    payload?: PayloadType;
}

type DispatchType = (action: IAction) => void;

export const ModalContext = createContext<{
    modalsState: IModalState;
    modalDispatch: DispatchType
} | undefined>(undefined);

const stateReducer = (modalsState: IModalState, action: IAction): IModalState => {
  switch (action.type) {
    case 'OPEN_BET_MODAL':
      return {
        ...modalsState,
        openedBetModal: true,
        betModalData: action.payload.betModalData,
      };
    case 'CLOSE_BET_MODAL':
      return {
        ...modalsState,
        openedBetModal: false,
      };
    case 'OPEN_YOU_ARE_IN_MODAL':
      return {
        ...modalsState,
        openedYouAreInModal: true,
        youAreInModalData: action.payload.youAreInModalData,
      };
    case 'CLOSE_YOU_ARE_IN_MODAL':
      return {
        ...modalsState,
        openedYouAreInModal: false,
        youAreInModalData: action.payload.youAreInModalData,
      };
    case 'OPEN_WRONG_NETWORK_MODAL':
      return {
        ...modalsState,
        openedWrongNetworkModal: true,
      };
    case 'CLOSE_WRONG_NETWORK_MODAL':
      return {
        ...modalsState,
        openedWrongNetworkModal: false,
      };
    case 'OPEN_LOADING_MODAL':
      return {
        ...modalsState,
        openedLoadingModal: true,
      };
    case 'CLOSE_LOADING_MODAL':
      return {
        ...modalsState,
        openedLoadingModal: false,
      };
    case 'OPEN_SEARCH_MODAL':
      return {
        ...modalsState,
        openedSearchModal: true,
      };
    case 'CLOSE_SEARCH_MODAL':
      return {
        ...modalsState,
        openedSearchModal: false,
      };
    case 'OPEN_STATISTICS_MODAL':
      return {
        ...modalsState,
        openedStatisticsModal: true,
      };
    case 'CLOSE_STATISTICS_MODAL':
      return {
        ...modalsState,
        openedStatisticsModal: false,
      };
    case 'OPEN_LEADERBOARD_MODAL':
      return {
        ...modalsState,
        openedLeaderboardModal: true,
        leaderboardSender: action.payload.sender,
      };
    case 'CLOSE_LEADERBOARD_MODAL':
      return {
        ...modalsState,
        openedLeaderboardModal: false,
        leaderboardSender: action.payload.sender,
      };
    case 'OPEN_LEADERBOARD_SEARCH_MODAL':
      return {
        ...modalsState,
        openedLeaderboardSearchModal: true,
      };
    case 'CLOSE_LEADERBOARD_SEARCH_MODAL':
      return {
        ...modalsState,
        openedLeaderboardSearchModal: false,
      };
    case 'OPEN_REFERAL_CONNECT_MODAL':
      return {
        ...modalsState,
        openedReferalConnectModal: true,
      };
    case 'CLOSE_REFERAL_CONNECT_MODAL':
      return {
        ...modalsState,
        openedReferalConnectModal: false,
      };
    case 'OPEN_REFERAL_RECOMMEND_MODAL':
      return {
        ...modalsState,
        openedReferalRecommendModal: true,
      };
    case 'CLOSE_REFERAL_RECOMMEND_MODAL':
      return {
        ...modalsState,
        openedReferalRecommendModal: false,
      };
    default:
      return initialState;
  }
};

const initialPoolState: IPool = {
  bearsQuote: 0,
  bullsQuote: 0,
  bullsWon: false,
  networkId: 42,
  creator: '',
  duration: 0,
  start: 0,
  startPeriod: 100,
  end: 101,
  endPeriod: 200,
  feedId: 0,
  id: 0,
  isPriceSet: false,
  minBet: 0,
  price: 0,
  tokenId: 0,
  fake: true,
  time: '',
};

const initialState: IModalState = {
  openedBetModal: false,
  openedYouAreInModal: false,
  openedWrongNetworkModal: false,
  openedLoadingModal: false,
  openedSearchModal: false,
  openedStatisticsModal: false,
  openedLeaderboardModal: false,
  openedLeaderboardSearchModal: false,
  openedReferalConnectModal: false,
  openedReferalRecommendModal: false,
  youAreInModalData: {},
  betModalData: {
    asset: { name: 'BNB', pair: 'BNB/BNB' },
    token: { id: 0, displayName: 'BNB', value: '0x0' },
    tokenId: 1,
    feedId: 1,
    conditionPrice: 0,
    currentPrice: 0,
    bearQuote: '0',
    bullQuote: '0',
    deadline: new Date(),
    actualPool: initialPoolState,
    volume: 0,
    bullCoef: '0',
    bearCoef: '0',
    chartExpanded: false,
  },
  leaderboardSender: null,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalsState, modalDispatch] = useReducer(stateReducer, initialState);

  return (
    <ModalContext.Provider value={{ modalsState, modalDispatch }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalState = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error('useModalState must be used in a modal state provider');

  return context;
};

export default StateProvider;
