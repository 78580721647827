import React, { createContext, useContext, useReducer } from 'react';
import {
  IActualPoolState,
  IPool,
} from 'interfaces/state.d';
import {
  IToken,
  IAsset,
} from 'interfaces/common.d';

type ActionType = 'SET_TOKEN'
    | 'SET_ASSET'
    | 'SET_ACTUAL_POOL'
    | 'SET_DEADLINE'
    | 'SET_CURRENT_PRICE'
    | 'SWITCH_CHART'
    | 'SWITCH_TOKEN_ID'
    | 'SWITCH_FEED_ID';
type PayloadType = IActualPoolState;
interface IAction {
    type: ActionType;
    payload: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const ActualPoolContext = createContext<{actualPoolState: IActualPoolState;
        actualPoolDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (actualPoolState: IActualPoolState, action: IAction): IActualPoolState => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...actualPoolState,
        token: action.payload.token,
      };
    case 'SET_ASSET':
      return {
        ...actualPoolState,
        asset: action.payload.asset,
      };
    case 'SET_ACTUAL_POOL':
      return fullFillActualPool(actualPoolState, action.payload.actualPool);
    case 'SET_DEADLINE':
      return {
        ...actualPoolState,
        deadline: action.payload.deadline,
      };
    case 'SET_CURRENT_PRICE':
      return {
        ...actualPoolState,
        currentPrice: action.payload.currentPrice,
      };
    case 'SWITCH_CHART':
      return {
        ...actualPoolState,
        chartExpanded: action.payload.chartExpanded,
      };
    case 'SWITCH_TOKEN_ID':
      return {
        ...actualPoolState,
        tokenId: action.payload.tokenId,
      };
    case 'SWITCH_FEED_ID':
      return {
        ...actualPoolState,
        feedId: action.payload.feedId,
      };
    default:
      return initialState;
  }
};

const getCoef = (volume: number, quote: number): string => {
  if (volume) {
    if (quote === 0) {
      return (2).toFixed(2);
    }
    return (volume / quote).toFixed(2);
  }
  return (1).toFixed(2);
};

const fullFillActualPool = (state: IActualPoolState, pool: IPool): IActualPoolState => {
  const {
    price,
    bullsQuote,
    bearsQuote,
  } = pool;

  const conditionPrice: number = price || 0;
  const bullQuote: string = (bullsQuote || 0).toFixed(4);
  const bearQuote: string = (bearsQuote || 0).toFixed(4);
  const volume: number = (bullsQuote || 0) + (bearsQuote || 0);
  const bullCoef: string = getCoef(volume, bullsQuote);
  const bearCoef: string = getCoef(volume, bearsQuote);

  return {
    ...state,
    actualPool: pool,
    conditionPrice,
    bullQuote,
    bearQuote,
    volume,
    bullCoef,
    bearCoef,
  };
};

const initialPoolState: IPool = {
  bearsQuote: 0,
  bullsQuote: 0,
  bullsWon: false,
  networkId: 42,
  creator: '',
  duration: 0,
  start: 0,
  startPeriod: 100,
  end: 101,
  endPeriod: 200,
  feedId: 0,
  id: 0,
  isPriceSet: false,
  minBet: 0,
  price: 0,
  tokenId: 0,
  fake: true,
  time: '',
};

const initialToken: IToken = {
  id: 0,
  displayName: '',
  value: '',
};

const initialAsset: IAsset = {
  name: '',
  pair: '/',
};

const initialState: IActualPoolState = {
  token: initialToken,
  asset: initialAsset,
  actualPool: initialPoolState,
  feedId: 0,
  tokenId: 0,
  conditionPrice: 0,
  currentPrice: 0,
  bullQuote: '0',
  bearQuote: '0',
  volume: 0,
  bullCoef: '0',
  bearCoef: '0',
  deadline: new Date(),
  chartExpanded: false,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [actualPoolState, actualPoolDispatch] = useReducer(stateReducer, initialState);

  return (
    <ActualPoolContext.Provider value={{ actualPoolState, actualPoolDispatch }}>
      { children }
    </ActualPoolContext.Provider>
  );
};

export const useActualPoolState = () => {
  const context = useContext(ActualPoolContext);

  if (!context) throw new Error('useActualPoolContext must be used in a actual pool state provider');

  return context;
};

export default StateProvider;
