import React, { ReactElement } from 'react';
import hamburger from 'static/images/hamburger.svg';
import { DropDownBlockContainer, DropDownName, StyledHamburgerItem } from 'styles/desktop/StyledDropDownNavigation';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps{
    onClick : () => void;
    active: boolean;
    isDark: boolean;
}

const DropDownBlockAbout: React.FC<IProps> = (props : IProps): ReactElement => {
  const { onClick, active, isDark } = props;
  const { t } = useTranslation(['header']);

  return (
    <DropDownBlockContainer
      container
      alignItems="flex-start"
      justifyContent="center"
      onClick={onClick}
      active={active}
      isDark={isDark}
      id="openAbout"
    >
      <Grid item>
        <StyledHamburgerItem src={hamburger} />
      </Grid>
      <DropDownName item>
        {t('About')}
      </DropDownName>
    </DropDownBlockContainer>
  );
};

export default DropDownBlockAbout;
