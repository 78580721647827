import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import socialPredictions from 'static/images/socialPredictionsIcon.svg';

import {
  FormControl,
  SelectChangeEvent,
  Grid, MenuItem,
} from '@mui/material';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  StyledDefaultLink,
  StyledDropDownSelect,
  StyledMenuItemIcon,
} from '../../../../styles/desktop/StyledDropDownNavigation';

import DropDownBlockProducts from './DropDownBlockProducts';
import { useGlobalState } from '../../../../context/globalState';

const DropDownProducts: React.FC = (): ReactElement => {
  const [isReferralPage, setIsReferralPage] = useState<boolean>(false);
  const [openedSelect, setOpenedSelect] = useState<boolean>(false);
  const { dispatch } = useGlobalState();
  const { t } = useTranslation(['header']);

  const openSelect = () : void => {
    setOpenedSelect(true);
  };

  const closeSelect = (): void => {
    setOpenedSelect(false);
  };

  const redirect = (link: string) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform link click',
        amplitudeProps: {
          placement: 'header',
          link,
        },
      },
    });
  };

  useEffect(() => {
    if (window.location.pathname.includes('referral-info')) {
      setIsReferralPage(true);
    } else {
      setIsReferralPage(false);
    }
  }, [window.location.pathname]);

  return (
    <FormControl>
      <DropDownBlockProducts
        onClick={openSelect}
        active={openedSelect}
        isDark={isReferralPage}
      />
      <StyledDropDownSelect
        variant="outlined"
        open={openedSelect}
        onClose={closeSelect}
        MenuProps={{
          anchorEl: document.getElementById('openProducts'),
        }}
      >
        <Grid container>
          <Grid item>
            <Grid item>
              <StyledDefaultLink
                href="https://dao.prosper.so"
                onClick={() => redirect('https://dao.prosper.so')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledMenuItemIcon src={socialPredictions} alt="dao" />
                DAO
              </StyledDefaultLink>
            </Grid>
            <Grid item>
              <StyledDefaultLink
                href="https://votes.prosper.so"
                onClick={() => redirect('https://votes.prosper.so')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledMenuItemIcon src={socialPredictions} alt="votes" />
                Votes
              </StyledDefaultLink>
            </Grid>
          </Grid>
        </Grid>
      </StyledDropDownSelect>
    </FormControl>

  );
};

export default DropDownProducts;
