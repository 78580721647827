import styled from 'styled-components';

const StyledStatus = styled.span`
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
`;

export default StyledStatus;
