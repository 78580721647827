import React, { ReactElement, useEffect } from 'react';
// styles
import {
  OfficeContainer,
  StyledOfficeBackground,
} from 'styles/desktop/StyledReferralPrivateOffice';
// components
import BoldTitle from 'components/common/BoldTitle';
import TotalEarnings from './TotalEarnings';
import PayoutTable from './PayoutTable';
import ReferralLink from './ReferralLinkBlock';

const PrivateOfficeContainer: React.FC = (): ReactElement => (
  <StyledOfficeBackground container justifyContent="center">
    <OfficeContainer>
      <BoldTitle placement="header">Profile of referral program</BoldTitle>
      <TotalEarnings />
      <PayoutTable />
      <ReferralLink />
    </OfficeContainer>
  </StyledOfficeBackground>
);

export default PrivateOfficeContainer;
