import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';

import {
  StyledCardContainer,
  StyledLeaderBoard,
  StyledLeaderboardDropdown,
  StyledTableContainer,
} from 'styles/desktop/StyledLeaderBoard';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import BoldTitle from '../../common/BoldTitle';
import Search from '../../common/Search';
import LeaderCard from './LeaderCard';
import LeaderBoardTable from './LeaderBoardTable';
import ContentPagination from '../pools/table/content/ContentPagination';
import { useGlobalState } from '../../../context/globalState';
import { ILeaderboardUser } from '../../../interfaces/leaderboard.d';
import { useLeaderboardState } from '../../../context/leaderboardContext';

const LeaderBoardContainer: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['leaderboard']);
  const { state, dispatch } = useGlobalState();
  const [page, setPage] = useState(0);
  const [findUserState, setFindUserState] = useState<ILeaderboardUser | undefined | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();
  const [sortType, setSortType] = useState<string>(t('Net winnings').toUpperCase());

  type SortType =
      'NET WINNINGS' |
      'ROUNDS WON' |
      'WIN RATE';

  const sortTypesDictionary = {
    'NET WINNINGS': 'ByNetWinnings',
    'ROUNDS WON': 'ByRoundsWon',
    'WIN RATE': 'ByWinrate',
  };

  const translateTypesDictionary = {
    'NET WINNINGS': t('Net winnings').toUpperCase(),
    'ROUNDS WON': t('Rounds won').toUpperCase(),
    'WIN RATE': t('Win rate').toUpperCase(),
  };

  const getKeyByValue = (object : typeof translateTypesDictionary, value : string) : SortType => {
    const neededKey = Object.keys(object).find((key) => object[key as SortType] === value);
    return (
        neededKey || 'NET WINNINGS'
    ) as SortType;
  };

  const getUsers = useCallback(async () => {
    try {
      const usersResponse = await axios.get(`${state.baseUrl}/statistics/leaderboard?networkId=${state.currentNetworkId}&sortingType=${sortTypesDictionary[getKeyByValue(translateTypesDictionary, sortType)]}`);
      if (usersResponse.data) {
        await leaderboardDispatch({
          type: 'SET_USERS',
          payload: {
            ...leaderboardState,
            account: state.account,
            users: usersResponse.data,
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error received in Leaderboard when calling the method getUsers', e);
    }
  }, [state.baseUrl, state.account, sortType, state.currentNetworkId]);

  const getPlacesArray = (count : number) : number[] => {
    switch (count) {
      case 1:
        return [1];
      case 2:
        return [2, 1];
      case 3:
        return [3, 1, 2];
      default:
        return [3, 1, 2];
    }
  };

  const changePage = (newPage: number) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform switch page leaderboard',
        amplitudeProps: {
          page: newPage,
        },
      },
    });

    setPage(newPage - 1);
    leaderboardDispatch({
      type: 'SWITCH_PAGE',
      payload: {
        ...leaderboardState,
        currentPage: newPage - 1,
      },
    });
  };

  const checkByCurrentUser = (place: number) => (
    leaderboardState.users?.length
      ? leaderboardState.users[place - 1].sender === state.account.toLowerCase()
      : false
  );

  const findUserById = (userId : string) => {
    if (userId && leaderboardState.users) {
      setFindUserState(leaderboardState.users
        .find((user) => user.sender === userId.toLowerCase()));
    } else {
      setFindUserState(null);
    }
  };

  const doSearch = (userId : string) => {
    setSearchText(userId);
  };

  useEffect(() => {
    if (searchText) findUserById(searchText);
    else setFindUserState(null);
  }, [searchText]);

  useEffect(() => {
    if (leaderboardState.users) {
      leaderboardDispatch({
        type: 'SET_USERS',
        payload: {
          ...leaderboardState,
          users: [],
        },
      });
    }
  }, [sortType]);

  useEffect(() => {
    getUsers().then();
  }, [getUsers, sortType]);

  return (
    <Grid container justifyContent="center">
      <StyledLeaderBoard>
        <BoldTitle placement="leaderboard">
          {t('Leaderboard')}
        </BoldTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <StyledLeaderboardDropdown
              title={t('Rank by')}
              currentItem={sortType}
              items={Object.values(translateTypesDictionary)}
              changeItem={(event) => setSortType(event.target.value as SortType)}
            />
          </Grid>
          <Grid item>
            <Search doSearch={doSearch} />
          </Grid>
        </Grid>

        <StyledCardContainer container flexWrap="nowrap" alignItems="flex-end" justifyContent={leaderboardState.users.length === 1 ? 'center' : 'flex-start'}>
          {
              getPlacesArray(leaderboardState.users.length).map((place) => (
                <LeaderCard place={place} isCurrentUser={checkByCurrentUser(place)} />
              ))
          }

        </StyledCardContainer>
        <StyledTableContainer>
          <LeaderBoardTable foundUser={findUserState} />
        </StyledTableContainer>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={leaderboardState.users.length < 10
            ? { paddingBottom: '20px' }
            : { paddingBottom: '0' }}
        >
          {leaderboardState.users.length > 10 && (
          <ContentPagination
            total={leaderboardState.users.length}
            changePage={changePage}
          />
          )}
        </Grid>
      </StyledLeaderBoard>
    </Grid>
  );
};

export default LeaderBoardContainer;
