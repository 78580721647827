import React, { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  ModalContainer, PaperContainer, StyledPaper,
} from 'styles/modals/StyledSearchModal';
import {
  ReferalConnectWalletTextTitle,
  ReferalConnectWalletTextContainer,
  ReferalConnectWalletImgContainer,
  ReferalConnectWalletImg,
  ReferalOurAdvantagesButtonConnect,
  CloseButton,
} from 'styles/mobile/StyledReferal';

import { useTranslation } from 'react-i18next';
import {
  ReferalConnectWalletTextSubtitle,
} from '../../../styles/common/StyledReferalModals';
import ConnectWallet from '../../../static/images/connect_wallet.svg';
import { useGlobalState } from '../../../context/globalState';
import { useModalState } from '../../../context/modalState';
import { ReactComponent as ReturnIcon } from '../../../static/images/common_icons/return_icon.svg';

const ReferralConnectWalletModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);
  const { state, dispatch } = useGlobalState();
  const { modalsState, modalDispatch } = useModalState();
  const { t } = useTranslation(['referral']);

  const handleClose = () => {
    modalDispatch({
      type: 'CLOSE_REFERAL_CONNECT_MODAL',
    });
  };

  useEffect(() => {
    if (modalsState.openedReferalConnectModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open referral connect modal',
        },
      });
    }

    setOpen(modalsState.openedReferalConnectModal);
  }, [modalsState.openedReferalConnectModal]);

  const connect = async (): Promise<void> => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform connect wallet click',
        amplitudeProps: {
          metamaskInstalled: window.ethereum && window.ethereum.isMetaMask,
          trustWalletInstalled: window.ethereum && window.ethereum.isTrustWallet,
        },
      },
    });

    if (!(window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet))) {
      window.location.href = 'https://metamask.io';
    }
    let accounts: Array<string> = [];
    const networkId: number | null = state.currentNetworkId;

    if (state.networkProvider) {
      accounts = await state.networkProvider.listAccounts();
    }

    if (accounts.length < 1) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.location.reload();
    }

    if (+window.ethereum.chainId !== networkId) {
      modalDispatch({ type: 'OPEN_WRONG_NETWORK_MODAL' });
    }
  };

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <StyledPaper>
        <PaperContainer
          container
          justifyContent="space-between"
          direction="column"
        >
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item container flexDirection="column" wrap="nowrap">
              <CloseButton onClick={handleClose}>
                <ReturnIcon onClick={handleClose} />
                {t('Return')}
              </CloseButton>
              <ReferalConnectWalletTextContainer
                item
                container
                direction="column"
                wrap="nowrap"
              >
                <ReferalConnectWalletTextTitle item>
                  {t('Connect a wallet')}
                </ReferalConnectWalletTextTitle>
                <ReferalConnectWalletTextSubtitle item>
                  {t('To participate in Predix pool you first need to connect a wallet. Please select an option Below. You can also connect a Ledger via your Metamask')}
                </ReferalConnectWalletTextSubtitle>
              </ReferalConnectWalletTextContainer>

              <ReferalConnectWalletImgContainer item>
                <ReferalConnectWalletImg src={ConnectWallet} />
              </ReferalConnectWalletImgContainer>

              <ReferalOurAdvantagesButtonConnect onClick={connect}>
                {t('Connect')}
              </ReferalOurAdvantagesButtonConnect>
            </Grid>
          </Grid>
        </PaperContainer>
      </StyledPaper>
    </ModalContainer>
  );
};

export default ReferralConnectWalletModal;
