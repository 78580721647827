import * as React from 'react';

import Typography from '@mui/material/Typography';
import {
  QuestionLine, ReferalAccordionDetails, ReferalAccordionSummary, ReferalQuestionDescription,
  ReferalQuestionsAccordion,
  ReferalQuestionsContainer, ReferalQuestionsInfoContainer, ReferalQuestionSubtitle,
  ReferalQuestionTitle,
  ReferalTypography,
} from 'styles/desktop/StyledReferal';
import { useTranslation } from 'react-i18next';

function ReferralQuestions() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { i18n, t } = useTranslation(['referral']);

  return (
    <ReferalQuestionsContainer>
      <ReferalQuestionTitle>
        {t('Answers to popular questions')}
      </ReferalQuestionTitle>
      <ReferalQuestionsInfoContainer>
        <ReferalQuestionSubtitle>{t('How do I join this program?')}</ReferalQuestionSubtitle>
        <ReferalQuestionDescription>
          {t('You need to press the “Join the program” button and after that in pop-up emerged press the “Copy link”. This link can be sent to your friends or you can share it on your social media.')}
        </ReferalQuestionDescription>
        <QuestionLine />
        <ReferalQuestionsAccordion
          onChange={handleChange('panel1')}
        >
          <ReferalAccordionSummary>
            <ReferalTypography>
              {t('How much can I earn?')}
            </ReferalTypography>
          </ReferalAccordionSummary>
          <ReferalAccordionDetails>
            <Typography>
              {t('Depends on the activity of your referrals - but we can provide you with up to 30% of our commission income. You can use our calculator to make the counts which can be found above.')}
            </Typography>
          </ReferalAccordionDetails>
        </ReferalQuestionsAccordion>
        <QuestionLine />
        <ReferalQuestionsAccordion onChange={handleChange('panel2')}>
          <ReferalAccordionSummary>
            <ReferalTypography>
              {t('Is the number of referrals limited?')}
            </ReferalTypography>
          </ReferalAccordionSummary>
          <ReferalAccordionDetails>
            <Typography>
              {t('No, you can invite any number of users.')}
            </Typography>
          </ReferalAccordionDetails>
        </ReferalQuestionsAccordion>
        <QuestionLine />
        <ReferalQuestionsAccordion onChange={handleChange('panel3')}>
          <ReferalAccordionSummary>
            <ReferalTypography>
              {t('How are the payments conducted?')}
            </ReferalTypography>
          </ReferalAccordionSummary>
          <ReferalAccordionDetails>
            <Typography>
              {t('They will be conducted once in two weeks.')}
            </Typography>
          </ReferalAccordionDetails>
        </ReferalQuestionsAccordion>
        <QuestionLine />
        <ReferalQuestionsAccordion onChange={handleChange('panel3')}>
          <ReferalAccordionSummary>
            <ReferalTypography>
              {t('What is the minimum payment?')}
            </ReferalTypography>
          </ReferalAccordionSummary>
          <ReferalAccordionDetails>
            <Typography>
              {t('The minimal payment is 0.1 $BNB.')}
            </Typography>
          </ReferalAccordionDetails>
        </ReferalQuestionsAccordion>
        <QuestionLine />
        <ReferalQuestionsAccordion onChange={handleChange('panel3')}>
          <ReferalAccordionSummary>
            <ReferalTypography>
              {t('Do I need to request the payment manually?')}
            </ReferalTypography>
          </ReferalAccordionSummary>
          <ReferalAccordionDetails>
            <Typography>
              {t('No, they will be conducted automatically if your income from the referral program matches the minimum payment of 0.1 $BNB')}
            </Typography>
          </ReferalAccordionDetails>
        </ReferalQuestionsAccordion>
        <QuestionLine />
      </ReferalQuestionsInfoContainer>

    </ReferalQuestionsContainer>
  );
}

export default ReferralQuestions;
