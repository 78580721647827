import React, { createContext, useContext, useReducer } from 'react';
import { ILeaderboardState, ILeaderboardUser } from '../interfaces/leaderboard.d';

const initialState: ILeaderboardState = {
  users: [],
  currentUser: null,
  account: '',
  currentPage: 0,
};
type PayloadType = ILeaderboardState;

type ActionType =
    'SET_USERS'
    | 'SWITCH_PAGE';

interface IAction {
    type: ActionType;
    payload: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const LeaderboardContext = createContext<{leaderboardState: ILeaderboardState;
  leaderboardDispatch: DispatchType} | undefined>(undefined);

const getCurrentUserFromAllUsers = (
  account : string,
  leaderboardState: ILeaderboardState,
  users: ILeaderboardUser[],
)
    : ILeaderboardState => {
  const currentUser = users.length > 1 ? users
    .filter((data: ILeaderboardUser) => data.sender === account.toLowerCase())[0]
    : users[0];

  return {
    ...leaderboardState,
    currentUser,
    users,
  };
};

const stateReducer = (leaderboardState : ILeaderboardState, action: IAction) => {
  switch (action.type) {
    case 'SET_USERS':
      return getCurrentUserFromAllUsers(
        action.payload.account,
        leaderboardState,
        action.payload.users,
      );
    case 'SWITCH_PAGE':
      return {
        ...leaderboardState,
        currentPage: action.payload.currentPage,
      };
    default:
      return initialState;
  }
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [leaderboardState, leaderboardDispatch] = useReducer(stateReducer, initialState);
  return (
    <LeaderboardContext.Provider value={{ leaderboardState, leaderboardDispatch }}>
      { children }
    </LeaderboardContext.Provider>
  );
};

export const useLeaderboardState = () => {
  const context = useContext(LeaderboardContext);

  if (!context) throw new Error('useLeaderboardContext must be used in a leaderboard state provider');

  return context;
};

export default StateProvider;
