import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

interface ILogo {
    network?: 'BNB' | 'ETH' | undefined;
}

export const HeaderContainer = styled(Grid)`
  background: linear-gradient(#08083E 0%, rgba(14, 14, 30, 0) 100%);
  height: 96px;
  padding: 35px;
  z-index: 100;
`;

export const ContentRow = styled(Grid)`
  width: 1440px;
`;

export const StyledLink = styled(NavLink)`
  margin: 0 32px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  &.active {
    color: #FD0;
    border-bottom: 1px solid #FD0;
    padding-bottom: 2px;
  }
`;

export const StyledOutPlatformLink = styled.a`
  margin: 0 32px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const StyledDefaultLink = styled.a`
  margin: 0 20px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  cursor: pointer;
`;

export const DropDownSelect = styled(Select)`
  &.MuiOutlinedInput-root{
    display: none;
  }
`;

export const DropDownMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root{
    color: black;
  }
`;

export const DropDownLogo = styled.div<ILogo>`
    display: flex;
    justify-content: center;align-items: center;
    background-color: ${(props) => {
    if (props.network) {
      return (props.network === 'ETH' ? '#5900F7' : 'white');
    }
    return '';
  }};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 12px;
    cursor: pointer;
`;

export const StatisticsButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  padding-right: 12px;
  height: 38px;
  width: 141px;
  background: #5900F7;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
`;

export const StatisticsFireContainer = styled.div<{ golden: boolean }>`
  position: relative;
  background: ${(props) => (props.golden ? '#FD0' : '#5900F7')};
  border-radius: 50%;
  width: 38px;
  height: 38px;
  min-width: 38px;
  margin-right: ${isMobile && 12}px;

  svg {
    position: absolute;
    top: 7px;
    right: 7px;
  }
`;

export const LanguageItemLogo = styled.img<{ selected: boolean }>`
  ${(props) => props.selected === true
          && 'border-radius: 50%; box-shadow: 0 0 0 3px rgb(255 160 0 / 50%);'};
`;
