import styled from 'styled-components';
import {
  Link,
} from 'react-router-dom';
import {
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

export const StyledPrivateOfficeContainer = styled.div`
  padding: 22px 17px 56px;
  background-color: #0C0C21;
  min-height: calc(100vh - 72px);
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: white;
  text-decoration: none;
  
  svg {
    margin-right: 10px;
  }
`;

export const StyledPayoutTableContainer = styled.div`
  margin: 48px 0 50px;
`;

export const PayoutTableTitle = styled.h2`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: center;
    border: none;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
  }
`;

export const StyledPayoutTableHeader = styled(TableHead)`
  ${StyledTableCell} {
    line-height: 14px;
    color: white;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
  }
`;

export const StyledRow = styled(TableRow)`
  background-color: rgba(206, 181, 181, 0.1);
  height: 52px;

  ${StyledTableCell}:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  ${StyledTableCell}:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const StatusBlock = styled.span<{paid: boolean}>`
  font-size: 10px;
  line-height: 11px;
  margin: 2px 0px;
  color: ${({ paid }) => (paid ? '#00FF47' : '#FFDD00')};
`;
