import React, { ReactElement } from 'react';
import { ReactComponent as ProgressCursorContainer } from 'static/images/progress_cursor.svg';
import CursorBull from 'static/images/cursor_bull.svg';
import CursorBear from 'static/images/cursor_bear.svg';
import CursorBoth from 'static/images/cursor_both.svg';
// styles
import {
  ProgressBarContainer,
  StylesProgressBar,
  ProgressCursor,
} from 'styles/desktop/StyledClosedPools';

interface IProps {
    progress: number;
}

const cursorImage = (progress: number): string => {
  if (progress === 50) {
    return CursorBoth;
  }

  if (progress < 50) {
    return CursorBear;
  }

  return CursorBull;
};

const Progress: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    progress,
  } = props;

  return (
    <ProgressBarContainer>
      <ProgressCursor progress={progress}>
        <ProgressCursorContainer />
        <img src={cursorImage(progress)} alt="icon" />
      </ProgressCursor>
      <StylesProgressBar variant="determinate" value={progress} />
    </ProgressBarContainer>
  );
};

export default Progress;
