import React, { ReactElement, useEffect, useState } from 'react';
import { Backdrop, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ReferalConnectWalletImg,
  ReferalConnectWalletImgContainer,
  ReferalConnectWalletModalContainer,
  ReferalConnectWalletTextContainer, ReferalConnectWalletTextSubtitle,
  ReferalConnectWalletTextTitle,
} from '../../../styles/common/StyledReferalModals';
import ConnectWallet from '../../../static/images/connect_wallet.svg';
import { ReferalOurAdvantagesButton } from '../../../styles/desktop/StyledReferal';
import { useGlobalState } from '../../../context/globalState';
import { useModalState } from '../../../context/modalState';

const ReferralConnectWalletModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);
  const { state, dispatch } = useGlobalState();
  const { modalsState, modalDispatch } = useModalState();
  const { i18n, t } = useTranslation(['referral']);

  const handleClose = () => {
    modalDispatch({
      type: 'CLOSE_REFERAL_CONNECT_MODAL',
    });
  };

  useEffect(() => {
    if (modalsState.openedReferalConnectModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open referral connect modal',
        },
      });
    }

    setOpen(modalsState.openedReferalConnectModal);
  }, [modalsState.openedReferalConnectModal]);

  const connect = async (): Promise<void> => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform connect wallet click',
        amplitudeProps: {
          metamaskInstalled: window.ethereum && window.ethereum.isMetaMask,
          trustWalletInstalled: window.ethereum && window.ethereum.isTrustWallet,
        },
      },
    });

    if (!(window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet))) {
      window.location.href = 'https://metamask.io';
    }
    let accounts: Array<string> = [];
    const networkId: number | null = state.currentNetworkId;

    if (state.networkProvider) {
      accounts = await state.networkProvider.listAccounts();
    }

    if (accounts.length < 1) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.location.reload();
    }

    if (+window.ethereum.chainId !== networkId) {
      modalDispatch({ type: 'OPEN_WRONG_NETWORK_MODAL' });
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <ReferalConnectWalletModalContainer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <>
          <Grid item container wrap="nowrap">
            <ReferalConnectWalletTextContainer
              item
              container
              direction="column"
              wrap="wrap"
            >
              <ReferalConnectWalletTextTitle item>
                {t('Connect a wallet')}
              </ReferalConnectWalletTextTitle>
              <ReferalConnectWalletTextSubtitle item>
                {t('To participate in Predix pool you first need to connect a wallet. Please select an option Below. You can also connect a Ledger via your Metamask')}
              </ReferalConnectWalletTextSubtitle>
              <ReferalOurAdvantagesButton onClick={connect}>
                {t('Connect')}
              </ReferalOurAdvantagesButton>
            </ReferalConnectWalletTextContainer>

            <ReferalConnectWalletImgContainer item>
              <ReferalConnectWalletImg src={ConnectWallet} />
            </ReferalConnectWalletImgContainer>
          </Grid>
        </>
      </ReferalConnectWalletModalContainer>
    </Grid>
  );
};

export default ReferralConnectWalletModal;
