import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';
import bull from 'static/images/standing_bull.svg';
import bear from 'static/images/standing_bear.svg';

const ButtonContainer = styled(Grid)<{bull: String, selected: boolean}>`
  padding: 20px 24px;
  border-radius: 16px;
  height: 164px;
  margin: 8px 0;
  background: ${(props) => (props.bull === 'true'
    ? `url(${bull}) no-repeat -105px -20px/250px 250px, linear-gradient(180deg, #4919D0 0%, #38149F 100%)`
    : `url(${bear}) no-repeat 160px 4px/250px 250px, linear-gradient(180deg, #333333 0%, #2D2C2C 100%)`)};
  box-shadow: ${(props) => (props.selected
    ? '0 0 4px rgb(255 221 0 / 50%), 0 0 16px rgb(255 221 0 / 70%), 0 0 0 1px #fd0'
    : 'none')};
`;

export const ButtonContentContainer = styled(Grid)<{bull: String}>`
  &.MuiGrid-root{
    margin-left: ${(props) => (props.bull === 'true' ? 140 : 0)}px;
  }
`;

export const ButtonHint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 88px;
  height: 24px;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 1px;
`;

export const ButtonContentTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 12px;
  margin-bottom: 4px;
`;

export const ButtonContent = styled.div`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;

  img {
    margin-right: 8px;
  }
`;

export default ButtonContainer;
