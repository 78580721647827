import React, {
  ReactElement,
  useState,
  useEffect,
  useMemo, ReactNode, ChangeEvent,
} from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, SelectChangeEvent } from '@mui/material';
import {
  getNetworksNameViaIds,
  networkIcons,
} from 'networksHelper';
import { useMiningState } from 'context/miningState';
import { useTranslation } from 'react-i18next';

import DropDown from 'components/common/DropDown';

// styles
import {
  MiningStageContainer,
} from 'styles/desktop/StyledMining';

import DataBlock from 'components/common/MiningDataBlock';

const DataContainer: React.FC = (): ReactElement => {
  const [networks, setNetworks] = useState<Array<string>>([]);
  const [stages, setStages] = useState<Array<string>>([]);
  const { miningState, miningDispatch } = useMiningState();
  const { t } = useTranslation(['mining']);

  const dailyPools = useMemo(() => (miningState.stageData.stageDailyRewardPools
    ? miningState.stageData.stageDailyRewardPools.map(
      (pool) => ({
        amount: (miningState.stageData.stageTotalRewardPool * pool.payoutRatio) / 10,
        pair: `${pool.feed.split('/')[0]}/${pool.token}`,
      }),
    )
    : []),
  [miningState.stageData.stageDailyRewardPools]);

  const changeNetwork = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const network = Number(event.target.value);
    miningDispatch({
      type: 'SWITCH_NETWORK',
      payload: {
        ...miningState,
        network,
      },
    });
  };

  const changeStage = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const stage = Number(event.target.value);
    miningDispatch({
      type: 'SWITCH_STAGE',
      payload: {
        ...miningState,
        stage,
      },
    });
  };

  useEffect(() => {
    if (miningState.epochsData) {
      const networksName = getNetworksNameViaIds(miningState.epochsData
        .map((epoch) => epoch.networkId));
      const knownNetworks = networksName.map((networkName) => networkName || 'BSC');
      const epochStages = miningState.epochsData.map((epoch) => `Stage ${epoch.stage}`);
      setNetworks(knownNetworks);
      setStages(epochStages);
    }
  }, [miningState.epochsData]);

  return (
    <Grid container direction="column">
      <Grid item container>
        <Grid item xs={isMobile && 6}>
          <DropDown
            title={t('common:Network')}
            items={networks}
            icons={networkIcons}
            currentItem={getNetworksNameViaIds([miningState.network])[0] || ''}
            changeItem={changeNetwork}
          />
        </Grid>
        <Grid item xs={isMobile && 6}>
          <DropDown
            title={t('Stage')}
            items={stages}
            currentItem={miningState.stage ? `${t('Stage')} ${miningState.stage}` : ''}
            changeItem={changeStage}
          />
        </Grid>
      </Grid>
      <MiningStageContainer item container spacing={isMobile ? 1 : 4}>
        <DataBlock title={t('common:Date')} amount={miningState.stageData.stageDate} />
        <DataBlock title={t('Total reward pool')} amount={miningState.stageData.stageTotalRewardPool} token="REVV" />
        {
          dailyPools.map((pool) => (
            <DataBlock title={t('Daily reward pool')} pair={pool.pair} amount={pool.amount} token="REVV" />
          ))
        }
      </MiningStageContainer>
    </Grid>
  );
};

export default DataContainer;
