import React, { useEffect, useState } from 'react';
import { BetWarningText } from '../../styles/modals/StyledBetModal';

interface IProps{
    userAmount: string,
    accountBalance: number,
    isMobile : boolean
}

const BetModalWarningTitle: React.FC<IProps> = (props: IProps): React.ReactElement | null => {
  const { userAmount, accountBalance, isMobile } = props;
  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (+userAmount < 0.005) {
      setText('Must be greater than 0.005 BNB');
    } else if (+accountBalance < +userAmount) {
      setText('You dont have enough funds');
    }
    if (+userAmount === 0) {
      setText('');
    }
  }, [userAmount, accountBalance]);

  return (
    <BetWarningText mobile={isMobile}>
      {text}
    </BetWarningText>
  );
};

export default BetModalWarningTitle;
