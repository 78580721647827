export const numberToFixedNumber = (number: number): number => +number.toFixed(2);

export const stringToFixedNumber = (stringNumber: string): number => +(+stringNumber).toFixed(2);

export const numberToProperFixedNumber = (number: number, unit: number): number => (
  +number.toFixed(unit)
);

export const stringToProperFixedNumber = (stringNumber: string, unit: number): number => (
  +(+stringNumber).toFixed(unit)
);
