import React, { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ReferralCurrentButton from 'components/desktop/referral/ReferralCurrentButton';
import RecommendGirl from 'static/images/recommendModalGirl.svg';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import { ModalContainer, PaperContainer, StyledPaper } from 'styles/modals/StyledSearchModal';
import {
  ReferalConnectWalletTextContainer,
  ReferalConnectWalletImgContainer,
  ReferalModalButton,
  ReferalConnectRecommendImg,
  CloseButtonRecommend,
} from 'styles/mobile/StyledReferal';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  ReferalRecommendTextTitle,
} from '../../../styles/common/StyledReferalModals';

const ReferralRecommendModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);
  const { state, dispatch } = useGlobalState();
  const { modalsState, modalDispatch } = useModalState();
  const [referrerCode, setReferrerCode] = useState<string>('');
  const { t } = useTranslation(['referral']);

  const createReferalLink = () : string => `${state.baseUrl}/referral-link/${referrerCode}`;

  const handleClose = () => {
    modalDispatch({
      type: 'CLOSE_REFERAL_RECOMMEND_MODAL',
    });
  };

  const getReferralCode = async () => {
    try {
      const referralCode = await axios.get(`${state.baseUrl}/referral/code?referrerWallet=${state.account.toLowerCase()}`);
      setReferrerCode(referralCode.data);
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong in getReferralCode');
    }
  };

  useEffect(() => {
    if (modalsState.openedReferalRecommendModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open referral recommend modal',
        },
      });
    }
    setOpen(modalsState.openedReferalRecommendModal);
  }, [modalsState.openedReferalRecommendModal]);

  useEffect(() => {
    if (state.account) {
      getReferralCode().then((r) => r);
    }
  }, [state.account]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <ModalContainer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <StyledPaper>
          <PaperContainer
            container
            justifyContent="space-between"
            direction="column"
          >
            <Grid container flexDirection="column" justifyContent="center" alignItems="center">
              <Grid item container wrap="wrap">
                <CloseButtonRecommend onClick={handleClose}>
                  {`${t('Close')} X`}
                </CloseButtonRecommend>
                <ReferalConnectWalletTextContainer
                  item
                  container
                  direction="column"
                  wrap="wrap"
                >
                  <ReferalRecommendTextTitle item>
                    {t('Recommend Prosper and earn money')}
                  </ReferalRecommendTextTitle>
                </ReferalConnectWalletTextContainer>
                <ReferalConnectWalletImgContainer item>
                  <ReferalConnectRecommendImg src={RecommendGirl} />
                </ReferalConnectWalletImgContainer>
                <ReferalModalButton>
                  <ReferralCurrentButton referalLink={createReferalLink()} />
                </ReferalModalButton>
              </Grid>
            </Grid>
          </PaperContainer>
        </StyledPaper>
      </ModalContainer>
    </Grid>
  );
};

export default ReferralRecommendModal;
