import styled from 'styled-components';

export const StyledBoldtitle = styled.h2`
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.03em;
  text-shadow: 4px 4px 0px #4919CF;
`;

export const TableTitle = styled(StyledBoldtitle)`
  letter-spacing: 0.02em;
  font-size: 34px;
  line-height: 39px;
`;

export const MiningTitle = styled(StyledBoldtitle)`
  font-size: 50px;
  line-height: 57px;
`;

export const ReferalTitle = styled(StyledBoldtitle)`
  font-size: 50px;
  line-height: 57px;
  padding-top: 96px;
`;

export const MobileTitle = styled(StyledBoldtitle)`
  font-size: 24px;
  line-height: 28px;
  text-shadow: 2px 2px 0px #4919CF;
`;

export const LeaderboardTitle = styled(StyledBoldtitle)`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.03em;
`;
