import React, { ReactElement, useCallback, useEffect } from 'react';
import axios from 'axios';
import { IPayout } from 'interfaces/state.d';
import { useGlobalState } from 'context/globalState';
import { useReferralPrivateOfficeState } from 'context/referralPrivateOfficeState';
// styles
import {
  StyledPayoutTableContainer,
  PayoutTableTitle,
} from 'styles/mobile/StyledReferralPrivateOffice';
// components
import LoadButton from 'components/mobile/closed_pools/LoadMoreButton';
import Table from './PayoutTableContent';

const PayoutTableContainer: React.FC = (): ReactElement => {
  const { state } = useGlobalState();
  const {
    referralPrivateOfficeState,
    referralPrivateOfficeDispatch,
  } = useReferralPrivateOfficeState();

  const showMore = () => {
    referralPrivateOfficeDispatch({
      type: 'SET_TABLE_PAGE',
      payload: {
        ...referralPrivateOfficeState,
        tablePage: referralPrivateOfficeState.tablePage + 1,
      },
    });
  };

  const getPayouts = useCallback(async () => {
    if (referralPrivateOfficeState.filter === 'unpaid') {
      return [{
        amount: referralPrivateOfficeState.unpaidAmount,
        date: null,
      }];
    }

    const periodDictionary = {
      week: 0,
      month: 1,
      'all time': 2,
      unpaid: 2,
    };
    const period = periodDictionary[referralPrivateOfficeState.filter];

    try {
      const payoutsResponse = await axios.get(`${state.baseUrl}/referral-payout`, {
        params: {
          networkId: state.currentNetworkId,
          period,
          wallet: state.account.toLowerCase(),
          isPaid: true,
        },
      });

      if (payoutsResponse.data) {
        return payoutsResponse.data;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong while getting payouts ', e);
    }

    return [];
  }, [state.baseUrl, state.account, referralPrivateOfficeState.filter, state.currentNetworkId]);

  useEffect(() => {
    if (state.account) {
      getPayouts().then(
        (payouts: Array<IPayout>) => {
          referralPrivateOfficeDispatch({
            type: 'SET_PAYOUTS',
            payload: {
              ...referralPrivateOfficeState,
              payouts,
            },
          });
        },
      );
    }
  }, [state.account, referralPrivateOfficeState.filter]);

  return (
    <StyledPayoutTableContainer>
      <PayoutTableTitle>payout table</PayoutTableTitle>
      <Table />
      <LoadButton
        onClick={showMore}
        canLoadMore={
          referralPrivateOfficeState.payouts.slice(
            referralPrivateOfficeState.tablePage * 7,
            referralPrivateOfficeState.tablePage * 7 + 1,
          ).length > 0
        }
        loading={false}
      />
    </StyledPayoutTableContainer>
  );
};

export default PayoutTableContainer;
