import React, { ReactElement } from 'react';
import { useStatisticsState } from 'context/statisticsState';
import { useTranslation } from 'react-i18next';
// styles
import {
  StatisticsPnlStatContainer,
  StatisticsRowTitle,
  StatisticsRowContent,
  StatisticsRowHint,
  ProgressDifferenceContainer,
} from 'styles/modals/StyledStatisticsModal';
import ProgressCircle from 'components/common/StyledCircle';
import { useGlobalState } from '../../../context/globalState';
import { getCurrencyName } from '../../../networksHelper';

const PNLStat: React.FC = (): ReactElement => {
  const { statisticsState } = useStatisticsState();
  const { t } = useTranslation(['modals']);
  const { pnl, rounds } = statisticsState;
  const { state } = useGlobalState();

  const getSumRounds = () => rounds.wonRounds + rounds.lostRounds;

  const getVictoryPercent = (): number => (
    +((rounds.wonRounds / getSumRounds()) * 100).toFixed(2)
    || 0
  );

  const convertResults = () : number => +(Math.abs(pnl.netResults) * statisticsState.tokenCost)
    .toFixed(2);

  return (
    <StatisticsPnlStatContainer>
      <ProgressCircle percentage={getVictoryPercent()} />
      <ProgressDifferenceContainer>
        <StatisticsRowTitle>{t('Net results')}</StatisticsRowTitle>
        <StatisticsRowContent color="yellow">
          {`${pnl.netResults} ${getCurrencyName(state.currentNetworkId)}`}
        </StatisticsRowContent>
        <StatisticsRowHint>{`~ $${convertResults()}`}</StatisticsRowHint>
      </ProgressDifferenceContainer>
    </StatisticsPnlStatContainer>
  );
};

export default PNLStat;
