import styled from 'styled-components';
import {
  Paper,
  Modal,
  Grid,
  TextField,
} from '@mui/material';
import StyledCount from 'styles/common/StyledCountDown';
import StyledPoolStatus from 'styles/common/StyledPoolStatus';

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  outline: none;
  overflow: scroll;
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
`;

export const CloseButton = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
`;

export const ChoiseBlock = styled(Grid)`
  position: relative;
  background: #1A1A39;
  border-radius: 0px 0px 40px 40px;
  padding: 25px 20px;
`;

export const ChoiceItemTitle = styled(Grid)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 12px;

  ${StyledPoolStatus} {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-transform: none;
  }
`;

export const ChoiceItemContent = styled(Grid)`
  &.MuiGrid-root {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    margin-top: 4px;
  }
`;

export const ChoiceContentBlock = styled(Grid)`
  margin-top: 4px;

  ${StyledCount} {
    margin: 0;
  }
`;

export const ChoiceAmountBlock = styled(ChoiceContentBlock)`
  margin-bottom: 200px;
`;

export const ChoiceButtonsBlock = styled(ChoiceContentBlock)`
  position: absolute;
  left: 0;
  bottom: -55px;
  padding: 16px 18px 0;
  margin-bottom: 10px;
`;

export const ChoiceTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 35px;
  }
`;

export const SubmitBlock = styled(Grid)`
    padding: 55px 18px 10px;

`;

export const SubmitTitle = styled(ChoiceItemTitle)`
  color: rgba(12, 12, 33, 0.7);
`;
export const SubmitContent = styled(Grid)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #0C0C21;
`;

export const ChosenSideBlock = styled(Grid)<{side: String | undefined}>`
  &.MuiGrid-root{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 1px;
    border-radius: 4px;
    width: 76px;
    height: 24px;
    margin-top: 2px;
    margin-bottom: 2px;
    background: ${(props) => {
    if (props.side === '0') {
      return '#5900F7';
    }
    if (props.side === '1') {
      return '#323232';
    }
    return '';
  }};
  }
`;

export const SubmitButtonContainer = styled(Grid)`
  &.MuiGrid-root{
    margin-top: 20px;
  }
`;
