import styled from 'styled-components';
import {
  Paper,
  Modal,
  Grid,
} from '@mui/material';

export const ModalContainer = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root{
    width: 924px;
    height: 705px;
    border-radius: 16px;
    outline: none;
  }
`;

export const ChoiseBlock = styled(Grid)`
    background: #1A1A39;
    border-radius: 16px;
    padding: 32px 119px;
`;

export const SubmitBlock = styled(Grid)`
    padding: 32px 80px;
`;

export const Title = styled.h2`
    font-weight: bold;
    font-size: 29.375px;
    line-height: 34px;
    letter-spacing: 0.03em;
    margin-top: 0;
`;

export const ChoiceItemTitle = styled(Grid)`
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.7);
`;

export const SubmitTitle = styled(ChoiceItemTitle)`
    color: rgba(12, 12, 33, 0.7);
`;
export const SubmitContent = styled(Grid)`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #0C0C21;
`;

export const ChoiceItemContent = styled(Grid)`
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.01em;
    margin: 4px 0;
`;

export const ChoiceContentBlock = styled(Grid)`
  &.MuiGrid-root{
    margin-top: 20px;
  }
`;

export const BetWarningText = styled.p<{ mobile: boolean }>`
  color: red;
  padding-left: 4px;
  font-size: ${({ mobile }) => (mobile ? 11 : 13)}px;
  height: 6px;
`;
