import styled from 'styled-components';
import {
  Button,
} from '@mui/material';

interface IButton {
    secondary?: string;
    mobile: string;
}

const StyledButton = styled(Button)<IButton>`
  &.MuiButtonBase-root{
    font-family: 'TT Supermolot Neue';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    border: none;
    border-radius: 12px;
    height: 43px;
    background: ${(props) => (props.secondary === 'true'
    ? '#5900F7'
    : 'linear-gradient(180deg, #FFDD00 0%, #FFC700 100%)')};
    color: ${(props) => (props.secondary === 'true'
    ? 'white'
    : '#0C0C21')};
    text-transform: none;
    font-weight: ${(props) => props.mobile === 'true' && 'bold'};
  }
    &.Mui-disabled {
        background: #E7E7E9;
        color: #0C0C21;
    }
`;

export default StyledButton;
