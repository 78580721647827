import React, {
  ReactElement, useState, useEffect, useCallback,
} from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { useActualPoolState } from 'context/actualPoolState';
import {
  IPool,
} from 'interfaces/state.d';
import {
  IQueryParameters,
} from 'interfaces/common.d';
import PredictInformation from './PredictInformation';
import PoolStatus from './ActualPoolStatus';
import BetContainer from './ActualPoolBetContainer';
import PoolProgress from './ActualPoolProgressContainer';

const ActualPoolContainer: React.FC = (): ReactElement => {
  const { state } = useGlobalState();
  const { actualPoolState, actualPoolDispatch } = useActualPoolState();

  const getActualPool = useCallback(async (): Promise<void> => {
    const queryParams: IQueryParameters = {
      networkId: state.currentNetworkId,
      page: 0,
      feedId: actualPoolState.feedId,
      tokenId: actualPoolState.tokenId,
    };

    try {
      const actualPoolResponse = await axios.get(`${state.baseUrl}/pools/_actual`, {
        params: {
          networkId: queryParams.networkId,
          feed: queryParams.feedId,
          token: queryParams.tokenId,
        },
      });
      const actualPoolData: IPool = actualPoolResponse.data as IPool;
      if (actualPoolData) {
        actualPoolDispatch({ type: 'SET_ACTUAL_POOL', payload: { ...actualPoolState, actualPool: actualPoolData } });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Got error during getting actual pool', e);
    }
  }, [state.baseUrl, state.currentNetworkId, actualPoolState.feedId, actualPoolState.tokenId]);

  const getCurrentPrice = useCallback(async (): Promise<void> => {
    try {
      const currentPriceResponse = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=axie-infinity&order=market_cap_desc&per_page=1&page=1&sparkline=false');

      if (currentPriceResponse.data) {
        actualPoolDispatch({
          type: 'SET_CURRENT_PRICE',
          payload: {
            ...actualPoolState,
            currentPrice: currentPriceResponse.data[0].current_price,
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Got error during getting current price', e);
    }
  }, [state.baseUrl, state.currentNetworkId, actualPoolState.feedId]);

  useEffect(() => {
    if (actualPoolState.tokenId && actualPoolState.feedId) {
      getActualPool();
    }
  }, [actualPoolState.tokenId, actualPoolState.feedId]);

  useEffect(() => {
    getCurrentPrice();

    const currentPriceIntervalId = setInterval(() => {
      getCurrentPrice();
    }, 60000);

    return () => clearInterval(currentPriceIntervalId);
  }, []);

  useEffect(() => {
    if (state.hubConnection) {
      state.hubConnection.on('bet', () => {
        getActualPool();
      });
      state.hubConnection.on('new-pool', (msg) => {
        getActualPool();
      });
    }
  }, [state.hubConnection]);

  return (
    <>
      <PredictInformation />
      <PoolStatus />
      <BetContainer />
      <PoolProgress />
    </>
  );
};

export default ActualPoolContainer;
