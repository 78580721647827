import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
// styles
import {
  EmptyH2,
  EmptyContainer,
} from 'styles/desktop/StyledReferal';
import {
  StyledOfficeBackground,
  OfficeContainer,
} from 'styles/desktop/StyledReferralPrivateOffice';

const EmptyReferralContainer: React.FC = (): ReactElement => (
  <StyledOfficeBackground container justifyContent="center">
    <OfficeContainer>
      <EmptyH2 />
      <EmptyContainer />
    </OfficeContainer>
  </StyledOfficeBackground>
);

export default EmptyReferralContainer;
