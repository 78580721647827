import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import leaderboard from 'static/images/leaderboardIcon.svg';
import mining from 'static/images/miningIcon.svg';
import information from 'static/images/informationIcon.svg';
import pools from 'static/images/poolsIcon.svg';
import referral from 'static/images/referralIcon.svg';

import {
  FormControl, Grid,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
  StyledDefaultLink, StyledDropDownBlockAbout,
  StyledDropDownSelect,
  StyledMenuItemIcon, StyledNavlink,
} from '../../../../styles/desktop/StyledDropDownNavigation';

import { useGlobalState } from '../../../../context/globalState';

const DropDownAbout: React.FC = (): ReactElement => {
  const [isReferralPage, setIsReferralPage] = useState<boolean>(false);
  const [openedSelect, setOpenedSelect] = useState<boolean>(false);
  const { dispatch } = useGlobalState();
  const { t } = useTranslation(['header']);

  const openSelect = () : void => {
    setOpenedSelect(true);
  };

  const closeSelect = (): void => {
    setOpenedSelect(false);
  };

  const redirect = (link: string) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform link click',
        amplitudeProps: {
          placement: 'header',
          link,
        },
      },
    });
  };

  useEffect(() => {
    if (window.location.pathname.includes('referral-info')) {
      setIsReferralPage(true);
    } else {
      setIsReferralPage(false);
    }
  }, [window.location.pathname]);

  return (
    <FormControl>
      <StyledDropDownBlockAbout
        onClick={openSelect}
        active={openedSelect}
        isDark={isReferralPage}
      />
      <StyledDropDownSelect
        variant="outlined"
        open={openedSelect}
        onClose={closeSelect}
        MenuProps={{
          anchorEl: document.getElementById('openAbout'),
        }}
      >
        <Grid container>
          <Grid item>
            <StyledNavlink
              to="/leaderboard"
              onClick={() => redirect('leaderboard')}
            >
              <StyledMenuItemIcon src={leaderboard} alt="social" />
              {t('Leaderboard')}
            </StyledNavlink>
            <Grid item>
              <StyledNavlink
                to="/referral-info"
                onClick={() => redirect('referral-info')}
              >
                <StyledMenuItemIcon src={referral} alt="social" />
                {t('Referral')}
              </StyledNavlink>
            </Grid>
            <Grid item>
              <StyledDefaultLink
                href="https://prosper.so/rules"
                onClick={() => redirect('https://prosper.so/rules')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledMenuItemIcon src={information} alt="social" />
                {t('common:Information')}
              </StyledDefaultLink>
            </Grid>
            <Grid item>
              <StyledNavlink
                to="/mining"
                onClick={() => redirect('mining')}
              >
                <StyledMenuItemIcon src={mining} alt="social" />
                {t('Mining')}
              </StyledNavlink>
            </Grid>
            <Grid item>
              <StyledNavlink
                to="/"
                onClick={() => redirect('')}
              >
                <StyledMenuItemIcon src={pools} alt="social" />
                {t('Pools')}
              </StyledNavlink>
            </Grid>
          </Grid>
        </Grid>
      </StyledDropDownSelect>
    </FormControl>

  );
};

export default DropDownAbout;
