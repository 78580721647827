import styled from 'styled-components';
import {
  Modal,
  Paper,
} from '@mui/material';

export const ModalContainer = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPaper = styled(Paper)<{bull: String}>`
  &.MuiPaper-root{
    width: 924px;
    height: 400px;
    border-radius: 16px;
    outline: none;
    background: #1A1A39;
    padding: 72px 88px;
    overflow: hidden;

    img {
      margin: ${(props) => (props.bull === 'true'
    ? '-150px 0 0 -88px'
    : '-55px 0 0 0')};
    }
  }
`;

export const Title = styled.h2`
    font-weight: bold;
    font-size: 29.375px;
    line-height: 34px;
    letter-spacing: 0.03em;
`;

export const Congrats = styled.div`
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    margin-bottom: 32px;
`;
