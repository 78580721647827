import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import {
  IIcons,
} from 'interfaces/common.d';
import { useTranslation } from 'react-i18next';
// currency
import BNB from 'static/images/currency_icons/BNB_icon_white.svg';
import PROS from 'static/images/currency_icons/PROS_icon_white.svg';
import USDC from 'static/images/currency_icons/USDC_icon_white.svg';
import BTC from 'static/images/currency_icons/BTC_icon_white.svg';
import REVV from 'static/images/currency_icons/REVV_icon.svg';
import OKT from 'static/images/currency_icons/OKT_icon_white.svg';
// styles
import {
  ChoiceSideButton,
  SideItemContent,
  ChoiceContainer,
  SideButtonHint,
} from 'styles/common/StyledMobileBetModalSideButton';
import {
  ChoiceItemTitle,
} from 'styles/modals/StyledMobileBetModal';

interface IProps {
    isBull: boolean;
    currency: string;
    selected: boolean;
    volume: number;
    ratio: string;
    potential: number;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

const currencies: IIcons = {
  PROS,
  USDC,
  BNB,
  BTC,
  REVV,
  OKT,
};

const MobileBetModalSideButton: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    isBull,
    currency,
    selected,
    volume,
    ratio,
    potential,
    onClick,
  } = props;

  const { t } = useTranslation();

  return (
    <ChoiceSideButton active={selected.toString()} bull={isBull.toString()} onClick={onClick}>
      <ChoiceContainer container direction="column" justifyContent="flex-end">
        <Grid item container direction="column">
          <ChoiceItemTitle item>
            {t('participantVolume')}
          </ChoiceItemTitle>
          <SideItemContent item>
            <Grid container alignItems="center">
              <img
                src={currencies[currency as keyof IIcons]}
                alt="icon"
                height="16"
              />
              { volume }
            </Grid>
          </SideItemContent>
        </Grid>
        <Grid item container direction="column">
          <ChoiceItemTitle item>
            {t('Ratio')}
          </ChoiceItemTitle>
          <SideItemContent item>
            х
            {' '}
            { ratio }
          </SideItemContent>
        </Grid>
        <Grid item container direction="column">
          <ChoiceItemTitle item>
            {t('Potential win')}
          </ChoiceItemTitle>
          <SideItemContent item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <img
                  src={currencies[currency as keyof IIcons]}
                  alt="icon"
                  height="16"
                />
                { potential }
              </Grid>
            </Grid>
          </SideItemContent>
        </Grid>
        <Grid item>
          <SideButtonHint active={selected.toString()} item>
            {
                isBull
                  ? t('BULL')
                  : t('BEAR')
              }
          </SideButtonHint>
        </Grid>
      </ChoiceContainer>
    </ChoiceSideButton>
  );
};

export default MobileBetModalSideButton;
