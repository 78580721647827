import React, {
  ReactElement, useState, useEffect, useCallback,
} from 'react';
import {
  Grid,
} from '@mui/material';
import PredictBlock from 'components/common/CurrencyPredictContainer';
import { useActualPoolState } from 'context/actualPoolState';
import { useGlobalState } from 'context/globalState';
import {
  IToken,
  IAsset,
} from 'interfaces/common.d';
// styles
import {
  StyledInformationContainer,
} from 'styles/desktop/StyledClosedPools';
import Information from './ActualPoolInformation';

const PredictInformation: React.FC = (): ReactElement => {
  const [tokens, setTokens] = useState<Array<IToken> | null>(null);
  const [assets, setAssets] = useState<Array<IAsset> | null>(null);
  const [infoInitialized, setInfoInitialized] = useState<boolean>(false);
  const { state } = useGlobalState();
  const { actualPoolState, actualPoolDispatch } = useActualPoolState();

  const getDisplayTokens = useCallback((): void => {
    const displayTokens = state.tokens;
    setTokens(displayTokens);
    actualPoolDispatch({ type: 'SET_TOKEN', payload: { ...actualPoolState, token: displayTokens[0] } });
    actualPoolDispatch({ type: 'SWITCH_TOKEN_ID', payload: { ...actualPoolState, tokenId: displayTokens[0].id } });
  }, [state.tokens]);

  const getDisplayAssets = useCallback(() => {
    const displayAssets: Array<IAsset> = state.feeds.map((feed) => ({
      name: feed.assetName,
      pair: feed.displayName,
    }));
    setAssets(displayAssets);
    actualPoolDispatch({ type: 'SET_ASSET', payload: { ...actualPoolState, asset: displayAssets[0] } });
    actualPoolDispatch({ type: 'SWITCH_FEED_ID', payload: { ...actualPoolState, feedId: state.feeds[0].id } });
  }, [state.feeds]);

  const changeToken = useCallback((token: IToken): void => {
    actualPoolDispatch({ type: 'SET_TOKEN', payload: { ...actualPoolState, token } });
    actualPoolDispatch({ type: 'SWITCH_TOKEN_ID', payload: { ...actualPoolState, tokenId: token.id } });
  }, [actualPoolDispatch, actualPoolState]);

  const changeAsset = useCallback((asset: IAsset): void => {
    actualPoolDispatch({ type: 'SET_ASSET', payload: { ...actualPoolState, asset } });
    actualPoolDispatch({
      type: 'SWITCH_FEED_ID',
      payload: {
        ...actualPoolState,
        feedId: state.feeds.find((feed) => feed.displayName === asset.pair)?.id || 0,
      },
    });
  }, [actualPoolDispatch, actualPoolState]);

  useEffect(() => {
    setInfoInitialized(false);
  }, [state.currentNetworkId]);

  // if tokens not set and in state there is some tokens
  useEffect(() => {
    if (state.tokens.length) {
      getDisplayTokens();
    }
  }, [state.tokens]);

  // if assets not set and in state there is some feeds
  useEffect(() => {
    if (state.feeds.length) {
      getDisplayAssets();
    }
  }, [state.feeds]);

  // set initial token and asset
  useEffect(() => {
    if (!infoInitialized && tokens && assets) {
      changeToken(tokens[0]);
      changeAsset(assets[0]);
      actualPoolDispatch({ type: 'SET_TOKEN', payload: { ...actualPoolState, token: tokens[0] } });
      actualPoolDispatch({ type: 'SWITCH_TOKEN_ID', payload: { ...actualPoolState, tokenId: tokens[0].id } });
      actualPoolDispatch({ type: 'SWITCH_FEED_ID', payload: { ...actualPoolState, feedId: state.feeds[0].id } });
      setInfoInitialized(true);
    }
  }, [tokens, assets, infoInitialized, actualPoolState]);

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <PredictBlock
          tokens={tokens}
          assets={assets}
          changeToken={changeToken}
          changeAsset={changeAsset}
        />
      </Grid>
      <StyledInformationContainer item>
        <Information />
      </StyledInformationContainer>
    </Grid>
  );
};

export default PredictInformation;
