import styled from 'styled-components';
import {
  StyledBetContainer,
  PredictButtonContainer,
} from 'styles/desktop/StyledClosedPools';
import { Grid, Tooltip } from '@mui/material';
import { isMobile } from 'react-device-detect';

export const PageContainer = styled.div`
  width: 1400px;
`;

export const MiningStageContainer = styled(Grid)`
  &.MuiGrid-root{
    margin-top:  ${isMobile ? 24 : 48}px;
  }
`;

export const MiningDataContainer = styled.div`
  background: rgba(206, 181, 181, 0.1);
  border-radius: 10px;
  width: ${isMobile ? 'calc(100vw - 34px)' : '366px'};
  padding: 16px 24px;
`;

export const HistoricalContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HistoricalContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'space-around'};
  font-size: ${isMobile ? 11 : 16}px;
  line-height: ${isMobile ? 17 : 24}px;
  text-align: center;
  letter-spacing: 0.01em;
  min-height: 170px;
  max-width: ${isMobile ? 134 : 189}px;
`;

export const HistoricalAmount = styled.div`
  font-size: ${isMobile ? 17 : 24}px;
  line-height: ${isMobile ? 20 : 28}px;
  letter-spacing: 0.02em;
  margin: 14px 0;
`;

export const MiningDataPair = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #FD0;
  margin-bottom: 11px;
  letter-spacing: 0.02em;
`;

export const MiningDataTitle = styled.div`
  font-size: ${isMobile ? 12 : 16}px;
  line-height: ${isMobile ? 14 : 18}px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
`;

export const MiningDataAmount = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${isMobile ? 18 : 24}px;
  line-height: ${isMobile ? 27 : 28}px;
  margin-top: ${!isMobile && '8px'};
  letter-spacing: 0.02em;
`;

export const MiningDataDifferencePercent = styled.div`
  margin-left: 16px;
  color: #5EFF5A;
`;

export const MiningStatisticsColoredContainer = styled.div`
  margin-top: ${isMobile ? 40 : 64}px;
  background: linear-gradient(90deg, #7E38F9 38.83%, #00BCF7 80.93%, #00BCF7 98.99%);
  border-radius: 24px;
  padding: 1px;
`;

export const MiningStatisticsContainer = styled.div`
  padding: 32px;
  margin: 1px;
  border-radius: 24px;
  background: rgba(0, 9, 75, 1);
`;

export const MiningStatisticsTitle = styled.div`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  margin-bottom: 32px;
  margin-left: ${isMobile && '24px'};
`;

export const StyledMiningBetContainer = styled(StyledBetContainer)`
  margin-top: 56px;
  margin-bottom: 112px;
  
  ${PredictButtonContainer} {
    position: relative;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  margin-left: 20px;
`;

export const TooltipInfo = styled.div`
  font-size: 16px;
`;
