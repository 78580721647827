import styled from 'styled-components';
import {
  Tabs,
  Tab,
} from '@mui/material';

export const TabsContainer = styled(Tabs)`
  background: rgba(206, 181, 181, 0.1);
  border-radius: 24px;
  height: 55px;


  .MuiTabs-scroller {
    z-index: 1;
  }
  
  .MuiTabs-indicator {
    background: white;
    height: 100%;
    border-radius: 24px;
    z-index: -1;
  }

  .MuiTab-textColorPrimary{
    color: white;
  }
  .Mui-selected {
    font-weight: bold;
    font-size: 19.8507px;
    line-height: 23px;
    letter-spacing: 0.01em;

    &.MuiButtonBase-root {
      color: #5900F7;
    }
  }
`;

export const StyledTab = styled(Tab)`
  font-weight: normal;
  font-size: 19.8507px;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
`;
