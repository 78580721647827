import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import {
  ReferalArrow,
  ReferalBubble,
  ReferalHowItWorksContainer,
  ReferalHowItWorksText,
  ReferalHowItWorksTitle,
  ReferalLastHowItWorksText,
  ReferalMiddleHowItWorksText,
} from 'styles/desktop/StyledReferal';
import firstBubble from 'static/images/first_bubble.svg';
import secondBubble from 'static/images/second_bubble.svg';
import thirdBubble from 'static/images/third_bubble.svg';
import arrow from 'static/images/referal_arrow.svg';
import { useTranslation } from 'react-i18next';

const ReferralHowItWorks: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['referral']);
  return (
    <>
      <ReferalHowItWorksTitle>
        {t('How the referral program works')}
      </ReferalHowItWorksTitle>
      <ReferalHowItWorksContainer
        container
        justifyContent="space-around"
        alignItems="center"
        position="relative"
      >
        <Grid container justifyContent="space-around">
          <Grid item>
            <ReferalBubble src={firstBubble} />
          </Grid>
          <Grid item>
            <ReferalArrow src={arrow} />
          </Grid>

          <Grid item>
            <ReferalBubble src={secondBubble} />
          </Grid>
          <Grid item>
            <ReferalArrow src={arrow} />
          </Grid>

          <Grid item>
            <ReferalBubble src={thirdBubble} />
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between">
          <ReferalHowItWorksText>
            {t('After logging in to the wallet, copy the link')}
          </ReferalHowItWorksText>

          <ReferalMiddleHowItWorksText>
            {t('Share the link on your page')}
          </ReferalMiddleHowItWorksText>

          <ReferalLastHowItWorksText>
            {t('Receive payments from incoming users')}
          </ReferalLastHowItWorksText>
        </Grid>

      </ReferalHowItWorksContainer>
    </>
  );
};

export default ReferralHowItWorks;
