import React, { ReactElement } from 'react';
// styles
import {
  TabsContainer,
  StyledTab,
} from 'styles/common/StyledPillsSwitcher';

interface IProps {
    tabs: Array<string>;
    selectedTab: number;
    changeTab: (tab: number) => void;
}

const PillsSwithcher: React.FC<IProps> = (props: IProps):ReactElement => {
  const {
    tabs,
    selectedTab,
    changeTab,
  } = props;

  const changePill = (event: React.ChangeEvent<{}>, newPill: number) => {
    changeTab(newPill);
  };

  return (
    <TabsContainer variant="fullWidth" value={selectedTab} onChange={changePill} centered>
      {
        tabs.map((tab) => (
          <StyledTab label={tab} style={{ width: '160px' }} />
        ))
      }
    </TabsContainer>
  );
};

export default PillsSwithcher;
