import React, { ReactElement } from 'react';

interface IProps {
    color?: string;
}

const SearchIcon: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    color,
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4262 16.5476C13.0972 17.4637 11.4862 18 9.74994
                18C5.19359 18 1.49994 14.3063 1.49994 9.75C1.49994 5.19365 5.19359 1.5 9.74994 1.5C14.3063 1.5 17.9999
                5.19365 17.9999 9.75C17.9999 11.4862 17.4636 13.0972 16.5475 14.4263L22.0606 19.9393L19.9393 22.0607L14.4262
                16.5476ZM9.74997 15C12.6495 15 15 12.6495 15 9.75003C15 6.85054 12.6495 4.50003 9.74997 4.50003C6.85047
                4.50003 4.49997 6.85054 4.49997 9.75003C4.49997 12.6495 6.85047 15 9.74997 15Z"
        fill={color}
      />
    </svg>
  );
};

SearchIcon.defaultProps = {
  color: '#0C0C21',
};

export default SearchIcon;
