import React, {
  ReactElement, useState, useCallback, useEffect,
} from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { ethers } from 'ethers';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { useCurrentBlockContext } from 'context/currentBlockContext';
import { useTranslation } from 'react-i18next';
import {
  Slide,
  Grid,
} from '@mui/material';
import {
  IPool,
} from 'interfaces/state.d';
import {
  IPersonalQueryParameters,
} from 'interfaces/common.d';
import { ReactComponent as ReturnIcon } from 'static/images/common_icons/return_icon.svg';
import CountDown from 'components/common/CountDown';
import SideButton from 'components/common/MobileBetModalSideButton';
import Button from 'components/common/CustomButton';
import PoolStatus from 'components/common/PoolStatus';
// styles
import {
  ModalContainer,
  StyledPaper,
  ChoiseBlock,
  CloseButton,
  Title,
  ChoiceItemTitle,
  ChoiceItemContent,
  ChoiceContentBlock,
  ChoiceAmountBlock,
  ChoiceButtonsBlock,
  SubmitTitle,
  ChoiceTextField,
  SubmitBlock,
  SubmitContent,
  SubmitButtonContainer,
  ChosenSideBlock,
} from 'styles/modals/StyledMobileBetModal';
import BetModalWarningTitle from '../../common/BetModalWarningTitle';

type UserSideType = null | boolean;

const getCoef = (volume: number, quote: number): string => {
  if (volume) {
    if (quote === 0) {
      return (2).toFixed(2);
    }
    return (volume / quote).toFixed(2);
  }
  return (1).toFixed(2);
};

const BetModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [userAmount, setUserAmount] = useState<string>('0');
  const [chosenSide, setChosenSide] = useState<number | null>(null);
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [betInProgress, setBetInProgress] = useState<boolean>(false);
  const [signedUpHub, setSignedUpHub] = useState<boolean>(false);
  const [userSide, setUserSide] = useState<UserSideType>(null);

  const { modalsState, modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t } = useTranslation('modals');

  const getPersonalPool = useCallback(async (actualPool: IPool,
    baseUrl: string): Promise<void> => {
    const ids: Array<number> = [actualPool.id];
    const queryPersonalParams: IPersonalQueryParameters = {
      ids,
      user: state.account,
      networkId: state.currentNetworkId,
    };

    const poolStatuses = await axios.post(`${baseUrl}/users/${queryPersonalParams.user}/user-volume`, {
      ids: queryPersonalParams.ids,
      networkId: queryPersonalParams.networkId,
    });

    if (poolStatuses.data) {
      setUserSide(poolStatuses.data[0].userVolume.isBull);
    }
  }, [state.account, state.currentNetworkId]);

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);
    const intervalId = setInterval(() => {
      getCurrentBlock().then(setCurrentBlock);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setOpen(modalsState.openedBetModal);
    if (modalsState.openedBetModal) {
      getPersonalPool(modalsState.betModalData.actualPool, state.baseUrl);
    }
  }, [modalsState.openedBetModal, state.baseUrl, modalsState.betModalData.actualPool]);

  // initialize token contract every time modal is opened
  useEffect(() => {
    if (modalsState.openedBetModal) {
      dispatch({
        type: 'INIT_TOKEN_CONTRACT',
        payload: {
          ...state,
          tokenAddress: modalsState.betModalData.token.value,
        },
      });
    }
  }, [modalsState.openedBetModal]);

  useEffect(() => {
    if (typeof modalsState.betModalData?.chosenSide === 'number') {
      setChosenSide(modalsState.betModalData.chosenSide);
    }
  }, [modalsState.betModalData]);

  useEffect(() => {
    if (state.hubConnection && !signedUpHub && state.account) {
      state.hubConnection?.on('bet', (msg) => {
        if (msg.sender === state.account) {
          setBetInProgress(false);
          modalDispatch({ type: 'CLOSE_LOADING_MODAL' });
          modalDispatch({
            type: 'OPEN_YOU_ARE_IN_MODAL',
            payload: { youAreInModalData: { isBull: msg.isBull } },
          });
        }
      });
      setSignedUpHub(true);
    }
  }, [state.hubConnection, signedUpHub, state.account,
    betInProgress, modalDispatch]);

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_BET_MODAL' });
  };

  const changeSide = (sideIndex: number): void => {
    setChosenSide(sideIndex);
  };

  const changeUserAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let amount: string = event.target.value;
    if (+amount < 0) {
      amount = '0';
    }
    setUserAmount(amount);
  };

  const sideUserPosition = (sideNeedToBe: number): number => {
    if (typeof chosenSide === 'number') {
      return chosenSide === sideNeedToBe ? +userAmount : 0;
    }
    return 0;
  };

  const sideVolume = (side: number): number => (side
    ? parseFloat(modalsState.betModalData.bearQuote) + sideUserPosition(1)
    : parseFloat(modalsState.betModalData.bullQuote) + sideUserPosition(0));

  const sideRatio = (side: number): string => (side
    ? getCoef(sideVolume(0) + sideVolume(1), sideVolume(1))
    : getCoef(sideVolume(0) + sideVolume(1), sideVolume(0)));

  const sidePotential = (side: number): number => (side
    ? sideUserPosition(1) * +sideRatio(1)
    : sideUserPosition(0) * +sideRatio(0));

  const confirmBet = async () => {
    setBetInProgress(true);
    modalDispatch({ type: 'OPEN_LOADING_MODAL' });
    modalDispatch({ type: 'CLOSE_BET_MODAL', payload: { betModalData: {} } });
    try {
      const amount = ethers.utils.parseEther(userAmount);
      const spender = state.address;
      const isToken = modalsState.betModalData.token.value !== '0x0000000000000000000000000000000000000000';
      const overrides = {
        value: isToken ? undefined : amount,
        gasPrice: ethers.utils.parseUnits(`${state.currentNetworkId === 97 ? '15' : '7.5'}`, 'gwei'),
      };
      if (isToken) {
        const approveTx = await state.tokenContract?.approve(
          spender,
          amount,
          overrides,
        );
        await approveTx.wait();
      }
      if (state.contract) {
        await state.contract?.bet(
          modalsState.betModalData.actualPool.id,
          modalsState.betModalData.token.value,
          amount,
          !chosenSide,
          overrides,
        );

        await dispatch({
          type: 'LOG_AMPLITUDE_ACTION',
          payload: {
            actionName: 'platform make bet',
            amplitudeProps: {
              token: modalsState.betModalData.asset.pair,
              asset: modalsState.betModalData.token.displayName,
              current_timeframe: 1,
              side: getChosenSide(),
              amount: userAmount,
              ratio: sideRatio(Number(chosenSide)),
              poolId: modalsState.betModalData.actualPool.id,
            },
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'platform make bet',
          amplitudeProps: {
            error: e,
          },
        },
      });
      modalDispatch({ type: 'CLOSE_LOADING_MODAL' });
      setBetInProgress(false);
      // this.$modal.show('wrong-modal');
    }
  };

  const getChosenSide = (): string => (chosenSide ? 'BEAR' : 'BULL');

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Slide direction="up" in={open}>
        <StyledPaper>
          <ChoiseBlock>
            <CloseButton onClick={handleClose}>
              <ReturnIcon />
              {
                ` ${t('Return')}`
              }
            </CloseButton>
            <Title>{t('My position')}</Title>
            <ChoiceContentBlock container justifyContent="space-between">
              <Grid item xs={5}>
                <ChoiceItemTitle>
                  {t('Prediction pair')}
                </ChoiceItemTitle>
                <ChoiceItemContent>{ modalsState.betModalData.asset.pair }</ChoiceItemContent>
              </Grid>
              <Grid item xs={5}>
                <ChoiceItemTitle>{t('common:Timeframe')}</ChoiceItemTitle>
                <ChoiceItemContent>
                  {`1 ${t('common:h')}`}
                </ChoiceItemContent>
              </Grid>
            </ChoiceContentBlock>
            <ChoiceContentBlock container justifyContent="space-between">
              <Grid item xs={5}>
                <ChoiceItemTitle>
                  {t('Prediction in')}
                </ChoiceItemTitle>
                <ChoiceItemContent>
                  { modalsState.betModalData.token.displayName }
                </ChoiceItemContent>
              </Grid>
              <Grid item xs={5}>
                <ChoiceItemTitle>
                  {t('actual_pool:conditionalPrice')}
                </ChoiceItemTitle>
                <ChoiceItemContent>
                  $
                  {' '}
                  { modalsState.betModalData.conditionPrice }
                </ChoiceItemContent>
              </Grid>
            </ChoiceContentBlock>
            <ChoiceAmountBlock container justifyContent="space-between">
              <Grid item xs={5}>
                <ChoiceItemTitle>
                  {t('My position')}
                  { ` (${modalsState.betModalData.token.displayName})` }
                </ChoiceItemTitle>
                <ChoiceItemContent>
                  <ChoiceTextField
                    value={userAmount}
                    onChange={changeUserAmount}
                    variant="outlined"
                    placeholder={t('Enter the amount')}
                    type="number"
                    disabled={state.balance === 0}
                  />
                  <BetModalWarningTitle
                    userAmount={userAmount}
                    accountBalance={state.balance}
                    isMobile={isMobile}
                  />
                </ChoiceItemContent>
              </Grid>
              <Grid item xs={5}>
                <ChoiceItemTitle>
                  <PoolStatus
                    pool={modalsState.betModalData.actualPool}
                    currentBlock={currentBlock}
                  />
                </ChoiceItemTitle>
                <ChoiceItemContent>
                  <CountDown deadline={modalsState.betModalData.deadline} />
                </ChoiceItemContent>
              </Grid>
            </ChoiceAmountBlock>
            <ChoiceButtonsBlock container justifyContent="space-between">
              <SideButton
                isBull
                currency={modalsState.betModalData.token.displayName}
                selected={chosenSide === 0}
                volume={sideVolume(0)}
                ratio={sideRatio(0)}
                potential={sidePotential(0)}
                onClick={() => changeSide(0)}
              />
              <SideButton
                isBull={false}
                currency={modalsState.betModalData.token.displayName}
                selected={chosenSide === 1}
                volume={sideVolume(1)}
                ratio={sideRatio(1)}
                potential={sidePotential(1)}
                onClick={() => changeSide(1)}
              />
            </ChoiceButtonsBlock>
          </ChoiseBlock>
          <SubmitBlock container direction="column">
            <Grid item container>
              <Grid item xs={4} container direction="column">
                <SubmitTitle item>{t('Pair')}</SubmitTitle>
                <SubmitContent item>{ modalsState.betModalData.asset.pair }</SubmitContent>
              </Grid>
              <Grid item xs={4}>
                <SubmitTitle item>{t('common:Timeframe')}</SubmitTitle>
                <SubmitContent item>
                  {`1 ${t('common:h')}`}
                </SubmitContent>
              </Grid>
              <Grid item xs={4}>
                <SubmitTitle item>{t('common:Side')}</SubmitTitle>
                {
                  typeof chosenSide === 'number'
                    ? (
                      <ChosenSideBlock side={chosenSide?.toString()} item>
                        {t(`common:${getChosenSide()}`)}
                      </ChosenSideBlock>
                    )
                    : (
                      <SubmitContent item>
                        {t('Choose a side bull or bear?')}
                      </SubmitContent>
                    )
                }
              </Grid>
              <Grid item xs={4}>
                <SubmitTitle item>{t('common:Condition')}</SubmitTitle>
                <SubmitContent item>
                  { modalsState.betModalData.conditionPrice }
                  {' USD'}
                </SubmitContent>
              </Grid>
              <Grid item xs={4}>
                <SubmitTitle item>{t('Your position')}</SubmitTitle>
                <SubmitContent item>
                  { userAmount }
                  {` ${modalsState.betModalData.token.displayName}`}
                </SubmitContent>
              </Grid>
            </Grid>
            <SubmitButtonContainer item>
              <Button
                disabledButton={typeof chosenSide !== 'number'
                || !!chosenSide === userSide || +userAmount < 0.005 || +userAmount > state.balance}
                onClick={confirmBet}
              >
                {t('Confirm')}
              </Button>
            </SubmitButtonContainer>
          </SubmitBlock>
        </StyledPaper>
      </Slide>
    </ModalContainer>
  );
};

export default BetModal;
