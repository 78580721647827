import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  TableBody,
  TableRow,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import {
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  StyledTable,
  PersonIcon,
  StyledUserInfo,
  StyledRanked,
  StyledId,
  StyledWinningsGrid,
} from 'styles/desktop/StyledLeaderBoard';
import iconPurple from '../../../static/images/leaderboard/iconPurple.svg';
import { useModalState } from '../../../context/modalState';
import { useLeaderboardState } from '../../../context/leaderboardContext';
import { useGlobalState } from '../../../context/globalState';
import { ILeaderboardUser } from '../../../interfaces/leaderboard.d';
import { getCurrencyName } from '../../../networksHelper';

interface ITableProps{
  foundUser : ILeaderboardUser | null | undefined,
}
const LeaderBoardTable: React.FC<ITableProps> = (props : ITableProps): ReactElement => {
  const { i18n, t } = useTranslation(['leaderboard']);
  const { modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();
  const { foundUser } = props;

  const openLeaderboardModal = (sender : string | undefined) => {
    modalDispatch({
      type: 'OPEN_LEADERBOARD_MODAL',
      payload: {
        sender,
      },
    });
  };

  const isCurrentUser = (row : ILeaderboardUser) => row.sender === state.account.toLowerCase();

  const getNetWinnings = () => {
    if (currentUser) {
      return currentUser.netWinnings;
    }
    return 0;
  };

  const currentUser = (
    leaderboardState.users?.filter((user) => user.sender === state.account.toLowerCase())[0]
  );

  const currentUserId = (
    leaderboardState.users?.findIndex((user) => user.sender === currentUser?.sender)
  );

  return (
    <>
      <StyledTable sx={{ minWidth: 1399 }} size="small" aria-label="a dense table">
        <StyledTableHead mobile={isMobile}>
          <TableRow>
            <StyledTableCell align="left">{t('User')}</StyledTableCell>
            <StyledTableCell align="center">
              {t('Net winnings')}
              {` (${getCurrencyName(state.currentNetworkId)})`}
            </StyledTableCell>
            <StyledTableCell align="center">{t('Win rate')}</StyledTableCell>
            <StyledTableCell align="center">{t('Rounds won')}</StyledTableCell>
            <StyledTableCell align="center">{t('Rounds played')}</StyledTableCell>
          </TableRow>
        </StyledTableHead>

        <TableBody>
          {
            currentUser && typeof currentUserId === 'number' && currentUserId > 2 && (
              <LeaderBoardCurrentUserRow
                currentUser={currentUser}
                getNetWinnings={getNetWinnings}
                openLeaderboardModal={openLeaderboardModal}
              />
            )
          }
          {foundUser ? (
            <LeaderBoardCurrentUserRow
              currentUser={foundUser}
              openLeaderboardModal={openLeaderboardModal}
              getNetWinnings={getNetWinnings}
            />
          ) : leaderboardState.users?.slice(leaderboardState.currentPage * 10,
            leaderboardState.currentPage * 10 + 10)
            .map((row, index) => (
              <StyledTableRow
                key={row.sender}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                isCurrentUser={isCurrentUser(row)}
              >
                <StyledTableCell scope="row">
                  <Grid container alignItems="center">
                    <StyledUserInfo>
                      <PersonIcon src={iconPurple} mobile={isMobile} alt="icon" onClick={() => openLeaderboardModal(row.sender)} />
                      <StyledRanked
                        mobile={isMobile}
                        isCurrentUser={isCurrentUser(row)}
                      >
                        {leaderboardState.currentPage * 10 + index + 1}
                        {' '}
                        {t('place')}
                      </StyledRanked>
                    </StyledUserInfo>
                    <StyledId
                      onClick={() => openLeaderboardModal(row.sender)}
                      isCurrentUser={row.sender === state.account.toLowerCase()}
                    >
                      {row.sender.slice(0, 17)}
                      ...
                      {isCurrentUser(row) ? '(you)' : ''}
                    </StyledId>
                  </Grid>

                </StyledTableCell>
                <StyledTableCell align="center">
                  <Grid container direction="column">
                    <StyledWinningsGrid
                      item
                      netWinnings={row.netWinnings}
                    >
                      {row.netWinnings > 0 ? `+${row.netWinnings.toFixed(6)}` : `${row.netWinnings.toFixed(6)}`}
                    </StyledWinningsGrid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell isCurrentUser={isCurrentUser(row)} align="center">{`${row.winrate}%`}</StyledTableCell>
                <StyledTableCell isCurrentUser={isCurrentUser(row)} align="center">{row.roundsWon}</StyledTableCell>
                <StyledTableCell isCurrentUser={isCurrentUser(row)} align="center">{row.roundsPlayed}</StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </StyledTable>
    </>

  );
};

interface IProps{
  currentUser : ILeaderboardUser | undefined,
  getNetWinnings : ()=>number,
  openLeaderboardModal: (sender : string | undefined)=>void,
}

export const LeaderBoardCurrentUserRow : React.FC<IProps> = (props : IProps) : ReactElement => {
  const { currentUser, getNetWinnings, openLeaderboardModal } = props;
  const { state, dispatch } = useGlobalState();
  const isCurrentUser = currentUser?.sender === state.account.toLowerCase();
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();

  const currentUserId = (
    leaderboardState.users?.findIndex((user) => user.sender === currentUser?.sender)
  );

  return (
    <StyledTableRow
      key={currentUser?.sender}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      isCurrentUser={isCurrentUser}
    >
      <StyledTableCell scope="row">
        <Grid container alignItems="center">
          <StyledUserInfo>
            <PersonIcon src={iconPurple} mobile={isMobile} alt="icon" onClick={() => openLeaderboardModal(currentUser?.sender)} />
            <StyledRanked
              mobile={isMobile}
              isCurrentUser={isCurrentUser}
            >
              {typeof currentUserId === 'number' && currentUserId > -1 ? currentUserId + 1 : 0}
              {' '}
              place
            </StyledRanked>
          </StyledUserInfo>
          <StyledId
            onClick={() => openLeaderboardModal(currentUser?.sender)}
            isCurrentUser={currentUser?.sender === state.account.toLowerCase()}
          >
            {currentUser?.sender.slice(0, 17)}
            {' '}
            {isCurrentUser ? '(you)' : ''}
          </StyledId>
        </Grid>

      </StyledTableCell>
      <StyledTableCell align="center">
        <Grid container direction="column">
          <StyledWinningsGrid
            item
            netWinnings={Number(currentUser?.netWinnings)}
          >
            {Number(currentUser?.netWinnings) > 0 ? `+${currentUser?.netWinnings.toFixed(6)}` : `${currentUser?.netWinnings.toFixed(6)}`}
          </StyledWinningsGrid>
        </Grid>
      </StyledTableCell>
      <StyledTableCell isCurrentUser={isCurrentUser} align="center">{`${currentUser?.winrate}%`}</StyledTableCell>
      <StyledTableCell isCurrentUser={isCurrentUser} align="center">{currentUser?.roundsWon}</StyledTableCell>
      <StyledTableCell isCurrentUser={isCurrentUser} align="center">{currentUser?.roundsPlayed}</StyledTableCell>
    </StyledTableRow>
  );
};
export default LeaderBoardTable;
