import styled from 'styled-components';

export const ReferalManIcon = styled.img`
  position: absolute;
  height: 288px;
  right: calc(50% + 250px);
`;

export const ReferalWomenIcon = styled.img`
  position: absolute;
  height: 288.33px;
  left: calc(50% + 511px);
`;
