import React, {
  ReactElement, useCallback, useState, useEffect,
} from 'react';
import {
  Grid,
} from '@mui/material';
import Title from 'components/common/BoldTitle';
import DarkContainer from 'components/common/DarkContainer';
import Menu from 'components/mobile/common/MenuContainer';
import CurrencySwitch from 'components/common/CurrencyPredictContainer';
import { useGlobalState } from 'context/globalState';
import { useActualPoolState } from 'context/actualPoolState';
import { useTranslation } from 'react-i18next';
import {
  IToken,
  IAsset,
} from 'interfaces/common.d';
// styles
import {
  ActualPoolDataContainer,
} from 'styles/mobile/StyledHeader';
import PoolStatus from './ActualPoolStatus';
import CurrentPrice from './ActualPoolCurrentPrice';
import ChartSwitcher from './ChartSwitcher';

const HeaderContainer: React.FC = (): ReactElement => {
  const [tokens, setTokens] = useState<Array<IToken> | null>(null);
  const [assets, setAssets] = useState<Array<IAsset> | null>(null);
  const [infoInitialized, setInfoInitialized] = useState<boolean>(false);
  const { state } = useGlobalState();
  const { actualPoolState, actualPoolDispatch } = useActualPoolState();
  const { t } = useTranslation(['common']);

  const getDisplayTokens = useCallback((): void => {
    const displayTokens = state.tokens;
    setTokens(displayTokens);
    actualPoolDispatch({ type: 'SET_TOKEN', payload: { ...actualPoolState, token: displayTokens[0] } });
    actualPoolDispatch({ type: 'SWITCH_TOKEN_ID', payload: { ...actualPoolState, tokenId: displayTokens[0].id } });
  }, [state.tokens]);

  const getDisplayAssets = useCallback(() => {
    const displayAssets: Array<IAsset> = state.feeds.map((feed) => ({
      name: feed.assetName,
      pair: feed.displayName,
    }));
    setAssets(displayAssets);
    actualPoolDispatch({ type: 'SET_ASSET', payload: { ...actualPoolState, asset: displayAssets[0] } });
    actualPoolDispatch({ type: 'SWITCH_FEED_ID', payload: { ...actualPoolState, feedId: state.feeds[0].id } });
  }, [state.feeds]);

  const changeToken = useCallback((token: IToken): void => {
    actualPoolDispatch({ type: 'SET_TOKEN', payload: { ...actualPoolState, token } });
    actualPoolDispatch({ type: 'SWITCH_TOKEN_ID', payload: { ...actualPoolState, tokenId: token.id } });
  }, [actualPoolDispatch, actualPoolState]);

  const changeAsset = useCallback((asset: IAsset): void => {
    actualPoolDispatch({ type: 'SET_ASSET', payload: { ...actualPoolState, asset } });
    actualPoolDispatch({
      type: 'SWITCH_FEED_ID',
      payload: {
        ...actualPoolState,
        feedId: state.feeds.find((feed) => feed.displayName === asset.pair)?.id || 0,
      },
    });
  }, [actualPoolDispatch, actualPoolState]);

  useEffect(() => {
    setInfoInitialized(false);
  }, [state.currentNetworkId]);

  // if tokens not set and in state there is some tokens
  useEffect(() => {
    if (state.tokens.length) {
      getDisplayTokens();
    }
  }, [state.tokens]);

  // if assets not set and in state there is some feeds
  useEffect(() => {
    if (state.feeds.length) {
      getDisplayAssets();
    }
  }, [state.feeds]);

  // set initial token and asset
  useEffect(() => {
    if (!infoInitialized && tokens && assets) {
      changeToken(tokens[0]);
      changeAsset(assets[0]);
      actualPoolDispatch({ type: 'SET_TOKEN', payload: { ...actualPoolState, token: tokens[0] } });
      actualPoolDispatch({ type: 'SWITCH_TOKEN_ID', payload: { ...actualPoolState, tokenId: tokens[0].id } });
      actualPoolDispatch({ type: 'SWITCH_FEED_ID', payload: { ...actualPoolState, feedId: state.feeds[0].id } });
      setInfoInitialized(true);
    }
  }, [tokens, assets, infoInitialized, actualPoolState]);

  return (
    <DarkContainer>
      <Menu />
      <Title placement="mobile">
        {t('Who are you today?')}
      </Title>
      <CurrencySwitch
        tokens={tokens}
        assets={assets}
        changeToken={changeToken}
        changeAsset={changeAsset}
      />
      <ActualPoolDataContainer container>
        <Grid item xs={3}>
          <ChartSwitcher />
        </Grid>
        <Grid item xs={9}>
          <PoolStatus />
          {/* <CurrentPrice /> */}
        </Grid>
      </ActualPoolDataContainer>
    </DarkContainer>
  );
};

export default HeaderContainer;
