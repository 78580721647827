import styled from 'styled-components';
import {
  Grid,
  TextField,
} from '@mui/material';

export const SearchTitle = styled(Grid)`
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 2px;
`;

export const StyledTextField = styled(TextField)`
    width: 300px;

    .MuiOutlinedInput-input {
        color: #0C0C21;
    }
`;
