import styled from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  Grid,
  Skeleton,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ReferalAdvantagesBackground from 'static/images/ReferalAdvantagesBackground.svg';
import ReferalAdditionalBackground from 'static/images/ReferalAdditionalBackground.svg';

export const ReferalPage = styled(Grid)`
  &.MuiGrid-container{
    background: #000523;    
    margin-top: -100px;
    padding-top: 100px;
    margin-bottom: -200px;
    padding-bottom: 200px;
    max-width: 100%;
  }
`;

export const ReferalPageContainer = styled(Grid)`
  &.MuiGrid-container{
  } 
`;

export const ReferalMainContainer = styled(Grid)`
  background: linear-gradient(rgba(26, 26, 57, 1) 31%, #2E2E4A 31%) no-repeat;
  padding-top: 146px;
`;

export const ReferalProsperProgram = styled.div`
  background: linear-gradient(180deg, rgba(126, 24, 246, 0) 84.79%, rgba(126, 24, 246, 0.4) 100%), #000523;
  position: relative;
  padding-top: 100px;
  width: 100%;
`;
export const ReferalVideoContainer = styled.div`
  padding-top: 64px;
  display: block;
  margin:0 25% 0 25%;
  max-width: 1440px;
`;

export const ReferalVideoText = styled(Grid)`
  text-align: center;
  font-size: 13px;
;`;
export const ReferalSvgContainer = styled(Grid)`
  padding-top: 100px;
  margin: 0 auto;
  top: -290px;
  left: -125px;
  width: 1440px;
  position: relative;
`;

export const ReferalHowItWorksTitle = styled.h2`
  padding-top: 96px;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
`;

export const ReferalArrow = styled.img`

`;

export const ReferalBubble = styled.img`
  background: radial-gradient(122% 26% at 70.4% 31.2%,#3d448a 0%,rgba(0,2,16,-57) 37%),#09005E;
  box-shadow: 0px 4.92152px 98.4304px #2300f8, inset 0px 2.46076px 12.3038px rgb(255 255 255 / 0%), inset 12.3038px 19.6861px 24.6076px rgb(130 5 255 / 0%);
`;
export const ReferalHowItWorksText = styled(Grid)`
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 2px;
  color: #FFDD00;
  width: 230px;
`;

export const ReferalMiddleHowItWorksText = styled(ReferalHowItWorksText)`
`;

export const ReferalLastHowItWorksText = styled(ReferalHowItWorksText)`

`;

export const ReferalHowItWorksContainer = styled(Grid)`
  padding-top: 40px;
  max-width: 1320px;
`;
export const ReferalOutAdvantagesPage = styled.div`
  background: url(${ReferalAdvantagesBackground}) no-repeat;
  background-position: center;
  width: 100%;
`;
export const ReferalOurAdvantagesContainer = styled(Grid)`
  padding-top: 100px;
  margin: 0 auto;
  max-width: 1440px;
`;

export const ReferalOurAdvantagesText = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
`;

export const ReferalOurAdvantagesCard = styled(Grid)`
  padding: 35px;
  margin: 30px;
  width: 210px;
  height: 210px;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  outline: #7121f1 1px solid;
  background: rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ReferalOurAdvantagesCardText = styled.p`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
`;

export const ReferalOurAdvantagesCardIcon = styled.img`
  width: 74px;
  height: 74px;
`;

export const ReferalOurAdvantagesButton = styled.button`
  margin-top: 20px;
  background: linear-gradient(180deg, #FFC700 0%, #FFDD00 100%);
  border-radius: 16px;
  border: 1px;
  width: 338px;
  height: 63px;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-style
  : normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #1A1A39;
  &:hover{
    cursor: pointer;
  }
`;

export const ReferalAdditionalButton = styled(ReferalOurAdvantagesButton)`
  margin: 0 auto;
`;

export const ReferalLinkCopiedButton = styled.button`
  background: #5900F7;
  border-radius: 16px;
  width: 338px;
  height: 63px;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: white;
  &:hover{
    cursor: pointer;
  }
  border: 1px;
`;

export const ReferalProfitContainer = styled(Grid)`
  padding-top: 170px;
  width: 1440px;
`;

export const ReferalProfitTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
`;

export const ReferalProfitSvgContentContainer = styled(Grid)`
  width: 200px;
  padding-top: 100px;
  align-items: center
`;
export const ReferralProfitContainer = styled(Grid)`
`;

export const ProfitImgPlanets = styled.img`
  max-width: 500px;
  margin-right: 30px;
`;

export const ProfitBigRectangle = styled.div`
  width: 430px;
  height: 160px;
  background: linear-gradient(180deg, rgba(89, 0, 247, 0.44) 0%, #5900F7 63.54%);
  border-radius: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  
  padding: 20px;
  margin-bottom: 50px;
`;

export const ProfitSmallRectangle = styled.div`
  width: 332px;
  height: 180px;
  background: linear-gradient(180deg, rgba(89, 0, 247, 0.44) 0%, #5900F7 63.54%);
  border-radius: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  
  padding: 20px;
`;

export const ReferralProfitPersonIcon = styled.img`
`;
export const ReferalProfitCalculatorContainer = styled(Grid)`
  padding-top: 110px;
  max-width: 1320px;
`;
export const ReferalCalculatorBar = styled(Grid)`
  width: 70%;
  padding-right: 20px;
`;
export const ReferalCalculcatorBox = styled(Grid)`
  width: 329px;
  height: 123px;
  border: 1px solid #FFDD00;
  box-sizing: border-box;
  border-radius: 16px;
`;

export const ReferalCalculatorBoxIncomeText = styled(Grid)`
  padding-top: 24px; 
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const ReferalCalculcatorBoxProsText = styled(Grid)`
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
  color: #FFDD00;
`;

export const ReferalCalculatorBarText = styled(Grid)`
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const ReferalCalculatorBarAmountText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const AmountValue = styled.p`
  display: inline;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  order: 1;
  flex-grow: 0;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFDD00;
`;

export const ReferalAdditionalBonusesContainer = styled(Grid)`
  padding-top: 96px;
  background: url(${ReferalAdditionalBackground}) no-repeat;
  background-position: center;
  width: 1440px;
`;

export const ReferalAdditionalBonusesTitle = styled(Grid)`
  &.MuiGrid-root{
    margin: 0 auto;
  }
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
  letter-spacing: 0.02em;
  text-align: center;
  padding-bottom: 64px ;
`;

export const ReferalAdditionalBonusesImg = styled.img`
  box-shadow: 0px 4.60514px 92.1028px #2300F8,
  inset 0px 2.30257px 
  11.5129px rgba(255, 255, 255, 0.58),
  inset 11.5129px 18.4206px 23.0257px 
  rgba(130, 5, 255, 0.95);
  border-radius: 50%;
`;

export const ReferalAdditionalBonusesImgSubtitle = styled(Grid)`
  padding-top: 15px;
  padding-bottom: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
`;

export const ReferalQuestionsAccordion = styled(Accordion)`
  &.MuiPaper-root{
    background: #000523;  
    
  }
`;

export const QuestionLine = styled.div`
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border: 0.5px solid #FFFFFF;
  width: 924px;
`;

export const ReferalTypography = styled(Typography)`
  &.MuiTypography-root{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ReferalQuestionsContainer = styled(Grid)`
  padding-top:  100px;
  padding-bottom: 170px ;
`;

export const ReferalQuestionTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding-bottom: 64px;
`;
export const ReferalQuestionSubtitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  padding-bottom: 8px;
`;
export const ReferalQuestionDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  width: 924px;
  padding-bottom: 16px;
`;
export const ReferalQuestionsInfoContainer = styled.div`
  width: 924px;
  padding-bottom: 16px;
`;
export const ReferalAccordionSummary = styled(AccordionSummary)`
`;

export const ReferalAccordionDetails = styled(MuiAccordionDetails)`
  border-top: 1px solid rgb(0, 0, 0, .125);
`;

export const EmptyH2 = styled(Skeleton)`
  width: 700px;
  border-radius: 24px;
  &.MuiSkeleton-root {
    height: 70px;
    margin: 1rem auto;
  }

  @media screen and (max-width: 420px) {
    width: 300px;
  }
`;

export const EmptyContainer = styled(Skeleton)`
  width: 1218px;
  border-radius: 24px;

  &.MuiSkeleton-root {
    height: 400px;
  }

  @media screen and (max-width: 420px) {
    width: 300px;
  }
`;

export const ReferralProfitBigPlanetContainer = styled.div``;
export const ReferralProfitMiddlePlanetContainer = styled.div``;
export const ReferralProfitSmallPlanetContainer = styled.div``;

export const BigPlanet = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: radial-gradient(96% 96% at 70.4% 31.2%, #FF5C00 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFDD00;
  box-shadow: 0px 0px 27.4947px rgba(217, 137, 0, 0.13), 52.651px 26.3255px 87.6323px rgba(5, 0, 255, 0.2), 0px 3.99491px 87.8467px rgba(255, 15, 0, 0.7), inset 0px 1.99745px 21.048px rgba(255, 255, 255, 0.65), inset 9.98726px 19.9745px 110.616px #FF0000;

  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 39px;
  z-index: 1;
  &:after{
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 0.574313px solid rgba(116, 82, 177, 0.8);    width: calc(100% + 250px);
    height: calc(100% + 250px);
  }
`;
export const MiddlePlanet = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  background: #000523;
  border: 0.415571px solid #5900F7;
  border-radius: 50%;
  box-shadow: inset 8.9051px 17.8102px 23.7469px rgba(130, 5, 255, 0.7);
  height: 140px;
  width: 140px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  z-index: 1;
  &:after{
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 0.574313px solid rgba(116, 82, 177, 0.8);
    width: calc(100% + 120px);
    height: calc(100% + 120px);
  }
`;

export const MiddleText = styled.span`
  font-size: 24px;
  font-weight: 600;
  padding-top: 5px;
  
`;

export const SmallPlanet = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 82px;
  background: linear-gradient(0deg, #000523, #000523), #920069;
  border: 0.231339px solid #E300F7;
  border-radius: 50%;
  box-shadow: 0px 3.39029px 67.8057px #100073, inset 0px 1.69514px 8.47572px rgba(255, 255, 255, 0.58), inset 4.95727px 9.91454px 13.2194px rgba(255, 5, 140, 0.7);
  z-index: 1;
  
  &:after{
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 0.574313px solid rgba(116, 82, 177, 0.8);    width: calc(100% + 42px);
    height: calc(100% + 42px);
  }
`;
