/* eslint-disable no-console */
import React, { ReactElement, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { useMiningState } from 'context/miningState';
import { useTranslation } from 'react-i18next';
// styles
import {
  MiningStatisticsTitle,
} from 'styles/desktop/StyledMining';

import Statistics from './PersonalStatistics';

const PersonalStatisticsContainer: React.FC = (): ReactElement => {
  const { state } = useGlobalState();
  const { t } = useTranslation(['mining']);
  const { miningState, miningDispatch } = useMiningState();

  const getPersonalStatistics = useCallback(async () => {
    const queryParams = {
      networkId: state.currentNetworkId,
      user: state.account,
      stage: miningState.stage,
    };
    try {
      const personalStatisticsResponse = await axios.get(`${state.baseUrl}/mining-data/personal`, { params: queryParams });
      if (personalStatisticsResponse.data) {
        miningDispatch({
          type: 'SET_PERSONAL_STAGE_STATISTICS',
          payload: {
            ...miningState,
            personalStageStatistics: {
              predictionVolume: personalStatisticsResponse.data.predictionVolume,
              currentDayPredictionVolume:
                personalStatisticsResponse.data.currentDayPredictionVolume,
              tokensEarned: personalStatisticsResponse.data.tokensEarned,
              currentDayTokensEarned: personalStatisticsResponse.data.currentDayTokensEarned,
              netProfit: personalStatisticsResponse.data.netProfit,
              currentDayNetProfit: personalStatisticsResponse.data.currentDayNetProfit,
              predictionsMade: personalStatisticsResponse.data.predictionsMade,
              correctPredictions: personalStatisticsResponse.data.correctPredictions,
              currentReturnRatio: personalStatisticsResponse.data.returnRatio,
              currentDayReturnRatio: personalStatisticsResponse.data.currentDayReturnRatio,
            },
          },
        });
      }
    } catch (e) {
      console.log('Something went wrong during getting personal statistics');
    }
  }, [state.baseUrl, state.currentNetworkId, state.account, miningState.stage]);

  useEffect(() => {
    if (state.account) {
      getPersonalStatistics();
    }
  }, [miningState.network, miningState.stage, state.account]);

  return (
    <div>
      <MiningStatisticsTitle>
        {t('PERSONAL STATISTICS')}
      </MiningStatisticsTitle>
      <Statistics />
    </div>
  );
};

export default PersonalStatisticsContainer;
