import React, { ReactElement } from 'react';
// styles
import StyledDarkContainer from 'styles/common/StyledDarkContainer';

interface IProps {
  children: ReactElement[] | ReactElement;
}

const DarkContainer: React.FC<IProps> = ({ children }: IProps):
  ReactElement => <StyledDarkContainer>{children}</StyledDarkContainer>;

export default DarkContainer;
