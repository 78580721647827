import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SwipeableDrawer,
  Grid,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/globalState';
import Account from 'components/common/Account';
import DropDown from 'components/common/DropDown';
// styles
import SideMenuContainer, {
  Wallet,
  NetworkContainer,
} from 'styles/mobile/StyledSideMenu';

import {
  getNetworkId,
  getNetworkName,
  getNetworksNames,
  networkIcons,
} from 'networksHelper';
import SideLink from './SideMenuLink';
import LanguageSwitcher from './LanguageSwitcher';
import Socials from './SideMenuSocialBlock';

interface IProps {
  openedMenu: boolean;
  toggleMenu: (open: boolean,
    event: React.KeyboardEvent| React.MouseEvent | React.SyntheticEvent<{}, Event>) => void;
  currency: 'BNB' | 'ETH' | 'OKT' | null;
  amount: Number;
}

const SideMenu: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    openedMenu,
    toggleMenu,
    currency,
    amount,
  } = props;
  const history = useHistory();
  const { state, dispatch } = useGlobalState();
  const { t } = useTranslation(['header']);

  const triggerAmplitude = (link: string) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform link click',
        amplitudeProps: {
          placement: 'burger',
          link,
        },
      },
    });
  };

  const redirectGlobal = async (link: string,
    e: React.KeyboardEvent
    | React.MouseEvent
    | React.SyntheticEvent<{}, Event>) => {
    await triggerAmplitude(link);
    window.open(link);
    toggleMenu(false, e);
  };

  const redirectLocal = async (link: string,
    e: React.KeyboardEvent
    | React.MouseEvent
    | React.SyntheticEvent<{}, Event>) => {
    await triggerAmplitude(link || 'predict');
    history.push(`/${link}`);
    toggleMenu(false, e);
  };

  const changeNetwork = (event: SelectChangeEvent<unknown>) => {
    dispatch({
      type: 'SET_CURRENT_NETWORK',
      payload: {
        ...state,
        currentNetworkId: getNetworkId(event.target.value as string),
      },
    });
  };

  return (
    <SwipeableDrawer
      swipeAreaWidth={20}
      disableSwipeToOpen={false}
      open={openedMenu}
      onOpen={(e) => toggleMenu(true, e)}
      onClose={(e) => toggleMenu(false, e)}
    >
      <SideMenuContainer container direction="column" justifyContent="space-between">
        <Grid item>
          <Wallet>{ `${state.account.substr(0, 7)}...${state.account.substr(-4)}` }</Wallet>
          {
          state.account && state.currentNetworkId === +window.ethereum.chainId
          && <Account currency={currency} amount={amount} />
        }
          <NetworkContainer>
            <DropDown
              title={t('Network')}
              items={getNetworksNames()}
              icons={networkIcons}
              currentItem={getNetworkName(state.currentNetworkId)}
              changeItem={changeNetwork}
              dark
            />
          </NetworkContainer>
          <div>
            <SideLink redirect={(e) => redirectLocal('', e)}>
              {t('common:Predict')}
            </SideLink>
            <SideLink redirect={(e) => redirectLocal('closed-pools', e)}>
              {t('common:closedPools')}
            </SideLink>
            <SideLink redirect={(e) => redirectGlobal('https://prosper.so/rules', e)}>
              {t('common:Rules')}
            </SideLink>
            <SideLink redirect={(e) => redirectLocal('leaderboard', e)}>
              {t('Leaderboard')}
            </SideLink>
            <SideLink redirect={(e) => redirectLocal('referral-info', e)}>
              Referral
            </SideLink>
            <SideLink redirect={(e) => redirectLocal('mining', e)}>
              {t('Mining')}
            </SideLink>
          </div>
          <div>
            <LanguageSwitcher />
          </div>
        </Grid>
        <Socials />
      </SideMenuContainer>
    </SwipeableDrawer>
  );
};

export default SideMenu;
