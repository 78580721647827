import styled from 'styled-components';
import { Modal, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as LinkIcon } from 'static/images/common_icons/link_icon.svg';

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root{
    width: 100%;
    height: 100%;
    outline: none;
    overflow: scroll;
    background: #0C0C21;
  }
`;

export const StatisticsContentContainer = styled.div`
  width: 100%;
  padding: 22px 20px;
`;

export const ReturnRow = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 10px;
  }
`;

export const StatisticsTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
`;

export const NotificationContainer = styled.div`
  background-color: #5900F7;
  border-radius: 10px;
  padding: 14px;
  margin-bottom: 16px;
`;

export const NotificationContent = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #FD0;
`;

export const StyledLinkIcon = styled(LinkIcon)`
  margin-left: 8px;
  
  path {
    fill: #FD0;
  }
`;

export const SwitcherContainer = styled.div`
  margin-bottom: 24px;
`;
