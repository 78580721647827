import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const StyledCount = styled.div`
    font-weight: bold;
    font-size: ${isMobile ? 14 : 16}px;
    line-height: 30px;
    color: #fd0;
    margin: 0 10px;
`;

export default StyledCount;
