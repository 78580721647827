import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import {
  Grid,
  TableBody,
  TableRow,
  Backdrop,
} from '@mui/material';
import axios from 'axios';
import { ReactComponent as ReturnIcon } from '../../../static/images/common_icons/return_icon.svg';
import { CloseButton } from '../../../styles/modals/StyledMobileBetModal';
import {
  StyledLeaderboardModalContainer,
  PersonCardContainer,
  CardHeader,
  PersonIcon,
  StatisticPersonComponent,
  StatisticComponentsContainer,
  WinningPersonComponent,
  WinningPersonValues,
  StyledWinningsGrid,
  LeaderboardTitle,
  StyledTableModalMobileRow,
  ModalContainer,
} from '../../../styles/modals/StyledMobileLeaderboard';

import iconPurple from '../../../static/images/leaderboard/iconPurple.svg';
import {
  RoundCountLabel,
  StyledDirectionItem,
  StyledLastBets,
  StyledTableRoundModalCell,
  StyledLeaderboardButton,
} from '../../../styles/common/StyledLeaderboardModal';
import { StyledTable, StyledTableCell, StyledTableHead } from '../../../styles/desktop/StyledLeaderBoard';
import { useModalState } from '../../../context/modalState';
import { useGlobalState } from '../../../context/globalState';
import { useLeaderboardState } from '../../../context/leaderboardContext';
import { getCurrencyName } from '../../../networksHelper';

const LeaderBoardModal: React.FC = (): ReactElement => {
  const { modalsState, modalDispatch } = useModalState();
  const { i18n, t } = useTranslation(['leaderboard']);
  const [open, setOpen] = useState<boolean>(false);
  const { state, dispatch } = useGlobalState();
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();
  const { leaderboardSender } = modalsState;
  const [betState, setBetState] = useState<any[]>([]);
  const history = useHistory();

  const getLastFiveBets = (async () => {
    const userId = modalsState.leaderboardSender?.toLowerCase();
    const data = await axios.get(`${state.baseUrl}/statistics/user/${userId}/last-bets?networkId=${state.currentNetworkId}`);
    if (data.data) {
      setBetState(data.data);
    }
  });

  const currentSender = modalsState.leaderboardSender || 'empty';

  const getCurrentUser = () => (
    leaderboardState.users?.filter((data) => data.sender === state.account.toLowerCase())[0]
  );

  const getUserBySender = (sender : string) => (
    leaderboardState.users?.filter((data) => data.sender === sender.toLowerCase())[0]
  );
  const routeChangeToMain = () => {
    const path = '/';
    history.push(path);
  };

  useEffect(() => {
    if (modalsState.leaderboardSender) getLastFiveBets();
  }, [state.baseUrl, state.account, modalsState.leaderboardSender]);

  useEffect(() => {
    if (modalsState.openedLeaderboardModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open leaderboard modal',
        },
      });
    }

    setOpen(modalsState.openedLeaderboardModal);
  }, [modalsState.openedLeaderboardModal]);

  const handleClose = () => {
    modalDispatch({
      type: 'CLOSE_LEADERBOARD_MODAL',
      payload: {
        sender: '',
      },
    });
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <ModalContainer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          <StyledLeaderboardModalContainer container>
            <CloseButton>
              <ReturnIcon onClick={handleClose} />
              {t('Return to Leaderboard')}
            </CloseButton>
            <LeaderboardTitle>{t('Profile in leaderboard')}</LeaderboardTitle>

            <Grid item style={{ width: '100%' }}>
              <PersonCardContainer>
                <CardHeader container justifyContent="flex-start" alignItems="center">
                  <PersonIcon src={iconPurple} alt="icon" />
                  <Grid item>
                    {leaderboardSender?.slice(0, 17)}
                    ...
                    {getCurrentUser()?.sender === state.account.toLowerCase() ? '(you)' : ''}
                  </Grid>
                </CardHeader>
                <StatisticComponentsContainer>
                  <StatisticPersonComponent container justifyContent="space-between">
                    <Grid item>{t('Win rate')}</Grid>
                    <Grid item>{getUserBySender(currentSender)?.winrate}</Grid>
                  </StatisticPersonComponent>
                  <StatisticPersonComponent container justifyContent="space-between">
                    <Grid item>{t('Rounds won')}</Grid>
                    <Grid item>{`${getUserBySender(currentSender)?.roundsWon}/${getUserBySender(currentSender)?.roundsPlayed}`}</Grid>
                  </StatisticPersonComponent>
                  <WinningPersonComponent container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      {t('Net winnings')}
                      {` (${getCurrencyName(state.currentNetworkId)})`}
                    </Grid>
                    <Grid item>
                      <WinningPersonValues container direction="column">
                        <StyledWinningsGrid item>
                          {getUserBySender(currentSender)?.netWinnings.toFixed(6)}
                        </StyledWinningsGrid>
                      </WinningPersonValues>
                    </Grid>
                  </WinningPersonComponent>
                </StatisticComponentsContainer>
              </PersonCardContainer>
            </Grid>

            <RoundCountLabel item>{t('LAST 5 BETS')}</RoundCountLabel>
            <StyledLastBets container justifyContent="center">
              <TableBody>
                <StyledTable sx={{ minWidth: 350, maxWidth: 375 }} size="small" aria-label="a dense table">
                  <StyledTableHead mobile={isMobile}>
                    <TableRow>
                      <StyledTableCell align="left">{t('Round')}</StyledTableCell>
                      <StyledTableCell align="center">{t('Side')}</StyledTableCell>
                      <StyledTableCell align="right">
                        {t('Net winnings')}
                        {` (${getCurrencyName(state.currentNetworkId)})`}
                      </StyledTableCell>
                    </TableRow>
                  </StyledTableHead>
                  {betState.map((row) => (
                    <StyledTableModalMobileRow>
                      <StyledTableRoundModalCell align="left">{row.poolId}</StyledTableRoundModalCell>
                      <StyledTableCell align="center">
                        <StyledDirectionItem container justifyContent="center">
                          <Grid
                            item
                            style={row.isBull ? { color: 'rgba(44, 214, 2, 1)' } : { color: 'red' }}
                          >
                            {row.isBull ? 'Bull' : 'Bear'}
                          </Grid>
                        </StyledDirectionItem>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Grid container direction="column">
                          <StyledWinningsGrid
                            style={row.netWinnings > 0 ? { color: 'rgba(44, 214, 2, 1)' } : { color: 'red' }}
                            item
                          >
                            {row.netWinnings.toFixed(6)}
                          </StyledWinningsGrid>
                        </Grid>
                      </StyledTableCell>
                    </StyledTableModalMobileRow>
                  ))}
                </StyledTable>
              </TableBody>
            </StyledLastBets>
            {getCurrentUser()?.sender === state.account.toLowerCase() && (
            <Grid item>
              <StyledLeaderboardButton
                onClick={routeChangeToMain}
              >
                {t('PREDICT')}
              </StyledLeaderboardButton>
            </Grid>
            )}
          </StyledLeaderboardModalContainer>
        </>
      </ModalContainer>
    </Grid>
  );
};

export default LeaderBoardModal;
