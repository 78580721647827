import React, {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
// components
import Landing from './RefferalLandingContainer';
import PrivateOffice from './private-office/PrivateOfficeContainer';
import EmptyReferralContainer from './EmptyReferralContainer';

const ReferralContainer: React.FC = (): ReactElement => {
  const [userHasReferrals, setUserHasReferrals] = useState<boolean | null>(null);
  const { state } = useGlobalState();

  const getReferrals = useCallback(async () => {
    try {
      const referralsResponse = await axios.get(`${state.baseUrl}/referral/invites`, {
        params: {
          referrerWallet: state.account.toLowerCase(),
        },
      });

      if (referralsResponse.data) {
        return referralsResponse.data;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong while getting referrals ', e);
    }

    return [];
  }, [state.baseUrl, state.account]);

  const referralPageAppearance = useMemo(() => {
    if (userHasReferrals === null) {
      return <EmptyReferralContainer />;
    }
    if (userHasReferrals) {
      return <PrivateOffice />;
    }
    return <Landing />;
  }, [userHasReferrals]);

  useEffect(() => {
    if (userHasReferrals === null) {
      getReferrals().then(
        (referrals) => setUserHasReferrals(referrals.length > 0),
      );
    }
  }, [state.account, userHasReferrals]);

  useEffect(() => {
    setUserHasReferrals(null);
  }, [state.account]);

  return referralPageAppearance;
};

export default ReferralContainer;
