import React, {
  ReactElement, useState, useEffect, useCallback,
} from 'react';
import Switcher from 'components/common/PillsSwitcher';
import CurrencyBlock from 'components/common/CurrencyPredictContainer';
import Search from 'components/common/Search';
import {
  Grid,
} from '@mui/material';
import { useGlobalState } from 'context/globalState';
import { useTableState } from 'context/tableState';
import { useTranslation } from 'react-i18next';
// styles
import {
  SwitcherContainer,
  SearchContainer,
  AppearenceContainer,
} from 'styles/desktop/StyledClosedPools';
import {
  IToken,
  IAsset,
} from 'interfaces/common.d';
import Appearance from './TableAppearenceButtons';

const TableHeader: React.FC = (): ReactElement => {
  const [tokens, setTokens] = useState<Array<IToken> | null>(null);
  const [assets, setAssets] = useState<Array<IAsset> | null>(null);
  const { state, dispatch } = useGlobalState();
  const { tableState, tableDispatch } = useTableState();
  const { t, i18n } = useTranslation(['closed_pools']);

  const getDisplayTokens = useCallback((): void => {
    let tmpTokens: Array<IToken> = [{ id: 0, displayName: t('All'), value: '' }];
    const stateTokens: Array<IToken> = state.tokens.map((token) => ({
      id: token.id,
      displayName: token.displayName,
      value: token.displayName,
    }));
    tmpTokens = tmpTokens.concat(stateTokens);
    setTokens(tmpTokens);
  }, [state.tokens]);

  const getDisplayAssets = useCallback(() => {
    let tmpAssets: Array<IAsset> = [{ name: t('All'), pair: '' }];
    const stateAssets: Array<IAsset> = state.feeds.map((feed) => ({
      name: feed.assetName,
      pair: feed.displayName,
    }));
    tmpAssets = tmpAssets.concat(stateAssets);
    setAssets(tmpAssets);
  }, [state.feeds]);

  const changeToken = (token: IToken): void => {
    tableDispatch({ type: 'SET_TOKEN', payload: { ...tableState, token } });
  };

  const changeAsset = (asset: IAsset): void => {
    tableDispatch({ type: 'SET_ASSET', payload: { ...tableState, asset } });
  };

  const changePersonalFilter = (tab: number): void => {
    if (state.account) {
      tableDispatch({ type: 'SET_ACCOUNT_FILTER', payload: { ...tableState, accountFilter: !tab } });
    }
  };

  const changeSearchId = (poolId: string): void => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform use search',
        amplitudeProps: {
          text: poolId,
        },
      },
    });
    tableDispatch({ type: 'SET_ACCOUNT_FILTER', payload: { ...tableState, accountFilter: false } });
    tableDispatch({ type: 'SET_SEARCH_ID', payload: { ...tableState, searchId: poolId ? +poolId : null } });
  };

  useEffect(() => {
    setAssets(null);
    setTokens(null);
  }, [i18n.language]);

  // if tokens not set and in state there is some tokens
  useEffect(() => {
    if (!tokens && state.tokens.length) {
      getDisplayTokens();
    }
  }, [state.tokens, tokens, getDisplayTokens]);

  // if assets not set and in state there is some feeds
  useEffect(() => {
    if (!assets && state.feeds.length) {
      getDisplayAssets();
    }
  }, [state.feeds, assets, getDisplayAssets]);

  useEffect(() => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform switch my/all',
        amplitudeProps: {
          filter: tableState.accountFilter ? 'my' : 'all',
        },
      },
    });
  }, [tableState.accountFilter]);

  return (
    <Grid container justifyContent="space-between">
      <SwitcherContainer item>
        <Switcher
          tabs={[t('My'), t('All')]}
          selectedTab={tableState.accountFilter ? 0 : 1}
          changeTab={changePersonalFilter}
        />
      </SwitcherContainer>
      <Grid item>
        <CurrencyBlock
          tokens={tokens}
          assets={assets}
          changeToken={changeToken}
          changeAsset={changeAsset}
        />
      </Grid>
      <SearchContainer item>
        <Search doSearch={changeSearchId} />
      </SearchContainer>
      <AppearenceContainer item>
        <Appearance />
      </AppearenceContainer>
    </Grid>
  );
};

export default TableHeader;
