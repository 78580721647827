import styled from 'styled-components';
import { Button } from '@mui/material';

const StyledClosedPoolsContainer = styled.div`
  background-color: #1A1A39;
  height: 100%;
  min-height: calc(100vh - 64px);
  padding: 8px 18px;
  margin-top: -1px;
`;

export const ClosedPoolsTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
  margin: 0;
`;

export const SwitcherContainer = styled.div`
    margin: 25px 0;
`;

export const SeatchIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin-top: 14px;
    background: white;
    border-radius: 16px;
`;

export const StyledLoadMoreButton = styled(Button)`
  &.MuiButton-root{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #5900F7;
    background: linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%);
    height: 56px;
    margin: 24px 0;
    border-radius: 16px;
  }
`;

export default StyledClosedPoolsContainer;
