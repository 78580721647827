import React, { createContext, useContext, useReducer } from 'react';
import {
  ITableState,
} from 'interfaces/state.d';

type ActionType = 'SET_TOKEN'
    | 'SET_ASSET'
    | 'SET_PAGE'
    | 'SET_ACCOUNT_FILTER'
    | 'SET_SEARCH_ID'
    | 'CHANGE_TABLE_VIEW';
type PayloadType = ITableState;
interface IAction {
    type: ActionType;
    payload: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const TableContext = createContext<{tableState: ITableState;
  tableDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (tableState: ITableState, action: IAction): ITableState => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...tableState,
        token: action.payload.token,
      };
    case 'SET_ASSET':
      return {
        ...tableState,
        asset: action.payload.asset,
      };
    case 'SET_PAGE':
      return {
        ...tableState,
        page: action.payload.page,
      };
    case 'SET_ACCOUNT_FILTER':
      return {
        ...tableState,
        accountFilter: action.payload.accountFilter,
      };
    case 'SET_SEARCH_ID':
      return {
        ...tableState,
        searchId: action.payload.searchId,
      };
    case 'CHANGE_TABLE_VIEW':
      return {
        ...tableState,
        tableViewIndex: action.payload.tableViewIndex,
      };
    default:
      return initialState;
  }
};

const initialState: ITableState = {
  token: { id: 0, displayName: '', value: '' },
  asset: { name: '', pair: '' },
  page: 0,
  accountFilter: false,
  searchId: null,
  tableViewIndex: 1,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [tableState, tableDispatch] = useReducer(stateReducer, initialState);

  return (
    <TableContext.Provider value={{ tableState, tableDispatch }}>
      { children }
    </TableContext.Provider>
  );
};

export const useTableState = () => {
  const context = useContext(TableContext);

  if (!context) throw new Error('useTableContext must be used in a table state provider');

  return context;
};

export default StateProvider;
