import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import { useActualPoolState } from 'context/actualPoolState';
import { useTranslation } from 'react-i18next';
// styles
import {
  ProgressContainer,
  ProgressTitle,
  ProgressAmountSide,
} from 'styles/desktop/StyledClosedPools';
import Progress from './Progress';

const ActualPoolProgressContainer: React.FC = (): ReactElement => {
  const { actualPoolState } = useActualPoolState();
  const { t } = useTranslation(['actual_pool']);
  const {
    volume,
    bullQuote,
    bearQuote,
  } = actualPoolState;

  return (
    <ProgressContainer container direction="column">
      <ProgressTitle item>
        {t('stats of predict')}
      </ProgressTitle>
      <Grid item>
        <Progress progress={
                    volume
                      ? (parseFloat(bullQuote) / volume) * 100
                      : 50
                }
        />
      </Grid>
      <Grid item container justifyContent="space-between">
        <ProgressAmountSide>
          {t('common:BULL')}
          { bullQuote }
        </ProgressAmountSide>
        <ProgressAmountSide>
          {t('common:BEAR')}
          { bearQuote }
        </ProgressAmountSide>
      </Grid>
    </ProgressContainer>
  );
};

export default ActualPoolProgressContainer;
