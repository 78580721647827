import styled from 'styled-components';
import {
  Grid,
  Select,
  MenuItem,
  FormLabel,
} from '@mui/material';

const SideMenuContainer = styled(Grid)`
  background-color: #0C0C21;
  width: 200px;
  height: 100%;
  padding: 22px 18px;
`;

export const Wallet = styled.span`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
`;

export const NetworkContainer = styled.div`
  margin-top: 8px;
`;

export const StyledSideMenuLink = styled(Grid)`
    height: 58px;
    border-bottom: 1px solid rgba(228, 228, 228, 0.2);
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    padding: 0 18px;
    transition: all .2s;

    &:hover {
      padding-right: 10px;
    }
  
`;

export const LanguageSelect = styled(Select)`
  &.MuiOutlinedInput-root{
    display: none;
  }
`;

export const LanguageMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root{
    color: black;
  }
`;

export const LanguageContentContainer = styled(Grid)`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
`;

export const LanguageLabel = styled(FormLabel)`
  &.MuiFormLabel-root{
    margin-top: 11px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
  }
`;

export const LanguageLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin-right: 12px;
    cursor: pointer;
`;

export const SocialsLabel = styled.div`
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 30px;
    margin-bottom: 7px;
`;

export default SideMenuContainer;
