import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Grid,
} from '@mui/material';
import Account from 'components/common/Account';
import Button from 'components/common/CustomButton';
import { ReactComponent as Logo } from 'static/images/logo.svg';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import { useTranslation } from 'react-i18next';
import {
  getCurrencyName,
} from 'networksHelper';
// styles
import {
  HeaderContainer,
  ContentRow,
} from 'styles/desktop/StyledHeader';
import NetworkSwitcher from './NetworkSwitcher';
import LanguageSwitcher from './LanguageSwitcher';
import Statistics from './Statistics';
import DropDownProducts from './DropDownNavigationComponents/DropDownProducts';
import DropDownAbout from './DropDownNavigationComponents/DropDownAbout';

const Header: React.FC = (): ReactElement => {
  const [currency, setCurrency] = useState<'BNB' | 'ETH' | 'OKT' | null>(null);
  const [amount, setAmount] = useState<number>(0);

  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrency(getCurrencyName(state.currentNetworkId));
  }, [state.currentNetworkId]);

  // fixed user balance to 4 signs if he has it
  useEffect(() => {
    const balance: number = +state.balance.toFixed(4);
    setAmount(balance);
  }, [state.balance]);

  // TODO: remove duplicate
  const connect = async (): Promise<void> => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform connect wallet click',
        amplitudeProps: {
          metamaskInstalled: window.ethereum && window.ethereum.isMetaMask,
          trustWalletInstalled: window.ethereum && window.ethereum.isTrustWallet,
        },
      },
    });

    if (!(window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet))) {
      window.location.href = 'https://metamask.io';
    }
    let accounts: Array<string> = [];
    const networkId: number | null = state.currentNetworkId;

    if (state.networkProvider) {
      accounts = await state.networkProvider.listAccounts();
    }

    if (accounts.length < 1) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.location.reload();
    }

    if (+window.ethereum.chainId !== networkId) {
      modalDispatch({ type: 'OPEN_WRONG_NETWORK_MODAL' });
    }
  };

  return (
    <HeaderContainer container justifyContent="center">
      <ContentRow item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Link to="/"><Logo /></Link>
          </Grid>
          <Grid item>
            <DropDownProducts />
          </Grid>
          <Grid item>
            <DropDownAbout />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <NetworkSwitcher />
              </Grid>
              <Grid item>
                <LanguageSwitcher />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                {
                  state.account && state.currentNetworkId === +window.ethereum.chainId
                    ? <Account currency={currency} amount={amount} />
                    : <Button secondary onClick={connect}>{t('Connect wallet')}</Button>
                }
              </Grid>
              <Grid item xs="auto">
                {
                  state.account && state.currentNetworkId === +window.ethereum.chainId
                  && <Statistics />
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentRow>
    </HeaderContainer>
  );
};

export default Header;
