import styled from 'styled-components';
import {
  Grid,
  Table,
  TableCell,
  TableRow,
} from '@mui/material';

export const StyledOfficeBackground = styled(Grid)`
  position: relative;
  min-height: calc(100vh - 96px - 220px);

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -96px;
    bottom: -220px;
    z-index: -10;
    background: #000523;
  }
`;

export const OfficeContainer = styled.div`
  width: 1218px;
  z-index: 100;

  @media screen and (max-width: 420px) {
    width: 300px;
    height: calc(100vh - 220px);
  }
`;

export const TotalEarningsContainer = styled.div`
  position: relative;
  z-index: 1;
  border: 2px solid transparent;
  border-radius: 24px;
  background-clip: padding-box;
  
  &:after {
    content: '';
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -2px;
    z-index: -1;
    background: linear-gradient(to right,
      rgb(126, 56, 249) 50%,
      rgb(0, 188, 247) 100%
    );
    border-radius: 24px;
  }
`;

export const TotalEarningsInnerContainer = styled.div`
  background-color: #000523;
  border-radius: 24px;
  padding: 32px;

  @media screen and (max-width: 420px) {
    padding: 24px 0;
    background-color: #0C0C21;
  }
`;

export const TotalEarningsTitle = styled.div`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  
  @media screen and (max-width: 420px) {
    margin-left: 24px;
  }
`;

export const StyledTotalEarningsItem = styled.div<{active: string}>`
  border-radius: 10px;
  padding: 16px 24px;
  height: 95px;
  width: 265px;
  cursor: pointer;
  
  background: ${({ active }) => (active === 'true'
    ? 'linear-gradient(90deg, #7E38F9 38.83%, #00BCF7 80.93%, #00BCF7 98.99%)'
    : 'rgba(206, 181, 181, 0.1)')};
  
  @media screen and (max-width: 420px) {
    width: 100%;
    height: 73px;
    border-radius: 0;
    margin: 4px 0;
  }
`;

export const TotalEarningsItemTitle = styled.div`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 8px;
  
  @media screen and (max-width: 420px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const TotalEarningsItemAmount = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  
  svg {
    margin-left: 12px;
  }

  @media screen and (max-width: 420px) {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
  }
`;

export const TotalEarningsItemHint = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(62, 59, 85, 0.8);
  border-radius: 24px;
  padding: 10px 18px;
  width: 290px;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  
  @media screen and (max-width: 420px) {
    margin-left: 19px;
  }
`;

export const StyledTableContent = styled(Table)`
  &.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 8px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    color: white;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 20px 24px;

    //@media screen and (max-width: 420px) {
    //  font-size: 12px;
    //  color: rgba(255, 255, 255, 0.7);
    //  font-weight: 400;
    //}
  }
`;

export const StyledRow = styled(TableRow)`
  background-color: #3E3B55;
  height: 27px;

  ${StyledTableCell}:first-child {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
  }

  ${StyledTableCell}:last-child {
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
    text-transform: capitalize;
  }
`;

export const StatusCell = styled(StyledTableCell)<{paid: string}>`
  &.MuiTableCell-root {
    color: ${({ paid }) => (paid === 'true' ? '#00FF47' : '#FD0')};  
  }
`;

export const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
`;

export const DownloadSpan = styled.span`
  margin-right: 18px;
`;

export const ReferralButtonContainer = styled.div`
  width: fit-content;
  margin: auto;
  
  @media screen and (max-width: 420px) {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const ReferralButtonContent = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

export const TableContainer = styled.div`
  margin-top: 80px;
`;

export const ReferralLinkBlockContainer = styled.div`
  margin-bottom: 160px;
`;
