import React, { ReactElement, useState, useRef } from 'react';
import { ReferalLinkCopiedButton, ReferalOurAdvantagesButton } from 'styles/desktop/StyledReferal';
import { useTranslation } from 'react-i18next';

interface IProps {
    referalLink : string;
}

const ReferralCurrentButton: React.FC<IProps> = (props: IProps): ReactElement => {
  const [copied, setCopied] = useState<boolean>(false);
  const { referalLink } = props;
  const { i18n, t } = useTranslation(['referral']);
  const btn = useRef<HTMLButtonElement>(null);

  const copy = (element: HTMLInputElement) => {
    const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

    if (isiOSDevice) {
      const range = document.createRange();
      range.selectNodeContents(element);

      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);

      element.setSelectionRange(0, 999999);
    } else {
      element.select();
    }

    document.execCommand('copy');
  };

  const copyToClipboard = async () => {
    const input = document.createElement('input');
    input.value = referalLink;
    btn.current?.appendChild(input);
    copy(input);
    btn.current?.removeChild(input);
    setCopied(true);
  };

  if (copied) {
    return (
      <ReferalLinkCopiedButton>
        {t('Link copied')}
      </ReferalLinkCopiedButton>
    );
  }
  return (
    <ReferalOurAdvantagesButton onClick={() => copyToClipboard()} ref={btn}>
      {t('Copy the link')}
    </ReferalOurAdvantagesButton>
  );
};

export default ReferralCurrentButton;
