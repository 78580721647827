import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  expanded: boolean;
}

const ChartCollapseItem: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    expanded,
  } = props;

  const { t } = useTranslation(['chart']);

  return (
    <div>
      {
        expanded
          ? t('Hide block')
          : t('Open block')
      }
    </div>
  );
};

export default ChartCollapseItem;
