import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferalMainText, ReferalMobileSubtitle, ReferralMobilePage } from '../../../styles/mobile/StyledReferal';
import MenuContainer from '../common/MenuContainer';
import BoldTitle from '../../common/BoldTitle';
import ReferalImages from './ReferalImages';
import ReferralHowItWorks from './ReferralHowItWorks';
import ReferalOurAdvantages from './ReferalOurAdvantages';
import ReferalProfit from './ReferalProfit';
import ReferralProfitCalculator from './ReferalProfitCalculator';
import ReferralQuestions from './ReferralQuestions';

const LandingContainer: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['referral']);
  return (
    <>
      <MenuContainer />
      <ReferralMobilePage container justifyContent="center">
        <ReferalMainText>{t('Referral Program')}</ReferalMainText>
        <ReferalMobileSubtitle>
          {t('We share our platform commission with those who'
            + ' have introduced other users to our platform'
            + ' (30% of our income is to be redistributed)')}
        </ReferalMobileSubtitle>
        <ReferalImages />
        <ReferralHowItWorks />
        <ReferalOurAdvantages />
        <ReferalProfit />
        <ReferralProfitCalculator />
        <ReferralQuestions />
      </ReferralMobilePage>
    </>
  );
};

export default LandingContainer;
