import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import TableStateProvider from 'context/tableState';
import ActualPoolStateProvider from 'context/actualPoolState';
// styles
import {
  PageContainer,
} from 'styles/desktop/StyledClosedPools';
import Header from './ClosedPoolsHeader';
import ActualPool from './actual_pool/ActualPoolContainer';
import Table from './table/TableContainer';
import Chart from './tradingViewChart/TradingViewChartContainer';

const FrozenChart = React.memo(Chart);

const PoolsContainer: React.FC = (): ReactElement => (
  <Grid container justifyContent="center">
    <PageContainer item>
      <Header />
      <ActualPoolStateProvider>
        <ActualPool />
      </ActualPoolStateProvider>
      <FrozenChart />
      <TableStateProvider>
        <Table />
      </TableStateProvider>
    </PageContainer>
  </Grid>
);

export default PoolsContainer;
