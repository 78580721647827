import styled from 'styled-components';
import {
  Grid, Modal, TableRow,
} from '@mui/material';
import { StyledTableCell } from '../desktop/StyledLeaderBoard';

export const StyledLeaderboardModal = styled(Modal)`
  width: 792px;
  height: 667px;
  background: #1A1A39;
  border-radius: 24px 24px 24px 24px;
  background: linear-gradient(rgba(26, 26, 57, 1) 31%, #2E2E4A 31%) no-repeat;
  margin: 0 auto;
`;
export const StyledUserInformation = styled(Grid)`
  padding-left: 42px;
  padding-top: 39px;
`;

export const StyledNameGrid = styled(Grid)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledUserIdGrid = styled(Grid)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 221, 0, 1);
`;
export const StyledLeaderboardModalContainer = styled(Grid)`
  margin-top: 30px;
`;

export const RoundCountLabel = styled(Grid)`
  padding-top: 32px;
  padding-left: 40px;
  padding-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  height: 28px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const StyledLastBets = styled(Grid)`
  margin-top: 24px;
`;

export const StyledDirectionItem = styled(Grid)`
  letter-spacing: 0.01em;
  color: #5EFF5A;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
export const StyledDirectionIcon = styled.img`

`;
export const StyledTableRoundModalCell = styled(StyledTableCell)`
  &.MuiTableCell-root {
    color: white;
  }
`;

export const StyledTableModalRow = styled(TableRow)`
  height: 56px;
  padding: 0;
  border-radius: 24px;
  background-color: #3E3B55;
  color: white;

  ${StyledTableCell}:first-child {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
  }

  ${StyledTableCell}:last-child {
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
  }

  border-collapse: unset;
  margin: 2px 0px;
`;

export const StyledLeaderboardButton = styled.button`
  width: 340px;
  height: 56px;
  left: 18px;
  top: 942px;
  background: linear-gradient(180deg, #FFDD00 0%, #FFC700 100%);
  border-radius: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin-top: 25px;
`;
