import styled from 'styled-components';
import {
  Grid,
  InputLabel, ListItemIcon,
  MenuItem,
  Select,
} from '@mui/material';

export const StyledLabel = styled(InputLabel)<{ mobile: String }>`
    &.MuiInputLabel-root{
      font-size: ${(props) => (props.mobile === 'true' ? 12 : 16)}px;
      line-height: ${(props) => (props.mobile === 'true' ? 14 : 24)}px;
      letter-spacing: 0.01em;
      color: rgba(255, 255, 255, 0.8);
    }
`;

export const StyledSelectContainer = styled(Grid)<{ mobile: String, short?: String }>`
  &.MuiGrid-root {
    display: flex;
    margin: ${(props) => (props.mobile === 'true' ? '0 6px' : '0 14px')};
    flex-wrap: wrap;
    align-items: flex-end;
    width: ${(props) => {
    if (props.mobile === 'true') {
      if (props.short === 'true') {
        return 138;
      }
      return 164;
    }
    return 242;
  }}px;
    
  }

`;

export const StyledSelect = styled(Select)<{ mobile: String, dark: String }>`
  &.MuiInputBase-root{
    height: ${(props) => (props.mobile === 'true' ? 40 : 55)}px;
    background-color: ${(props) => (props.dark === 'true' && '#323232')};

    .MuiSelect-outlined {
      background: transparent;
      color: ${(props) => (props.dark === 'true' && 'white')};
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
    margin: 0 16px;
    display: flex;
    justify-content: flex-start;
    &.MuiMenuItem-root{
      background-color: white;
      color: #1A1A39;
    }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
   
`;
