const shortMonth = (locale: string): string => {
  switch (locale) {
    case 'cn':
      return 'zh-CN';
    case 'ae':
      return 'ar-AE';
    case 'ph':
      return 'fil-PH';
    case 'jp':
      return 'ja-JP';
    default:
      return locale;
  }
};

export default shortMonth;
