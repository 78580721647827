import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { Grid } from '@mui/material';
import { StyledLeaderboardContainer } from 'styles/mobile/StyledLeaderboard';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import LeaderCard from '../../desktop/leaderboard/LeaderCard';
import BoldTitle from '../../common/BoldTitle';
import DropDown from '../../common/DropDown';
import MenuContainer from '../common/MenuContainer';
import SearchIcon from '../../../static/images/images_as_components/SearchIcon';
import { SeatchIconContainer } from '../../../styles/mobile/StyledClosedPools';
import ContentPagination from '../../desktop/pools/table/content/ContentPagination';
import PersonCard from './PersonCard';
import { useModalState } from '../../../context/modalState';
import { useGlobalState } from '../../../context/globalState';
import { useLeaderboardState } from '../../../context/leaderboardContext';
import { ILeaderboardUser } from '../../../interfaces/leaderboard.d';

const LeaderboardContainer: React.FC = (): ReactElement => {
  const { state, dispatch } = useGlobalState();
  const [page, setPage] = useState(0);
  const { modalDispatch } = useModalState();
  const { leaderboardState, leaderboardDispatch } = useLeaderboardState();
  const { i18n, t } = useTranslation(['leaderboard']);
  type SortType =
      'NET WINNINGS' |
      'ROUNDS WON' |
      'WIN RATE';

  const sortTypesDictionary = {
    'NET WINNINGS': 'ByNetWinnings',
    'ROUNDS WON': 'ByRoundsWon',
    'WIN RATE': 'ByWinrate',
  };
  const [sortType, setSortType] = useState<SortType>('NET WINNINGS');

  const getUsers = useCallback(async () => {
    try {
      const usersResponse = await axios.get(`${state.baseUrl}/statistics/leaderboard?networkId=${state.currentNetworkId}&sortingType=${sortTypesDictionary[sortType]}`);
      if (usersResponse.data) {
        if (usersResponse.data) {
          await leaderboardDispatch({
            type: 'SET_USERS',
            payload: {
              ...leaderboardState,
              account: state.account,
              users: usersResponse.data,
            },
          });
        }
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error received in Leaderboard when calling the method getUsers', e);
    }
  }, [state.baseUrl, state.account, sortType, state.currentNetworkId]);

  const changePage = (newPage: number) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform switch page leaderboard',
        amplitudeProps: {
          page: newPage,
        },
      },
    });

    setPage(newPage - 1);
    leaderboardDispatch({
      type: 'SWITCH_PAGE',
      payload: {
        ...leaderboardState,
        currentPage: newPage - 1,
      },
    });
  };

  const openLeaderboardSearchModal = () => {
    modalDispatch({
      type: 'OPEN_LEADERBOARD_SEARCH_MODAL',
    });
  };

  useEffect(() => {
    if (leaderboardState.users) {
      leaderboardDispatch({
        type: 'SET_USERS',
        payload: {
          ...leaderboardState,
          users: [],
        },
      });
    }
  }, [sortType]);

  useEffect(() => {
    getUsers().then();
  }, [getUsers, sortType]);

  return (
    <>
      <MenuContainer />
      <StyledLeaderboardContainer container>
        <BoldTitle placement="leaderboard">
          {t('Leaderboard')}
        </BoldTitle>
        <Grid item container justifyContent="left" wrap="nowrap">
          <Grid item>
            <DropDown
              title={t('Rank by')}
              currentItem={sortType}
              items={Object.keys(sortTypesDictionary)}
              changeItem={(event) => setSortType(event.target.value as SortType)}
            />
          </Grid>
          <Grid item>
            <SeatchIconContainer onClick={openLeaderboardSearchModal}>
              <SearchIcon color="#5900F7" />
            </SeatchIconContainer>
          </Grid>
        </Grid>
        {
          [1, 2, 3].map((place) => (
            <Grid item style={{ width: '100%' }}>
              <LeaderCard place={place} isCurrentUser={false} />
            </Grid>
          ))
        }
        <Grid item style={{ width: '100%' }}>
          <PersonCard />
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          {leaderboardState.users.length > 10 && (
          <ContentPagination
            total={leaderboardState.users.length}
            changePage={changePage}
          />
          )}
        </Grid>
      </StyledLeaderboardContainer>
    </>
  );
};
export default LeaderboardContainer;
