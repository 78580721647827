import React, { ReactElement, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ReferalOurAdvantagesButton,
  ReferalOurAdvantagesCard,
  ReferalOurAdvantagesCardIcon,
  ReferalOurAdvantagesCardText,
  ReferalOurAdvantagesContainer,
  ReferalOurAdvantagesText,
  ReferalOutAdvantagesPage,
} from 'styles/mobile/StyledReferal';
import firstIcon from 'static/images/referal_advantages_icon_1.svg';
import secondIcon from 'static/images/referal_advantages_icon_2.svg';
import thirdIcon from 'static/images/referal_advantages_icon_3.svg';
import fourthIcon from 'static/images/referal_advantages_icon_4.svg';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';

const ReferralOurAdvantages: React.FC = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useGlobalState();
  const { modalsState, modalDispatch } = useModalState();
  const { i18n, t } = useTranslation(['referral']);

  const openReferalConnectAWalletModal = () => {
    modalDispatch({
      type: 'OPEN_REFERAL_CONNECT_MODAL',
    });
  };

  const openReferalRecommendModal = () => {
    modalDispatch({
      type: 'OPEN_REFERAL_RECOMMEND_MODAL',
    });
  };

  const openModal = () => {
    if (state.contract && state.networkProvider && state.account) {
      return openReferalRecommendModal();
    }
    return openReferalConnectAWalletModal();
  };

  useEffect(() => {
    if (modalsState.openedReferalConnectModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open referral connect modal',
        },
      });
    }

    setOpen(modalsState.openedReferalConnectModal);
  }, [modalsState.openedReferalConnectModal]);

  useEffect(() => {
    if (modalsState.openedReferalRecommendModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open referral recommend modal',
        },
      });
    }

    setOpen(modalsState.openedReferalRecommendModal);
  }, [modalsState.openedReferalRecommendModal]);

  return (
    <ReferalOutAdvantagesPage>
      <ReferalOurAdvantagesContainer>
        <ReferalOurAdvantagesText>
          {t('Our advantages')}
        </ReferalOurAdvantagesText>

        <Grid container justifyContent="space-evenly">
          <ReferalOurAdvantagesCard>
            <Grid item>
              <ReferalOurAdvantagesCardIcon src={firstIcon} />
            </Grid>
            <Grid item>
              <ReferalOurAdvantagesCardText>
                {t('No registration required')}
              </ReferalOurAdvantagesCardText>
            </Grid>
          </ReferalOurAdvantagesCard>

          <ReferalOurAdvantagesCard>
            <Grid item>
              <ReferalOurAdvantagesCardIcon src={secondIcon} />
            </Grid>
            <Grid item>
              <ReferalOurAdvantagesCardText>
                {t('Stable payments')}
              </ReferalOurAdvantagesCardText>
            </Grid>
          </ReferalOurAdvantagesCard>

          <ReferalOurAdvantagesCard>
            <Grid item>
              <ReferalOurAdvantagesCardIcon src={thirdIcon} />
            </Grid>
            <Grid item>
              <ReferalOurAdvantagesCardText>
                {t('Extension of the referral program')}
              </ReferalOurAdvantagesCardText>
            </Grid>
          </ReferalOurAdvantagesCard>

          <ReferalOurAdvantagesCard>
            <Grid item>
              <ReferalOurAdvantagesCardIcon src={fourthIcon} />
            </Grid>
            <Grid item>
              <ReferalOurAdvantagesCardText>
                {t('Bonus tasks')}
              </ReferalOurAdvantagesCardText>
            </Grid>
          </ReferalOurAdvantagesCard>
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
        >
          <ReferalOurAdvantagesButton onClick={() => openModal()}>
            {t('join the program')}
          </ReferalOurAdvantagesButton>
        </Grid>

      </ReferalOurAdvantagesContainer>
    </ReferalOutAdvantagesPage>

  );
};

export default ReferralOurAdvantages;
