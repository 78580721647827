import BNB from 'static/images/currency_icons/BNB_icon_white.svg';
import PROS from 'static/images/currency_icons/PROS_icon_white.svg';
import USDC from 'static/images/currency_icons/USDC_icon_white.svg';
import BTC from 'static/images/currency_icons/BTC_icon_white.svg';
import REVV from 'static/images/currency_icons/REVV_icon_white.svg';
import { IIcons } from 'interfaces/common.d';

const icons: IIcons = {
  PROS,
  USDC,
  BNB,
  BTC,
  REVV,
};

export default icons;
