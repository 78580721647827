import React, {
  ReactElement,
  useState,
  useEffect,
} from 'react';
import SideButton from 'components/mobile/common/ActualPoolSideButton';
import { useActualPoolState } from 'context/actualPoolState';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
import { useCurrentBlockContext } from 'context/currentBlockContext';
import Button from 'components/common/CustomButton';
// styles
import BodyContainer, {
  ConditionPriceContainer,
  ConditionPriceTitle,
  ConditionPrice,
} from 'styles/mobile/StyledActualPoolBody';

const BetContainer: React.FC = (): ReactElement => {
  const [selectedSide, setSelectedSide] = useState<number | null>(null);
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const { actualPoolState } = useActualPoolState();
  const { modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t } = useTranslation(['actual_pool']);

  const openBetModal = (): void => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform predict click',
      },
    });

    modalDispatch({
      type: 'OPEN_BET_MODAL',
      payload: {
        betModalData: {
          ...actualPoolState,
          chosenSide: selectedSide,
        },
      },
    });
  };

  const changeSelectedSide = (sideNumber: number): void => {
    setSelectedSide(sideNumber);
  };

  const isDeadlineExpired = (aceptanceEndBlock: number, deadline: Date):
    boolean => aceptanceEndBlock < currentBlock || deadline < new Date();

  const isButtonDisabled = (): boolean => isDeadlineExpired(
    actualPoolState.actualPool.start
    + actualPoolState.actualPool.startPeriod,
    actualPoolState.deadline,
  ) || !state.account || +window.ethereum.chainId !== state.currentNetworkId;

  useEffect(() => {
    getCurrentBlock().then(setCurrentBlock);
    const intervalId = setInterval(() => {
      getCurrentBlock().then(setCurrentBlock);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <BodyContainer container direction="column">
      <SideButton
        isBull
        currency={actualPoolState.token.displayName}
        selected={selectedSide === 0}
        onClick={() => changeSelectedSide(0)}
      />
      <SideButton
        isBull={false}
        currency={actualPoolState.token.displayName}
        selected={selectedSide === 1}
        onClick={() => changeSelectedSide(1)}
      />
      <ConditionPriceContainer container justifyContent="center">
        <ConditionPriceTitle>{t('conditionalPrice')}</ConditionPriceTitle>
        <ConditionPrice>{`$ ${actualPoolState.conditionPrice}`}</ConditionPrice>
      </ConditionPriceContainer>
      <Button disabledButton={isButtonDisabled()} onClick={openBetModal}>
        {t('common:Predict')}
      </Button>
    </BodyContainer>
  );
};

export default BetContainer;
