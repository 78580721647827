import React, { ReactElement } from 'react';
import { useStatisticsState } from 'context/statisticsState';
import { useTranslation } from 'react-i18next';
import { numberToFixedNumber } from 'numbersHelper';
import Row from './StatisticsModalRow';

const RoundsData: React.FC = (): ReactElement => {
  const { statisticsState } = useStatisticsState();
  const { t } = useTranslation(['modals']);
  const { rounds } = statisticsState;

  const getSumRounds = () => rounds.wonRounds + rounds.lostRounds;

  const getWonRoundsPercent = () => (
    numberToFixedNumber(((rounds.wonRounds / getSumRounds()) || 0) * 100)
  );

  const getLostRoundsPercent = () => (
    numberToFixedNumber(((rounds.lostRounds / getSumRounds()) || 0) * 100)
  );

  const convertMoneyValueToFixedFormat = (resultValue : number)
      : number => +(resultValue * statisticsState.tokenCost).toFixed(2);

  return (
    <>
      <Row
        title={t('Won')}
        color="green"
        rounds={rounds.wonRounds}
        difference={rounds.wonMoney}
        percent={getWonRoundsPercent()}
        differenceUsd={convertMoneyValueToFixedFormat(rounds.wonMoney)}
      />

      <Row
        title={t('Lost')}
        color="red"
        rounds={rounds.lostRounds}
        difference={rounds.lostMoney}
        percent={getLostRoundsPercent()}
        differenceUsd={convertMoneyValueToFixedFormat(rounds.lostMoney)}
      />
      <Row
        title={t('Entered')}
        color="yellow"
        rounds={getSumRounds()}
        difference={rounds.overallMoney}
        differenceUsd={convertMoneyValueToFixedFormat(rounds.overallMoney)}
      />
    </>
  );
};

export default RoundsData;
