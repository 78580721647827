import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Grid,
} from '@mui/material';
import axios from 'axios';
import { useReferralPrivateOfficeState } from 'context/referralPrivateOfficeState';
import { useGlobalState } from 'context/globalState';
import {
  IReferralPrivateOfficeState,
} from 'interfaces/state.d';
// icons
import { ReactComponent as BNB } from 'static/images/currency_icons/BNB_icon_white.svg';
import { ReactComponent as Info } from 'static/images/common_icons/timeframe_icon.svg';
// styles
import {
  TotalEarningsContainer,
  TotalEarningsItemHint,
  TotalEarningsTitle,
  TotalEarningsInnerContainer,
} from 'styles/desktop/StyledReferralPrivateOffice';
// components
import Item from './TotalEarningsItem';

const TotalEarnings: React.FC = (): ReactElement => {
  const [allTimeAmount, setAllTimeAmount] = useState<string | null>(null);
  const [monthAmount, setMonthAmount] = useState<string | null>(null);
  const [weekAmount, setWeekAmount] = useState<string | null>(null);
  const {
    referralPrivateOfficeState,
    referralPrivateOfficeDispatch,
  } = useReferralPrivateOfficeState();
  const { state } = useGlobalState();

  const changeFilter = (newFilter: IReferralPrivateOfficeState['filter']) => {
    referralPrivateOfficeDispatch({
      type: 'SET_FILTER',
      payload: {
        ...referralPrivateOfficeState,
        filter: newFilter,
      },
    });
  };

  const getAmount = useCallback(async (period: 'all time' | 'month' | 'week', isPaid: boolean) => {
    const periodDArray = [
      'week',
      'month',
      'all time',
    ];
    try {
      const amountResponse = await axios.get(`${state.baseUrl}/referral-payout/total`, {
        params: {
          networkId: state.currentNetworkId,
          period: periodDArray.indexOf(period),
          wallet: state.account.toLowerCase(),
          isPaid,
        },
      });

      if (typeof amountResponse.data === 'number') {
        return (+amountResponse.data.toFixed(4)).toString();
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong while getting all time amount ', e);
    }
    return '-';
  }, [state.baseUrl, state.currentNetworkId, state.account]);

  useEffect(() => {
    if (typeof allTimeAmount !== 'string' && state.account) {
      getAmount('all time', true).then(
        (amount) => setAllTimeAmount(amount),
      );
    }
  }, [allTimeAmount, state.account]);

  useEffect(() => {
    if (typeof monthAmount !== 'string' && state.account) {
      getAmount('month', true).then(
        (amount) => setMonthAmount(amount),
      );
    }
  }, [monthAmount, state.account]);

  useEffect(() => {
    if (typeof weekAmount !== 'string' && state.account) {
      getAmount('week', true).then(
        (amount) => setWeekAmount(amount),
      );
    }
  }, [weekAmount, state.account]);

  useEffect(() => {
    if (typeof referralPrivateOfficeState.unpaidAmount !== 'string' && state.account) {
      getAmount('all time', false).then(
        (amount) => referralPrivateOfficeDispatch({
          type: 'SET_UNPAID_AMOUNT',
          payload: {
            ...referralPrivateOfficeState,
            unpaidAmount: amount,
          },
        }),
      );
    }
  }, [referralPrivateOfficeState.unpaidAmount, state.account]);

  return (
    <TotalEarningsContainer>
      <TotalEarningsInnerContainer>
        <TotalEarningsTitle>total earnings</TotalEarningsTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={3}>
            <Item
              active={referralPrivateOfficeState.filter === 'all time'}
              title="All time"
              amount={allTimeAmount || '-'}
              onClick={() => changeFilter('all time')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Item
              active={referralPrivateOfficeState.filter === 'month'}
              title="Month"
              amount={monthAmount || '-'}
              onClick={() => changeFilter('month')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Item
              active={referralPrivateOfficeState.filter === 'week'}
              title="Week"
              amount={weekAmount || '-'}
              onClick={() => changeFilter('week')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Item
              active={referralPrivateOfficeState.filter === 'unpaid'}
              title="Unpaid amount"
              amount={referralPrivateOfficeState.unpaidAmount || '-'}
              onClick={() => changeFilter('unpaid')}
            />
          </Grid>
        </Grid>
        <TotalEarningsItemHint>
          <Info height={20} width={20} />
          Payments are made from 0.1
          <BNB height={20} width={20} />
        </TotalEarningsItemHint>
      </TotalEarningsInnerContainer>
    </TotalEarningsContainer>
  );
};

export default TotalEarnings;
