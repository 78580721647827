import React, { ReactElement } from 'react';
import {
  ReferalProfitContainer,
  ReferalProfitSvgContentContainer,
  ReferalProfitTitle,
  ProfitBigRectangle,
  ProfitSmallRectangle,
  ReferralProfitPersonIcon,
  BigPlanet,
  MiddlePlanet,
  SmallPlanet,
  MiddleText,
  ReferralProfitContainer,
} from 'styles/desktop/StyledReferal';
import boyIcon from 'static/images/referral-boy.svg';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReferralProfit: React.FC = (): ReactElement => {
  const { i18n, t } = useTranslation(['referral']);

  return (
    <ReferalProfitContainer>
      <ReferalProfitTitle>
        {t('The profit of the referral program ')}
      </ReferalProfitTitle>
      <ReferralProfitContainer item container direction="row" wrap="nowrap">
        <ReferalProfitSvgContentContainer container item justifyContent="space-evenly">
          <BigPlanet>
            {t('You')}
          </BigPlanet>

          <MiddlePlanet>
            {t('Percentage of the invitee')}
            <MiddleText>+30%</MiddleText>
          </MiddlePlanet>

          <SmallPlanet />

        </ReferalProfitSvgContentContainer>
        <ReferalProfitSvgContentContainer
          container
          item
          justifyContent="flex-start"
          flex-direction="row-reverse"
        >
          <Grid item>
            <ProfitBigRectangle>
              {t('We suggest')}
            </ProfitBigRectangle>
            <ProfitSmallRectangle>
              {t('Try to calculate')}
            </ProfitSmallRectangle>
          </Grid>

          <Grid item>
            <ReferralProfitPersonIcon src={boyIcon} />
          </Grid>
        </ReferalProfitSvgContentContainer>
      </ReferralProfitContainer>

    </ReferalProfitContainer>
  );
};

export default ReferralProfit;
