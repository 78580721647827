import React, { ReactElement } from 'react';
import { ReactComponent as LinkIcon } from 'static/images/common_icons/link_icon.svg';
// styles
import {
  StyledSideMenuLink,
} from 'styles/mobile/StyledSideMenu';

interface IProps {
  children: string | React.ReactNode;
  redirect: (event: React.KeyboardEvent
    | React.MouseEvent
    | React.SyntheticEvent<{}, Event>) => void;
}

const SideMenuLink: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    children,
    redirect,
  } = props;

  return (
    <StyledSideMenuLink
      onClick={redirect}
      container
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <span>{children}</span>
      <span><LinkIcon /></span>
    </StyledSideMenuLink>
  );
};

export default SideMenuLink;
