import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
// styles
import {
  MiningContentContainer,
  MiningTitle,
  StageDataContainer,
  MiningMobileStatisticsContainer,
  PredictButtonContainer,
} from 'styles/mobile/StyledMining';
import {
  MiningStatisticsColoredContainer,
} from 'styles/desktop/StyledMining';

import Button from 'components/common/CustomButton';
import Header from 'components/mobile/common/MenuContainer';
import StageData from 'components/common/mining_stage_data/DataContainer';
import PersonalData from 'components/common/mining_personal_statistics/PersonalStatisticsContainer';
import TotalData from 'components/common/mining_total_statistics/TotalStatisticsContainer';
import HistoricalData from './HistoricalContainer';

const MiningContainer: React.FC = (): ReactElement => {
  const history = useHistory();

  const redirectToMain = () => {
    history.push('/');
  };

  return (
    <>
      <Header />
      <MiningContentContainer>
        <MiningTitle>Prediction mining</MiningTitle>
        <HistoricalData />
        <StageDataContainer>
          <StageData />
        </StageDataContainer>
        <MiningStatisticsColoredContainer>
          <MiningMobileStatisticsContainer>
            <PersonalData />
          </MiningMobileStatisticsContainer>
        </MiningStatisticsColoredContainer>
        <MiningStatisticsColoredContainer>
          <MiningMobileStatisticsContainer>
            <TotalData />
          </MiningMobileStatisticsContainer>
        </MiningStatisticsColoredContainer>
        <PredictButtonContainer>
          <Button onClick={redirectToMain}>Predict</Button>
        </PredictButtonContainer>
      </MiningContentContainer>
    </>
  );
};

export default MiningContainer;
