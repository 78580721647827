import React, { ReactElement, useEffect } from 'react';
import {
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStatisticsState } from 'context/statisticsState';
// styles
import {
  StatisticsRow,
  StatisticsRowContent,
  StatisticsRowHint,
  StatisticsRowTitle,
} from 'styles/modals/StyledStatisticsModal';

interface IProps {
    color: 'red' | 'green' | 'yellow';
    rounds?: number;
    difference: number;
    percent?: number;
    differenceUsd?: number;
    title: string;
    short?: boolean;
}

const StatisticsModalRow: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    color,
    rounds,
    difference,
    percent,
    differenceUsd,
    title,
    short,
  } = props;

  const { t } = useTranslation(['modals']);
  const { statisticsState } = useStatisticsState();

  const getPercentHint = (): string | typeof t => (color === 'yellow'
    ? t('total')
    : `${percent} %`);

  const getDifferenceSign = (): string => {
    switch (color) {
      case 'green':
        return '+';
      case 'red':
        return '-';
      default:
        return '';
    }
  };

  return (
    <StatisticsRow>
      <Grid container direction="column">
        <Grid item>
          <StatisticsRowTitle>
            {title}
          </StatisticsRowTitle>
        </Grid>
        <Grid item container>
          <Grid item xs={6} hidden={short}>
            <StatisticsRowContent color={color}>
              {`${rounds} ${t('Rounds')}`}
            </StatisticsRowContent>
          </Grid>
          <Grid item xs={6}>
            <StatisticsRowContent color={color}>
              {`${getDifferenceSign()} ${difference} ${statisticsState.token}`}
            </StatisticsRowContent>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={6} hidden={short}>
            <StatisticsRowHint>
              { getPercentHint() }
            </StatisticsRowHint>
          </Grid>
          <Grid item xs={6}>
            <StatisticsRowHint>
              {typeof differenceUsd === 'number' ? `~ $${differenceUsd}` : ''}
            </StatisticsRowHint>
          </Grid>
        </Grid>
      </Grid>
    </StatisticsRow>
  );
};

StatisticsModalRow.defaultProps = {
  percent: 0,
  short: false,
  rounds: 0,
  differenceUsd: 0,
};

export default StatisticsModalRow;
