import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { ReactComponent as Return } from 'static/images/common_icons/return_icon.svg';
// styles
import {
  ReferalMainText,
} from 'styles/mobile/StyledReferal';
import {
  StyledPrivateOfficeContainer,
  StyledLink,
  PayoutTableTitle,
} from 'styles/mobile/StyledReferralPrivateOffice';
import {
  ReferralButtonContainer,
} from 'styles/desktop/StyledReferralPrivateOffice';
// components
import MenuContainer from 'components/mobile/common/MenuContainer';
import TotalEarnings from 'components/desktop/referral/private-office/TotalEarnings';
import PayoutTable from './PayoutTableContainer';
import ReferralCurrentButton from '../../desktop/referral/ReferralCurrentButton';

const PrivateOfficeContainer: React.FC = (): ReactElement => {
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const { state } = useGlobalState();

  const referralInviteLink = useMemo(() => `${state.baseUrl}/referral-link/${referralCode}`, [referralCode, state.baseUrl]);

  const getReferralCode = useCallback(async () => {
    try {
      const referralCodeResponse = await axios.get(`${state.baseUrl}/referral/code?referrerWallet=${state.account.toLowerCase()}`);

      if (referralCodeResponse.data) {
        return referralCodeResponse.data;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wring while getting referral code');
    }

    return '';
  }, [state.account, state.baseUrl]);

  useEffect(() => {
    if (referralCode === null && state.account) {
      getReferralCode().then(
        (code) => setReferralCode(code),
      );
    }
  }, [referralCode, state.account]);

  return (
    <>
      <MenuContainer />
      <StyledPrivateOfficeContainer>
        <StyledLink to="/">
          <Return />
          {' Return'}
        </StyledLink>
        <ReferalMainText>Profile of referral program</ReferalMainText>
        <TotalEarnings />
        <PayoutTable />
        <PayoutTableTitle>personal referral link</PayoutTableTitle>
        <ReferralButtonContainer>
          <ReferralCurrentButton referalLink={referralInviteLink} />
        </ReferralButtonContainer>
      </StyledPrivateOfficeContainer>
    </>
  );
};

export default PrivateOfficeContainer;
