import React, { ReactElement, useState, useEffect } from 'react';
import {
  Slide,
  Grid,
} from '@mui/material';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/CustomButton';
import TwitterButton from 'components/common/TwitterButton';
// styles
import {
  ModalContainer,
  StyledPaper,
  Title,
  Congrats,
  CloseButton,
  PaperContainer,
} from 'styles/modals/StyledMobileYouAreInModal';

const YouAreInModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [isBull, setIsBull] = useState<boolean | undefined>(undefined);

  const { modalsState, modalDispatch } = useModalState();
  const { dispatch } = useGlobalState();
  const { t } = useTranslation('modals');

  const handleClose = () => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform predict more click',
        amplitudeProps: {
          is_bull: isBull,
        },
      },
    });
    modalDispatch({ type: 'CLOSE_YOU_ARE_IN_MODAL', payload: { openedYouAreInModal: false, youAreInModalData: {} } });
  };

  const twitterShare = () => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform share on twitter click',
        amplitudeProps: {
          is_bull: isBull,
        },
      },
    });
    window.open(`https://twitter.com/intent/tweet?url=https://prosper.so/${isBull ? 'bull' : 'bear'}&hashtags=PredictOnProsper,Prosper,AXS,Axie,PROS&via=Prosperpredict&text=I just predicted $AXS is ${isBull ? 'Bullish' : 'Bearish'} in the next hour on Prosper's Prediction platform! Make your own prediction on platform.prosper.so (and grab your chance to win $60)!`);
  };

  useEffect(() => {
    setOpen(modalsState.openedYouAreInModal);
    if (typeof modalsState.youAreInModalData.isBull === 'boolean') {
      setIsBull(modalsState.youAreInModalData.isBull);
    }
  }, [modalsState]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Slide direction="up" in={open}>
        <StyledPaper bull={(!!isBull).toString()}>
          <PaperContainer
            container
            justifyContent="space-between"
            direction="column"
          >
            <Grid item>
              <Grid container direction="column">
                <Grid item container justifyContent="flex-end">
                  <CloseButton onClick={handleClose}>{`${t('Close')} X`}</CloseButton>
                </Grid>
                <Grid item>
                  <Title>{`${t('You are in')}!`}</Title>
                </Grid>
                <Grid item>
                  <Congrats>
                    {`${t('Successfully made your prediction')}. `}
                    <br />
                    {`${t('We assume you to predict more via button below')}.`}
                  </Congrats>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button onClick={handleClose}>
                {t('Predict more')}
              </Button>
              <TwitterButton onClick={twitterShare}>{t('share on twitter')}</TwitterButton>
            </Grid>
          </PaperContainer>
        </StyledPaper>
      </Slide>
    </ModalContainer>
  );
};

export default YouAreInModal;
