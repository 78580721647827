import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { ReferalOurAdvantagesButton } from '../../../styles/desktop/StyledReferal';
import { ReferalSnackbar } from '../../../styles/common/StyledReferalFollowedTheLInk';
import { useGlobalState } from '../../../context/globalState';

interface IParams{
    referrerCode : string;
}

const ReferralFollowedSnackbar = () => {
  const [open, setOpen] = React.useState(false);
  const { referrerCode }: IParams = useParams();
  const { state } = useGlobalState();
  const { i18n, t } = useTranslation(['referral']);
  const [status, setStatus] = useState<number>(500);
  const history = useHistory();

  const getReferalEndpointStatusCode = async () => {
    try {
      const response = await axios.post(`${state.baseUrl}/referral`, {
        referrerCode,
        referralWallet: state.account.toLowerCase(),
      });
      setStatus(response.status);
    } catch (error : any) {
      try {
        setStatus(error.response.status);
        setOpen(true);
      } catch (e) {
        setStatus(500);
        setOpen(true);
      }
    }
  };

  const requestHandler = useCallback(async () => {
    if (state.account) {
      await getReferalEndpointStatusCode();
    }
  }, [state.account]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (status === 200) {
      history.push('/');
    }
  }, [status]);

  return (
    <div>
      <ReferalOurAdvantagesButton
        onClick={requestHandler}
      >
        {t('Connect')}
      </ReferalOurAdvantagesButton>
      <ReferalSnackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        {status === 400
          ? <Alert severity="error">{t('Invalid referrer wallet!')}</Alert>
          : <Alert severity="error">{t('You already have a referrer!')}</Alert>}

      </ReferalSnackbar>
    </div>
  );
};

export default ReferralFollowedSnackbar;
