import React, { ReactElement } from 'react';
import {
  Grid,
  TableBody,
  TableRow,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useReferralPrivateOfficeState } from 'context/referralPrivateOfficeState';
// icons
import BNB from 'static/images/currency_icons/BNB_icon.svg';
// styles
import {
  StyledTableContainer,
  StyledTableHeader as TH,
} from 'styles/desktop/StyledClosedPools';
import {
  StyledTableContent,
  StyledRow as TR,
  StyledTableCell as TC,
  StatusCell,
} from 'styles/desktop/StyledReferralPrivateOffice';
import { IconImage } from 'styles/common/StyledPoolBlock';

const TableContent: React.FC = (): ReactElement => {
  const { referralPrivateOfficeState } = useReferralPrivateOfficeState();

  const shortWallet = (wallet: string): string => `${wallet.slice(0, 6)}...${wallet.slice(-4)}`;

  const calculatedAmount = (amount: number): string => {
    if (amount) {
      if (amount >= 0.01) {
        const fractionDigits = 2;
        const multiplier = 10 ** fractionDigits;
        const fixedNumber = Math.floor(amount * multiplier);
        const numberToReturn = fixedNumber / multiplier;
        return numberToReturn.toString();
      }
      return '< 0.01';
    }
    return '0';
  };

  return (
    <StyledTableContainer>
      <StyledTableContent>
        <TH>
          <TableRow>
            <TC>Date</TC>
            <TC align="center">Amount</TC>
            <TC align="right">Status</TC>
          </TableRow>
        </TH>
        <TableBody>
          {
            referralPrivateOfficeState.payouts.slice(
              (referralPrivateOfficeState.tablePage - 1) * 7,
              referralPrivateOfficeState.tablePage * 7,
            ).map((payout) => (
              <TR key={payout.id}>
                <TC align="left">
                  {payout.date || '-'}
                </TC>
                <TC align="center">
                  <Grid container alignItems="center" justifyContent="center">
                    <IconImage
                      alt="icon"
                      height={isMobile ? 16 : 18}
                      src={
                      BNB
                    }
                    />
                    {calculatedAmount(payout.amount)}
                  </Grid>
                </TC>
                <StatusCell align="right" paid={payout.status.toString()}>
                  {payout.status ? 'Paid' : 'Unpaid'}
                </StatusCell>
              </TR>
            ))
        }
        </TableBody>
      </StyledTableContent>
    </StyledTableContainer>
  );
};

export default TableContent;
