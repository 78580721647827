import React, { ReactElement, useState, useEffect } from 'react';
import { ReactComponent as MenuIcon } from 'static/images/mobile_menu.svg';
import { ReactComponent as SmallLogo } from 'static/images/logo_small.svg';
import { ReactComponent as Fire } from 'static/images/common_icons/fire_icon.svg';
import Account from 'components/common/Account';
import Button from 'components/common/CustomButton';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import { useTranslation } from 'react-i18next';
// styles
import {
  Header,
  LogoContainer,
} from 'styles/mobile/StyledMenu';
import {
  StatisticsFireContainer,
} from 'styles/desktop/StyledHeader';
import {
  Grid,
} from '@mui/material';
import { getCurrencyName } from 'networksHelper';
import SideMenu from '../side_menu/SideMenu';

const MenuContainer: React.FC = (): ReactElement => {
  const [currency, setCurrency] = useState<'BNB' | 'ETH' | 'OKT' | null>(null);
  const [openedMenu, setOpenedMenu] = useState<boolean>(false);
  const [amount, setAmount] = useState<Number>(0);
  const { state, dispatch } = useGlobalState();
  const { modalsState, modalDispatch } = useModalState();
  const { t } = useTranslation();

  const connect = async (): Promise<void> => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform connect wallet click',
        amplitudeProps: {
          metamaskInstalled: window.ethereum && window.ethereum.isMetaMask,
          trustWalletInstalled: window.ethereum && window.ethereum.isTrustWallet,
        },
      },
    });

    if (!(window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet))) {
      window.location.href = 'https://metamask.io';
    }
    let accounts: Array<string> = [];
    const networkId: number | null = state.currentNetworkId;

    if (state.networkProvider) {
      accounts = await state.networkProvider.listAccounts();
    }

    if (accounts.length < 1) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.location.reload();
    }

    if (+window.ethereum.chainId !== networkId) {
      modalDispatch({ type: 'OPEN_WRONG_NETWORK_MODAL' });
    }
  };

  const toggleMenu = (open: boolean,
    event: React.KeyboardEvent| React.MouseEvent | React.SyntheticEvent<{}, Event>) => {
    if (
      event
      && event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenedMenu(open);
  };

  const openStatistics = () => {
    modalDispatch({
      type: 'OPEN_STATISTICS_MODAL',
    });
  };

  useEffect(() => {
    setCurrency(getCurrencyName(state.currentNetworkId));
  }, [state.currentNetworkId]);

  // fixed user balance to 4 signs if he has it
  useEffect(() => {
    if (state.balance) {
      const balance: number = +state.balance.toFixed(4);
      setAmount(balance);
    }
  }, [state.balance]);

  return (
    <Header>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <MenuIcon onClick={(e) => toggleMenu(true, e)} />
            <LogoContainer>
              <SmallLogo />
            </LogoContainer>
          </Grid>
        </Grid>
        <Grid item>
          {
            state.account && state.currentNetworkId === +window.ethereum.chainId
              ? (
                <Grid container wrap="nowrap" alignItems="center">
                  <StatisticsFireContainer
                    golden={modalsState.openedStatisticsModal}
                    onClick={openStatistics}
                  >
                    <Fire />
                  </StatisticsFireContainer>
                  <Account currency={currency} amount={amount} />
                </Grid>
              )
              : (
                <Button secondary onClick={connect}>
                  {t('Connect wallet')}
                </Button>
              )
          }
        </Grid>
      </Grid>
      <SideMenu
        openedMenu={openedMenu}
        toggleMenu={toggleMenu}
        currency={currency}
        amount={amount}
      />
    </Header>
  );
};

export default MenuContainer;
