import React, { ReactElement, useEffect, useState } from 'react';
import { Backdrop, Grid } from '@mui/material';
import ReferralCurrentButton from 'components/desktop/referral/ReferralCurrentButton';
import RecommendGirl from 'static/images/recommendModalGirl.svg';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ReferalConnectRecommendImg,
  ReferalConnectWalletImgContainer,
  ReferalConnectWalletModalContainer,
  ReferalConnectWalletTextContainer,
  ReferalConnectWalletTextSubtitle,
  ReferalRecommendedLink,
  ReferalRecommendTextTitle,
} from '../../../styles/common/StyledReferalModals';

const ReferralRecommendModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);
  const [referrerCode, setReferrerCode] = useState<string>('');
  const { state, dispatch } = useGlobalState();
  const { modalsState, modalDispatch } = useModalState();
  const { i18n, t } = useTranslation(['referral']);

  const createReferalLink = () : string => `${state.baseUrl}/referral-link/${referrerCode}`;

  const getReferralCode = async () => {
    try {
      const referralCode = await axios.get(`${state.baseUrl}/referral/code?referrerWallet=${state.account.toLowerCase()}`);
      setReferrerCode(referralCode.data);
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong in getReferralCode');
    }
  };
  const handleClose = () => {
    modalDispatch({
      type: 'CLOSE_REFERAL_RECOMMEND_MODAL',
    });
  };

  useEffect(() => {
    if (modalsState.openedReferalRecommendModal) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'open referral recommend modal',
        },
      });
    }
    setOpen(modalsState.openedReferalRecommendModal);
  }, [modalsState.openedReferalRecommendModal]);

  useEffect(() => {
    if (state.account) {
      getReferralCode().then((r) => r);
    }
  }, [state.account]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <ReferalConnectWalletModalContainer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Grid item container wrap="nowrap">
          <ReferalConnectWalletTextContainer
            item
            container
            direction="column"
            wrap="wrap"
          >
            <ReferalRecommendTextTitle item>
              {t('Recommend Prosper and earn money')}
            </ReferalRecommendTextTitle>
            <ReferalConnectWalletTextSubtitle item>
              <ReferalRecommendedLink>{createReferalLink()}</ReferalRecommendedLink>
            </ReferalConnectWalletTextSubtitle>
            <ReferralCurrentButton referalLink={createReferalLink()} />
          </ReferalConnectWalletTextContainer>
          <ReferalConnectWalletImgContainer item>
            <ReferalConnectRecommendImg src={RecommendGirl} />
          </ReferalConnectWalletImgContainer>
        </Grid>
      </ReferalConnectWalletModalContainer>
    </Grid>
  );
};

export default ReferralRecommendModal;
