import React, {
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import axios from 'axios';
import {
  Grid,
} from '@mui/material';
import { useGlobalState } from 'context/globalState';
import { useReferralPrivateOfficeState } from 'context/referralPrivateOfficeState';
import { IPayout } from 'interfaces/state.d';
// styles
import {
  DownloadButton,
  DownloadSpan,
  TableContainer,
} from 'styles/desktop/StyledReferralPrivateOffice';
// components
import BoldTitle from 'components/common/BoldTitle';
import Pagination from 'components/desktop/pools/table/content/ContentPagination';
import Table from './TableContent';

const PayoutTable: React.FC = (): ReactElement => {
  const { state } = useGlobalState();
  const {
    referralPrivateOfficeState,
    referralPrivateOfficeDispatch,
  } = useReferralPrivateOfficeState();

  const changePage = (newPage: number) => {
    referralPrivateOfficeDispatch({
      type: 'SET_TABLE_PAGE',
      payload: {
        ...referralPrivateOfficeState,
        tablePage: newPage,
      },
    });
  };

  const getPayouts = useCallback(async () => {
    if (referralPrivateOfficeState.filter === 'unpaid') {
      return [{
        amount: referralPrivateOfficeState.unpaidAmount,
        date: null,
      }];
    }

    const periodDictionary = {
      week: 0,
      month: 1,
      'all time': 2,
      unpaid: 2,
    };
    const period = periodDictionary[referralPrivateOfficeState.filter];

    try {
      const payoutsResponse = await axios.get(`${state.baseUrl}/referral-payout`, {
        params: {
          networkId: state.currentNetworkId,
          period,
          wallet: state.account.toLowerCase(),
          isPaid: true,
        },
      });

      if (payoutsResponse.data) {
        return payoutsResponse.data;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong while getting payouts ', e);
    }

    return [];
  }, [state.baseUrl, state.account, referralPrivateOfficeState.filter, state.currentNetworkId]);

  useEffect(() => {
    if (state.account) {
      getPayouts().then(
        (payouts: Array<IPayout>) => {
          referralPrivateOfficeDispatch({
            type: 'SET_PAYOUTS',
            payload: {
              ...referralPrivateOfficeState,
              payouts,
            },
          });
        },
      );
    }
  }, [state.account, referralPrivateOfficeState.filter]);

  return (
    <TableContainer>
      <BoldTitle placement="table">Payout table</BoldTitle>
      <Table />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4} container justifyContent="center">
          <Pagination
            total={referralPrivateOfficeState.payouts.length}
            changePage={changePage}
            currentPage={referralPrivateOfficeState.tablePage}
          />
        </Grid>
      </Grid>
    </TableContainer>
  );
};

export default PayoutTable;
