import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import icons from 'icons';
import { IIcons } from 'interfaces/common.d';
import { ReactComponent as ArrowUp } from 'static/images/arrow_up_green_icon.svg';
// styles
import {
  MiningDataContainer,
  MiningDataTitle,
  MiningDataAmount,
  MiningDataPair,
  MiningDataDifferencePercent,
} from 'styles/desktop/StyledMining';
import { IconImage } from 'styles/common/StyledPoolBlock';

import CircleProgress from 'static/images/images_as_components/CircleProgress';

interface IProps {
  title: string;
  pair?: string;
  amount: string | number;
  token?: string;
  withChart?: boolean;
  isPercent?: boolean;
  difference?: number;
  percent?: number;
}

const MiningDataBlock: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    title,
    pair,
    amount,
    token,
    withChart,
    isPercent,
    difference,
    percent,
  } = props;

  return (
    <Grid item>
      <MiningDataContainer>
        <Grid container>
          <Grid item>
            {
              withChart
              && <CircleProgress progress={percent || 0} />
            }
          </Grid>
          <Grid item>
            <MiningDataPair>
              { pair }
            </MiningDataPair>
            <MiningDataTitle>
              { title }
            </MiningDataTitle>
            <Grid container alignItems="center">
              <MiningDataAmount>
                {
                  token
                  && (
                    <IconImage
                      alt="icon"
                      height={24}
                      width={24}
                      src={icons[token as keyof IIcons]}
                    />
                  )
                }
                { amount }
                { isPercent && ' %' }
              </MiningDataAmount>
              {
                difference
                  ? (
                    <MiningDataDifferencePercent>
                      <ArrowUp />
                      {` ${difference}%`}
                    </MiningDataDifferencePercent>
                  )
                  : ''
              }
            </Grid>
          </Grid>
        </Grid>
      </MiningDataContainer>
    </Grid>
  );
};

MiningDataBlock.defaultProps = {
  pair: '',
  token: '',
  difference: 0,
  withChart: false,
  isPercent: false,
  percent: 0,
};

export default MiningDataBlock;
