import React, { ReactElement, useEffect, useState } from 'react';
import { AdvancedChart } from 'react-tradingview-embed';
import { useGlobalState } from 'context/globalState';
import { ReactComponent as ExpandIcon } from 'static/images/common_icons/expand_icon.svg';
import {
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  ExpandComment,
  ChartHint,
} from 'styles/desktop/StyledClosedPools';
import ChartCollapseItem from './ChartCollapseItem';

const TradingViewChartContainer: React.FC = React.memo((): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { dispatch } = useGlobalState();
  const { t } = useTranslation(['chart']);

  const handleAccordionClick = (): void => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded) {
      dispatch({
        type: 'LOG_AMPLITUDE_ACTION',
        payload: {
          actionName: 'platform show chart',
        },
      });
    }
  }, [expanded]);

  return (
    <StyledAccordion expanded={expanded} onChange={handleAccordionClick}>
      <StyledAccordionSummary expandIcon={<ExpandIcon />}>
        <Grid container justifyContent="space-between" alignItems="center">
          <span>Trading view</span>
          <ExpandComment>
            <ChartCollapseItem expanded={expanded} />
          </ExpandComment>
        </Grid>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Grid container direction="column">
          <AdvancedChart
            widgetProps={{
              theme: 'light',
              symbol: 'BINANCE:AXSUSD',
              interval: '5',
              withdateranges: false,
              hide_top_toolbar: true,
              hide_side_toolbar: true,
              style: '1',
              range: undefined,
            }}
          />
          <ChartHint>
            {`* ${t('chart hint')}.`}
          </ChartHint>
        </Grid>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
});

export default TradingViewChartContainer;
