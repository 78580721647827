import styled from 'styled-components';
import {
  Accordion, AccordionSummary, Grid, Modal,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Snackbar from '@mui/material/Snackbar';
import ReferalAdvantagesBackground from '../../static/images/ReferalAdvantagesBackground.svg';

export const ReferralMobilePage = styled(Grid)`
  width: 100%;
  background-color: #0C0C21;
  padding: 16px;
`;
export const ReferalMainText = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.03em;
  text-shadow: 4px 4px 0px #4919CF;
  
  @media screen and (max-width: 420px) {
    text-align: left;
    font-weight: 600;
  }
`;
export const ReferalMobileSubtitle = styled(Grid)`
  text-align: center;
  font-size: 14px;
  width: 290px;
  padding-bottom: 80px;;`;

export const ReferalSvgContainer = styled(Grid)`
  margin-top: 100px;
`;

export const ReferalManIcon = styled.img`
  position: absolute;
  height: 200px;
  left: -12px;
  top: -159px;
`;

export const ReferalWomenIcon = styled.img`
  position: absolute;
  left: 260px;
  height: 200px;
  top: -159px;
`;

export const ReferalHowItWorksTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1px;
  color: #FFFFFF;
  width: 226px;
  margin-top: 65px;
`;

export const ReferalHowItWorksContainer = styled(Grid)`
  padding-top: 40px;
  max-width: 1440px;
`;

export const ReferalBubble = styled.img`
  background: radial-gradient(122% 26% at 70.4% 31.2%, #3d448a 0%, rgba(0, 2, 16, -57) 37%), #09005E;
  box-shadow: 0px 4.92152px 98.4304px #2300f8, inset 0px 2.46076px 12.3038px rgb(255 255 255 / 0%), inset 12.3038px 19.6861px 24.6076px rgb(130 5 255 / 0%);
  border-radius: 100%;
  height: 75px;
  width: 75px;
`;

export const ReferalArrow = styled.img`
  width: 14.73px;
  height: 32.5px;
`;

export const ReferalHowItWorksText = styled(Grid)`
  font-family: TT Supermolot Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFDD00;
`;

export const ReferalMiddleHowItWorksText = styled(ReferalHowItWorksText)`
`;

export const ReferalLastHowItWorksText = styled(ReferalHowItWorksText)`

`;

export const ReferalOutAdvantagesPage = styled.div`
  background: url(${ReferalAdvantagesBackground}) no-repeat;
  background-position: center;
  width: 100%;
`;
export const ReferalOurAdvantagesContainer = styled(Grid)`
  padding-top: 100px;
  margin: 0 auto;
  max-width: 1440px;
`;

export const ReferalOurAdvantagesText = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 24px;
`;

export const ReferalOurAdvantagesCard = styled(Grid)`
  padding: 35px;
  margin: 30px;
  width: 150px;
  height: 176px;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  outline: #7121f1 1px solid;
  background: rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const ReferalOurAdvantagesCardText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  //display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  width: 130px;
`;

export const ReferalOurAdvantagesCardIcon = styled.img`
  width: 60px;
  height: 60px;
`;

export const ReferalOurAdvantagesButton = styled.button`
  background: linear-gradient(180deg, #FFC700 0%, #FFDD00 100%);
  border-radius: 16px;
  width: 338px;
  height: 63px;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #1A1A39;
  margin-top: 32px;

  &:hover {
    cursor: pointer;
  }

  border: 1px;
`;

export const ReferalProfitContainer = styled(Grid)`
  padding-top: 170px;
  position: relative;
  width: 390px;
  height: 1150px;
  overflow: hidden;
`;

export const ReferalProfitTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
`;

export const ReferalProfitSvgContentContainer = styled.img`
  display: block;
  position: absolute;
  top: 104px;
  left: calc(50% + -254px);
  overflow: hidden;
`;

export const ReferalProfitSvgContentIconWithText = styled.img`
  padding-top: 535px;
`;
export const ReferalProfitCalculatorContainer = styled(Grid)`
  padding-top: 110px;
`;

export const ReferalCalculatorBar = styled(Grid)`
  width: 100%;
  padding-right: 20px;
`;
export const ReferalCalculcatorBox = styled(Grid)`
  width: 329px;
  height: 123px;
  border: 1px solid #FFDD00;
  box-sizing: border-box;
  border-radius: 16px;
`;

export const ReferalCalculatorBoxIncomeText = styled(Grid)`
  padding-top: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const ReferalCalculcatorBoxProsText = styled(Grid)`
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
  color: #FFDD00;
`;

export const ReferalCalculatorBarText = styled(Grid)`
  padding-top: 48px;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const ReferalCalculatorBarAmountText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  width: 300px;
`;

export const AmountValue = styled.p`
  display: inline;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  order: 1;
  flex-grow: 0;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFDD00;
`;

export const ReferalQuestionsAccordion = styled(Accordion)`
  &.MuiPaper-root {
    background: #000523;

  }
`;

export const QuestionLine = styled.div`
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border: 0.5px solid #FFFFFF;
  width: 320px;
`;

export const ReferalTypography = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    width: 320px;
  }
`;

export const ReferalQuestionsContainer = styled(Grid)`
  padding-top: 100px;
  padding-bottom: 170px;
  margin-left: 20px;
`;

export const ReferalQuestionTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding-bottom: 30px;
`;
export const ReferalQuestionSubtitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  padding-bottom: 8px;
`;
export const ReferalQuestionDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  width: 320px;
  padding-bottom: 16px;
`;
export const ReferalQuestionsInfoContainer = styled.div`
  padding-bottom: 16px;
`;
export const ReferalAccordionSummary = styled(AccordionSummary)`
`;

export const ReferalAccordionDetails = styled(MuiAccordionDetails)`
  border-top: 1px solid rgb(0, 0, 0, .125);
`;

export const ReferalModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReferalConnectWalletTextTitle = styled(Grid)`
  font-style: normal;
  font-weight: bold;
  font-size: 29.375px;
  line-height: 34px;
  letter-spacing: 0.03em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
  padding-bottom: 24px;
  text-align: center;
`;

export const ReferalConnectWalletTextContainer = styled(Grid)`
  padding-top: 30px;
`;

export const ReferralFollowedContainer = styled(Grid)`
  padding-left: 18px;
`;

export const ReferalConnectWalletImgContainer = styled(Grid)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
`;

export const ReferalConnectWalletImg = styled.img`
  padding-top: 10px;
  padding-left: 62px;
`;

export const ReferalOurAdvantagesButtonConnect = styled.button`
  background: linear-gradient(180deg, #FFC700 0%, #FFDD00 100%);
  border-radius: 16px;
  width: 338px;
  height: 63px;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #1A1A39;
  margin-top: -6px;
  margin: 0 auto;
  border: 1px;
  &:hover {
    cursor: pointer;
  }

`;

export const CloseButton = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  margin-left: 18px;
`;

export const ReferalModalButton = styled.div`
  margin-left: 10px;
`;

export const ReferalConnectRecommendImg = styled.img`
  margin-left: 155px;
  margin-top: -50px;
`;

export const ReferalRecommendedLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #FFFFFF;
  word-break: break-word;
`;

export const CloseButtonRecommend = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  margin-left: 298px;
`;

export const FollowedTheLinkContainer = styled.div`
  padding-top: 100px;
  margin: 0 25% 0 25%;
  width: 50%;
`;

export const ReferalSnackbar = styled(Snackbar)`
  color: white;
`;

export const ReferalOurAdvantagesButtonMobile = styled(ReferalOurAdvantagesButton)`
  margin-left: 28px;
`;
