/* eslint-disable no-console */
import React, { ReactElement, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import { IEpoch } from 'interfaces/common.d';

import { useGlobalState } from 'context/globalState';
import { useMiningState } from 'context/miningState';
import { useTranslation } from 'react-i18next';

import HistoricalContainer from './HistoricalContainer';
import DataContainer from './DataContainer';

const StageDataContainer: React.FC = (): ReactElement => {
  const { state } = useGlobalState();
  const { miningState, miningDispatch } = useMiningState();
  const { i18n } = useTranslation();

  const getLocaleDateTime = (date: string): string => {
    const convertedDate = new Date(date);
    const localeDate = convertedDate.toLocaleDateString(i18n.language);

    return `${localeDate}`;
  };

  const getAllMiningEpochs = useCallback(async () => {
    const queryParams = {
      networkId: state.currentNetworkId,
    };
    try {
      const miningEpochsResponse = await axios.get(`${state.baseUrl}/mining-data`, { params: queryParams });
      if (miningEpochsResponse.data.length) {
        await miningDispatch({
          type: 'SET_EPOCHS_DATA',
          payload: {
            ...miningState,
            epochsData: miningEpochsResponse.data.map((epoch: IEpoch) => ({
              networkId: epoch.networkId,
              stage: epoch.id,
            })),
          },
        });
        await miningDispatch({
          type: 'SWITCH_STAGE',
          payload: {
            ...miningState,
            stage: miningEpochsResponse.data[0]?.id,
          },
        });
        await miningDispatch({
          type: 'SWITCH_NETWORK',
          payload: {
            ...miningState,
            network: miningEpochsResponse.data[0]?.networkId,
          },
        });
      }
    } catch (e) {
      console.log('Something went wrong during getting epochs data');
    }
  }, [state.baseUrl, state.currentNetworkId]);

  const getStageData = useCallback(async () => {
    const queryParams = {
      networkId: state.currentNetworkId,
      stage: miningState.stage,
    };
    try {
      const epochStageResponse = await axios.get(`${state.baseUrl}/mining-data`, { params: queryParams });
      if (epochStageResponse.data) {
        miningDispatch({
          type: 'SET_STAGE_DATA',
          payload: {
            ...miningState,
            stageData: {
              stageDate: `${getLocaleDateTime(epochStageResponse.data.startDate)} - ${getLocaleDateTime(epochStageResponse.data.endDate)}`,
              stageTotalRewardPool: epochStageResponse.data.allocated || 0,
              stageDailyRewardPools: epochStageResponse.data.pools,
            },
          },
        });
      }
    } catch (e) {
      console.log('something went wrong during getting stage information');
    }
  }, [state.baseUrl, state.currentNetworkId, miningState.stage]);

  const getHistoricalData = useCallback(async () => {
    const queryParams = {
      networkId: state.currentNetworkId,
    };
    try {
      const historicalDataResponse = await axios.get(`${state.baseUrl}/mining-data/historical`, { params: queryParams });
      if (historicalDataResponse.data) {
        miningDispatch({
          type: 'SET_HISTORICAL_AMOUNT',
          payload: {
            ...miningState,
            historicalPredictionVolume: historicalDataResponse.data,
          },
        });
      }
    } catch (e) {
      console.log('Something went wrong during getting historical information');
    }
  }, [state.baseUrl, state.currentNetworkId]);

  useEffect(() => {
    if (!miningState.epochsData) {
      getAllMiningEpochs();
    }
  }, [miningState.epochsData]);

  useEffect(() => {
    getStageData();
  }, [miningState.stage, miningState.network]);

  useEffect(() => {
    getHistoricalData();
  }, []);

  return (
    <Grid container>
      <Grid item xs={4}>
        <HistoricalContainer />
      </Grid>
      <Grid item xs={8}>
        <DataContainer />
      </Grid>
    </Grid>
  );
};

export default StageDataContainer;
