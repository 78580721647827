import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Slide,
  Grid,
} from '@mui/material';
import axios from 'axios';
import shortMonth from 'languageHelper';
import {
  IPersonalQueryParameters,
} from 'interfaces/common.d';
import { useModalState } from 'context/modalState';
import { useTableState } from 'context/tableState';
import { useGlobalState } from 'context/globalState';
import { useCurrentBlockContext } from 'context/currentBlockContext';
import { useTranslation } from 'react-i18next';
import Search from 'components/common/Search';
import {
  IPool,
  IPoolStatus,
} from 'interfaces/state.d';
import Pool from 'components/common/PoolBlock';
// styles
import {
  ModalContainer,
  StyledPaper,
  Title,
  CloseButton,
  PaperContainer,
  SearchContainer,
} from 'styles/modals/StyledSearchModal';

type PoolSideType = 'BULL' | 'BEAR' | undefined;
type UserSideType = PoolSideType;

const SearchModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [pools, setPools] = useState<Array<IPool> | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const [statusedPools, setStatusedPools] = useState<Array<IPool> | null>(null);
  const { modalsState, modalDispatch } = useModalState();
  const { tableState, tableDispatch } = useTableState();
  const { state, dispatch } = useGlobalState();
  const { getCurrentBlock } = useCurrentBlockContext();
  const { t, i18n } = useTranslation(['modals']);

  const getUserPoolStatus = (priceIsSet: boolean,
    userSide: UserSideType,
    poolSide: PoolSideType): string => {
    if (priceIsSet) {
      if (userSide === poolSide) {
        return t('closed_pools:youWon');
      }
      return t('closed_pools:youLost');
    }
    return `${t('closed_pools:Pending')}...`;
  };

  const getDisplayTime = useCallback((pool: IPool): string => {
    const { start } = pool;
    const diffInBlocks: number = start - currentBlock;
    const diffInSeconds: number = diffInBlocks * state.blockTime;
    const date: Date = new Date(new Date().getTime() + diffInSeconds * 1000);
    const month: string = date.toLocaleString(shortMonth(i18n.language), { month: 'short' });
    let hrs: string = date.getHours().toString();
    if (+hrs < 10) {
      hrs = `0${hrs}`;
    }
    let mins = date.getMinutes().toString();
    if (+mins < 10) {
      mins = `0${mins}`;
    }
    return `${hrs}:${mins} ${date.getDate()} ${month}`;
  }, [currentBlock, state.blockTime]);

  const getPoolSide = (isPriceSet: boolean, bullsWon: boolean): PoolSideType => {
    if (isPriceSet) {
      return bullsWon ? 'BULL' : 'BEAR';
    }
    return undefined;
  };

  const convertPoolsToShow = useCallback((poolsToConvert: Array<IPool>):
    Array<IPool> => poolsToConvert.map((pool) => {
    // old
    // const price: number = formatNumberUnits(pool.price.toString(), 8);
    // new
    const { price }: {price: number} = pool;
    const volume: number = pool.bullsQuote + pool.bearsQuote;
    // old
    // const finalPrice: number = formatNumberUnits((pool.finalPrice || 0).toString(), 8);
    // new
    const finalPrice: number = pool.finalPrice || 0;
    const poolSide: PoolSideType = getPoolSide(pool.isPriceSet, pool.bullsWon);
    const time: string = getDisplayTime(pool);

    const tmpPool = { ...pool };

    tmpPool.price = price;
    tmpPool.volume = parseFloat(volume.toFixed(4));
    tmpPool.finalPrice = finalPrice;
    tmpPool.side = poolSide;
    tmpPool.statusText = t('closed_pools:notParticipated');
    tmpPool.time = time;

    return tmpPool;
  }), [getDisplayTime]);

  const converPoolsToStatus = (poolsToConvert: Array<IPool>,
    poolStatuses: Array<IPoolStatus>): Array<IPool> => {
    poolStatuses.forEach((status) => {
      if (status.userVolume.betAmount) {
        const pool = poolsToConvert.find((poolToConvert) => poolToConvert.id === status.poolId);
        const { userVolume } = status;
        if (pool) {
          pool.prediction = userVolume.betAmount.toFixed(4);
          pool.userSide = getUserSide(userVolume.isBull);
          pool.reward = userVolume.collectAmount.toFixed(4);
          pool.status = getPoolStatus(pool.isPriceSet, pool.side, pool.userSide);
          pool.statusText = getUserPoolStatus(pool.isPriceSet, pool.userSide, pool.side);
        }
      }
    });

    return poolsToConvert;
  };

  const getSearchedPool = useCallback(async (poolId: number): Promise<void> => {
    try {
      setLoading(true);
      const pool = await axios.get(`${state.baseUrl}/pools/${poolId}?networkId=${state.currentNetworkId}`);
      if (pool.data) {
        const poolsToShow = convertPoolsToShow([pool.data as IPool]);
        setPools(poolsToShow);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Some error happened during pool search', e);
    } finally {
      setLoading(false);
    }
  }, [convertPoolsToShow, state.baseUrl, state.currentNetworkId]);

  const getPersonalPools = useCallback(async (poolsToConvert: Array<IPool>,
    baseUrl: string): Promise<void> => {
    const ids: Array<number> = poolsToConvert.map((pool) => pool.id);
    const queryPersonalParams: IPersonalQueryParameters = {
      ids,
      user: state.account,
      networkId: state.currentNetworkId,
    };

    const poolStatuses = await axios.post(`${baseUrl}/users/${queryPersonalParams.user}/user-volume`, {
      ids: queryPersonalParams.ids,
      networkId: queryPersonalParams.networkId,
    });
    const statusedPoolsResponse = converPoolsToStatus(poolsToConvert,
      poolStatuses.data as Array<IPoolStatus>);
    setStatusedPools(statusedPoolsResponse);
  }, [state.account, state.currentNetworkId]);

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_SEARCH_MODAL' });
    tableDispatch({ type: 'SET_SEARCH_ID', payload: { ...tableState, searchId: null } });
  };

  const changeSearchId = (poolId: string): void => {
    setPools(null);
    getSearchedPool(+poolId);

    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'platform use search',
        amplitudeProps: {
          text: poolId,
        },
      },
    });
    tableDispatch({ type: 'SET_SEARCH_ID', payload: { ...tableState, searchId: poolId ? +poolId : null } });
  };

  const getUserSide = (isBull: boolean | null): IPool['userSide'] => {
    if (typeof isBull === 'boolean') {
      return isBull ? 'BULL' : 'BEAR';
    }
    return undefined;
  };

  const getPoolStatus = (isPriceSet: boolean, side: IPool['side'], userSide: IPool['userSide']):
  IPool['status'] => {
    if (isPriceSet) {
      return side === userSide ? 'won' : 'lost';
    }
    return undefined;
  };

  const getPoolData = () => {
    if (loading) {
      return t('loading');
    }
    if (pools) {
      return (pools.map((pool) => (
        <Pool
          id={pool.id}
          creationDate={pool.time || ''}
          pair={state.feeds.find((feed) => (feed.id === pool.feedId))?.displayName || ''}
          condition={pool.price}
          volume={pool.volume}
          betSide={pool.userSide || ''}
          result={pool.side || ''}
          final={pool.finalPrice || '-'}
          prediction={pool.prediction || '-'}
          status={pool.status}
          statusText={pool.statusText || ''}
          timeframe={`1 ${t('common:h')}`}
          reward={!pool.reward || (+(pool.reward as string) <= 0) ? '-' : pool.reward}
          end={pool.end}
          endPeriod={pool.endPeriod}
          currentBlock={currentBlock}
          isPriceSet={pool.isPriceSet}
          bullsQuote={pool.bullsQuote}
          bearsQuote={pool.bearsQuote}
          tokenId={pool.tokenId}
        />
      )));
    }
    if (!loading && !pools && tableState.searchId) {
      return t('Sorry, nothing was found for your request. Try another one.');
    }
    return t('Enter pool ID to find it');
  };

  useEffect(() => {
    if (modalsState.openedSearchModal) {
      getCurrentBlock().then(setCurrentBlock);
    }
    setOpen(modalsState.openedSearchModal);
  }, [modalsState.openedSearchModal]);

  // get statuses when we have pools
  useEffect(() => {
    if (!statusedPools && pools && pools.length && state.account) {
      getPersonalPools(pools, state.baseUrl);
    }
  }, [statusedPools, pools, getPersonalPools,
    state.baseUrl, state.account]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Slide direction="up" in={open}>
        <StyledPaper>
          <PaperContainer
            container
            justifyContent="space-between"
            direction="column"
          >
            <Grid item>
              <Grid container direction="column">
                <Grid item container justifyContent="flex-end">
                  <CloseButton onClick={handleClose}>
                    {`${t('Close')} X`}
                  </CloseButton>
                </Grid>
                <SearchContainer item>
                  <Search doSearch={changeSearchId} />
                </SearchContainer>
                <Grid item>
                  <Title>{t('Searching results')}</Title>
                </Grid>
                <Grid item>
                  {
                    getPoolData()
                  }
                </Grid>
              </Grid>
            </Grid>
          </PaperContainer>
        </StyledPaper>
      </Slide>
    </ModalContainer>
  );
};

export default SearchModal;
